import { SET_VERIFY_DETAILS, SET_VERIFY_OTP_FLAG_FORGOT_EMAIL, SET_VERIFY_OTP_FLAG_FORGOT_MOBILE, SET_SECURITY_QUESTIONS_FOR_RESET_EMAIL,SET_SECURITY_ANSWERS_FOR_RESET_EMAIL } from "../../common/constants/ActionTypes";

const initialState = {
    isVerifyDetails: false,
    verifyOTPFlagForgotEmail: false,
    verifyOTPFlagForgotMobile : false,
    securityQuestionsList : {},
    securityAnswersForEmail : {}
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
        case SET_VERIFY_DETAILS:
            return{
                ...state,
                isVerifyDetails:action.payload
            }
        case SET_VERIFY_OTP_FLAG_FORGOT_EMAIL:
            return {
                ...state,
                verifyOTPFlagForgotEmail: action.payload
            } 
        case SET_VERIFY_OTP_FLAG_FORGOT_MOBILE:
            return {
                ...state,
                verifyOTPFlagForgotMobile: action.payload
            } 
        case SET_SECURITY_QUESTIONS_FOR_RESET_EMAIL:      
            return{
                ...state,
                securityQuestionsList : action.payload
            }
        case SET_SECURITY_ANSWERS_FOR_RESET_EMAIL :
            return{
                ...state,
                securityAnswersForEmail : action.payload
            }    
        default:
            return state;
    }
};
