/* eslint-disable */
import React, { useState, useEffect } from "react";
import numberformat from '../labels/serial_nos_en.json';
import { useForm, Controller } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import profileexperienceinofo from "../labels/experience_information_en.json";
import common from "../labels/common_en.json";
import { saveExperienceList } from '../actions/profileAction';
import { addExperienceList, editExperienceInfo, clearEditExperienceInfo, getAllJobDesignationDetails} from '../actions/profileAction';
import DatePicker from "react-datepicker";
import { getLookOptionsForADropdown } from "../../helpers/LookupOptionHelper";
import { BOOLEAN_OPTION } from '../../common/constants/dropdownOptions';
import { EMPLOYMENT_TYPE } from '../../common/constants/dropdownOptions';
import { GROUP_OPTION } from '../../common/constants/dropdownOptions';
import { FULLTIME_OPTION } from '../../common/constants/dropdownOptions';
import _ from "lodash/fp";
import { getExperienceInformation } from '../actions/profileAction';
import ExperienceTabTable from './ExperienceTabTable'
import dayjs from 'dayjs';
import { numberWithDecimal, numberWithDecimalAndHyphen, expLetterNumberRegex, numberWithDecimalandComma, devNagariRegex } from '../../common/constants/regex'
import { dateDifferece } from "../../helpers/Datetime";
import { toastr } from "react-redux-toastr";
import { experienceInfoReqDTO } from "../constants/experienceInfoReqDTO";
import '../../styles/profile.css'
import { Modal } from 'react-bootstrap';
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import ModuleHide from "../../common/components/ModuleHide";
import "../../styles/advertisementTable.css";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";

const ExperienceInformation = () => {
  const [experienceInfoState, setExperienceInfoState] = useState(experienceInfoReqDTO);
  let [showModal, setShowModal] = useState(false);
  const [isChangeFrom, setIsChangeFrom] = useState(false);
  const [isChangeTo, setIsChangeTo] = useState(false);
  const [reloadItem, setReloadItem] = useState(0);
  const [designationId, setDesignationId] = useState('');
  const [designationName, setDesignationName] = useState('');
  const [officeName, setOfficeName] = useState('');
  const [payScale, setPayScale] = useState('');
  const [gradePay, setGradePay] = useState('');
  const [basicPay, setBasicPay] = useState('');
  const [monthlyGrossSalary, setMonthlyGrossSalary] = useState('');

  useEffect(() => {
    setOfficeName('');
    setPayScale('');
    setGradePay('');
    setBasicPay('');
    setMonthlyGrossSalary('');
    setDesignationName("");
    setDesignationId("")
    experienceInfoState && experienceInfoState.designation && setDesignationName(experienceInfoState.designation);
    experienceInfoState && experienceInfoState.designationId && setDesignationId(experienceInfoState.designationId);
    experienceInfoState && experienceInfoState.officeName && setOfficeName(experienceInfoState.officeName);
    experienceInfoState && experienceInfoState.payScale && setPayScale(experienceInfoState.payScale);
    experienceInfoState && experienceInfoState.gradePay && setGradePay(experienceInfoState.gradePay);
    experienceInfoState && experienceInfoState.basicPay && setBasicPay(experienceInfoState.basicPay);
    experienceInfoState && experienceInfoState.monthlyGrossSalary && setMonthlyGrossSalary(experienceInfoState.monthlyGrossSalary);
    setReloadItem(!reloadItem);
  }, [experienceInfoState]);

  const handleExperienceInfoState = (evt) => {
    setReloadItem(!reloadItem);
    setIsChangeTo(false);
    setIsChangeFrom(false);
    const value = evt.target.value;
    const name = evt.target.name;
    let employmentFrom = '';
    let employmentTo = '';

    if((name === "jobNatureLookupId") || (name === "officeName") ||  (name === "postNameLookupId") ||  (name === "postNameOther") || (name === "flgOfficeGovOwned") || (name === "designationId") || (name === "designationName") || (name === "flgGazettedPost") ||  (name === "apointmentNatureLookupId") || (name === "apointmentNatureLookupId") || (name === "appointmentLetterNo") || (name === "payScale") || (name === "gradePay") || (name === "basicPay") || (name === "monthlyGrossSalary"))
    {
      setExperienceInfoState({
        ...experienceInfoState,
        [evt.target.name]: value,
      });
    }

    if ((name !== "jobNatureLookupId") && (name !== "officeName") && (name !== "postNameLookupId") && (name !== "postNameOther") && (name !== "flgOfficeGovOwned") && (name !== "designationId") && (name !== "designationName") && (name !== "flgGazettedPost") && (name !== "apointmentNatureLookupId") && (name !== "apointmentNatureLookupId") && (name !== "appointmentLetterNo") && (name !== "payScale") && (name !== "gradePay") && (name !== "basicPay") && (name !== "monthlyGrossSalary")) {
      if (value === "2" && name === "typeEmploymentLookupId") {
        employmentFrom = watch("employmentFrom") ? watch("employmentFrom") : dayjs(experienceInfoState.employmentFrom).toDate();
        employmentTo = new Date();
      }
      else {
        employmentFrom = watch("employmentFrom") ? watch("employmentFrom") : dayjs(experienceInfoState.employmentFrom).toDate();
        employmentTo = watch("employmentTo") ? watch("employmentTo") : dayjs(experienceInfoState.employmentTo).toDate();
      }

      let expYears = 0;
      let expMonths = 0;
      let expDays = 0;
      if (employmentFrom) {
        let difference = dateDifferece(employmentFrom, employmentTo);
        if (difference.firstDateWasLater) {
          expYears = 0;
          expMonths = 0;
          expDays = 0;
          toastr.error(profileexperienceinofo.TO_DATE_SHOULD_BE_GREATER_THAN_FROM_DATE);
        } else {
          expYears = difference.years;
          expMonths = difference.months;
          expDays = difference.days;
        }
      }
      setExperienceInfoState({
        ...experienceInfoState,
        expYears: expYears,
        expMonths: expMonths,
        expDays: expDays,
        [evt.target.name]: value,
      });
    }
    
    if (evt.target.name === "flgMpscSelection") {
      setExperienceInfoState({
        ...experienceInfoState,
        ["flgMpscSelection"]: value,
        ["postNameLookupId"]: ''
      });
      setValue("postNameLookupId", '');
    }
    if (evt.target.name === "flgGazettedPost") {
      setExperienceInfoState({
        ...experienceInfoState,
        ["flgGazettedPost"]: value,
        ["typeGroupLookupId"]: ''
      });
      setValue("typeGroupLookupId", '');
    }
    if (evt.target.name === "apointmentNatureLookupId") {
      setExperienceInfoState({
        ...experienceInfoState,
        ["apointmentNatureLookupId"]: value,
        ["fullTimeLookupId"]: '',
        ["appointmentLetterNo"]: '',
        ["appointmentLetterDate"]: ''
      });
      
      setValue("fullTimeLookupId", '')
      setValue("appointmentLetterNo", '')
      setValue("appointmentLetterDate", '')
    }
    setReloadItem(!reloadItem);
  }

  const { register, control, watch, errors, handleSubmit, reset, setValue } = useForm({
    mode: "onChange"
  });

  useEffect(()=>{
    setReloadItem(!reloadItem);
  },[experienceInfoState]);

 const isMpscSelectionflg = watch("flgMpscSelection");
 const isGazettedPostflg = watch("flgGazettedPost");
 const istypeEmploymentflg = watch("typeEmploymentLookupId");
 const apointmentNatureflag = watch("apointmentNatureLookupId");
 const dispatch = useDispatch();
 const [actionType, setActionType] = useState("add");
 const employmentFromSelected = watch("employmentFrom");
 const isDisableAllTabSave = useSelector(state => state.profile.isDisableAllTabSave);
 const [designationNameFlg, setDesignationNameFlg] = useState(""); 

  useEffect(() => {
    dispatch(getExperienceInformation());
    dispatch(getAllJobDesignationDetails())
  }, []);

  const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
  const [arraymModule, setarraymModule] = useState({})

  useEffect(() => {
    setarraymModule(moduleList && moduleList.filter((ele) => ele.moduleName === "EXPERIENCE_INFO")[0])
  }, [moduleList])

  let { experiencesList, profileCreationAllLookupData, flagExperienceEdit, experienceToBeEdited, experienceTableList, getJobDesignationList} = useSelector(state => state.profile, shallowEqual);
  useEffect(() => {
    setExperienceInfoState({
      typeEmploymentLookupId: experiencesList.typeEmploymentLookupId,
      flgMpscSelection: experiencesList.flgMpscSelection,
      postNameLookupId: experiencesList.postNameLookupId,
      postNameOther: experiencesList.postNameOther,
      officeName: experiencesList.officeName,
      flgOfficeGovOwned: experiencesList.flgOfficeGovOwned,
      designation: experiencesList.designation,
      designationId: experiencesList.designationId,
      jobNatureLookupId: experiencesList.jobNatureLookupId,
      flgGazettedPost: experiencesList.flgGazettedPost,
      typeGroupLookupId: experiencesList.typeGroupLookupId,
      apointmentNatureLookupId: experiencesList.apointmentNatureLookupId,
      fullTimeLookupId: experiencesList.fullTimeLookupId,
      appointmentLetterNo: experiencesList.appointmentLetterNo,
      appointmentLetterDate: experiencesList.appointmentLetterDate,
      payScale: experiencesList.payScale,
      gradePay: experiencesList.gradePay,
      basicPay: experiencesList.basicPay,
      monthlyGrossSalary: experiencesList.monthlyGrossSalary,
      employmentFrom: experiencesList.employmentFrom,
      employmentTo: experiencesList.employmentTo,
      expYears: experiencesList.expYears,
      expMonths: experiencesList.expMonths,
      expDays: experiencesList.expDays
    });
    setReloadItem(!reloadItem);
    let displayFlgExperience = "No";

    if (experiencesList && experiencesList[0]) {
      displayFlgExperience = experiencesList[0].flgExperience ? "Yes" : "No";
    }

    setFlgExperience(displayFlgExperience);
    setValue('flgExperience', displayFlgExperience);
    setReloadItem(!reloadItem);
  }, [experiencesList]);

  useEffect(() => {
    setExperienceInfoState(experienceToBeEdited);
    setReloadItem(!reloadItem);
    setValue('postNameOther', experienceToBeEdited ? experienceToBeEdited.postNameOther : "");
    if (experienceToBeEdited && experienceToBeEdited.appointmentLetterNo) {
      setValue('appointmentLetterNo', experienceToBeEdited.appointmentLetterNo);
      setValue('apointmentNatureLookupId', experienceToBeEdited.apointmentNatureLookupId);
      setValue('appointmentLetterDate', experienceToBeEdited.appointmentLetterDate);
    }
    setReloadItem(!reloadItem);

    if(experienceToBeEdited && (experienceToBeEdited.designationId === 0)){
      setDesignationNameFlg(experienceToBeEdited.designationId);
    }

  }, [experienceToBeEdited]);

  useEffect(() => {
    setExperienceInfoState({
      typeEmploymentLookupId: experiencesList && experiencesList.typeEmploymentLookupId,
      flgMpscSelection: experiencesList && experiencesList.flgMpscSelection,
      jobNatureLookupId: experiencesList && experiencesList.jobNatureLookupId,
      fullTimeLookupId: experiencesList && experiencesList.fullTimeLookupId,
      flgOfficeGovOwned: experiencesList && experiencesList.flgOfficeGovOwned,
      flgGazettedPost: experiencesList && experiencesList.flgGazettedPost,
      typeGroupLookupId: experiencesList && experiencesList.typeGroupLookupId,
      apointmentNatureLookupId: experiencesList && experiencesList.apointmentNatureLookupId,
      employmentFrom: experiencesList && experiencesList.employmentFrom,
      employmentTo: experiencesList && experiencesList.employmentTo
    });
    setReloadItem(!reloadItem);
  }, []);

  const handleFromToDateSelection = (fromdate, todate) => {
    if (!todate && !experienceInfoState.employmentTo) {
      todate =  experienceInfoState.employmentTo;
    }
    else if (!todate && experienceInfoState.employmentTo) {
      todate = experienceInfoState.employmentTo;
    }
  
    if (!fromdate && !experienceInfoState.employmentFrom) {
      fromdate = new Date();
    }
    else if (!fromdate && experienceInfoState.employmentFrom) {
      fromdate = experienceInfoState.employmentFrom;
    }

    let difference = dateDifferece(fromdate, todate);
    let expYears = 0;
    let expMonths = 0;
    let expDays = 0;

    if (difference.firstDateWasLater) {
      expYears = 0;
      expMonths = 0;
      expDays = 0;
      toastr.error(profileexperienceinofo.TO_DATE_SHOULD_BE_GREATER_THAN_FROM_DATE);
    } else {
      expYears = difference.years;
      expMonths = difference.months;
      expDays = difference.days;
    }

    setExperienceInfoState({
      ...experienceInfoState,
      employmentFrom: fromdate,
      employmentTo: todate,
      expYears: expYears,
      expMonths: expMonths,
      expDays: expDays
    })
    setReloadItem(!reloadItem);
  }

  const modalConfirm = () => {
    dispatch(saveExperienceList(experiencesList, flgExperience));
    dispatch(clearEditExperienceInfo(experienceInfoReqDTO));

    if (flgExperience === "Yes" && experiencesList.length === 0) {
      setValue("flgExperience", "No");
      setFlgExperience("No");
    }
    // clear row when action type is not set to add or edit
    setExperienceInfoState(experienceInfoReqDTO);
    setReloadItem(!reloadItem);
    reset();
    setReloadItem(!reloadItem);
    setShowModal(false);
  }

  const jobDesignationLabel = (value) => {
      const foundItem = getJobDesignationList && getJobDesignationList.length > 0 && getJobDesignationList.find(item => item.id == value);
      return foundItem ? foundItem.label : "";
  };

  const handleExperienceInfoSubmit = (e) => {
    e = experienceInfoState;
    let presentAddedError = e.typeEmploymentLookupId == 2 ? profileexperienceinofo.PRESENT_EXPERIENCE_IS_ALREADY_ADDED : profileexperienceinofo.DATES_ARE_OVERLAPPING_PLEASE_CHECK
    for (let element of experienceTableList) {
      if (actionType === "edit" && experienceToBeEdited.id == element.id) {
        setDesignationNameFlg("")
        continue;
      }
      if (e.employmentTo == null && ((element.employmentTo == "" || element.employmentTo == undefined))) {
        toastr.error(presentAddedError)
        return;
      }
      if (e.employmentTo == null && !(dayjs(e.employmentFrom).isAfter(element.employmentTo))) {
        toastr.error(profileexperienceinofo.FROM_DATE_GREATER_THAN_PREV_EXP_TO_DATE_1)
        return;
      }
      if ((element.employmentTo == "" || element.employmentTo == undefined) && !(dayjs(element.employmentFrom).isAfter(e.employmentTo))) {
        toastr.error(presentAddedError)
        return;
      }
      if (!(dayjs(e.employmentTo).isBefore(element.employmentFrom) || dayjs(e.employmentFrom).isAfter(element.employmentTo))) {
        toastr.error(profileexperienceinofo.FROM_DATE_GREATER_THAN_PREV_EXP_TO_DATE_2)
        return;
      }
    }
    experienceInfoState.typeEmploymentLookupId = e.typeEmploymentLookupId;
    experienceInfoState.flgMpscSelection = e.flgMpscSelection;
    experienceInfoState.jobNatureLookupId = e.jobNatureLookupId;
    experienceInfoState.postNameLookupId = e.postNameLookupId;
    experienceInfoState.postNameOther = e.postNameOther;
    experienceInfoState.officeName = e.officeName;
    experienceInfoState.flgOfficeGovOwned = e.flgOfficeGovOwned;
    experienceInfoState.flgGazettedPost = e.flgGazettedPost;
    experienceInfoState.typeGroupLookupId = e.typeGroupLookupId;
    experienceInfoState.apointmentNatureLookupId = e.apointmentNatureLookupId;
    experienceInfoState.fullTimeLookupId = e.fullTimeLookupId;
    experienceInfoState.designation = jobDesignationLabel(e.designationId);
    experienceInfoState.designationId = e.designationId;
    experienceInfoState.payScale = e.payScale;
    experienceInfoState.monthlyGrossSalary = e.monthlyGrossSalary;
    experienceInfoState.gradePay = e.gradePay;
    experienceInfoState.employmentFrom = e.employmentFrom;
    experienceInfoState.employmentTo = e.employmentTo;
    experienceInfoState.basicPay = e.basicPay;
    experienceInfoState.appointmentLetterNo = e.appointmentLetterNo;
    experienceInfoState.appointmentLetterDate = e.appointmentLetterDate;

    /** OAS-645 */
    experienceInfoState.flgExperience = flgExperience;

    if (actionType === "add") {
      let experienceInfoToBeAdded = { ...experienceInfoState };
      dispatch(addExperienceList(experienceInfoToBeAdded, experiencesList));
    }
    if (actionType === "edit") {
      experienceInfoState.id = experienceToBeEdited.id;
      dispatch(editExperienceInfo(experienceInfoState, experiencesList));
    }

    /** Clear the local row state once experienceInfo is added or updated*/
    setExperienceInfoState(experienceInfoReqDTO);
    setReloadItem(!reloadItem);
    reset();
  }
  const [flgExperience, setFlgExperience] = useState('No')
  const jobNatureList = getLookOptionsForADropdown("job_nature", profileCreationAllLookupData);
  const postList = getLookOptionsForADropdown("post_name", profileCreationAllLookupData);
  const appointmentNatureList = getLookOptionsForADropdown("appointment_nature", profileCreationAllLookupData);

  const saveExpInfo = () => {
    dispatch(clearEditExperienceInfo(experienceInfoReqDTO))
    dispatch(saveExperienceList(experiencesList, flgExperience));
  }

  return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule} /> :
    (
      <div className="p-4 f12">
        <div className="p-1">
          <h5>
            {profileexperienceinofo.EXPERIENCE_DETAILS}
          </h5>
        </div>
        <div className="row" >
          <span className="RadioText">{profileexperienceinofo.ARE_YOU_HAVING_EXPERIENCE}</span>
          <div className="radio ml-1">
            <label>
              <input
                type="radio"
                checked={(flgExperience === "Yes" ? true : false)}
                onChange={(e) => { setFlgExperience(e.target.value) }}
                className="RadioText"
                value="Yes"
                name="flgExperience"
                ref={register({ required: true })}
              />&nbsp;{profileexperienceinofo.YES}</label>
          </div>
          <div className="radio ml-2">
            <label>
              <input
                type="radio"
                checked={(flgExperience === "No" ? true : false)}
                onChange={(e) => { setFlgExperience(e.target.value); setExperienceInfoState(experienceInfoReqDTO); reset(); }}
                name="flgExperience"
                value="No"
                className="mr-1"
                ref={register({ required: true })} />{profileexperienceinofo.NO}</label>
          </div>
        </div>
        <hr />
        {watch('flgExperience') === 'Yes' ?
          <div>
            <ExperienceTabTable isChangeFrom={isChangeFrom}  setIsChangeFrom={setIsChangeFrom} isChangeTo={isChangeTo}  setIsChangeTo={setIsChangeTo} />
            <form onSubmit={handleSubmit((e) => { handleExperienceInfoSubmit(e) })}>
              <div className="form-row mt-2">
                <div className="col-md-3 mt-3 mb-3">
                  <label className="float-left">
                    {numberformat["5.1"]}&nbsp;{profileexperienceinofo.EMPLOYMENT_PRESENT_PAST} <span className="redColor">*</span>
                  </label>
                  <select className="form-control" name="typeEmploymentLookupId" value={experienceInfoState.typeEmploymentLookupId} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {EMPLOYMENT_TYPE.map((ele, i) => {
                      return <option key={i} value={ele.value}>{ele.label}</option>
                    })}
                  </select>
                </div>
                <div className="col-md-3 mt-3">
                  <label className="float-left">
                    {numberformat["5.2"]}&nbsp;{profileexperienceinofo["WHETHER_SELECTED_FROM_MPSC?"]} <span className="redColor">*</span>
                  </label>

                  <select className="form-control" name="flgMpscSelection" value={experienceInfoState.flgMpscSelection} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {BOOLEAN_OPTION.map((ele, i) => {
                      return <option key={i} value={ele.value}>{ele.label}</option>
                    })}
                  </select>
                  {_.get("flgMpscSelection.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
                {isMpscSelectionflg && isMpscSelectionflg == 'true' ?
                  <div className="col-md-3 mt-3">
                    <label className="float-left">
                      {numberformat["5.2.1"]}&nbsp;{profileexperienceinofo.POST_NAME} <span className="redColor">*</span>
                    </label>
                    <select className="form-control" name="postNameLookupId" value={experienceInfoState.postNameLookupId} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                      <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                      {postList && postList.sort((postName1, postName2) => { return postName1.label.localeCompare(postName2.label) })
                        .map((ele, i) =>
                          <option key={i} value={ele.id}>{ele.label}</option>
                        )}
                    </select>
                    {_.get("postNameLookupId.type", errors) === "required" && (
                      <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                  </div>
                  : ''}
                {
                  experienceInfoState.postNameLookupId && experienceInfoState.postNameLookupId == 433 ?
                    <div className="col-md-3 mt-3">
                      <label className="float-left">
                        {numberformat["5.2.2"]}&nbsp;{profileexperienceinofo.POST_NAME_OTHER} <span className="redColor">*</span>
                      </label>
                      <input type="text" className="form-control" name="postNameOther" defaultValue={experienceInfoState.postNameOther} maxLength={100} ref={register({ required: true, pattern: expLetterNumberRegex })} onChange={handleExperienceInfoState}></input>
                      {_.get("postNameOther.type", errors) === "required" && (
                        <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                      )}
                      {_.get("postNameOther.type", errors) === "pattern" && (
                        <p className="errorText">{profileexperienceinofo.ENTER_VALID_OTHER_POST_NAME}</p>
                      )}
                    </div>
                    : ""}
                <div className="col-md-3 mt-3" >
                  <label className="float-left">
                    {numberformat["5.3"]}&nbsp;{profileexperienceinofo.INSTITUTION_DEPARTMENT_ORGANISATION} <span className="redColor">*</span>
                  </label>
                  <input type="text" className="form-control" name="officeName" value={officeName} defaultValue={experienceInfoState.officeName} maxLength={500} ref={register({ required: true, pattern: expLetterNumberRegex })} onChange={(e)=>{handleExperienceInfoState(e); setOfficeName(e.target.value); }}></input>
                  {_.get("officeName.type", errors) === "required" && (
                    <p className="errorText"> {profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                  {_.get("officeName.type", errors) === "pattern" && (
                    <p className="errorText">{profileexperienceinofo.ENTER_VALID_INSTITUTE_NAME}</p>
                  )}
                </div>
                <div className="col-md-3">
                  <label className="float-left w250">
                    {numberformat["5.4"]}&nbsp;{
                      profileexperienceinofo[
                      "IS_OFFICE_INSTITUTION_OWNED_BY_GOVT_OF_MAHARASHTRA?"
                      ]
                    } <span className="redColor">*</span>
                  </label>
                  <select className="form-control" name="flgOfficeGovOwned" value={experienceInfoState.flgOfficeGovOwned} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {BOOLEAN_OPTION.map((ele, i) => {
                      return <option key={i} value={ele.value}>{ele.label}</option>
                    })}
                  </select>
                  {_.get("flgOfficeGovOwned.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
                <div className="col-md-3 mb-3 pt-3" >
                  <label className="float-left">
                    {numberformat["5.5"]}&nbsp;{profileexperienceinofo.DESIGNATION_POST_HELD} <span className="redColor">*</span>
                  </label>
                  {/* <input type="text" className="form-control" name="designation"  defaultValue={experienceInfoState.designation} value={designation}  maxLength={200} ref={register({ required: true, pattern: expLetterNumberRegex })} onChange={(e)=>{handleExperienceInfoState(e); setDesignation(e.target.value); }}></input> */}
                  <select className="form-control" name="designationId" defaultValue={experienceInfoState.designationId} value={designationId} ref={register({ required: true, pattern: expLetterNumberRegex })} onInput={(e)=>{handleExperienceInfoState(e); setDesignationId(e.target.value); }}>
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {getJobDesignationList && getJobDesignationList.map((ele, i) => {
                      return <option key={i} value={ele.id}>{ele.label}</option>
                    })}
                  </select>
                  {_.get("designationId.type", errors) === "required" && (
                    <p className="errorText"> {profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                  {_.get("designationId.type", errors) === "pattern" && (
                    <p className="errorText">{profileexperienceinofo.ENTER_VALID_DESIGNATION}</p>
                  )}
                </div>
                {((designationNameFlg === 0) || experienceToBeEdited) &&
                <div className="col-md-3 mb-3 pt-3" >
                  <label className="float-left">
                  {numberformat["5.5.1"]}&nbsp;{profileexperienceinofo.DESIGNATION_NAME}
                  </label>
                  <input type="text" className="form-control" name="designationName"  defaultValue={experienceInfoState.designation} value={designationName} disabled></input>
                </div>
                }

                <div className="col-md-3 mb-3 pt-3">
                  <label className="float-left">
                    {numberformat["5.6"]}&nbsp;{profileexperienceinofo.NATURE_OF_JOB} <span className="redColor">*</span>
                  </label>
                  <select className="form-control" name="jobNatureLookupId" value={experienceInfoState.jobNatureLookupId} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {jobNatureList && jobNatureList.sort((jobNature1, jobNature2) => {
                      return jobNature1.label.trim().localeCompare(jobNature2.label.trim())
                    }).map((ele, i) =>
                      <option key={i} value={ele.id}>{ele.label}</option>
                    )}
                  </select>
                  {_.get("jobNatureLookupId.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
                <div className="col-md-3 mb-3 pt-3">
                  <label className="float-left">
                    {numberformat["5.7"]}&nbsp;{profileexperienceinofo["WHETHER_THE_POST_IS_GAZETTED?"]} <span className="redColor">*</span>
                  </label>
                  <select className="form-control" name="flgGazettedPost" value={experienceInfoState.flgGazettedPost} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {BOOLEAN_OPTION.map((ele, i) => {
                      return <option key={i} value={ele.value}>{ele.label}</option>
                    })}
                  </select>
                  {_.get("flgGazettedPost.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
              </div>

              <div className="form-row">
                {isGazettedPostflg && isGazettedPostflg == 'true' ?
                  <div className="col-md-3 mb-3">
                    <label className="float-left">
                      {numberformat["5.7.1"]}&nbsp;{profileexperienceinofo.GROUP} <span className="redColor">*</span>
                    </label>
                    <select className="form-control" name="typeGroupLookupId" value={experienceInfoState.typeGroupLookupId} ref={register({ required: true })} onInput={handleExperienceInfoState}>
                      <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                      {GROUP_OPTION.map((ele, i) => {
                        return <option key={i} value={ele.value}>{ele.label}</option>
                      })}
                    </select>
                    {_.get("typeGroupLookupId.type", errors) === "required" && (
                      <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                  </div> : ''}
                <div className="col-md-3 mb-3">
                  <label className="float-left">
                    {numberformat["5.8"]}&nbsp;{profileexperienceinofo.NATURE_OF_APPOINTMENT} <span className="redColor">*</span>
                  </label>
                  <select className="form-control" name="apointmentNatureLookupId" value={experienceInfoState.apointmentNatureLookupId} ref={register({ required: true })} onInput={handleExperienceInfoState} >
                    <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                    {appointmentNatureList && appointmentNatureList.sort((appointmentNature1, appointmentNature2) => { return appointmentNature1.label.localeCompare(appointmentNature2.label) }).map((ele, i) =>
                      <option key={i} value={ele.id}>{ele.label}</option>
                    )}
                  </select>
                  {_.get("apointmentNatureLookupId.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>

                {apointmentNatureflag && apointmentNatureflag == "256" || apointmentNatureflag == "258" || apointmentNatureflag == "259" ?
                  <div className="col-md-3 mb-3">
                    <label className="float-left ml-3">
                      {numberformat["5.8.1"]}&nbsp;{profileexperienceinofo.FULL_TIME_OTHER}
                    </label>
                    <select className="form-control" name="fullTimeLookupId" value={experienceInfoState.fullTimeLookupId} ref={register({ required: false })} onInput={handleExperienceInfoState} >
                      <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
                      {FULLTIME_OPTION.map((ele, i) => {
                        return <option key={i} value={ele.value}>{ele.label}</option>
                      })}

                    </select>
                  </div> : ''}
                {apointmentNatureflag && apointmentNatureflag == "258" || apointmentNatureflag == "269" || apointmentNatureflag == "271" || apointmentNatureflag == "272" ?
                  <div className="col-md-3 mb-3">
                    <label className="ml-3">
                      {numberformat["5.8.1"]}&nbsp;{profileexperienceinofo.APPOINTMENT_LETTER_NO} <span className="redColor">*</span>
                    </label>
                    <input type="text" className="form-control" name="appointmentLetterNo" defaultValue={experienceInfoState.appointmentLetterNo} ref={register({ required: true, pattern: devNagariRegex })} onChange={handleExperienceInfoState}></input>
                    {_.get("appointmentLetterNo.type", errors) === "required" && (
                      <p className="errorText ml-3">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                    {errors.appointmentLetterNo?.type === "pattern" && (
                      <p className="errorText ml-3">{profileexperienceinofo.ENTER_VALID_APPOINMENT_LETTER_NUMBER}</p>
                    )}
                  </div> : ''}

                {apointmentNatureflag && apointmentNatureflag == "258" || apointmentNatureflag == "269" || apointmentNatureflag == "271" || apointmentNatureflag == "272" ?
                  <div className="col-md-3 mb-3">
                    <label className="float-left">
                      {numberformat["5.8.2"]}&nbsp;{profileexperienceinofo.LETTER_DATE} <span className="redColor">*</span>
                    </label>
                    <Controller
                      name="appointmentLetterDate"
                      key={experienceInfoState.appointmentLetterDate ? "appointmentLetterDate" : "appointmentLetterDate1"}
                      defaultValue={experienceInfoState.appointmentLetterDate ? dayjs(experienceInfoState.appointmentLetterDate).toDate() : ""}
                      rules={{ required: true }}
                      control={control}
                      render={({ onChange, value }) => (
                        <DatePicker
                          selected={fromUTCDate(value)}
                          maxDate={new Date()}
                          onChange={(e) => {
                            setExperienceInfoState({
                              ...experienceInfoState,
                              appointmentLetterDate: toUTCDate(e)
                            }); onChange(toUTCDate(e))
                          }}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText={commonLabels.DATE_FORMAT}
                          dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                          className="form-control"
                        />
                      )}
                    />
                    {_.get("appointmentLetterDate.type", errors) === "required" && (
                      <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                  </div> : ''}
              </div>

              <div className="form-row">
                <div className="col-md-3 mb-3">
                  <label className="float-left">
                    {numberformat["5.9"]}&nbsp;{profileexperienceinofo.PAY_BAND_PAY_SCALE} <span className="redColor">*</span>
                  </label>
                  <input type="text" className="form-control" maxLength={20} name="payScale"  defaultValue={experienceInfoState.payScale} value={payScale} ref={register({ required: true, pattern: numberWithDecimalAndHyphen })} onChange={(e)=>{handleExperienceInfoState(e); setPayScale(e.target.value);}}></input>
                  {_.get("payScale.type", errors) === "required" && (
                    <p className="errorText"> {profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                  {_.get("payScale.type", errors) === "pattern" && (
                    <p className="errorText">{profileexperienceinofo.ENTER_VALID_PAY_SCALE} </p>
                  )}
                </div>
                <div className="col-md-3 mb-3">
                  <label className="float-left">
                    {numberformat["5.10"]}&nbsp;{profileexperienceinofo.GRADE_PAY}
                  </label>
                  <input type="text" className="form-control" maxLength={6} name="gradePay"  defaultValue={experienceInfoState.gradePay} value={gradePay} ref={register({ required: false, pattern: numberWithDecimal })} onChange={(e)=>{handleExperienceInfoState(e); setGradePay(e.target.value);}}></input>
                  {_.get("gradePay.type", errors) === "pattern" && (
                    <p className="errorText">{profileexperienceinofo.ENTER_VALID_GRADE_PAY} </p>
                  )}

                </div>
                <div className="col-md-3 mb-3">
                  <label className="float-left">
                    {numberformat["5.11"]}&nbsp;{profileexperienceinofo.BASIC_PAY} <span className="redColor">*</span>
                  </label>
                  <input type="text" className="form-control" maxLength={6} name="basicPay" defaultValue={experienceInfoState.basicPay} value={basicPay} ref={register({ required: true, pattern: numberWithDecimal })} onChange={(e)=>{handleExperienceInfoState(e); setBasicPay(e.target.value); }} ></input>
                  {_.get("basicPay.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                  {_.get("basicPay.type", errors) === "pattern" && (
                    <p className="errorText">{profileexperienceinofo.ENTER_VALID_BASIC_PAY}</p>
                  )}

                </div>
                <div className="col-md-3 mb-3">
                  <label className="float-left">
                    {numberformat["5.12"]}&nbsp;{profileexperienceinofo.MONTHLY_GROSS_SALARY} <span className="redColor">*</span>
                  </label>
                  <input type="text" className="form-control" maxLength={6} name="monthlyGrossSalary" defaultValue={experienceInfoState.monthlyGrossSalary} value={monthlyGrossSalary} ref={register({ required: true, pattern: numberWithDecimalandComma })} onChange={(e)=>{handleExperienceInfoState(e); setMonthlyGrossSalary(e.target.value);}}></input>
                  {_.get("monthlyGrossSalary.type", errors) === "pattern" && (
                    <p className="errorText"> {profileexperienceinofo.ENTER_VALID_MONTHIY_GROSS_SALARY}</p>
                  )}
                  {_.get("monthlyGrossSalary.type", errors) === "required" && (
                    <p className="errorText"> {profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}

                </div>
              </div>
              <div className="form-row">
                <div className="col-md-3 mb-2">
                  <label className="float-left">
                    {numberformat["5.13"]}&nbsp;{profileexperienceinofo.FROM_DATE} <span className="redColor">*</span>
                  </label>
                  <Controller
                    name="employmentFrom"
                    key={experienceInfoState.employmentFrom ? "employmentFrom" : "employmentFrom1"}
                    value={experienceInfoState.employmentFrom ? dayjs(experienceInfoState.employmentFrom).toDate() : ""}
                    defaultValue={experienceInfoState.employmentFrom ? dayjs(experienceInfoState.employmentFrom).toDate() : ""}
                    rules={{ required: true, validate: value => value <= new Date() }}
                    control={control}
                    render={({ onChange, value }) => (
                      <DatePicker
                        selected={isChangeFrom ? fromUTCDate(value) : fromUTCDate(experienceInfoState.employmentFrom)}
                        onChange={(e) => {
                          setExperienceInfoState({
                            ...experienceInfoState,
                            employmentFrom: toUTCDate(e)
                          }); setIsChangeFrom(true); handleFromToDateSelection(toUTCDate(e), ''); setReloadItem(!reloadItem); onChange(toUTCDate(e))
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText={commonLabels.DATE_FORMAT}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        maxDate={watch("employmentTo") || new Date()}
                        className="form-control"
                      />
                    )}
                  />
                  {_.get("employmentFrom.type", errors) === "required" && (
                    <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div>
                {(istypeEmploymentflg === "1" && employmentFromSelected) || (istypeEmploymentflg === "1" && experienceInfoState.employmentFrom) ?
                  <div className="col-md-3 mb-2">
                    <label className="float-left">
                      {numberformat["5.14"]}&nbsp;{profileexperienceinofo.TO_DATE} <span className="redColor">*</span>
                    </label>
                    <Controller
                      name="employmentTo"
                      key={experienceInfoState.employmentTo ? "employmentTo" : "employmentTo1"}
                      value={experienceInfoState.employmentTo ? dayjs(experienceInfoState.employmentTo).toDate() : ""}
                      defaultValue={experienceInfoState.employmentTo ? dayjs(experienceInfoState.employmentTo).toDate() : ""}
                      rules={{ required: true, validate: value => value <= new Date() }}
                      control={control}
                      render={({ onChange, value }) => (
                        <DatePicker
                          selected={isChangeTo ? fromUTCDate(value) : fromUTCDate(experienceInfoState.employmentTo)}
                          maxDate={new Date()}
                          onChange={(e) => {
                            setExperienceInfoState({
                              ...experienceInfoState,
                              employmentTo: toUTCDate(e)
                            }); handleFromToDateSelection('', toUTCDate(e)); onChange(toUTCDate(e)); setIsChangeTo(true); setReloadItem(!reloadItem);
                          }}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText={commonLabels.DATE_FORMAT}
                          dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                          minDate={watch("employmentFrom") || new Date()}
                          className="form-control"
                        />
                      )}
                    />
                    {_.get("employmentTo.type", errors) === "required" && (
                      <p className="errorText">{profileexperienceinofo.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                  </div> : ''}

                <div className="col-md-1 mb-3">
                  <label className="float-left">
                    {profileexperienceinofo.YEARS}
                  </label>
                  <input type="text" className="form-control" name="expYears" defaultValue={experienceInfoState.expYears} ref={register({ required: false })} onChange={handleExperienceInfoState} disabled></input>
                </div>
                <div className="col-md-1 mb-3">
                  <label className="float-left">
                    {profileexperienceinofo.MONTHS}
                  </label>
                  <input type="text" className="form-control" name="expMonths" defaultValue={experienceInfoState.expMonths} ref={register({ required: false })} onChange={handleExperienceInfoState} disabled></input>
                </div>
                <div className="col-md-1 mb-3">
                  <label className="float-left">
                    {profileexperienceinofo.DAYS}
                  </label>
                  <input type="text" className="form-control" name="expDays" defaultValue={experienceInfoState.expDays} ref={register({ required: false })} onChange={handleExperienceInfoState} disabled></input>
                </div>
              </div>
              <div className="text-center">
                {
                  flagExperienceEdit
                    ?
                    <button type="submit" name="edit" className="btn btn-primary m-1" onClick={() => { setActionType('edit') }}>{common.UPDATE}</button>
                    :
                    flagExperienceEdit || istypeEmploymentflg ?
                      <button type="submit" name="add" className="btn btn-primary m-1" onClick={() => { setActionType('add') }}>{common.ADD}</button>
                      : ""
                }
              </div>
              <p className="paraCss"></p>
              <div className="text-center">
                <button type="button" className="btn btn-primary m-1" onClick={() => { setShowModal(true) }} disabled={isDisableAllTabSave}>{common.SAVE}</button>
              </div>
              <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop='static'>
                <Modal.Header closeButton>
                  <Modal.Title>{profileexperienceinofo.SAVE_EXPERIENCE_MODAL_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label>{profileexperienceinofo.SAVE_EXPERIENCE_CONFIRMATION_MESSAGE}</label>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-secondary" onClick={() => { setShowModal(false) }}>{common.CANCEL}</button>
                  <button className="btn btn-primary" onClick={modalConfirm}>{common.CONFIRM}</button>
                </Modal.Footer>
              </Modal>
            </form>
          </div>
          :
          <div className="text-center">
            <button type="button" className="btn btn-primary m-1" onClick={saveExpInfo} disabled={isDisableAllTabSave}>{common.SAVE}</button>
          </div>
        }
      </div>
    );
}

export default ExperienceInformation;
