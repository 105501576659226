export const BOOLEAN_OPTION = [
  {
    "label": "Yes",
    "value": true
  },
  {
    "label": "No",
    "value": false
  }
];

export const BOOLEAN_OPTION_PERSONALINFO = [
  {
    "label": "Select",
    "value": ""
  },
  {
    "label": "No",
    "value": "No"
  },
  {
    "label": "Yes",
    "value": "Yes"
  }
];

export const Disability_Certificate = [
  {
    "label": "SADM",
    "id": 1
  },
  {
    "label": "Swavalamban",
    "id": 2
  }
];

export const Division_Armed_Forces = [
  {
    "label": "Army",
    "id": "Army"
  },
  {
    "label": "Navy",
    "id": "Navy"
  },
  {
    "label": "Air Force",
    "id": "Air_Force"
  },
  {
    "label": "Gallantry Award winners of Territorial Army",
    "id": "Gallantry_Award_winners"
  }
];

export const Domicile_AVAILABLE = [
  {
    "label": "Available",
    "id": "Yes"
  },
  {
    "label": "Not Available",
    "id": "No"
  }
];

export const DEBARRED_NATURE = [
  {
    "label": "Permanent",
    "value": 1
  },
  {
    "label": "For certain period",
    "value": 2
  }
];

export const SCRIBE_OPTION = [
  {
    "label": "Self",
    "value": 1
  },
  {
    "label": "MPSC Office",
    "value": 2
  }
];

export const EMPLOYMENT_TYPE = [
  {
    "label": "Past",
    "value": 1
  },
  {
    "label": "Present",
    "value": 2
  }
];

export const GROUP_OPTION = [
  {
    "label": " Group A",
    "value": 1
  },
  {
    "label": "Group B",
    "value": 2
  }
];

export const FULLTIME_OPTION = [
  {
    "label": "Full Time",
    "value": 1
  },
  {
    "label": "Otherwise",
    "value": 2
  }
];

export const RESULTS = [
  {
    "label": "Select",
    "value": ""
  },
  {
    "label": "Passed",
    "value": 1
  },
  {
    "label": "Appeared",
    "value": 0
  }
];

export const AUTHOR_TYPES = [
  {
    "label": "Select",
    "value": ""
  },
  {
    "label": "First Author",
    "value": 1
  },
  {
    "label": "Second Author",
    "value": 2
  },
  {
    "label": "Other Author",
    "value": 3
  },
  {
    "label": "Third",
    "value": 4
  },
  {
    "label": "Corresponding",
    "value": 5
  }
];

export const PUBLICAION_TYPES = [
  {
    "label": "Select",
    "value": ""
  },
  {
    "label": "International",
    "value": 1
  },
  {
    "label": "National",
    "value": 2
  },
  {
    "label": "State",
    "value": 3
  },
  {
    "label": "Index Medicus",
    "value": 4
  }
];

export const ORPHAN_TYPE = [
  {
    "label": "Select",
    "value": "",
    "labelForCastListOption": ['323', '324', '325', '326', '327', '328', '329', '330', '331', '332', '333']
  },
  {
    "label": "Orphan Type-A",
    "value": "Orphan Type-A",
    "labelForCastListOption": ['323']
  },
  {
    "label": "Orphan Type-B",
    "value": "Orphan Type-B",
    "labelForCastListOption": ['323', '324', '325', '326', '327', '328', '329', '330', '331', '332', '333']
  },
  {
    "label": "Orphan Type-C",
    "value": "Orphan Type-C",
    "labelForCastListOption": ['323', '324', '325', '326', '327', '328', '329', '330', '331', '332', '333']
  }
];