import React from "react";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
 
const Devnagari = (props) => {
  return (
    <div className="container">
      <ReactTransliterate
        value={props.value}
        onChangeText={ props.onChange}
        lang="mr"
        className="form-control"

      />
    </div>
  );
};
 
export default Devnagari;