/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import common from "../labels/common_en.json"
import { Modal } from 'react-bootstrap';
import { setProfileUpdateCloseModal ,verificationOfOTP,updateProfileRequest} from "../actions/myAccountAction";
import ModuleHide from "../../common/components/ModuleHide";
import "../../styles/advertisementTable.css";
import "../../styles/profile.css";
import "../../styles/home.css";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import Loader from '../../common/components/Loader';

function UpdateProfileModal(props) {
    const { register, watch, errors, formState, handleSubmit, setError} = useForm({
        mode: 'onChange',
    });
    
    const dispatch = useDispatch();
    const { updateProfileModalFlg ,profileUpdateEmailAndMobileOtpResponse, verifyEmailOtpFlag,verifyMobileOtpFlag} = useSelector(state => state.myaccount, shallowEqual);
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
    const [arraymModule , setarraymModule] = useState({})
    const [otpTimer, setOtpTimer] = useState(0);
    const [hasMobile, setHasMobile] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [showLoaderFlg, setShowLoaderFlg] = useState(true);

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "UPDATE_PROFILE")[0])
    },[moduleList])

    const session = JSON.parse(sessionStorage.getItem("session"));
    const emailId = session && session.emailId;
    const mobileNumber = session && session.mobileNumber;
    let createClock;

    const modalCancel = () => {
        clearInterval(createClock);
        setOtpTimer(0);
        dispatch(setProfileUpdateCloseModal(false))
        setHasMobile(false);
        setHasEmail(false);
        setShowLoaderFlg(true);
    };

    function getMobileMask (mobileno){
        if(mobileno){
        const mobilenumber = mobileno.toString();
        const maskMobileNumber = mobilenumber.replace(mobilenumber.substring(3,8), 'XXXXXX')
        return maskMobileNumber
        }
    };

    useEffect(()=>{
        startCount();
        if(otpTimer !== 0){
            setOtpTimer(0);
        }
        return () => {
            clearInterval(createClock);
        };
      },[profileUpdateEmailAndMobileOtpResponse])

      useEffect(() => {
        if (profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length > 0) {
          setShowLoaderFlg(false);
        } else {
          setShowLoaderFlg(true);
        }
      }, [profileUpdateEmailAndMobileOtpResponse]);

      useEffect(() => {
        const types = profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.map(item => item.type);
        setHasMobile(types.includes("mobile"));
        setHasEmail(types.includes("email"));
      }, [profileUpdateEmailAndMobileOtpResponse]);

    const startCount = ()=>{
        let time = '';
        profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.map(e=>{
            time = parseInt(e.expiryDuration)
        })
        let start2 = '';
       if(time)
       {
       start2 = time;
       }
       createClock = setInterval(displayT, 1000);
        function displayT(){
            if(start2!='')
            {
                start2 = start2 - 1;
                setOtpTimer(start2)
            }
            if(start2==0){
                clearInterval(createClock)
            }
        }
    };

    const verificationEmailOTP =  (type) => {
        let reqBody;
        let otpid;
        let otptype;
        profileUpdateEmailAndMobileOtpResponse.map((ele)=>{
            if(ele.type===type){
                otpid =ele.id;
                otptype = ele.type
            }
        })
       
        if( watch("OTP_email") && otptype==='email'){
          reqBody = {
            "username": emailId,
            "otpId": otpid, 
            "otpType": otptype,
            "enteredOtp": watch("OTP_email")
          }
         } else{
            setError("OTP_email", {type: "required"});
          }
          dispatch(verificationOfOTP(reqBody,'email'))
      }

      const verificationMobileOTP =  (type) => {
        let reqBody;
        let otpid;
        let otptype;
            profileUpdateEmailAndMobileOtpResponse.map((ele)=>{
                if(ele.type===type){
                    otpid =ele.id;
                    otptype = ele.type
                }
            })
          if( watch("OTP_mobile")&& otptype==='mobile'){
             reqBody = {
              "username": emailId,
              "otpId": otpid, 
              "otpType": otptype,
              "enteredOtp": watch("OTP_mobile")
            }
          } else{
            setError("OTP_mobile", {type: "required"});
          }
          dispatch(verificationOfOTP(reqBody,'mobile'))
      }

    const onSubmit = () => {
        dispatch(updateProfileRequest(props))
        setHasMobile(false);
        setHasEmail(false);
        setShowLoaderFlg(true);
    };

    return (
        <form  className="UpdateProfileForm mt50">
            {((updateProfileModalFlg) && (showLoaderFlg)) ?
                <Loader showFlg={true}/>
                :
                <div>
                    <Modal show={updateProfileModalFlg} onHide={modalCancel} backdrop='static'>
                        <Modal.Header closeButton>
                            <Modal.Title>{common.UPDATE_PROFILE}</Modal.Title>
                        </Modal.Header>
                        { arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
                        <div>
                            <Modal.Body>
                                <div>
                                    <span className="Note-color">{common.UPDATE_PROFILE_NOTE_FOR_MOBILE_NO}</span>
                                </div>
                                { hasEmail &&
                                <div className="form-group">
                                    <label className="LeftText">{common.Email_OTP} {'('}{emailId? emailId.replace(emailId.substring(2,12), 'XXXXXX'):"" }{')'}<span className="text-danger"> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" maxLength={6} name="OTP_email" ref={register({ required: true })} aria-label="emailotp" aria-describedby="button-addon2"  disabled={verifyEmailOtpFlag}/>
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={()=>{verificationEmailOTP('email')}} disabled={verifyEmailOtpFlag}>{common.VERIFY_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("OTP_email.type", errors) === "required" && (
                                        <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                    )}
                                { otpTimer != 0 ?  <p className="Note-color f11"  >{common.OTP_EXPIRE_NOTE}  {otpTimer} {RegistrationJson.SECONDS}</p>:
                                <p className="Note-color d-none">{common.OTP_EXPIRE_NOTE}  {otpTimer} {RegistrationJson.SECONDS}</p>}
                                </div>}

                                { hasMobile &&
                                <div className="form-group">
                                    <label className="LeftText">{common.Mobile_OTP} {getMobileMask('('+mobileNumber+')')}<span className="text-danger"> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" maxLength={6} name="OTP_mobile" ref={register({ required: true })} aria-label="mobileotp" aria-describedby="button-addon2" disabled={verifyMobileOtpFlag} />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={()=>{verificationMobileOTP('mobile')}} disabled={verifyMobileOtpFlag}>{common.VERIFY_OTP}</button>
                                        </div>
                                    </div>
                                    {_.get("OTP_mobile.type", errors) === "required" && (
                                        <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                    )}
                                    { otpTimer != 0 ?  <p className="Note-color f11"  >{common.OTP_EXPIRE_NOTE}  {otpTimer} {RegistrationJson.SECONDS}</p>:
                                <p className="Note-color d-none">{common.OTP_EXPIRE_NOTE}  {otpTimer} {RegistrationJson.SECONDS}</p>}
                                </div>} 
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <button type="button"  disabled={!(verifyMobileOtpFlag && verifyEmailOtpFlag)} className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })} >{common.SUBMIT}</button> */}
                                {(profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length === 1 && profileUpdateEmailAndMobileOtpResponse[0].type == 'mobile')
                                    ?
                                    <button type="button"  disabled={!(verifyMobileOtpFlag)} className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })} >{common.SUBMIT}</button>
                                    :
                                    (profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.length === 1 && profileUpdateEmailAndMobileOtpResponse[0].type == 'email')
                                    ?
                                    <button type="button"  disabled={!(verifyEmailOtpFlag)} className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })} >{common.SUBMIT}</button>
                                    :
                                    <button type="button"  disabled={!(verifyMobileOtpFlag && verifyEmailOtpFlag)} className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })} >{common.SUBMIT}</button>
                                }
                                {/* <button type="button"  disabled={!(verifyMobileOtpFlag)} className="btn btn-primary" onClick={handleSubmit((e) => { onSubmit(e) })} >{common.SUBMIT}</button> */}
                                <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>
                            </Modal.Footer>
                        </div> }
                    </Modal>
                </div>
            }

        </form>
    );
}
export default UpdateProfileModal;