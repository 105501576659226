/* eslint-disable */
import React from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import myAccountLabel from "../../my_account/labels/my_account_en.json";
import common from "../labels/common_en.json"
import { Modal } from 'react-bootstrap';
import dayjs from "dayjs";
import { setPaymentHistoryModal } from "../actions/myAccountAction";
import "../../styles/advertisementTable.css";

function PaymentHistory() {
    const dispatch = useDispatch();
    const { paymentHistoryModalFlag, paymentHistoryList } = useSelector(
        (state) => state.myaccount,
        shallowEqual
    );

    const modalCancel = () => {
        dispatch(setPaymentHistoryModal(false))
    };

    return (
        <div>
            { paymentHistoryList?.length != 0 &&
            <Modal show={paymentHistoryModalFlag} onHide={modalCancel} backdrop='static' size="lg"
                aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>{myAccountLabel.PAYMENT_HISTORY}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="content-row">
                            <table className="table table-bordered table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>{myAccountLabel.CREATED_DATE}</th>
                                        <th>{myAccountLabel.UPDATE_DATE}</th>
                                        <th>{myAccountLabel.TRANSACTIONNO}</th>
                                        <th>{myAccountLabel.PAYMENT_FOR}</th>
                                        <th>{myAccountLabel.PAYMENT_MODE}</th>
                                        <th>{myAccountLabel.TYPE}</th>
                                        <th>{myAccountLabel.PAYMENT_STATUS}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentHistoryList.map((payment) => (
                                            <tr key={payment.id}>
                                                <td className="word-break w15">
                                                    {payment.createdDate
                                                        ? dayjs(
                                                            payment.createdDate
                                                        ).format(common.CURRENT_DATE_TIME_FORMAT)
                                                        : ""}
                                                </td>
                                                <td className="word-break w15">
                                                    {payment.updatedDate
                                                        ? dayjs(
                                                            payment.updatedDate
                                                        ).format(common.CURRENT_DATE_TIME_FORMAT)
                                                        : ""}
                                                </td>
                                                <td className="word-break w27">{payment.paymentRefNo}</td>
                                                <td className="word-break w13">
                                                    { payment.paymentFor === null ? "Application" : ((payment.paymentFor).toLowerCase()).charAt(0).toUpperCase()+ (payment.paymentFor).toLowerCase().slice(1)}
                                                </td>
                                                <td className="word-break w8">{payment.paymentMode}</td>
                                                <td className="word-break w12">{payment.type}</td>
                                                <td className="word-break w10">
                                                    { payment.paymentStatus == "challan_slip_downloaded" ?
                                                        "challan slip downloaded" :
                                                        payment.paymentStatus
                                                    }
                                                </td>
                                            </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>
                </Modal.Footer>
            </Modal>
            }
        </div>
    );
}
export default PaymentHistory;