/* eslint-disable */
import React, { useState } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import advertisementLabel from "../../application_management/labels/advertisement_details_en.json";
import common from "../labels/common_en.json"
import { Modal } from 'react-bootstrap';
import { setChangeCenterModal, updateExamCenter } from "../actions/myAccountAction";
import Select from "react-select";
import "../../styles/home.css";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";

function ChangeCenter({appDetails}) {
    const { errors } = useForm({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const { changeCenterModalFlag } = useSelector(state => state.myaccount, shallowEqual);
    const { examCenterList } = useSelector(
        (state) => state.myaccount,
        shallowEqual
    );
    const [selectedExamCenters, setSelectedExamCenters] = useState('');
    const maximumNoCenter=1;

    const modalCancel = () => {
        dispatch(setChangeCenterModal(false))
        setSelectedExamCenters('');
    };

    const handleSubmit1 = () => {
        const centerData = {
        applicationId:appDetails.application.id,
        applicationExamCenterPrefDtoList:
        selectedExamCenters
            .map(examCenterOption => ({ examCenterId: examCenterOption.value }))
        }
        dispatch(updateExamCenter(centerData));
        dispatch(setChangeCenterModal(false));
        setSelectedExamCenters('');
    };

    return (
        <form  className="ChangeCenterForm mt50" >
            <div>
                <Modal show={changeCenterModalFlag} onHide={modalCancel} backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title>{common.CHANG_CENTER}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="content-row">
                                <h6>{common.YOU_PREVIOUSLY_SELECTED}</h6>&nbsp;
                                 {appDetails && appDetails.applicationExamCenterPrefDto && appDetails.applicationExamCenterPrefDto.map((ele,i)=>
                                   <div className="ml-4">
                                     {i+1}. <span className="word-break">{ele.examCenterName} </span> 
                                    <br/>
                                 </div>
                                )}
                            </div>
                        </div>
                        <hr/>
                    <div className="form-group">
                        <label ><h6>{advertisementLabel.Select_exam_center}<span className="redColor">*</span></h6></label>
                        <br/>{maximumNoCenter ? <label className="noteColor f11"> {common.CHANGE_CENTER_NOTE} {maximumNoCenter} {common.EXAM_CENTER} </label> : "" }
                        <div className={`${examCenterList.length < 1? "pad_0 btn-dropdown disabled-link":"pad_0 btn-dropdown"}`} >
                       <Select
                            isMulti={true}
                            isOptionDisabled={() => maximumNoCenter ? selectedExamCenters.length >= maximumNoCenter : false }
                            value={selectedExamCenters}
                            onChange={setSelectedExamCenters}
                            options={examCenterList.filter(examCenter => examCenter.isDeleted == false)}
                            />
                            {selectedExamCenters && selectedExamCenters.length >= 1 || examCenterList.length == 1 ? '' : <p className="errorText">{advertisementDetailsLabel.SELECT_OPTIONS}</p>} 
                        {_.get("center.type", errors) === "required" && (
                        <p className="errorText">{common.validationMessage.FIELD_REQ}</p>
                        )}
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>                        
                        <button type="button" 
                        disabled={ selectedExamCenters === '' } 
                        className="btn btn-primary" 
                        onClick={handleSubmit1}  
                        >{common.SAVE}</button>                      
                    </Modal.Footer>
                </Modal>
            </div>
        </form>
    );
}
export default ChangeCenter;