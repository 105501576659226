/* eslint-disable */
import React, { useEffect, useState } from "react";
import {shallowEqual, useDispatch, useSelector  } from "react-redux";
import ProfileCreation from "../../candidate_profile_creation/components/ProfileCreation";
import CandidateHome from "../../home/components/Home";
import { logout } from "../../registration_login/actions/sessionActions";
import "../../styles/candidate_nav.css";
import Correspondence from "../../correspondence/components/Correspondence";
import MyAccount from "../../my_account/components/MyAccount";
import Advertisement from "../../application_management/components/Advertisement";
import { setShowAdvertisementDetails, resetUploadeDocumentChecks} from "../../application_management/actions/advertisementAction";
import { getPrfileSummaryDetails, setProfileViewFlg, setPrintProfile, backToMyAccount, setApplicationDetailsFlg } from "../../my_account/actions/myAccountAction";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
import advertisementDetailsLabels from "../../application_management/labels/advertisement_details_en.json"
import forgotPasswordLabels from "../../registration_login/labels/forgot_password_en.json";

const CandidateNav = (props) => {
    const [stepName, setName] = useState("1");
    const dispatch = useDispatch();
    const navToOnlineAppln = () => {
        setName("3");
        dispatch(setShowAdvertisementDetails(false, null));
        dispatch(resetUploadeDocumentChecks())
    };
    let { profileStatus } = useSelector(state => state.profile, shallowEqual);
    let { updateProfileDone , isPrintProfileDone} = useSelector(state => state.myaccount, shallowEqual);

    useEffect(() => {
        updateProfileDone ? setName("2"): setName("1");
    }, [updateProfileDone]);

    useEffect(() => {
        if(isPrintProfileDone){
            document.getElementById("myaccount").click();
            dispatch(backToMyAccount(false))
        }
    }, [isPrintProfileDone]);

    return (
        <div>
            <ul id="candidateNavBar" className="nav nav-tabs main-nav-tab bottom-shadow row top-nav ">
                <li className="nav-item col-md-2" onClick={() => setName("1")}>
                    <a
                        className={
                            stepName === "1"
                                ? "nav-link active"
                                : "nav-link bg-light"
                        }
                        href="#"
                    >
                        {" "}
                        <img src="/icons/home.svg" />
                        <span className="ml-1" data-toggle="tab">
                            {RegistrationJson.HOME}
                        </span>
                    </a>
                </li>
                <li className="nav-item col-md-2" data-toggle="tooltip" title={`${profileStatus ? commonLabels.CANDIDATE_PROFILE_NOTE : ""}`}>
                    <div className={`${profileStatus ? "disabled-link" : ""}`} onClick={() => setName("2")}>
                    <a
                        className={
                            stepName === "2"
                                ? "nav-link active"
                                : "nav-link bg-light"
                        }
                        href="#"
                    >
                        {" "}
                        <img src="/icons/profile_creation.svg" />
                        <span className="ml-1" data-toggle="tab">
                            {RegistrationJson.PROFILE_CREATION}
                        </span>
                    </a>
                    </div>
                </li>
                <li  className="nav-item col-md-2" onClick={navToOnlineAppln}>
                    <a
                        className={
                            stepName === "3"
                                ? "nav-link active"
                                : "nav-link bg-light"
                        }
                        href="#"
                    >
                        {" "}
                        <img src="/icons/online_application.svg" />
                        <span className="ml-1" data-toggle="tab">
                           {advertisementDetailsLabels.ONLINE_APPLICATION}
                        </span>
                    </a>
                </li>
                <li id="myaccount" className="nav-item col-md-2" onClick={() =>{ setName("4"); dispatch(getPrfileSummaryDetails());dispatch(setPrintProfile(false)); dispatch(setProfileViewFlg()); dispatch(setApplicationDetailsFlg(false))}}>
                    <a
                        className={
                            stepName === "4"
                                ? "nav-link active"
                                : "nav-link bg-light"
                        }
                        href="#"
                    >
                        {" "}
                        <img src="/icons/my_account.svg" />
                        <span className="ml-1" data-toggle="tab">
                            {RegistrationJson.MY_ACCOUNT}
                        </span>
                    </a>
                </li>
                <li className="nav-item col-md-2" onClick={() => setName("5")}>
                    <a
                        className={
                            stepName === "5"
                                ? "nav-link active"
                                : "nav-link bg-light"
                        }
                        href="#"
                    >
                        {" "}
                        <img src="/icons/help.svg" />
                        <span className="ml-1" data-toggle="tab">
                           {RegistrationJson.CORRESPONDENCE} 
                        </span>
                    </a>
                </li>
                <li
                    className="nav-item col-md-2"
                    onClick={() => {
                        setName("6");
                        dispatch(logout(props));
                    }}
                >
                    <a
                        className={
                            stepName === "6"
                                ? "nav-link active"
                                : "nav-link bg-light"
                        }
                        href="#"
                    >
                        {" "}
                        <img src="/icons/logout.svg" data-toggle="tab" />
                        <span className="ml-1">{forgotPasswordLabels.LOGOUT}</span>
                    </a>
                </li>
            </ul>
            <div
                className="tab-content candidateHomeTabCss"
            >
                <div
                    className={
                        stepName === "1"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                    }
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                >
                    <CandidateHome {...props}/>
                </div>
                <div
                    className={
                        stepName === "2"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                    }
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                >
                    <ProfileCreation />
                </div>
                <div
                    className={
                        stepName === "3"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                    }
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                >
                    <Advertisement />
                </div>
                <div
                    className={
                        stepName === "4"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                    }
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                >
                    <MyAccount   {...props}/>
                </div>
                <div
                    className={
                        stepName === "5"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                    }
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                >
                    <Correspondence />
                </div>
                <div
                    className={
                        stepName === "6"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                    }
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                ></div>
            </div>
        </div>
    );
};

export default CandidateNav;
