export let sportsInfoDTO = {
    typeOfCompetitionLookupId: "",
    nameOfCompetitionLookupId: "",
    positionMedalLookupId: "",
    tournamentStartDate: "",
    tournamentEndDate: "",
    certificateVerificationStatus: "",
    sportsCertiVerificationCityLookupId: "",
    sportsCertiReportNo: "",
    dateOfIssue: "",
    eligibleForGroup: [],
    acknowledgementNumber: "",
    dateOfSubmission: "",
    flgDeleted: false
 };