/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import numberformat from "../labels/serial_nos_en.json";
import _ from "lodash/fp";
import DatePicker from "react-datepicker";
import sportsInformationLable from "../labels/sports_details_en.json";
import { setSportsDetails, clearSportForm } from "../actions/profileAction";
import { sportsInfoDTO } from "../constants/sportsInfoDTO";
import dayjs from 'dayjs';
import { getLookOptionsForADropdown } from "../../helpers/LookupOptionHelper";
import { alphanumeric, certificateNumberRegex } from '../../common/constants/regex';
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/advertisementTable.css";

const casteCertificationMaxLength = 25;
const onlyParticipationInGamesIds = [1344, 1345];
const participationPositionId = 1371;

const SportsDetails = () => {
    const { register, handleSubmit, errors, control, watch, setValue } = useForm({
        mode: "onChange"
    });
    const dispatch = useDispatch();
    let { editSportsData, meritoriousSportsmanDtoList, sportsTableIndex, profileCreationAllLookupData, clearSportDTO } = useSelector(state => state.profile, shallowEqual);
    const [formStatus, setFormStatus] = useState('add');
    const [SportsState, setSportState] = useState(sportsInfoDTO);
    const [eligibleForGroups, setEligibleForGroups] = useState([false, false, false, false])
    const [isEligibleValid, setEligibleValidation] = useState(false)
    const [certificateVerificationStatus, setCertificateVerificationStatus] = useState("Yes")

    const sportsSectionSubmit = (data, status) => {
        if (eligibleForGroups.includes(true)) {
            let newSportRow = {
                ...data,
                certificateVerificationStatus: (data.certificateVerificationStatus != undefined ? (data.certificateVerificationStatus === 'Yes' ? true : false) : undefined),
                flgDeleted: false
            }
            if (status === 'add') {
                let updatedMeritoriousSportsmanDtoList = meritoriousSportsmanDtoList ? [...meritoriousSportsmanDtoList, newSportRow] : [newSportRow];
                dispatch(setSportsDetails(updatedMeritoriousSportsmanDtoList))
            }
            if (status === 'edit') {
                let toBeUpdatedMeritoriousSportsmanDtoList = [...meritoriousSportsmanDtoList];
                toBeUpdatedMeritoriousSportsmanDtoList[sportsTableIndex] = newSportRow;
                dispatch(setSportsDetails(toBeUpdatedMeritoriousSportsmanDtoList));
            }

            setSportState(sportsInfoDTO)
            setValue('sportsCertiReportNo', '');
            setValue('eligibleForGroup', '');
            setValue('acknowledgementNumber', '');
            setValue('eligibleForGroup', [false, false, false, false])
            setFormStatus('add');
            setEligibleForGroups([false, false, false, false]);
            setEligibleValidation(false);
            setValue("certificateVerificationStatus", "Yes")
        } else {
            setEligibleValidation(watch("eligibleForGroup") && watch("eligibleForGroup").includes(false))
        }
    }

    const handleSportsDetailsChange = (evt) => {
        const value = evt.target.value;
        setSportState({
            ...SportsState,
            [evt.target.name]: value
        });
    }

    useEffect(() => {
           setValue("typeOfCompetitionLookupId", "")
           setValue("nameOfCompetitionLookupId", "")
            setValue('sportsCertiReportNo', '');
            setValue('acknowledgementNumber', '');
            setValue('eligibleForGroup', [false, false, false, false])
            setFormStatus('add');
            setEligibleForGroups([false, false, false, false]);
            setSportState(sportsInfoDTO)
            dispatch(clearSportForm(false));
    }, [clearSportDTO])

    useEffect(() => {
        if (editSportsData && editSportsData.typeOfCompetitionLookupId) {
            setSportState(editSportsData);
            setCurrentCompetitionNamesOptions(editSportsData.typeOfCompetitionLookupId);
            let list = [false, false, false, false]
            if (sportsTableIndex >= 0) {
                setFormStatus('edit');
                editSportsData.eligibleForGroup.map((ele, index) => {
                    if (ele) {
                        list[index] = true
                    }
                })
            }
            setValue('tournamentStartDate', editSportsData.tournamentStartDate ? dayjs(editSportsData.tournamentStartDate).toDate() : "");
            setValue('tournamentEndDate', editSportsData.tournamentStartDate ? dayjs(editSportsData.tournamentEndDate).toDate() : "");
            setValue('dateOfIssue', editSportsData.dateOfIssue ? dayjs(editSportsData.dateOfIssue).toDate() : "");
            setValue('dateOfSubmission', editSportsData.dateOfSubmission ? dayjs(editSportsData.dateOfSubmission).toDate() : "");
            setValue('sportsCertiReportNo', editSportsData.sportsCertiReportNo);
            setValue('eligibleForGroup', list);
            setValue('acknowledgementNumber', editSportsData.acknowledgementNumber);
            setValue('typeOfCompetitionLookupId', editSportsData.typeOfCompetitionLookupId);
            let status = editSportsData.certificateVerificationStatus != undefined ? (editSportsData.certificateVerificationStatus ? "Yes" : "No") : "";
            setValue('certificateVerificationStatus', status, { shouldValidate: true });
            setEligibleForGroups(list);
            setCertificateVerificationStatus(status)
        }

    }, [editSportsData])

    const changeState = (evt, index) => {
        eligibleForGroups[index] = evt.target.checked;
        setEligibleForGroups(eligibleForGroups);
        setValue('eligibleForGroup', eligibleForGroups);
    }

    /** OAS-662 */
    const competitionTypesOptions = getLookOptionsForADropdown("competition_type", profileCreationAllLookupData);
    const competitionNameAllOptions = getLookOptionsForADropdown("competition_name", profileCreationAllLookupData);
    const positionMedalOptions = getLookOptionsForADropdown("position_medal", profileCreationAllLookupData);
    const sportsCenterName = getLookOptionsForADropdown("sports_cc_center_district", profileCreationAllLookupData);
    let [competitionNamesOptions, setCompetitionNamesOptions] = useState([]);

    const handleCompetitionTypeChange = (e) => {
        handleSportsDetailsChange(e);
        setCurrentCompetitionNamesOptions(e.target.value);
    }

    const setCurrentCompetitionNamesOptions = (selectedCompetiotionTypeLookupId) => {
        let currentCompetitionNamesOptions = competitionNameAllOptions && competitionNameAllOptions.filter((competition) => {
            return Number(competition.parentId) === Number(selectedCompetiotionTypeLookupId)
        });
        setCompetitionNamesOptions(currentCompetitionNamesOptions);
    }

    /** OAS-662 */
    return (
        <div className="form-row">
            <div className="col-md-3">
                <label className="LeftText">{numberformat["1.23.1"]}&nbsp; {sportsInformationLable.Type_Of_Competition}<span className="redColor">*</span></label>
                <select className="form-control" name="typeOfCompetitionLookupId" ref={register({ required: true })} value={SportsState.typeOfCompetitionLookupId} onInput={handleCompetitionTypeChange}>
                    <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                    {
                        competitionTypesOptions && competitionTypesOptions
                            .sort((a, b) => a.sortOrder < b.sortOrder ? - 1 : 1)
                            .map((ele) =>
                                <option key={ele.id} value={ele.id}>{ele.label}</option>
                            )
                    }
                </select>
                {_.get("typeOfCompetitionLookupId.type", errors) === "required" && (
                    <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                )}
            </div>
            <div className="col-md-3">
                <label className="LeftText">{numberformat["1.23.2"]}&nbsp; {sportsInformationLable.Name_Of_Competition}<span className="redColor">*</span></label>
                <select className="form-control" name="nameOfCompetitionLookupId"
                    ref={register({ required: !(competitionNamesOptions && competitionNamesOptions.length === 0) })}
                    value={SportsState.nameOfCompetitionLookupId}
                    onInput={handleSportsDetailsChange}
                    disabled={competitionNamesOptions && competitionNamesOptions.length === 0}
                >
                    <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                    {
                        competitionNamesOptions && competitionNamesOptions
                            .sort((a, b) => a.sortOrder < b.sortOrder ? - 1 : 1)
                            .map((ele) =>
                                <option key={ele.id} value={ele.id}>{ele.label}</option>
                            )
                    }
                </select>
                {_.get("nameOfCompetitionLookupId.type", errors) === "required" && (
                    <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                )}
            </div>
            <div className="col-md-3">
                <label className="LeftText">{numberformat["1.23.3"]}&nbsp; {sportsInformationLable.Position_Medal}<span className="redColor">*</span></label>
                <select className="form-control" name="positionMedalLookupId" ref={register({ required: true })} value={SportsState.positionMedalLookupId} onInput={handleSportsDetailsChange}>
                    <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                    {
                        positionMedalOptions && positionMedalOptions
                            .filter((position) => {
                                return onlyParticipationInGamesIds.includes(Number(watch("typeOfCompetitionLookupId")))
                                    ?
                                    Number(position.id) === participationPositionId
                                    :
                                    Number(position.id) !== participationPositionId
                            })
                            .sort((a, b) => a.sortOrder < b.sortOrder ? - 1 : 1)
                            .map((ele, index) =>
                                <option key={index} value={ele.id}>{ele.label}</option>
                            )
                    }
                </select>
                {_.get("positionMedalLookupId.type", errors) === "required" && (
                    <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                )}
            </div>
            <div className="col-md-3">
                <label className="LeftText">{numberformat["1.23.4"]}&nbsp; {sportsInformationLable.TournamentStartDate}<span className="redColor">*</span></label>
                <Controller
                    name="tournamentStartDate"
                    key={SportsState.tournamentStartDate ? "tournamentStartDate" : "tournamentStartDate1"}
                    defaultValue={SportsState.tournamentStartDate ? dayjs(SportsState.tournamentStartDate).toDate() : ""}
                    rules={{ required: true }}
                    control={control}
                    render={({ onChange, value }) => (
                        <DatePicker
                            selected={fromUTCDate(value)}
                            onChange={(e) => {
                                setSportState({
                                    ...SportsState,
                                    tournamentStartDate: toUTCDate(e)
                                }); onChange(toUTCDate(e))
                            }}
                            showMonthDropdown
                            showYearDropdown
                            placeholderText={commonLabels.DATE_FORMAT}
                            dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                            className="form-control"
                            maxDate={watch("tournamentEndDate") || new Date()}
                        />
                    )}
                />
                {_.get("tournamentStartDate.type", errors) === "required" && (
                    <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                )}
            </div>
            <div className="col-md-3 mb-3 mt-3">
                <label className="LeftText">{numberformat["1.23.5"]}&nbsp; {sportsInformationLable.TournamentEndDate}<span className="redColor">*</span></label>
                <Controller
                    name="tournamentEndDate"
                    control={control}
                    rules={{ required: true }}
                    key={SportsState.tournamentEndDate ? "tournamentEndDate" : "tournamentEndDate1"}
                    defaultValue={SportsState.tournamentEndDate ? dayjs(SportsState.tournamentEndDate).toDate() : ""}
                    render={({ onChange, value }) => (
                        <DatePicker
                            selected={fromUTCDate(value)}
                            onChange={(e) => {
                                setSportState({
                                    ...SportsState,
                                    tournamentEndDate: toUTCDate(e)
                                }); onChange(toUTCDate(e))
                            }}
                            showMonthDropdown
                            showYearDropdown
                            placeholderText={commonLabels.DATE_FORMAT}
                            dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                            className="form-control"
                            minDate={watch("tournamentStartDate") || new Date()}
                            maxDate={new Date()}
                        />
                    )}
                />
                {_.get("tournamentEndDate.type", errors) === "required" && (
                    <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                )}
            </div>
            {!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) ?
                <div className="col-md-3 mb-3 mt-3">
                    <label className="LeftText">{numberformat["1.23.6"]}&nbsp; {sportsInformationLable.CertificateVerificationStatus}<span className="redColor">*</span></label>
                    <br></br>
                    <br></br>
                    <label className="mr-2">
                        <input
                            type="radio"
                            checked={certificateVerificationStatus === "Yes" ? true : false}
                            onChange={(e) => { setCertificateVerificationStatus(e.target.value) }}
                            className="RadioText"
                            value={"Yes"}
                            name="certificateVerificationStatus"
                            key="certificateVerificationStatus1"
                            ref={register({ required: true })}
                        />&nbsp;{sportsInformationLable.YES}</label>

                    <label>
                        <input
                            type="radio"
                            checked={certificateVerificationStatus === "No" ? true : false}
                            onChange={(e) => { setCertificateVerificationStatus(e.target.value) }}
                            name="certificateVerificationStatus"
                            key="certificateVerificationStatus2"
                            value={"No"}
                            className="mr-1"
                            ref={register({ required: true })} />{sportsInformationLable.NO}</label>
                </div> : ""}
                {!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) ?
                <div className="col-md-3 mb-3">
                    <label className="LeftText">{numberformat["1.23.7"]}&nbsp; {sportsInformationLable.SportsCertiVerificationCityLookupId}<span className="redColor">*</span></label>
                    <select className="form-control" name="sportsCertiVerificationCityLookupId" ref={register({ required: true })} value={SportsState.sportsCertiVerificationCityLookupId} onInput={handleSportsDetailsChange}>
                        <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                        {sportsCenterName && sportsCenterName.map((ele, index) =>
                            <option key={index} value={ele.id}>{ele.label}</option>
                        )}
                    </select>
                    {_.get("sportsCertiVerificationCityLookupId.type", errors) === "required" && (
                        <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                </div> : ""}
            {!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) && certificateVerificationStatus === "Yes" ?
                <div className="col-md-3 mb-3 mt-3">
                    <label className="LeftText">{numberformat["1.23.8"]}&nbsp; {sportsInformationLable.SportsCertiReportNo}<span className="redColor">*</span></label>
                    <input className="form-control" name="sportsCertiReportNo" defaultValue={SportsState.sportsCertiReportNo} ref={register({ required: true, pattern: certificateNumberRegex })} maxLength={casteCertificationMaxLength} />
                    {_.get("sportsCertiReportNo.type", errors) === "required" && (
                        <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                    {_.get("sportsCertiReportNo.type", errors) === "pattern" && (
                        <p className="errorText">{sportsInformationLable.VALIDATION_MESSAGE}</p>
                    )}
                </div> : ""}
            {!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) && certificateVerificationStatus === "No" ?
                <div className="col-md-3 mb-3 mt-3">
                    <label className="LeftText">{numberformat["1.23.8"]}&nbsp; {sportsInformationLable.AcknowledgementNumber}<span className="redColor">*</span></label>
                    <input className="form-control" name="acknowledgementNumber" defaultValue={SportsState.acknowledgementNumber} ref={register({ required: true, pattern: alphanumeric })} maxLength={casteCertificationMaxLength} />
                    {_.get("acknowledgementNumber.type", errors) === "required" && (
                        <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                    {_.get("acknowledgementNumber.type", errors) === "pattern" && (
                        <p className="errorText">{sportsInformationLable.VALIDATION_MESSAGE}</p>
                    )}
                </div> : ""}
            {!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) && certificateVerificationStatus === "Yes" ?
                <div className="col-md-3 mb-3">
                    <label className="LeftText">{numberformat["1.23.9"]}&nbsp; {sportsInformationLable.DateOfIssue}<span className="redColor">*</span></label>
                    <Controller
                        name="dateOfIssue"
                        control={control}
                        rules={{ required: true }}
                        key={SportsState.dateOfIssue ? "dateOfIssue" : "dateOfIssue1"}
                        defaultValue={SportsState.dateOfIssue ? dayjs(SportsState.dateOfIssue).toDate() : ""}
                        render={({ onChange, value }) => (
                            <DatePicker
                                selected={fromUTCDate(value)}
                                onChange={(e) => {
                                    setSportState({
                                        ...SportsState,
                                        dateOfIssue: toUTCDate(e)
                                    }); onChange(toUTCDate(e))
                                }}
                                showMonthDropdown
                                showYearDropdown
                                placeholderText={commonLabels.DATE_FORMAT}
                                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                                className="form-control"
                                minDate={watch("tournamentEndDate")}
                                maxDate={new Date()}
                            />
                        )}
                    />
                    {_.get("dateOfIssue.type", errors) === "required" && (
                        <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                </div> : ""}
            {!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) && certificateVerificationStatus === "No" ?
                <div className="col-md-3 mb-3">
                    <label className="LeftText">{numberformat["1.23.9"]}&nbsp; {sportsInformationLable.DateOfSubmission}<span className="redColor">*</span></label>
                    <Controller
                        name="dateOfSubmission"
                        control={control}
                        key={SportsState.dateOfSubmission ? "dateOfSubmission" : "dateOfSubmission1"}
                        defaultValue={SportsState.dateOfSubmission ? dayjs(SportsState.dateOfSubmission).toDate() : ""}
                        rules={{ required: true }}
                        render={({ onChange, value }) => (
                            <DatePicker
                                selected={fromUTCDate(value)}
                                onChange={(e) => {
                                    setSportState({
                                        ...SportsState,
                                        dateOfSubmission: toUTCDate(e)
                                    }); onChange(toUTCDate(e))
                                }}
                                showMonthDropdown
                                showYearDropdown
                                placeholderText={commonLabels.DATE_FORMAT}
                                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                                className="form-control"
                                minDate={watch("tournamentEndDate")}
                                maxDate={new Date()}
                            />
                        )}
                    />
                    {_.get("dateOfSubmission.type", errors) === "required" && (
                        <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                </div> : ""}
            <div className={!onlyParticipationInGamesIds.includes(Number(watch('typeOfCompetitionLookupId'))) ? "col-md-3 mb-3 " : "col-md-3 mb-3 mt-3"}>
                <div className="d-flex">
                    <label className="LeftText">{numberformat["1.23.10"]}&nbsp; {sportsInformationLable.EligibleForGroup}<span className="redColor">*</span></label>
                </div>{
                    sportsInformationLable.eligibilityGroup.map(
                        (ele, index) =>
                            <div key={index} className="form-check form-check-inline">
                                <input className="form-check-input"
                                    type="checkbox"
                                    checked={eligibleForGroups[index]}
                                    defaultValue={ele.value}
                                    name={"eligibleForGroup." + index}
                                    ref={register({ validate: value => (value == true ||  watch("eligibleForGroup") && watch("eligibleForGroup").includes(true))  ? setEligibleValidation(false) : setEligibleValidation(true) })}
                                    onChange={(e) => changeState(e, index)}
                                />
                                <label className="form-check-label" />{ele.label}
                            </div>   
                    )
                }
                {watch("eligibleForGroup")}
                {isEligibleValid ? <p className="errorText">{sportsInformationLable.MANDATORY_FIELD_MESSAGE}</p> : ""}
            </div>
            <div className="col-md-12 d-flex justify-content-center">
                {formStatus === 'add' ?
                    <button className="btn btn-primary f12" type="button" id="button-addon2" onClick={handleSubmit((e) => { sportsSectionSubmit(e, 'add') })} >{sportsInformationLable.ADD}</button> :
                    <button className="btn btn-primary f12" type="button" id="button-addon2" onClick={handleSubmit((e) => { sportsSectionSubmit(e, 'edit') })} >{sportsInformationLable.UPDATE}</button>
                }
            </div>
        </div>
    )
}

export default SportsDetails;