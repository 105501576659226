/* eslint-disable */
import React, { useEffect, useState } from "react";
import profileadressinformation from '../labels/adress_information_en.json';
import profile from '../labels/personal_information_en.json';
import '../../styles/profile.css'
import numberformat from '../labels/serial_nos_en.json';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { addressAction, setDistrictDropdown, setDistrictDropdownCorrespondenceAddress, setTalukaDropdown, setTalukaDropdownCorrespondenceAddress, setVillageDropdown, setVillageDropdownCorrespondenceAddress } from '../actions/profileAction';
import { getAddressInformation } from '../actions/profileAction';
import _ from "lodash/fp";
import { pincode, addressValidation ,onlyCharacters } from '../../common/constants/regex'
import ModuleHide from "../../common/components/ModuleHide";
import "../../styles/advertisementTable.css";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";

function AddressInformation() {
  const { register, errors, handleSubmit, setValue,clearErrors } = useForm({
    mode: "onChange"
    });

  // module-enable-disable
  const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
  const [arraymModule , setarraymModule] = useState({})

  useEffect(()=>{
      setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "ADDRESS_INFO")[0])
  },[moduleList])

  const [permenantAddressState, setPermenantAddressState] = useState({
    permenantHouseNo: "",
    permenantBuildingName: "",
    permenantRoadOrPo: "",
    permenantArea: "",
    permenantLandmark: "",
    permenantState: "",
    permenantDistrict: "",
    permenantTaluka: "",
    permenantVillageCity: "",
    permenantDistrict1: "",
    permenantTaluka1: "",
    permenantVillageCity1: "",
    permenantPin: "",
  });

  const [correspondenceAddressState, setCorrespondenceAddressState] = useState({
    correspondenceHouseNo: "",
    correspondenceBuildingName: "",
    correspondenceRoadOrPo: "",
    correspondenceArea: "",
    correspondenceLandmark: "",
    correspondenceState: "",
    correspondenceDistrict: "",
    correspondenceTaluka: "",
    correspondenceVillageCity: "",
    correspondenceDistrict1: "",
    correspondenceTaluka1: "",
    correspondenceVillageCity1: "",
    correspondencePin: ""
  });

  const handlePermenantAddressChange = (evt) => {
    const value = evt.target.value;
    if (evt.target.type === "select-one") {
      switch (evt.target.name) {
        case "permenantState":
          dispatch(setDistrictDropdown(value));
        case "permenantDistrict":
          dispatch(setTalukaDropdown(value));
        case "permenantTaluka":
          dispatch(setVillageDropdown(value));
      }
    };
    if (isSameAsAbove) {
      switch (evt.target.name) {
        case "permenantState":
          dispatch(setDistrictDropdownCorrespondenceAddress(value));
        case "permenantDistrict":
          dispatch(setTalukaDropdownCorrespondenceAddress(value));
        case "permenantTaluka":
          dispatch(setVillageDropdownCorrespondenceAddress(value));
      }
    };
    setPermenantAddressState({
      ...permenantAddressState,
      [evt.target.name]: value
    });
    if(isSameAsAbove){
      sameAsAboveChanges(evt.target.name, evt.target.value )
    }
    if(evt.target.name === "permenantState"){
      setPermenantAddressState({
      ...permenantAddressState,
      ["permenantState"]: value,
      ["permenantDistrict"]:'',
      ["permenantTaluka"]:'',
      ["permenantVillageCity"]:''
      });
      setValue("permenantDistrict",'')
      setValue("permenantTaluka",'')
      setValue("permenantVillageCity",'')
    }
  }

  function sameAsAboveChanges(name, value) {
    if(name === 'permenantHouseNo'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceHouseNo']:value
      })
      setValue("correspondenceHouseNo", value)
      if(!errors.permenantHouseNo){
        clearErrors("correspondenceHouseNo")
      }
    }
    
    if(name === 'permenantBuildingName'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceBuildingName']:value
      })
      setValue("correspondenceBuildingName", value)
      if(!errors.permenantBuildingName){
        clearErrors("correspondenceBuildingName")
      }
    }

    if(name === 'permenantRoadOrPo'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceRoadOrPo']:value
      })
      setValue("correspondenceRoadOrPo", value)
      if(!errors.permenantRoadOrPo){
        clearErrors("correspondenceRoadOrPo")
      }
    }

    if(name === 'permenantLandmark'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceLandmark']:value
      })
      setValue("correspondenceLandmark", value)
      if(!errors.permenantLandmark){
        clearErrors("correspondenceLandmark")
      }      
    }

    if(name === 'permenantPin'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondencePin']:value
      })
      setValue("correspondencePin", value)
      if(!errors.permenantPin){
        clearErrors("correspondencePin")
      }      
    }

    if(name === 'permenantState'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceState']:value
      })
      setValue("correspondenceState", value)
      clearErrors("correspondenceState")     
    }

    if(name === 'permenantDistrict'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceDistrict']:value
      })
      setValue("correspondenceDistrict", value)
      clearErrors("correspondenceDistrict")     
    }

    if(name === 'permenantTaluka'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceTaluka']:value
      })
      setValue("correspondenceTaluka", value)
      clearErrors("correspondenceTaluka")     
    }

    if(name === 'permenantVillageCity'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceVillageCity']:value
      })
      setValue("correspondenceVillageCity", value)
      clearErrors("correspondenceVillageCity")     
    }

    if(name === 'permenantDistrict1'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceDistrict1']:value
      })
      setValue("correspondenceDistrict1", value)
      if(!errors.permenantDistrict1){
        clearErrors("correspondenceDistrict1")
      }      
    }

    if(name === 'permenantTaluka1'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceTaluka1']:value
      })
      setValue("correspondenceTaluka1", value)
      if(!errors.permenantTaluka1){
        clearErrors("correspondenceTaluka1")
      }      
    }

    if(name === 'permenantVillageCity1'){
      setCorrespondenceAddressState({
        ...correspondenceAddressState,
        ['correspondenceVillageCity1']:value
      })
      setValue("correspondenceVillageCity1", value)
      if(!errors.permenantVillageCity1){
        clearErrors("correspondenceVillageCity1")
      }      
    }
    
  }

  const handleCorrespondenceAddressChange = (evt) => {
    const value = evt.target.value;
    if (evt.target.type === "select-one") {
      switch (evt.target.name) {
        case "correspondenceState":
          dispatch(setDistrictDropdownCorrespondenceAddress(value));
        case "correspondenceDistrict":
          dispatch(setTalukaDropdownCorrespondenceAddress(value));
        case "correspondenceTaluka":
          dispatch(setVillageDropdownCorrespondenceAddress(value));
      }
    };

    setCorrespondenceAddressState({
      ...correspondenceAddressState,
      [evt.target.name]: value
    });
  }

  const [isSameAsAbove, setIsSameAsAbove] = useState(false);
  const dispatch = useDispatch();
  const statesList = useSelector(state => state.profile.statesList);
  const districtList = useSelector(state => state.profile.districtList);
  const districtCorrespondenceList = useSelector(state => state.profile.districtCorrespondenceList);
  const talukaList = useSelector(state => state.profile.talukaList);
  const talukaCorrespondenceList = useSelector(state => state.profile.talukaCorrespondenceList);
  const villageList = useSelector(state => state.profile.villageList);
  const villageCorrospondenceList = useSelector(state => state.profile.villageCorrospondenceList)
  const isDisableAllTabSave = useSelector(state => state.profile.isDisableAllTabSave);

  function handleClick() {
    var toggleValue = !isSameAsAbove
    setIsSameAsAbove(toggleValue);
    if (toggleValue) {
      // Correspondance Address would be same as Permenant Address
      dispatch(setDistrictDropdownCorrespondenceAddress(permenantAddressState.permenantState));
      dispatch(setTalukaDropdownCorrespondenceAddress(permenantAddressState.permenantDistrict));
      dispatch(setVillageDropdownCorrespondenceAddress(permenantAddressState.permenantTaluka));
      setCorrespondenceAddressState({
        correspondenceHouseNo: permenantAddressState.permenantHouseNo,
        correspondenceBuildingName: permenantAddressState.permenantBuildingName,
        correspondenceRoadOrPo: permenantAddressState.permenantRoadOrPo,
        correspondenceArea: permenantAddressState.permenantArea,
        correspondenceLandmark: permenantAddressState.permenantLandmark,
        correspondenceState: permenantAddressState.permenantState,
        correspondenceDistrict: permenantAddressState.permenantDistrict,
        correspondenceTaluka: permenantAddressState.permenantTaluka,
        correspondenceVillageCity: permenantAddressState.permenantVillageCity,
        correspondencePin: permenantAddressState.permenantPin,
        correspondenceDistrict1: permenantAddressState.permenantDistrict1,
        correspondenceTaluka1: permenantAddressState.permenantTaluka1,
        correspondenceVillageCity1: permenantAddressState.permenantVillageCity1,
      })
      setValue("correspondenceHouseNo", permenantAddressState.permenantHouseNo)
      clearErrors("correspondenceHouseNo")
      setValue("correspondenceBuildingName", permenantAddressState.permenantBuildingName)
      clearErrors("correspondenceBuildingName")
      setValue("correspondenceRoadOrPo", permenantAddressState.permenantRoadOrPo)
      clearErrors("correspondenceRoadOrPo")
      setValue("correspondenceArea", permenantAddressState.permenantArea)
      clearErrors("correspondenceArea")
      setValue("correspondenceLandmark", permenantAddressState.permenantLandmark)
      clearErrors("correspondenceLandmark")
      setValue("correspondenceState", permenantAddressState.permenantState)
      clearErrors("correspondenceState")
      setValue("correspondenceDistrict", permenantAddressState.permenantDistrict)
      clearErrors("correspondenceDistrict")
      setValue("correspondenceTaluka", permenantAddressState.permenantTaluka)
      clearErrors("correspondenceTaluka")
      setValue("correspondenceVillageCity", permenantAddressState.permenantVillageCity)
      clearErrors("correspondenceVillageCity")
      setValue("correspondencePin", permenantAddressState.permenantPin)
      clearErrors("correspondencePin")
      setValue("correspondenceDistrict1", permenantAddressState.permenantDistrict1)
      clearErrors("correspondenceDistrict1")
      setValue("correspondenceTaluka1", permenantAddressState.permenantTaluka1)
      clearErrors("correspondenceTaluka1")
      setValue("correspondenceVillageCity1", permenantAddressState.permenantVillageCity1)
      clearErrors("correspondenceVillageCity1")
      setValue("correspondencePin", permenantAddressState.permenantPin)
      clearErrors("correspondencePin")
    }
  }
  
  let addressInformationResponse = useSelector(state => state.profile.addressInformationResponse);
  const autoPopulateSetDropdowns = () => {
    dispatch(setDistrictDropdown(addressInformationResponse.permenantAddressState.permenantState));
    dispatch(setTalukaDropdown(addressInformationResponse.permenantAddressState.permenantDistrict));
    dispatch(setVillageDropdown(addressInformationResponse.permenantAddressState.permenantTaluka));
    dispatch(setDistrictDropdownCorrespondenceAddress(addressInformationResponse.correspondenceAddressState.correspondenceState));
    dispatch(setTalukaDropdownCorrespondenceAddress(addressInformationResponse.correspondenceAddressState.correspondenceDistrict));
    dispatch(setVillageDropdownCorrespondenceAddress(addressInformationResponse.correspondenceAddressState.correspondenceTaluka));
  };

  const handleAddressInfoSubmit = (data) => {
    let addressInfoReq = {
      ...permenantAddressState,
      ...correspondenceAddressState,
      isSameAsAbove
    }
    dispatch(addressAction(addressInfoReq))
  }

  /** Get and set the Address Information (If already stored) on page load */
  useEffect(() => {
    dispatch(getAddressInformation());
  }, [])

  /** Get and set the latest Address Information on successful Save/Update */
  useEffect(() => {
    setPermenantAddressState({
      ...addressInformationResponse.permenantAddressState
    });

    setCorrespondenceAddressState({
      ...addressInformationResponse.correspondenceAddressState
    });
    setIsSameAsAbove(addressInformationResponse.isSameAsAbove);
    autoPopulateSetDropdowns();

  }, [addressInformationResponse]);

  return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
  (
    <form onSubmit={handleSubmit(handleAddressInfoSubmit)}>
      <div className="p-4 f12">
        <div>
          <h5 className="float-left m-1">{profileadressinformation.PERMENANT_ADDRESS}</h5>
        </div>
        <br />
        <hr/>
        <div className="form-row">
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.1"]}&nbsp;{profileadressinformation.ADDRESS_LINE_1}</label>
            <span className="redColor">*</span>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="permenantHouseNo" defaultValue={permenantAddressState.permenantHouseNo} ref={register({ required: true, pattern: addressValidation })} onChange={handlePermenantAddressChange}></input>
            {_.get("permenantHouseNo.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
            {_.get("permenantHouseNo.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.2"]}&nbsp;{profileadressinformation.ADDRESS_LINE_2}</label>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="permenantBuildingName" defaultValue={permenantAddressState.permenantBuildingName} ref={register({ required: false, pattern: addressValidation })} onChange={handlePermenantAddressChange}></input>
            {_.get("permenantBuildingName.type", errors) === "pattern" && (
              <p className="errorText"> {profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
          </div>
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.3"]}&nbsp;{profileadressinformation.ADDRESS_LINE_3}</label>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="permenantRoadOrPo" defaultValue={permenantAddressState.permenantRoadOrPo} ref={register({ required: false, pattern: addressValidation })} onChange={handlePermenantAddressChange}></input>
            {_.get("permenantRoadOrPo.type", errors) === "pattern" && (
              <p className="errorText"> {profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
            </div>
           <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.4"]}&nbsp;{profileadressinformation.LANDMARK}</label>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="permenantLandmark" defaultValue={permenantAddressState.permenantLandmark} ref={register({ required: false, pattern: addressValidation })} onChange={handlePermenantAddressChange}></input>
            {_.get("permenantLandmark.type", errors) === "pattern" && (
              <p className="errorText"> {profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.5"]}&nbsp;{profileadressinformation.STATE_UNION_TERRITORY}</label><span className="redColor">*</span>
            <select className="form-control" name="permenantState" value={permenantAddressState.permenantState} ref={register({ required: true })} onInput={handlePermenantAddressChange} >
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {statesList.length > 0 && statesList.sort((a,b) => a.statename < b.statename ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.statecode}>{ele.statename}</option>
              )}
            </select>
            {_.get("permenantState.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-9">
          {permenantAddressState && parseInt(permenantAddressState.permenantState) === 27 ?
          <div className="row">
          <div className="col-md-4 mb-3">
            <label className="float-left mb-0">{numberformat["2.6"]}&nbsp;{profileadressinformation.DISTRICT}</label><span className="redColor">*</span>
            <select className="form-control" name="permenantDistrict" value={permenantAddressState.permenantDistrict} ref={register({ required: true })} onInput={handlePermenantAddressChange} disabled={permenantAddressState.permenantState === ""}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {districtList.length > 0 && districtList.sort((a,b) => a.districtname < b.districtname ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.districtcode}>{ele.districtname}</option>
              )}
            </select>
            {_.get("permenantDistrict.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-4 mb-3">
            <label className="float-left mb-0">{numberformat["2.7"]}&nbsp;{profileadressinformation.TALUKA}</label><span className="redColor">*</span>
            <select className="form-control" name="permenantTaluka" value={permenantAddressState.permenantTaluka} ref={register({ required: true })} onInput={handlePermenantAddressChange} disabled={permenantAddressState.permenantDistrict === ""}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {talukaList.length > 0 && talukaList.sort((a,b) => a.subdistrictname < b.subdistrictname ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.subdistrictcode}>{ele.subdistrictname}</option>
              )}
            </select>
            {_.get("permenantTaluka.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-4 mb-3">
            <label className="float-left mb-0">{numberformat["2.8"]}&nbsp;{profileadressinformation.VILLAGE_CITY}</label><span className="redColor">*</span>
            <select className="form-control" name="permenantVillageCity" value={permenantAddressState.permenantVillageCity} ref={register({ required: true })} onInput={handlePermenantAddressChange} disabled={permenantAddressState.permenantTaluka === ""}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {villageList.length > 0 && villageList.sort((a,b) => a.villagename < b.villagename ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.villagecode}>{ele.villagename}</option>
              )}
            </select>
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
            {_.get("permenantVillageCity.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div> 
          </div>:
          <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="float-left mb-0">{numberformat["2.6"]}&nbsp;{profileadressinformation.DISTRICT}</label>
                  <input type="text" className="form-control" name="permenantDistrict1" ref={register({ required:false , pattern : onlyCharacters})} defaultValue={permenantAddressState.permenantDistrict1} maxLength={100} onChange={handlePermenantAddressChange} />
                  {_.get("permenantDistrict1.type", errors) === "pattern" && (
                  <p className="errorText">{profileadressinformation.ENTER_VALID_DISTRICT}</p>
                  )}
                  <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="float-left mb-0">{numberformat["2.7"]}&nbsp;{profileadressinformation.TALUKA}</label>
                  <input type="text" className="form-control" name="permenantTaluka1" ref={register({ required: false ,pattern : onlyCharacters})} defaultValue={permenantAddressState.permenantTaluka1} maxLength={100} onChange={handlePermenantAddressChange} />
                  {_.get("permenantTaluka1.type", errors) === "pattern" && (
                  <p className="errorText">{profileadressinformation.ENTER_VALID_TALUKA}</p>
                  )}
                  <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
                </div>
                <div className="col-md-4 mb-3">
                  <label className="float-left mb-0">{numberformat["2.8"]}&nbsp;{profileadressinformation.VILLAGE_CITY}</label>
                  <input type="text" className="form-control" name="permenantVillageCity1" ref={register({ required: false ,pattern : onlyCharacters})}  defaultValue={permenantAddressState.permenantVillageCity1} maxLength={100} onChange={handlePermenantAddressChange} />
                  {_.get("permenantVillageCity1.type", errors) === "pattern" && (
                  <p className="errorText">{profileadressinformation.ENTER_VALID_VILLAGE_CITY}</p>
                  )}
                  <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
                </div>
          </div> }
        </div>
        </div>
        <div className="form-row">
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.9"]}&nbsp;{profileadressinformation.PINCODE}</label><span className="redColor">*</span>
            <input type="text" maxLength={6} className="form-control" name="permenantPin" defaultValue={permenantAddressState.permenantPin} ref={register({ required: true, pattern: pincode })} onChange={handlePermenantAddressChange}></input>
            {_.get("permenantPin.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_PINCODE} </p>
            )}
            {_.get("permenantPin.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-6 mb-6">
            <h5 className="float-left m-1">{profileadressinformation.CORRESPONDANCE_ADDRESS}</h5>
          </div>
          <div className="col-md-6 mb-6 f15">
            <div className="custom-control custom-checkbox mb-3">
              <input type="checkbox" className="custom-control-input" id="customCheck" name="isSameAsAbove" value={isSameAsAbove} ref={register({ required: false })} checked={isSameAsAbove} onClick={() => handleClick()} />
              <label className="custom-control-label" htmlFor="customCheck">{profileadressinformation.SAME_AS_ABOVE}</label>
            </div>
          </div>
        </div>
        <hr/>
        <div className="form-row">
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.1"]}&nbsp;{profileadressinformation.ADDRESS_LINE_1}</label><span className="redColor">*</span>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="correspondenceHouseNo" defaultValue={correspondenceAddressState.correspondenceHouseNo} ref={register({ required: true, pattern: addressValidation })} onChange={handleCorrespondenceAddressChange} disabled={isSameAsAbove}></input>
            {_.get("correspondenceHouseNo.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
            {_.get("correspondenceHouseNo.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.2"]}&nbsp;{profileadressinformation.ADDRESS_LINE_2}</label>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="correspondenceBuildingName" defaultValue={correspondenceAddressState.correspondenceBuildingName} ref={register({ required: false, pattern: addressValidation })} onChange={handleCorrespondenceAddressChange} disabled={isSameAsAbove}></input>
            {_.get("correspondenceBuildingName.type", errors) === "pattern" && (
              <p className="errorText"> {profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
          </div>
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.3"]}&nbsp;{profileadressinformation.ADDRESS_LINE_3}</label>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="correspondenceRoadOrPo" defaultValue={correspondenceAddressState.correspondenceRoadOrPo} ref={register({ required: false, pattern: addressValidation })} onChange={handleCorrespondenceAddressChange} disabled={isSameAsAbove}></input>
            {_.get("correspondenceRoadOrPo.type", errors) === "pattern" && (
              <p className="errorText"> {profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
          </div>
           <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.4"]}&nbsp;{profileadressinformation.LANDMARK} </label>
            <input type="text" minLength={1} maxLength={100} className="form-control" name="correspondenceLandmark" defaultValue={correspondenceAddressState.correspondenceLandmark} ref={register({ required: false, pattern: addressValidation })} onChange={handleCorrespondenceAddressChange} disabled={isSameAsAbove}></input>
            {_.get("correspondenceLandmark.type", errors) === "pattern" && (
              <p className="errorText"> {profileadressinformation.ENTER_VALID_ADDRESS}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.5"]}&nbsp;{profileadressinformation.STATE_UNION_TERRITORY}</label><span className="redColor">*</span>
            <select className="form-control" name="correspondenceState" value={correspondenceAddressState.correspondenceState} ref={register({ required: true })} onInput={(e) => { handleCorrespondenceAddressChange(e); }} disabled={isSameAsAbove}>
              <option value="">{advertisementDetailsLabel.SELECT}</option>
              {statesList.length > 0 && statesList.sort((a,b) => a.statename < b.statename ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.statecode}>{ele.statename}</option>
              )}
            </select>
            {_.get("correspondenceState.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-9">
          {correspondenceAddressState && parseInt(correspondenceAddressState.correspondenceState) === 27 ?
          <div className="row">
          <div className="col-md-4 mb-3">
            <label className="float-left mb-0">{numberformat["2.6"]}&nbsp;{profileadressinformation.DISTRICT}</label><span className="redColor">*</span>
            <select className="form-control" name="correspondenceDistrict" value={correspondenceAddressState.correspondenceDistrict} ref={register({ required: true })} onInput={handleCorrespondenceAddressChange} disabled={isSameAsAbove || correspondenceAddressState.correspondenceState === ""}>
              <option value="">{advertisementDetailsLabel.SELECT}</option>
              {districtCorrespondenceList.length > 0 && districtCorrespondenceList.sort((a,b) => a.districtname < b.districtname ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.districtcode}>{ele.districtname}</option>
              )}
            </select>
            {_.get("correspondenceDistrict.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-4 mb-3">
            <label className="float-left mb-0">{numberformat["2.7"]}&nbsp;{profileadressinformation.TALUKA}</label><span className="redColor">*</span>
            <select className="form-control" name="correspondenceTaluka" value={correspondenceAddressState.correspondenceTaluka} ref={register({ required: true })} onInput={handleCorrespondenceAddressChange} disabled={isSameAsAbove || correspondenceAddressState.correspondenceDistrict === ""}>
              <option value="">{advertisementDetailsLabel.SELECT}</option>
              {talukaCorrespondenceList.length > 0 && talukaCorrespondenceList.sort((a,b) => a.subdistrictname < b.subdistrictname ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.subdistrictcode}>{ele.subdistrictname}</option>
              )}
            </select>
            {_.get("correspondenceTaluka.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
          <div className="col-md-4 mb-3">
            <label className="float-left mb-0">{numberformat["2.8"]}&nbsp;{profileadressinformation.VILLAGE_CITY}</label><span className="redColor">*</span>
            <select className="form-control" name="correspondenceVillageCity" value={correspondenceAddressState.correspondenceVillageCity} ref={register({ required: true })} onInput={handleCorrespondenceAddressChange} disabled={isSameAsAbove || correspondenceAddressState.correspondenceTaluka === ""}>
              <option value="">{advertisementDetailsLabel.SELECT}</option>
              {villageCorrospondenceList.length > 0 && villageCorrospondenceList.sort((a,b) => a.villagename < b.villagename ? -1 : 1).map((ele, i) =>
                <option key={i} value={ele.villagecode}>{ele.villagename}</option>
              )}
            </select>
            {_.get("correspondenceVillageCity.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
        </div> : 
        <div className="row">
        <div className="col-md-4 mb-3">
          <label className="float-left mb-0">{numberformat["2.6"]}&nbsp;{profileadressinformation.DISTRICT}</label>
          <input type="text" className="form-control" name="correspondenceDistrict1" defaultValue={correspondenceAddressState.correspondenceDistrict1} maxLength={100} ref={register({ required: false , pattern: onlyCharacters})}  disabled={isSameAsAbove} onChange={handleCorrespondenceAddressChange}/>
          {_.get("correspondenceDistrict1.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_DISTRICT}</p>
          )}
          <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
        </div>
        <div className="col-md-4 mb-3">
          <label className="float-left mb-0">{numberformat["2.7"]}&nbsp;{profileadressinformation.TALUKA}</label>
          <input type="text" className="form-control" name="correspondenceTaluka1" 
          maxLength={100} defaultValue={correspondenceAddressState.correspondenceTaluka1} ref={register({ required: false ,pattern: onlyCharacters})}  disabled={isSameAsAbove} onChange={handleCorrespondenceAddressChange}/>
          {_.get("correspondenceTaluka1.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_TALUKA}</p>
          )}
          <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
        </div>
        <div className="col-md-4 mb-3">
          <label className="float-left mb-0">{numberformat["2.8"]}&nbsp;{profileadressinformation.VILLAGE_CITY}</label>
          <input type="text" className="form-control" name="correspondenceVillageCity1" 
          maxLength={100} defaultValue={correspondenceAddressState.correspondenceVillageCity1} ref={register({ required: false ,pattern: onlyCharacters})} disabled={isSameAsAbove} onChange={handleCorrespondenceAddressChange}/>
          {_.get("correspondenceVillageCity1.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_VILLAGE_CITY}</p>
          )}
          <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
        </div>
      </div>}
        </div>
        </div>
        <div className="form-row">
          <div className="col-md-3 mb-3">
            <label className="float-left mb-0">{numberformat["2.9"]}&nbsp;{profileadressinformation.PINCODE}</label><span className="redColor">*</span>
            <input type="text" maxLength={6} className="form-control" name="correspondencePin" defaultValue={correspondenceAddressState.correspondencePin} ref={register({ required: true, pattern: pincode })} onChange={handleCorrespondenceAddressChange} disabled={isSameAsAbove}></input>
            {_.get("correspondencePin.type", errors) === "pattern" && (
              <p className="errorText">{profileadressinformation.ENTER_VALID_PINCODE}</p>
            )}
            {_.get("correspondencePin.type", errors) === "required" && (
              <p className="errorText">{profileadressinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            <span className="float-left ml-1 mt-1 f13"> {profile["INFO_HELP_ERROR_TEXT "]}</span>
          </div>
        </div>
        <p className="paraCss"></p>
        <div className="text-left">
          <button type="submit" className="btn btn-primary m-1" disabled={isDisableAllTabSave} > {profile.SAVE}</button>
        </div>
      </div>
    </form>
  );
}

export default AddressInformation;


