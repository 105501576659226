import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {cropperImageURL, setCropperSignaturesrc, showImageSignatureFlag} from '../actions/profileAction';
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/advertisementTable.css";

class ImageCropper extends PureComponent {
    state = {
      src: null,
      cropImg: {
        x: 0,
        y:0,
        unit: '%',
        width: 1,
        height:0
      },
      cropSign: {
        x: 0,
          y:0,
          unit: '%',
          width: 1,
          height:0
      }
    };

  setDefaultCrop = ()=>{
    this.setState(prevState => ({
      cropImg: {                   
          ...prevState.cropImg,    
          x: 0,
          y:0,
          unit: '%',
          width: 1,
          height:0
      },
      cropSign: {                   
        ...prevState.cropSign,    
        x: 0,
        y:0,
        unit: '%',
        width: 1,
        height:0
    }
  }));
    this.props.showImageSignatureFlag(false, false);
  };

  onImageLoaded = image => {
   this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (percentCrop) => {
    if(this.props.isShowImageCropper)
    {
      this.setState({ cropImg: percentCrop,cropSign: {
         x: 0,
        y:0,
        unit: '%',
        width: 1,
        height:0
      } })
    }
    if(this.props.isShowSignatureCropper)
    {
      this.setState({cropSign: percentCrop ,cropImg: {
        x: 0,
        y:0,
        unit: '%',
        width: 1,
        height:0
      }})
    }
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width!==0 && crop.height!==0) {
      if(this.props.isShowImageCropper )
    {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
    if(this.props.isShowSignatureCropper )
    {
      const croppedSignUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedSignUrl });
    }
    }
    else
    {
      if(this.props.isShowImageCropper )
      {
        const croppedImageUrl = this.props.imagesrc;
        this.setState({ croppedImageUrl });
      }
      if(this.props.isShowSignatureCropper )
      {
        const croppedSignUrl = this.props.signatureSRC;
        this.setState({ croppedSignUrl });
      }
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  setCropperImage = (cropImage) => {
    let obj = {}
     obj.cropImage = cropImage
     this.props.cropperImageURL(obj);
     this.props.showImageSignatureFlag(false, false);
     this.setDefaultCrop()
  };

  setCropperSignature = (cropSignature) => {
    let obj = {}
    obj.cropSignature = cropSignature
    this.props.setCropperSignaturesrc(obj);
    this.props.showImageSignatureFlag(false, false)
    this.setDefaultCrop();
  }

  render() {
    const { cropImg,croppedImageUrl} = this.state;
    const { cropSign,croppedSignUrl} = this.state;
    
    return (
      <div className="App">
        {this.props.isShowImageCropper ?
          <ReactCrop
          src={this.props.imagesrc}
          crop={cropImg}
          ruleOfThirds
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
        />  : ''}
        {this.props.isShowSignatureCropper ? 
         <ReactCrop
         src={this.props.signatureSRC}
         crop={cropSign}
         ruleOfThirds
         onImageLoaded={this.onImageLoaded}
         onComplete={this.onCropComplete}
         onChange={this.onCropChange}
       />  : ''}
        <br></br>
        <br></br>
        {this.props.isShowImageCropper && this.props.imagesrc ?
        <div>
          <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>this.setCropperImage(e,croppedImageUrl)}>{commonLabels.SET_IMAGE}</button>
          <button type="button" className="btn btn-secondary btn-sm position-relative cropImgCss" onClick={()=>{this.setDefaultCrop()}} >{commonLabels.CANCEL}</button>
        </div>
        : ''}
         {this.props.isShowSignatureCropper && this.props.signatureSRC ?
        <div>
          <button type="button" className="btn btn-primary btn-sm" onClick={(e)=>this.setCropperSignature(e,croppedSignUrl)}>{commonLabels.SET_SIGNATURE}</button>
          <button type="button" className="btn btn-secondary btn-sm position-relative cropImgCss" onClick={()=>{this.setDefaultCrop()}} >{commonLabels.CANCEL}</button>
        </div> 
        :'' }
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        imagesrc : state.profile.imageSRC,
        signatureSRC: state.profile.signatureSRC,
        isShowImageCropper: state.profile.isShowImageCropper,
        isShowSignatureCropper: state.profile.isShowSignatureCropper
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cropperImageURL: bindActionCreators(cropperImageURL, dispatch),
        setCropperSignaturesrc: bindActionCreators(setCropperSignaturesrc, dispatch),
        showImageSignatureFlag: bindActionCreators(showImageSignatureFlag, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropper);