/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import experienceinformation from "../labels/experience_information_en.json";
import common from "../labels/common_en.json";
import { getLookupOptionLabel ,getDropdownForMpscSelection,getDropdownOptionForFullTime} from "../../helpers/LookupOptionHelper";
import dayjs from 'dayjs';
import { getDetailsForEditExperience,clearEditExperienceInfo, setExperienceTableList} from '../actions/profileAction';
import { Modal } from 'react-bootstrap';
import { BOOLEAN_OPTION, FULLTIME_OPTION } from '../../common/constants/dropdownOptions';
import { experienceInfoReqDTO } from "../constants/experienceInfoReqDTO";
import { toastr } from 'react-redux-toastr';
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/advertisementTable.css";

const ExperienceTabTable = (props) => {
  let { profileStatus, experiencesList, profileCreationAllLookupData, getJobDesignationList } = useSelector( state => state.profile, shallowEqual);
  const dispatch = useDispatch();
  let [showModal, setShowModal] = useState(false);
  let [deleteIndex, setDeleteIndex] = useState("");
  let [expList, setExpList] = useState([])
  let deleteExperienceRow = (experienceRowIndex) => {
    experiencesList[experienceRowIndex].flgDeleted = true;
    toastr.info(experienceinformation.EXPERIENCE_DELETED_NOTE);
    if(experiencesList[experienceRowIndex].id){
      expList.splice(experienceRowIndex, 1)
    }else{
      experiencesList.splice(experienceRowIndex, 1)
    }
    dispatch(clearEditExperienceInfo(experienceInfoReqDTO));
  }

  let editExperienceRow = (experienceRowIndex) => {
    props.setIsChangeFrom(false);
    props.setIsChangeTo(false);
    let experienceToBeEdited = experiencesList[experienceRowIndex];
    dispatch(getDetailsForEditExperience(experienceToBeEdited));
  }

  const modalConfirm = () => {
    deleteExperienceRow(deleteIndex);
    setDeleteIndex("");
    setShowModal(false);
  }

  useEffect(() => {
    if(experiencesList && experiencesList.length > 0){
      let list = []
      experiencesList.map(ele => {
        list.push(ele)
      })
      setExpList(list);
      dispatch(setExperienceTableList(list));
    }
  }, [experiencesList])
  
  return(
      <div className="border border-light text-center">
        <table className="table table-bordered m-0 position-relative" >
          <thead className="thead-light">
            <tr>
              {experienceinformation.experienceHeaderContent.map((ele, index) => 
                <th scope="col" key={index}>{ele}</th>
              )}
            </tr>
          </thead>
            <tbody className='f12'>
            {
              expList.map((ele,index) => {
                if(!ele.flgDeleted) {
                  return (
                    <tr key={index}>
                      <td className="word-break">{index+1}</td>
                      <td className="word-break">{ele.officeName}</td>
                      <td className="word-break">{ele && ele.designation }</td>
                      <td className="word-break">{getLookupOptionLabel(ele.apointmentNatureLookupId, profileCreationAllLookupData)}</td>
                      <td className="word-break">{getLookupOptionLabel(ele.jobNatureLookupId, profileCreationAllLookupData)}</td>
                      <td className="word-break">{getDropdownOptionForFullTime(ele.fullTimeLookupId, FULLTIME_OPTION)}</td>
                      <td className="word-break">{ele.payScale}</td>
                      <td className="word-break">{ele.gradePay}</td>
                      <td className="word-break">{ele.monthlyGrossSalary}</td>
                      <td className="word-break">{ele.employmentFrom ? dayjs(ele.employmentFrom).format(commonLabels.DATE_FORMAT): ""}</td>
                      <td className="word-break">{ele.employmentTo ? dayjs(ele.employmentTo).format(commonLabels.DATE_FORMAT): ""}</td>
                      <td className="word-break">{ele.expYears}</td>
                      <td className="word-break">{ele.expMonths}</td>
                      <td className="word-break">{ele.expDays}</td>
                      <td className="word-break">{getDropdownForMpscSelection(ele.flgMpscSelection, BOOLEAN_OPTION)}
                      </td>
                      <td className="word-break"><i className={`fa fa-lg btn-link btn fa-edit ${profileStatus ? "disabled-link" : ""}`} data-toggle="tooltip" title={profileStatus ? common.EDIT_DISALLOWED_TOOLTIP : common.EDIT_TOOLTIP} onClick={() => { editExperienceRow(index)}}/></td>
                      <td className="word-break"><i className={`fa fa-lg btn-link btn fa-trash ${profileStatus ? "disabled-link" : ""}`} data-toggle="tooltip" title={profileStatus ? common.DELETE_DISALLOWED_TOOLTIP : common.DELETE_TOOLTIP} onClick={() => { setShowModal(true); setDeleteIndex(index); }}/></td>
                    </tr>
                  )
                }
              })
            }  
            </tbody>
        </table>
        {expList && expList.length === 0 ?
         <h6 className="text-muted text-center">{commonLabels.NO_DATA_TO_DISPLAY}</h6>:""}
        <Modal show={showModal} onHide={() => { setShowModal(false) }} backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>{experienceinformation.DELETE_EXPERIENCE_MODAL_TITLE}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>{experienceinformation.DELETE_EXPERIENCE_CONFIRMATION_MESSAGE}</label>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => { setShowModal(false) }}>{common.CANCEL}</button>
            <button className="btn btn-primary" onClick={modalConfirm}>{common.CONFIRM}</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
}

export default ExperienceTabTable;