/* eslint-disable */
import { useEffect } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { createBrowserHistory } from 'history';

import AppLayout from './common/components/AppLayout';
import { getAPIRepoVersion } from './registration_login/actions/sessionActions';
import SupportProfileDisplay from './support/components/SupportProfileDisplay';

import './App.css';

function App() {
  const history = createBrowserHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAPIRepoVersion());
  }, []);

  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/candidate/adminsupport/viewprofile" component={SupportProfileDisplay} />
        <Route exact path="" component={AppLayout} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
