/* eslint-disable */
export const getLookOptionsForADropdown = (type, profileCreationAllLookupData) => {
    if(profileCreationAllLookupData && profileCreationAllLookupData.length > 0)
    return profileCreationAllLookupData.filter((option) => {
        return option.type === type
    });
};

export const getLookupOptionLabel = (id, profileCreationAllLookupData) => {
    let lookupRow = profileCreationAllLookupData.find((row, index) => {
        return (row.id === Number(id));
    });
    return lookupRow ? lookupRow.label: "-";
};

export const getDropdownOptionLabel = (value, myDropdownOptions) => {
    let selectedOption = myDropdownOptions.find((element) => {
        return (element.value === value);
    });
    return selectedOption ? selectedOption.label: "";
};

export const getDropdownOptionForFullTime = (id) => {
    if(id == 1){
        return "Full Time"
    }
    if(id == 2){
        return "Otherwise"
    }
};

export const getDropdownForMpscSelection = (value) => {
        if((value === false)|| (value === "false")){
            return "No"
        }
        if((value === true)|| (value === "true")){
            return "Yes"
        }
};

export const nameOfIndexingBodies = (indexingBodyLookupId, nameOfIndexingBodiesList) => {
    const foundItem = nameOfIndexingBodiesList && nameOfIndexingBodiesList.length > 0 && nameOfIndexingBodiesList.find(item => item.id == indexingBodyLookupId);
    return foundItem ? foundItem.label : "";
  };

export const articleType = (articleTypeLookupId, getPublicationArticleTypeList) => {
    const foundItem = getPublicationArticleTypeList && getPublicationArticleTypeList.length > 0 && getPublicationArticleTypeList.find(item => item.id == articleTypeLookupId);
    return foundItem ? foundItem.label : "";
  };

export const flgPublicationFirstAuthor = (value, BOOLEAN_OPTION) => {
    const foundItem = BOOLEAN_OPTION.find(item => item.value === value);
    return foundItem ? foundItem.label : "";
};

export const authorType = (value, AUTHOR_TYPES) => {
    const foundItem = AUTHOR_TYPES && AUTHOR_TYPES.length > 0 && AUTHOR_TYPES.find(item => item.value == value);
    return foundItem ? foundItem.label : "";
  };
