/* eslint-disable */
export const name = /^[A-Za-z_.]+$/i;
export const numberValidation = /^[0-9]+$/i;
export const divyangPercentageRegex = /^[0-9.]+$/;
export const weightRegex = /^([0-9]{0,3})(\.[0-9]{1,2})?$/;
export const percentage = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/; 
export const fullname = /^[A-Za-z_. ]+$/i;
export const schoolname = /^[A-Za-z_.,0-9-()/ ]+$/i;
export const registrationNo = /^[a-z0-9 ]+$/i;
export const councilNumber = /^[a-zA-Z0-9-()/" ]+$/i;
export const projectTitle = /^[a-z0-9-(). ]+$/i;
export const alphanumeric = /^[a-zA-Z0-9()/"]*$/i;
export const certificateNumberRegex = /^[a-zA-Z0-9-.@/\\\\()\"]+$/;
export const certificateNumbeForNclandDomicileRegex = /^[a-zA-Z0-9-.\s@/\\\\()\"]+$/;
export const devNagariRegex = /^[a-zA-Z0-9-.@/\\\\()\"/ ]+$/;
export const sevaarthId = /^[A-Z-.@/\\\\()\"]{7}(0[0-9]|[0-9][0-9][A-Za-z0-9]{0,16})$/i;
export const onlyCharacters = /^[a-zA-Z]+$/i;
export const commaAndCharacters = /^[a-zA-Z, ]+$/i;
export const email = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
export const mobile = /^\s*([1-9]{1}[0-9]{9})\s*$/;
export const emailRegex = /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
export const mobileRegex = /^\s*([1-9]{1}[0-9]{9})\s*$/;
export const aadhaarNumber = /^\d{12}$/i;
export const aadhaarENRNumber = /^[0-9]{4}[/][0-9]{5}[/][0-9]{5}$/i;
export const alphabets =/^[A-Za-z._{}|  ]+$/i;
export const experienceName = /^[a-zA-Z0-9-() ]+$/i;
export const appointmentLetterNumber =  /^[_A-z0-9!@#$%^&*|\<>?"':;+="',.{} ]+$/i; 
export const expLetterNumberRegex =  /^[_A-z0-9!@#$%^&*|\<>?"':;+="',.{}/ ]+$/i; 
export const payScaleValidation =/^[0-9-]+$/i;
export const addressValidation = /^[ A-Za-z0-9_.','/-]*$/;
export const pincode = /^[1-9]{1}[0-9]{5}$/i;
export const otherQualificationRegex = /^[A-Za-z0-9_&./+()[\] ]+$/i; 
export const forgotUserNameRegex = /^[A-Za-z0-9_. ]+$/i;
export const numberWithDecimal = /^\d*\.?\d*$/i;
export const numberRegex =  /^\d*$/i;
export const numberWithDecimalAndHyphen = /^[a-zA-Z0-9.-]*$/i;
export const twoDigitInteger = /^[1-9]{1,1}[0-9]?$/i;
export const numberWithDecimalandComma = /^[ 0-9.,]*$/;
export const securityQuestionRegex = /^[A-Za-z- ]+$/i