import React from "react";
import { Controller } from "react-hook-form";
import qualificationinformation from '../labels/qualification_information_en.json'
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import numberformat from "../labels/serial_nos_en.json";
import {numberValidation } from '../../common/constants/regex';
import dayjs from 'dayjs';
import _ from "lodash/fp";
import useQualificationActions from "./useQualificationActions";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import commonLabels from "../../my_account/labels/common_en.json";

const PhDGuideQualifiation = () => {
  const { handleQualificationChange, handleQualificationInfoSubmit, setActionType, setQualificationState,
    register, handleSubmit, errors, qualificationState, currentEditQualificationIndex, control } = useQualificationActions();

  return (
    <form onSubmit={handleSubmit((e) => {handleQualificationInfoSubmit(e)})}>
      <div className="form-row">
        <QualificationTypeDropdown />
        <div className="col-md-4 mb-2">
          <label className="float-left mb-0">{numberformat["4.2"]}&nbsp;{qualificationinformation.RECOGNITION_DATE}</label>
          <span className="redColor">*</span>
          <Controller
            name ="recognitionDate"
            rules={{ required: true }}
            key= {qualificationState.recognitionDate ? "recognitionDate": "recognitionDate1"}
            defaultValue={qualificationState.recognitionDate? dayjs(qualificationState.recognitionDate).toDate(): ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.recognitionDate? dayjs(qualificationState.recognitionDate).toDate(): fromUTCDate(value)}
                onChange={(e) => {setQualificationState({
                  ...qualificationState,
                  recognitionDate: toUTCDate(e)
                }); onChange(toUTCDate(e))}}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
              />
            )}
          />
          {_.get("recognitionDate.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-4 mb-4">
          <label className="float-left mb-0">{numberformat["4.3"]}&nbsp;{qualificationinformation.YW_STUDENTS}</label>
          <span className="redColor">*</span>
          <input type="text" className="form-control" defaultValue = {qualificationState.noStudentsRegisteredYw}  maxLength={4} name ="noStudentsRegisteredYw" ref={register({ required: true, pattern:numberValidation })} onChange={handleQualificationChange}/>
          {_.get("noStudentsRegisteredYw.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {_.get("noStudentsRegisteredYw.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.Please_Enter_Only_Number}</p>
          )}
        </div>
        <div className="col-md-3 mb-4">
          <label className="float-left mb-0">{numberformat["4.4"]}&nbsp;{qualificationinformation.GUIDED_BY}</label>
          <input type="text" className="form-control" defaultValue = {qualificationState.guidedBy} name ="guidedBy" onChange={handleQualificationChange}/>
        </div>
        <div className="col-md-4 mb-4">
          <label className="float-left mb-0">{numberformat["4.5"]}&nbsp;{qualificationinformation.NO_STUDENTS_RESEARCH_COMPLETED}</label>
          <span className="redColor">*</span>
          <input type="text" className="form-control" defaultValue = {qualificationState.noStudentsResearchComplete}  maxLength={4} name ="noStudentsResearchComplete" ref={register({ required: true, pattern:numberValidation })} onChange={handleQualificationChange}/>
          {_.get("noStudentsResearchComplete.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {_.get("noStudentsResearchComplete.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.Please_Enter_Only_Number}</p>
          )}
        </div>
        <div className="col-md-4 mb-4">
          <label className="float-left mb-0">{numberformat["4.6"]}&nbsp;{qualificationinformation.NO_STUDENTS_PHD_AWARDED}</label>
          <span className="redColor">*</span>
          <input type="text" className="form-control" defaultValue = {qualificationState.noStudentsPhdAwarded}  maxLength={4} name ="noStudentsPhdAwarded" ref={register({ required: true, pattern:numberValidation })} onChange={handleQualificationChange}/>
          {_.get("noStudentsPhdAwarded.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {_.get("noStudentsPhdAwarded.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.Please_Enter_Only_Number}</p>
          )}
        </div>
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
          <button type="submit" name="edit" className="btn btn-primary m-1" onClick={() => {setActionType('edit')}}>{qualificationinformation.UPDATE}</button>
            :
          <button type="submit" name="add" className="btn btn-primary m-1" onClick={() => {setActionType('add')}}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default PhDGuideQualifiation;