import "../../styles/profile.css";

const ModuleHide = (details) => {
    return (
        <div className="container">
            <div className="row mb35">
                <div className="col-md-2"></div>
                <div className="col-md-8 border border-light moduleHideDiv">
                    <p className="Note-color">{details.details.msgToDisplay}</p>
                </div>
                <div className="md-col-2">
                </div>
            </div>
        </div>    
    )
};
export default ModuleHide;