/* eslint-disable */
import React from "react";
import qualificationinformation from '../labels/qualification_information_en.json'
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import { BOOLEAN_OPTION, AUTHOR_TYPES } from "../../common/constants/dropdownOptions";
import numberformat from "../labels/serial_nos_en.json";
import _ from "lodash/fp";
import { fullname, numberValidation, projectTitle } from '../../common/constants/regex';
import useQualificationActions from "./useQualificationActions";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import { Controller } from "react-hook-form";

const PublicationsQualification = () => {
  const { handleQualificationChange, handleQualificationInfoSubmit, setActionType,
    register, handleSubmit, errors, qualificationState, onQualificationNameChange,
    currentEditQualificationIndex, publicationTypeList, nameOfIndexingBodiesList, getPublicationArticleTypeList, watch, control, setQualificationState } = useQualificationActions();
    return (
    <form onSubmit={handleSubmit((e) => { handleQualificationInfoSubmit(e) })}>
      <div className="form-row">
        <QualificationTypeDropdown />

        <div className="col-md-3 mb-2">
          <label className="float-left mb-0">{numberformat["4.2"]}&nbsp;{qualificationinformation.PUBLICATION_TYPE}</label><span className="redColor">*</span>
          <select className="form-control" value={qualificationState.publicationType} name="publicationType" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
            {
              publicationTypeList && publicationTypeList.map((ele, i) => {
                return <option value={ele.id} key={i}>{ele.name}</option>
              })
            }
          </select>
          {_.get("publicationType.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>

        <div className="col-md-3 mb-2">
          <label className="float-left mb-0">{numberformat["4.3"]}&nbsp;{qualificationinformation.NAME_OF_INDEXING_BODIES}</label><span className="redColor">*</span>
          <select className="form-control" value={qualificationState.indexingBodyLookupId} name="indexingBodyLookupId" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
            {nameOfIndexingBodiesList && nameOfIndexingBodiesList.sort((a, b) => { return Number(a.sortOrder) - Number(b.sortOrder) }).map((ele, ind) => {
              return <option key={ind++} value={ele.id}>{ele.label}</option>
            })}
          </select>
          {_.get("indexingBodyLookupId.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>

        <div className="col-md-3 mb-2">
          <label className="float-left mb-0">{numberformat["4.4"]}&nbsp;{qualificationinformation.TYPE_OF_ARTICLES}</label><span className="redColor">*</span>
          <select className="form-control" value={qualificationState.articleTypeLookupId} name="articleTypeLookupId" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key="" value="">{advertisementDetailsLabel.SELECT}</option>
            {getPublicationArticleTypeList && getPublicationArticleTypeList.sort((a, b) => { return Number(a.sortOrder) - Number(b.sortOrder) }).map((ele, ind) => {
              return <option key={ind++} value={ele.id}>{ele.label}</option>
            })}
          </select>
          {_.get("articleTypeLookupId.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>

        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.5"]}&nbsp;{qualificationinformation.AUTHOR_NAME}</label><span className="redColor">*</span>
          <input type="text" className="form-control" defaultValue={qualificationState.publicationAuthorName} maxLength={50} name="publicationAuthorName" ref={register({ required: true, pattern: fullname })} onChange={handleQualificationChange} />
          {_.get("publicationAuthorName.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {_.get("publicationAuthorName.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.Please_Enter_Text_Only}</p>
          )}
        </div>

        <div className="col-md-3 mb-2">
          <label className="float-left mb-0">{numberformat["4.6"]}&nbsp;{qualificationinformation.FLG_FIRST_AUTHOR}</label><span className="redColor">*</span>
          <select className="form-control" value={qualificationState.flgPublicationFirstAuthor} name="flgPublicationFirstAuthor" ref={register({ required: true })} onInput={handleQualificationChange}>
            <option value="">{advertisementDetailsLabel.SELECT}</option>
            {
              BOOLEAN_OPTION.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("flgPublicationFirstAuthor.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-2">
          <label className="float-left mb-0">{numberformat["4.7"]}&nbsp;{qualificationinformation.AUTHOR_TYPE}</label><span className="redColor">*</span>
          <select className="form-control" value={qualificationState.publicationAuthorType} name="publicationAuthorType" ref={register({ required: true })} onInput={handleQualificationChange}>
            {
              AUTHOR_TYPES.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("publicationAuthorType.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-md-10">
          <label className="float-left mb-0" for="publicationJournalDetails">{numberformat["4.8"]}&nbsp;{qualificationinformation.JOURNAL_DETAILS}</label><span className="redColor">*</span>
          <textarea id="publicationJournalDetails" className="form-control" maxLength={350} defaultValue={qualificationState.publicationJournalDetails} name="publicationJournalDetails" ref={register({ required: true })} onChange={handleQualificationChange} />
          {_.get("publicationJournalDetails.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {/* {_.get("publicationJournalDetails.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.ONLY_CHARATERS_NUMBERS}</p>
          )} */}
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3 mb-2">
          <label className="float-left mb-0">{numberformat["4.9"]}&nbsp;{qualificationinformation.PUBLICATION_YEAR} </label><span className="redColor">*</span>
          <input type="text" className="form-control" maxLength={4}  minLength={4} defaultValue={qualificationState.publicationYear} name="publicationYear" ref={register({ required: true, pattern: numberValidation })} onChange={handleQualificationChange} />
          {_.get("publicationYear.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {_.get("publicationYear.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.Please_Enter_Only_Number}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.10"]}&nbsp;{qualificationinformation.FLG_IMPACT_INDEX_2_0}</label><span className="redColor">*</span>
          <select className="form-control" value={qualificationState.flgImpactIndex2Or0OrMore} name="flgImpactIndex2Or0OrMore" ref={register({ required: true })} onInput={handleQualificationChange}>
            <option value="">{advertisementDetailsLabel.SELECT}</option>
            {
              BOOLEAN_OPTION.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("flgImpactIndex2Or0OrMore.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.11"]}&nbsp; {qualificationinformation.PUBLICATION_ACCEPTANCE_DATE}</label><span className="redColor">*</span>
          <Controller
            name="publicationAcceptanceDate"
            key={qualificationState.publicationAcceptanceDate ? "publicationAcceptanceDate" : "publicationAcceptanceDate1"}
            defaultValue={qualificationState.publicationAcceptanceDate ? dayjs(qualificationState.publicationAcceptanceDate).toDate() : ""}
            rules={{ required: true }}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.publicationAcceptanceDate? dayjs(qualificationState.publicationAcceptanceDate).toDate(): fromUTCDate(value)}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    publicationAcceptanceDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
                maxDate={new Date()}
              />
            )}
          />
          {_.get("publicationAcceptanceDate.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.12"]}&nbsp; {qualificationinformation.PUBLICATION_PUBLISHED_DATE}</label><span className="redColor">*</span>
          <Controller
            name="publicationPublishedDate"
            key={qualificationState.publicationPublishedDate ? "publicationPublishedDate" : "publicationPublishedDate1"}
            control={control}
            rules={{ required: true }}
            defaultValue={qualificationState.publicationPublishedDate ? dayjs(qualificationState.publicationPublishedDate).toDate() : ""}
            render={({ onChange, value }) => (
              <DatePicker
              selected={qualificationState.publicationPublishedDate? dayjs(qualificationState.publicationPublishedDate).toDate(): fromUTCDate(value)}
              onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    publicationPublishedDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
                maxDate={new Date()}
              />
            )}
          />
          {_.get("publicationPublishedDate.type", errors) === "required" && (
              <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-8 mb-3">
          <label className="float-left mb-0">{numberformat["4.13"]}&nbsp;{qualificationinformation.PAPER_TITLE}</label><span className="redColor">*</span>
          <input type="text" id="publicationPaperTitle" maxLength={80} className="form-control" defaultValue={qualificationState.publicationPaperTitle} name="publicationPaperTitle" ref={register({ required: true, pattern: projectTitle })} onChange={handleQualificationChange} />
          {_.get("publicationPaperTitle.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          {_.get("publicationPaperTitle.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_INPUT}</p>
          )}
          <span className="text-primary">{qualificationinformation.PROJ_TITLE_VALIDATION_NOTE}</span>
        </div>
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
            <button type="submit" name="edit" className="btn btn-primary m-1" onClick={() => { setActionType('edit') }}>{qualificationinformation.UPDATE}</button>
            :
            <button type="submit" name="add" className="btn btn-primary m-1" onClick={() => { setActionType('add') }}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default PublicationsQualification;