/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual} from 'react-redux';
import '../../styles/candidate_nav.css';
import { downloadFile, downloadLinkFile, getCorrespondenceData } from '../actions/correspondenceAction';
import dayjs from "dayjs";
import correspondenceJson from "../labels/correspondence_en.json";
import ModuleHide from '../../common/components/ModuleHide';
import commonLabels from "../../my_account/labels/common_en.json";

const Correspondence = () => {
    const dispatch = useDispatch();
    const { correspondenceData  } = useSelector(state => state.correspondence);
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
    const [arraymModule , setarraymModule] = useState({})

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "CORRESPONDENCE_TAB")[0])
    },[moduleList])

    useEffect(() => {
        dispatch(getCorrespondenceData());
    }, []);

    const fileDownload =(ele)=>{
        let requestBody ={}
        requestBody.correspondenceFileId = ele.correspondenceFileId
        requestBody.type = ele.type
        requestBody.filename =ele.filename
        dispatch(downloadFile(requestBody))
    }

    const linkFileDownload = (ele)=> {
        dispatch(downloadLinkFile(ele))
    }

    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    (
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="main-box">
                    <p>{correspondenceJson.page_Heading}</p>
                            <table className="table table-bordered table-hover table-sm">
                                    <thead>
                                    <tr>
                                        {correspondenceJson.tableHeader.map((ele, index) => 
                                            <th scope="col" key={index}>{ele}</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {correspondenceData &&correspondenceData.map((ele,i)=>(
                                            <tr>
                                                <td className="w-10 word-break text-center" >{i+1}</td>
                                                <td className="word-break">{ele.date ? dayjs(ele.date).format(commonLabels.DATE_FORMAT):""}</td>
                                                <td className="word-break">{ele.description}</td>
                                                <td className="w-15 word-break text-center">
                                                    {ele.type =="internal_file_download"? 
                                                        <button className="btn btn-sm btn-outline-primary"onClick={()=>fileDownload(ele)}>{correspondenceJson.DOWNLOAD}</button>
                                                      :
                                                    ele.type =="link_file_download"?
                                                      <button className="btn btn-sm btn-outline-primary" onClick={()=>linkFileDownload(ele)}>{correspondenceJson.DOWNLOAD}</button>
                                                      :
                                                       <a href={ele.url} target="_blank">{correspondenceJson.OPEN}</a>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                            </table>
                    </div>
            </div>
        </div>
    )
};
export default Correspondence;