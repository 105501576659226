/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import profile from "../labels/personal_information_en.json";
import numberformat from "../labels/serial_nos_en.json";
import '../../styles/profile.css'
import DatePicker from "react-datepicker";
import profileotherinformation from "../labels/other_information_en.json";
import { useForm, Controller } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { savePersonalInfoData, setIssuingDistrictDropdown, setSportsDetails, clearSportForm,getAdditionResEnvData } from "../actions/profileAction";
import { aadhaarNumber, mobile, email, name, fullname, alphanumeric, sevaarthId, weightRegex, certificateNumberRegex, certificateNumbeForNclandDomicileRegex, percentage} from '../../common/constants/regex'
import _ from "lodash/fp";
import Select from "react-select";
import { toastr } from 'react-redux-toastr';
import { getLookOptionsForADropdown } from "../../helpers/LookupOptionHelper";
import { BOOLEAN_OPTION_PERSONALINFO, Domicile_AVAILABLE, Disability_Certificate, Division_Armed_Forces, ORPHAN_TYPE } from '../../common/constants/dropdownOptions';
import dayjs from 'dayjs';
import Devnagari from './devnagari';
import "react-transliterate/dist/index.css";
import { dateDiffereceExServiceMan } from "../../helpers/Datetime";
import SportsDetails from './SportsDetails';	
import SportsTable from './SportsTable';	
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import ModuleHide from "../../common/components/ModuleHide";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/advertisementTable.css";

const nameInEnglishMaxLength = 50;
const shortNameMaxLength = 30;
const fullNameMaxLength = 150;
const casteCertificationMaxLength = 25;
const mandatoryMultipleDisabilitySubCats = [454, 455]; 
const multiSelectCatId = 439;

function PersonalInformation() {
  const castCertiIssuingDateRef = useRef(null)
  const latestNclIssueDateRef = useRef(null)
  const castValidityCertIssueDateRef = useRef(null)
  const disabilitySubCategoriesLookupIdsRef = useRef(null)
  const sadmCertificateDateRef = useRef(null)
  const dateRetireArmedRegularRef = useRef(null)
  const dateApptArmedRegularRef = useRef(null)
  const dateDischargeArmedRegularRef = useRef(null)
  const ageRelaxCertiNo1Ref = useRef(null)
  const orphanCertiIssueDateRef = useRef(null)
  const domicileIssuingDateRef = useRef(null)
  const projectAffectedIssuingDateRef = useRef(null)
  const earthquakeAffectedIssuingDateRef = useRef(null)
  const graduateEmployeeIssuingDateRef = useRef(null)
  const { profileCreationAllLookupData, meritoriousSportsmanDtoList,additionResEnvData, allDisabilitiesData} = useSelector(state => state.profile, shallowEqual);
  const motherTongueList = getLookOptionsForADropdown("mother_tongue", profileCreationAllLookupData);
  const listOfDisability = getLookOptionsForADropdown("disability_category", profileCreationAllLookupData);
  const genderList = getLookOptionsForADropdown("gender", profileCreationAllLookupData);
  const nationality = getLookOptionsForADropdown("nationality", profileCreationAllLookupData);
  const castCategoryList = getLookOptionsForADropdown("caste_category", profileCreationAllLookupData);
  const maritalStatusList = getLookOptionsForADropdown("marital_status", profileCreationAllLookupData);
  const issuingDistrictList = useSelector(state => state.profile.issuingDistrictList);
  const issuingDistrictCategoryList = useSelector(state => state.profile.issuingDistrictList);
  const isDisableAllTabSave = useSelector(state => state.profile.isDisableAllTabSave);
  const [personal_info_form, setSaveData] = useState([]);
  const profileFormDetailsData = useSelector(state => state.profile.personalInfo);
  const [hideCastCertificate, setHideCastCertificate] = useState(false);
  const [hideNCL, setHideNCL] = useState(false);
  const [hideCastValidity, setHideCastValidity] = useState(false);
  const [showEWS, setShowEWS] = useState(false);
  const [ disabilitySubCategoriesOptions, setDisabilitySubCategoriesOptions ] = useState([]);
  const [ disabilitySubCategoriesLookupIds, setDisabilitySubCategoriesLookupIds ] = useState("");
  const [projectAffectedPersonFlag, setProjectAffectedPersonFlag] = useState(false);
  const [IS_PROJECT_AFFECTED_REQUIRED, setIsProjectAffectedRequired] = useState(false);
  const [earthquakeAffectedFlag, setEarthquakeAffectedFlag] = useState(false);
  const [IS_EARTHQUAKE_AFFECTED_REQUIRED, setIsEarthquakeAffectedRequired] = useState(false);
  const [graduateEmployeeFlag, setGraduateEmployeeFlag] = useState(false);
  const [isAdditionalReservationFlag, setIsAdditionalReservationFlag] = useState('No');
  const [IS_GRADUATE_EMPLOYEE_REQUIRED, setIsGraduateEmployeeRequired] = useState(false);
  const [reloadItem, setReloadItem] = useState(0);
  const [certificateNoPa, setCertificateNoPa] = useState('');
  const [issuingDatePa, setIssuingDatePa] = useState('');
  const [certificateNoEqa, setCertificateNoEqa] = useState('');
  const [issuingDateEqa, setIssuingDateEqa] = useState('');
  const [employmentNoGpte, setEmploymentNoGpte] = useState('');
  const [issuingDateGpte, setIssuingDateGpte] = useState('');
  const [authenticatedUserData, setAuthenticatedUserData] = useState({
    dateOfBirth: "",
    age: "",
    registeredEmailId: "",
    registeredMobileNo: ""
  })
  const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
  const [arraymModule , setarraymModule] = useState({})
  const [disabilitySubCategoryErr, setDisabilitySubCategoryErr] = useState("");
  const [sevaartIdErr, setSevaartIdErr] = useState(false);
  const [showDisabilitiesNote, setShowDisabilitiesNote] = useState(false);

  useEffect(()=>{
    dispatch(getAdditionResEnvData())
},[])

useEffect(()=>{
  additionResEnvData && setIsProjectAffectedRequired(additionResEnvData.projectAffectedRequired);
  additionResEnvData && setIsEarthquakeAffectedRequired(additionResEnvData.earthquakeAffetctedRequired);
  additionResEnvData && setIsGraduateEmployeeRequired(additionResEnvData.graduateEmployeeRequired);
  setReloadItem(!reloadItem);
},[additionResEnvData])

  useEffect(()=>{
    setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "PERSONAL_INFO")[0])
    setReloadItem(!reloadItem);
},[moduleList])

  useEffect(() => {
    let profileFormDetails = profileFormDetailsData
    dispatch(setIssuingDistrictDropdown());
    const session = JSON.parse(sessionStorage.getItem("session"));
    let dobData = session && session.dob;
    let ageData = session && session.age;
    let registeredEmailId = session && session.emailId;
    let registeredMobileNo = session && session.mobileNumber;
    setAuthenticatedUserData({
      ...authenticatedUserData,
      dateOfBirth: dobData && dayjs(dobData).toDate(),
      age: ageData,
      registeredEmailId: registeredEmailId,
      registeredMobileNo: registeredMobileNo
    });
    setIsAdditionalReservationFlag(profileFormDetails.isAdditionalReservation?'Yes':'No');
    setProjectAffectedPersonFlag(profileFormDetails.isProjectAffected);
    setEarthquakeAffectedFlag(profileFormDetails.isEarthquakeAffected);
    setGraduateEmployeeFlag(profileFormDetails.isGraduateEmployee);
    setCertificateNoPa(profileFormDetails && profileFormDetails.certificateNoPa);
    setIssuingDatePa(profileFormDetails && profileFormDetails.issuingDatePa && dayjs(profileFormDetails.issuingDatePa).toDate());
    setCertificateNoEqa(profileFormDetails && profileFormDetails.certificateNoEqa);
    setIssuingDateEqa(profileFormDetails && profileFormDetails.issuingDateEqa && dayjs(profileFormDetails.issuingDateEqa).toDate());
    setEmploymentNoGpte(profileFormDetails && profileFormDetails.employmentNoGpte);
    setIssuingDateGpte(profileFormDetails && profileFormDetails.issuingDateGpte && dayjs(profileFormDetails.issuingDateGpte).toDate());
    setSaveData(profileFormDetails);
    if (profileFormDetails) {
      setFlagOfAdharCard(profileFormDetails && profileFormDetails.flgAadhaarCard === true ? "Yes" : "No")
      setcastCategoryBooleanState({
        castCertificateDistrict: profileFormDetails && profileFormDetails.ccIssuingDistrictLookupId,
        doYouBelongToNonCreamyLayer: (profileFormDetails && profileFormDetails.flgNcl != null) ? (profileFormDetails.flgNcl=== true ? "Yes" : "No"): "",
        doYouHaveCastValidityCertificate: (profileFormDetails && profileFormDetails.flgCastValidityCert != null) ? (profileFormDetails && profileFormDetails.flgCastValidityCert == true ? "Yes" : "No") :"",
      });
      setCastType({
        castType :profileFormDetails && profileFormDetails.categoryLookupId
      })
      shownotConsiderUnreservedcategoryForCastValidity(profileFormDetails && profileFormDetails.flgUnreservedCastValidity)
      setSportsBooleanState({
        Meritorious_Sports_Person: (profileFormDetails && profileFormDetails.flgMetritoriousSportsman != null ? (profileFormDetails.flgMetritoriousSportsman == true ? "Yes" : "No") : ""),
      });
       setExServiceManBooleanState({
        flgExArmedReg: (profileFormDetails && profileFormDetails.flgExArmedReg != null ? (profileFormDetails.flgExArmedReg == true ? "Yes" : "No") : ""),
        exServiceManDivision: profileFormDetails && profileFormDetails.exServiceManDivision
      });

      setGovOfMaharashtraEmpDetail({
        flgGomEmployee: (profileFormDetails && profileFormDetails.flgGomEmployee != null ? (profileFormDetails.flgGomEmployee == true ? "Yes" : "No") : ""),
        flgBmcEmployee: (profileFormDetails && profileFormDetails.flgBmcEmployee != null  ? (profileFormDetails.flgBmcEmployee == true ? "Yes" : "No") : ""),
        flgBestEmployee: (profileFormDetails && profileFormDetails.flgBestEmployee != null  ? (profileFormDetails.flgBestEmployee == true ? "Yes" : "No") : ""),
        flgStateEmployee: (profileFormDetails && profileFormDetails.flgStateEmployee != null  ? (profileFormDetails.flgStateEmployee == true ? "Yes" : "No") : ""),
        });

      setDomicileDetails({
        maharashtraDomicileCertificate: (profileFormDetails && profileFormDetails.flgDomicile != null ?  (profileFormDetails.flgDomicile == true ? "Yes" : "No") :""),
        domicileIssuingDistrict: profileFormDetails && profileFormDetails.domicileIssuingDistrict,
        domicileCertificateAvailable: profileFormDetails && profileFormDetails.flgDomicileCertificate !== null ? (profileFormDetails && profileFormDetails.flgDomicileCertificate == true ? "Yes" : "No") : "",
      });
      setDatePiker({
        dateOfBirth: profileFormDetails && profileFormDetails.dob && dayjs(profileFormDetails.dob).toDate(),
        castCertiIssuingDate: profileFormDetails && profileFormDetails.castCertiIssuingDate && dayjs(profileFormDetails.castCertiIssuingDate).toDate(),
        latestNclIssueDate: profileFormDetails && profileFormDetails.latestNclIssueDate && dayjs(profileFormDetails.latestNclIssueDate).toDate(),
        castValidityCertIssueDate: profileFormDetails && profileFormDetails.castValidityCertIssueDate && dayjs(profileFormDetails.castValidityCertIssueDate).toDate(),
        sadmCertificateDate: profileFormDetails && profileFormDetails.sadmCertificateDate && dayjs(profileFormDetails.sadmCertificateDate).toDate(),
        dateApptArmedRegular: profileFormDetails && profileFormDetails.dateApptArmedRegular && dayjs(profileFormDetails.dateApptArmedRegular).toDate(),
        ageRelaxCertiNo1: profileFormDetails && profileFormDetails.ageRelaxCertiNo1 && dayjs(profileFormDetails.ageRelaxCertiNo1).toDate(),
        dateDischargeArmedRegular: profileFormDetails && profileFormDetails.dateDischargeArmedRegular && dayjs(profileFormDetails.dateDischargeArmedRegular).toDate(),
        dateRetireArmedRegular: profileFormDetails && profileFormDetails.dateRetireArmedRegular && dayjs(profileFormDetails.dateRetireArmedRegular).toDate(),
        domicileIssuingDate: profileFormDetails && profileFormDetails.domicileIssuingDate && dayjs(profileFormDetails.domicileIssuingDate).toDate(),
        orphanCertiAckReceiptDate: profileFormDetails && profileFormDetails.orphanCertiAckReceiptDate && dayjs(profileFormDetails.orphanCertiAckReceiptDate).toDate(),
        orphanCertiIssueDate: profileFormDetails && profileFormDetails.orphanCertiIssueDate && dayjs(profileFormDetails.orphanCertiIssueDate).toDate(),
        exServiceYears:profileFormDetails && profileFormDetails.exServiceYears,
        exServiceMonths:profileFormDetails && profileFormDetails.exServiceMonths,
        exServiceDays:profileFormDetails && profileFormDetails.exServiceDays
      });
      setPersonalDetail({
        gender: profileFormDetails && profileFormDetails.genderLookupId,
        maritalStatus: profileFormDetails && profileFormDetails.maritalStatusLookupId,
        flgCandidateLiable: (profileFormDetails && profileFormDetails.flgCandidateLiable != null ? (profileFormDetails.flgCandidateLiable ? "Yes" : "No") : ""),
        Nationality: profileFormDetails && profileFormDetails.nationalityLookupId,
        motherTongue: profileFormDetails && profileFormDetails.motherTongueLookupId,
        canYouRead: profileFormDetails && profileFormDetails.flgMarathiRead ? "Yes" : "No",
        canYouWrite: profileFormDetails && profileFormDetails.flgMarathiWrite ? "Yes" : "No",
        canYouSpeak: profileFormDetails && profileFormDetails.flgMarathiSpeak ? "Yes" : "No",
        flg_candidate_liable: profileFormDetails && profileFormDetails.flg_candidate_liable ? "Yes" : "No",
        flgNameChanged: profileFormDetails && profileFormDetails.flgNameChanged ? "Yes" : "No",
      });
      
      setDivyangDetails({
        areYouDivyangjan: (profileFormDetails && profileFormDetails.flgDivyang != null ? (profileFormDetails.flgDivyang == true ? "Yes" : "No") : ""),
        typeOfDisabilityLookupId: profileFormDetails && profileFormDetails.typeOfDisabilityLookupId,
        typeDisabilityCertificate: profileFormDetails && profileFormDetails.typeDisabilityCertificate,
        disabilitySubCategoriesLookupIds: profileFormDetails && profileFormDetails.disabilitySubCategoriesLookupIds,
        areYouHaveUDID: profileFormDetails && profileFormDetails.udid ? 'Yes' :  profileFormDetails.udidEnrolmentNo && profileFormDetails.udidEnrolmentNo ? 'No' : '',
        udid:profileFormDetails && profileFormDetails.udid ? profileFormDetails.udid :  '',
        udidEnrolmentNo:profileFormDetails && profileFormDetails.udidEnrolmentNo ? profileFormDetails.udidEnrolmentNo :  '',    
      });
      /** OAS-640 Border Area Changes */

      setBorderAreaDetails({
        borderDistrictLookupId: profileFormDetails && profileFormDetails.borderDistrictLookupId,
        borderTalukaLookupId: profileFormDetails && profileFormDetails.borderTalukaLookupId,
        borderVillageLookupId: profileFormDetails && profileFormDetails.borderVillageLookupId
      });

      setFlgMaharashtraKarnatakBorderArea(profileFormDetails && profileFormDetails.flgMaharashtraKarnatakBorderArea === true ? "Yes" : "No");
      setFlgTransgenderFemale(profileFormDetails && profileFormDetails.flgTransgenderFemale === true ? "Yes" : "No");
      /** OAS-640 Border Area Changes */

      setOrphanDetails({
        DoYouWantTotakeAvantageOfOrphan: (profileFormDetails && profileFormDetails.flgOrphanReservation != null ? (profileFormDetails.flgOrphanReservation === true ? "Yes" : "No") : ""),
        OrphanCertificateNumber: profileFormDetails && profileFormDetails.orphanReservationCertiNo,
        orphanType: profileFormDetails && profileFormDetails.orphanType
      }) 
      setFlgDisabilityPensionHolder(profileFormDetails.flgDisabilityPensionHolder ? 'Yes' : 'No')
      dispatch(setSportsDetails(profileFormDetails.meritoriousSportsmanDtoList));
    }
    setReloadItem(!reloadItem);
  }, [profileFormDetailsData]);
  const handleprojectAffectedPersonFlag=(evt)=>{
    const value = evt.target.value;
    if(value == "Yes") {
      setProjectAffectedPersonFlag(true);
    }
    else
    { 
      setProjectAffectedPersonFlag(false);
      setCertificateNoPa('');
    setIssuingDatePa('');
    }
    setReloadItem(!reloadItem);
  }

  const handleearthquakeAffectedFlag=(evt)=>{
    const value = evt.target.value;
    if(value == "Yes") {
      setEarthquakeAffectedFlag(true);
    }
    else
    { 
      setEarthquakeAffectedFlag(false);
      setCertificateNoEqa('');
    setIssuingDateEqa('');
    }
    setReloadItem(!reloadItem);
  }

  const handlegraduateEmployeeFlag=(evt)=>{
    const value = evt.target.value;
    if(value == "Yes") {
      setGraduateEmployeeFlag(true);
    }
    else
    { 
      setGraduateEmployeeFlag(false);
      setEmploymentNoGpte('');
      setIssuingDateGpte('');
    }
    setReloadItem(!reloadItem);
  }
  const handleAdditionalReservationFlag=(evt)=>{
    const value = evt.target.value;
      if(value=='Yes')
      {
        setIsAdditionalReservationFlag(value);
      }
      else
      {
        setIsAdditionalReservationFlag(value);
        setProjectAffectedPersonFlag(false);
        setEarthquakeAffectedFlag(false);
        setGraduateEmployeeFlag(false);
        setCertificateNoPa('');
        setIssuingDatePa('');
        setCertificateNoEqa('');
        setIssuingDateEqa('');
        setEmploymentNoGpte('');
        setIssuingDateGpte('');
      }
      setReloadItem(!reloadItem);
  }
  /** OAS-661 OAS-640 */
  useEffect(() => {
    let storedDisabilityLookupId = DivyangDetails.typeOfDisabilityLookupId;
    let currentDisabilitySubCategories = allDisabilitiesData && allDisabilitiesData.filter((subCat) => {
      return Number(subCat.parentId) === Number(storedDisabilityLookupId)
    });
    setDisabilitySubCategoriesOptions(currentDisabilitySubCategories);
    let savedDisabilitySubCategoriesLookupIds = DivyangDetails.disabilitySubCategoriesLookupIds ?
                      DivyangDetails.disabilitySubCategoriesLookupIds : [];
    let savedSubcategoryOptions = [];
    for(let i=0; i < savedDisabilitySubCategoriesLookupIds.length ; i++) {
      let savedId = savedDisabilitySubCategoriesLookupIds[i];
      let savedSubCat = currentDisabilitySubCategories && currentDisabilitySubCategories.find((subCat) => {
        return Number(subCat.id) === Number(savedId)
      })
      if(savedSubCat) {
        savedSubcategoryOptions.push({
          id: savedSubCat.id,
          label: savedSubCat.label,
          isFixed: mandatoryMultipleDisabilitySubCats.includes(savedSubCat.id),
          isRequired: savedSubCat.isRequired
        })
      }

    }
    let selectedOptions;
    if(Number(DivyangDetails.typeOfDisabilityLookupId) !== multiSelectCatId) {
      selectedOptions = savedSubcategoryOptions[0];
    } else {
      selectedOptions = savedSubcategoryOptions
    }
    setDisabilitySubCategoriesLookupIds(selectedOptions);
    const listOfAllBorderTalukasLocal = getLookOptionsForADropdown("maha_karnatak_border_taluka", profileCreationAllLookupData);
    const listOfAllBorderVillagesLocal = getLookOptionsForADropdown("maha_karnatak_border_village", profileCreationAllLookupData);
    let borderTalukaOptionsForSavedDistrict = [];
    let borderVillageOptionsForSavedDistrict = [];
    borderTalukaOptionsForSavedDistrict = listOfAllBorderTalukasLocal && listOfAllBorderTalukasLocal.filter((taluka) => {
      return Number(taluka.parentId) === Number(borderAreaDetails.borderDistrictLookupId);
    })
    borderVillageOptionsForSavedDistrict = listOfAllBorderVillagesLocal && listOfAllBorderVillagesLocal.filter((village) => {
      return Number(village.parentId) === Number(borderAreaDetails.borderTalukaLookupId);
    })
    setBorderTalukaOptions(borderTalukaOptionsForSavedDistrict);
    setBorderVillageOptions(borderVillageOptionsForSavedDistrict);
    setReloadItem(!reloadItem);
  }, [profileCreationAllLookupData.length])
  /** OAS-661 OAS-640 */

  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: "onChange"
  });

  const onSubmit = (data, e) => {
    if(!disabilitySubCategoryErr && !sevaartIdErr){
    if(isMaharashtraDomicileCertificate == "No" && isAdditionalReservationFlag == "Yes"){
      toastr.error(profileotherinformation.ADDITIONAL_RES_CAT_TOASTR_ERR);
    }else{
    data.flgUnreservedCastValidity = flgUnreservedCastValidity;
    data.emailID = watch("emailID");
    data.mobileNumber = watch("mobileNumber");
    data.disabilitySubCategoriesLookupIds = DivyangDetails.disabilitySubCategoriesLookupIds;
    let exServiceManDate = datePickerData;
    data.isAdditionalReservation = false;
    data.isProjectAffected=false;
    data.certificateNoPa=null;
    data.issuingDatePa=null;
    data.isEarthquakeAffected=false;
    data.certificateNoEqa=null;
    data.issuingDateEqa=null;
    data.isGraduateEmployee=false;
    data.employmentNoGpte=null;
    data.issuingDateGpte=null;
    if(isMaharashtraDomicileCertificate == 'Yes'){
      if(isAdditionalReservationFlag == 'Yes')
      {
        data.isAdditionalReservation = true;
          if(projectAffectedPersonFlag)
          {
            data.isProjectAffected=true;
            data.certificateNoPa=watch("projectAffectedCertificateNumber");
            data.issuingDatePa=watch("projectAffectedIssuingDate");
          }
          if(earthquakeAffectedFlag)
          {
            data.isEarthquakeAffected=true;
            data.certificateNoEqa=watch("earthquakeAffectedCertificateNumber");
            data.issuingDateEqa=watch("earthquakeAffectedIssuingDate");
          }
          if(graduateEmployeeFlag)
          {
            data.isGraduateEmployee=true;
            data.employmentNoGpte=watch("graduateEmployeeNumber");
            data.issuingDateGpte=watch("graduateEmployeeIssuingDate");
          }

      }
      
    }

    if(exservicemanoftheregularArmedData === 'Yes'){
      data.exServiceYears = exServiceManDate.exServiceYears
      data.exServiceMonths = exServiceManDate.exServiceMonths
      data.exServiceDays = exServiceManDate.exServiceDays
    } else{
      data.exServiceYears = 0
      data.exServiceMonths = 0
      data.exServiceDays = 0
    }
    data.flgMaharashtraKarnatakBorderArea = flgMaharashtraKarnatakBorderArea;
    if(data.maharashtraDomicileCertificate == "No"){
      setBorderAreaDetails({
        borderDistrictLookupId: "",
        borderTalukaLookupId: "",
        borderVillageLookupId: ""
      });
      setValue("borderDistrictLookupId", "")
      setValue("borderTalukaLookupId", "")
      setValue("borderVillageLookupId", "")
    }else{
      data.borderDistrictLookupId = borderAreaDetails.borderDistrictLookupId;
      data.borderTalukaLookupId = borderAreaDetails.borderTalukaLookupId;
      data.borderVillageLookupId = borderAreaDetails.borderVillageLookupId;
    }
    data.meritoriousSportsmanDtoList = meritoriousSportsmanDtoList;
    let sportsList  = []
    meritoriousSportsmanDtoList && meritoriousSportsmanDtoList.length > 0 && meritoriousSportsmanDtoList.forEach(ele => {
      if(!ele.flgDeleted){
        sportsList.push(ele)
      }
    })
    dispatch(clearSportForm(true));
    if((sportsList && sportsList.length > 0) || (Meritorious_Sports_Person_Data === 'No')) {
      if (data.aadhaarNo || isAdharCardPresentFlag === "No" ) {
        data.isAdharCardPresent = isAdharCardPresent
        dispatch(savePersonalInfoData(data))
      } else {
        try {
          let message = <div className="confirmMessage">
            <p className="word-break">{profileotherinformation.SPORTS_AADHAR_DETAILS_FIRST_MESSAGE}</p>
            <p>{profileotherinformation.SPORTS_AADHAR_DETAILS_SECOND_MESSAGE}</p>
          </div>
          toastr.confirm(message, {
            onOk: async () => {
              data.isAdharCardPresent = isAdharCardPresent
              dispatch(savePersonalInfoData(data))
            }
          });
        }
        catch (err) {
          toastr.error(profile.SOMETHING_WENT_WRONG_ERROR);
        }
      }
    }else{
      try {
        let message = <div className="confirmMessage">
          <p>{profileotherinformation.ADD_SPORTS_DETAILS_MESSAGE}</p>
        </div>
        toastr.confirm(message, {
          onOk: async () => {
           
          }
        });
      }
      catch (err) {
        toastr.error(profile.SOMETHING_WENT_WRONG_ERROR);
      }
    }
    setReloadItem(!reloadItem);
  }
  }else{
    toastr.error(profileotherinformation.PLEASE_FILL_MANDATORY_FIELDS);
  }
  }
  const gender = watch("gender");
  const doYouBelongToNonCreamyLayerData = watch("doYouBelongToNonCreamyLayer")
  const doYouHaveCastValidityCertificateData = watch("doYouHaveCastValidityCertificate")
  const areYouDivyangjanData = watch("areYouDivyangjan")
  const motherTongueData = watch("motherTongue")
  const Meritorious_Sports_Person_Data = watch("Meritorious_Sports_Person")
  const exservicemanoftheregularArmedData = watch('flgExArmedReg')
  const domicileCertificateAvailableData = watch('domicileCertificateAvailable')
  const castListOption = watch("castType")
  const isMaharashtraDomicileCertificate = watch("maharashtraDomicileCertificate")
  const isAdharCardPresent = watch("isAdharCardPresentFlag")
  const maritalStatusData = watch("maritalStatus")
  const DoYouWantTotakeAvantageOfOrphanData = watch("DoYouWantTotakeAvantageOfOrphan")
  let [ flgTransgenderFemale, setFlgTransgenderFemale ] = useState("No");

  useEffect(() => {
    if (gender || castListOption) {
      setShowEWS(false)
      if(castListOption != '323'){
        shownotConsiderUnreservedcategoryForCastValidity(false)
      }
      if ((gender == '321'|| (Number(gender) === 322 && flgTransgenderFemale === "Yes") ) && castListOption == '323') {
        setHideNCL(true)
        setHideCastCertificate(false)
        setHideCastValidity(false)
      } else if ((gender == '320' || (Number(gender) === 322 && flgTransgenderFemale === "No") || gender == '') && castListOption == '323') {
        setHideNCL(false)
        setHideCastCertificate(false)
        setHideCastValidity(false)
      } else if (castListOption == '324' || castListOption == '325') {
        setHideNCL(false)
        setHideCastCertificate(true)
        setHideCastValidity(true)
      } else if (castListOption === '326' || castListOption === '327' || castListOption === '328' || castListOption === '329' || castListOption === '330' || castListOption === '331' || castListOption === '333') {
        setHideCastCertificate(true)
        setHideCastValidity(true)
        setHideNCL(true)
      } else if (castListOption === '332') {
        setShowEWS(true)
        setHideCastCertificate(false)
        setHideNCL(false)
        setHideCastValidity(false)
      }
      else {
        setHideCastCertificate(false)
        setHideNCL(false)
        setHideCastValidity(false)
      }
    }
    setReloadItem(!reloadItem);
  }, [gender, castListOption, flgTransgenderFemale])

  const [isAdharCardPresentFlag, setFlagOfAdharCard] = useState('Yes')
  const [ flgDisabilityPensionHolder, setFlgDisabilityPensionHolder] = useState('Yes')
  const [ flgJoinedStateGovt, setFlgJoinedStateGovt] = useState('Yes')
  // Have you joined State Govt 
  const [castCategoryBooleanState, setcastCategoryBooleanState] = useState({
    castCertificateDistrict: "",
    doYouBelongToNonCreamyLayer: "",
  });
  const [castTypeSelection, setCastType] = useState({
    castType:""
  })
  const [SportsBooleanState, setSportsBooleanState] = useState({
    Meritorious_Sports_Person: "",
  });

  const [ExServiceManBooleanState, setExServiceManBooleanState] = useState({
    flgExArmedReg: "",
    exServiceManDivision:""
  });

  const [govOfMaharashtraEmpDetail, setGovOfMaharashtraEmpDetail] = useState({
    flgGomEmployee: "",
    flgBmcEmployee: "",
    flgBestEmployee: "",
    flgStateEmployee: "",
  })

  const [DomicileDetailsBooleanState, setDomicileDetails] = useState({
    maharashtraDomicileCertificate: "",
    domicileIssuingDistrict: "",
    domicileCertificateAvailable: "",
  })

  const [personalDetails, setPersonalDetail] = useState({
    gender: "",
    maritalStatus: "",
    Nationality: "",
    motherTongue: "",
    canYouRead: "Yes",
    canYouWrite: "Yes",
    canYouSpeak: "Yes",
    flgCandidateLiable: "",
    flgNameChanged:"Yes"
  })

  const [DivyangDetails, setDivyangDetails] = useState({
    areYouDivyangjan: false,
    typeOfDisabilityLookupId: "",
    typeDisabilityCertificate: "",
    areYouHaveUDID: '',
    udid:"",
    udidEnrolmentNo:"",
    disabilitySubCategoriesLookupIds: []
  })

  const [orphanDetails, setOrphanDetails] = useState({
    DoYouWantTotakeAvantageOfOrphan: "",
    OrphanCertificateNumber: "",
    orphanCertiIssueDate: "",
    orphanType: ""
  })
  const [datePickerData, setDatePiker] = useState({
    dateOfBirth: "",
    ageRelaxCertiNo1: "",
    castCertiIssuingDate: "",
    castValidityCertIssueDate: "",
    dateApptArmedRegular: "",
    dateDischargeArmedRegular: "",
    dateRetireArmedRegular: "",
    domicileIssuingDate: "",
    latestNclIssueDate: "",
    orphanCertiAckReceiptDate: "",
    orphanCertiIssueDate: "",
    sadmCertificateDate: "",
    sportsCertiReportDate: "",
    exServiceYears: "",
    exServiceMonths: "",
    exServiceDays: "",
    issuingDatePa:"",
    issuingDateEqa:"",
    issuingDateGpte:""
  })

  const [flgUnreservedCastValidity, shownotConsiderUnreservedcategoryForCastValidity] = useState(false)
  
  const handleOrphanDetails = (evt) => {
    if(evt.target.name === 'DoYouWantTotakeAvantageOfOrphan'){
      setOrphanDetails({
        ...orphanDetails,
        OrphanCertificateNumber: "",
        ["orphanType"]: "",
      });
      setDatePiker({
        ...datePickerData,
        'orphanCertiIssueDate': ''
      })
      if(personal_info_form && personal_info_form.orphanCertiAckReceipt) {
        personal_info_form.orphanCertiAckReceipt = ""
      }
      if(personal_info_form && personal_info_form.orphanReservationCertiNo){
        personal_info_form.orphanReservationCertiNo = ""
      }
      if(personal_info_form && personal_info_form.orphanType){
        personal_info_form.orphanType = ""
      }
      setValue('OrphanCertificateNumber', '')
      setValue('orphanType', '')
    }
    const value = evt.target.value;
    setOrphanDetails({
      ...orphanDetails,
      [evt.target.name]: value
    });
    if(evt.target.name === 'DoYouWantTotakeAvantageOfOrphan' && evt.target.value === "No"){
      setOrphanDetails({
        ["orphanType"]: "",
      });
    }
    setReloadItem(!reloadItem);
  }

  const handleCastPersonalInfoBooleanState = (evt) => {
    const value = evt.target.value;
    setcastCategoryBooleanState({
      ...castCategoryBooleanState,
      [evt.target.name]: value
    });
    setReloadItem(!reloadItem);
  }

  const handleCastType = (evt) => {
    setCastType({
      castType: evt.target.value
    })

    if (evt.target.value != '323') {
      setDomicileDetails({
        ...DomicileDetailsBooleanState,
        ['maharashtraDomicileCertificate']: '',
      });
      setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
    }
    setcastCategoryBooleanState({
      ...castCategoryBooleanState,
      'doYouBelongToNonCreamyLayer': '',
      'doYouHaveCastValidityCertificate': '',
      'castCertificateDistrict': ''
    });
    setDatePiker({
      ...datePickerData,
      'castCertiIssuingDate': ''
    })
    setValue('castCertificateDistrict', '')
    setValue('doYouBelongToNonCreamyLayer', '')
    setValue('doYouHaveCastValidityCertificate', '')
    setValue('castCertificateNumber', '')
    setValue('castCertiIssuingDate', '')
    if(personal_info_form && personal_info_form.castCertificateNo){
      personal_info_form.castCertificateNo = '';
    }
    setReloadItem(!reloadItem);
  }

  const handleNCLFlag = (evt) => {
    const value = evt.target.value;
    setcastCategoryBooleanState({
      ...castCategoryBooleanState,
      [evt.target.name]: value
    });
    if(personal_info_form && personal_info_form.nclCertificateNo){
      personal_info_form.nclCertificateNo = '';
    }
    setDatePiker({
      ...datePickerData,
      'latestNclIssueDate': ''
    })
    if (((gender == '321'|| (Number(gender) === 322 && flgTransgenderFemale === "Yes") ) && castListOption !== '323' && value == 'No') || ((castListOption == '324' || castListOption == '325' || castListOption === '326' || castListOption === '327' || castListOption === '328' || castListOption === '329' || castListOption === '330' || castListOption === '331' || castListOption === '333') && value === 'No')) {
      let message = <div className="confirmMessage">
        <p className="word-break">{profile.FORTH_NOTE} </p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {
          setcastCategoryBooleanState({
            ...castCategoryBooleanState,
            doYouBelongToNonCreamyLayer: "No",
          });
          setValue('doYouBelongToNonCreamyLayer', 'No')
        },
        onCancel: async () => {
          setcastCategoryBooleanState({
            ...castCategoryBooleanState,
            doYouBelongToNonCreamyLayer: "",
          });
          setValue('doYouBelongToNonCreamyLayer', '')
        }
      });
    }
    setReloadItem(!reloadItem);
  }

  const handleCastValidityFlag = (evt) => {
    const value = evt.target.value;
    setcastCategoryBooleanState({
      ...castCategoryBooleanState,
      [evt.target.name]: value
    });
    if(personal_info_form && personal_info_form.castValidityCertNo){
      personal_info_form.castValidityCertNo = '';
    }
    setDatePiker({
      ...datePickerData,
      'castValidityCertIssueDate': ''
    })
    setReloadItem(!reloadItem);
  }
  
  const handleSportsPersonalInfoBooleanState = (evt) => {
    dispatch(clearSportForm(true));
    const value = evt.target.value;
    setSportsBooleanState({
      ...SportsBooleanState,
      [evt.target.name]: value
    });
    
    let meritoriousSportsmanDto = meritoriousSportsmanDtoList
    if(value === 'No'){
      dispatch(clearSportForm(true));
      meritoriousSportsmanDto && meritoriousSportsmanDto.length > 0 && meritoriousSportsmanDto.forEach((ele, index) => {
        if(ele.id){
          ele.flgDeleted = true;
        }else{
          meritoriousSportsmanDto.splice(index, 1)
        }
      })
    }
    dispatch(setSportsDetails(meritoriousSportsmanDto))
    if(evt.target.name === 'Meritorious_Sports_Person'){
      setDomicileDetails({
        ...DomicileDetailsBooleanState,
        ['maharashtraDomicileCertificate']: '',
      });
      setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
    }
    setReloadItem(!reloadItem);
  }

  const handleExServiceManState = (evt) => {
    const value = evt.target.value;
    setExServiceManBooleanState({
      ...ExServiceManBooleanState,
      [evt.target.name]: value,
      ["exServiceManDivision"]:''
    });
    if(evt.target.name === 'flgExArmedReg'){
      setDomicileDetails({
        ...DomicileDetailsBooleanState,
        ['maharashtraDomicileCertificate']: '',
      });
      setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
    }
    setDatePiker({
      ...datePickerData,
      ["ageRelaxCertiNo1"]: "",
      ["dateApptArmedRegular"]: "",
      ["dateDischargeArmedRegular"]: "",
      ["dateRetireArmedRegular"]: "",
      ["exServiceYears"]: "",
      ["exServiceMonths"]: "",
      ["exServiceDays"]: ""
    })
    setValue("exServiceManDivision",'')
    setValue("ageRelaxCertiNo1",'')
    setValue("dateApptArmedRegular",'')
    setValue("dateDischargeArmedRegular",'')
    setValue("dateRetireArmedRegular",'')
    setValue("exServiceYears",'')
    setValue("exServiceMonths",'')
    setValue("exServiceDays",'')
    personal_info_form.dischargeCertificateNo = ""
    personal_info_form.ageRelaxCertiNo = ""
    setFlgMaharashtraKarnatakBorderArea("No")
    setValue("flgMaharashtraKarnatakBorderArea")
    setReloadItem(!reloadItem);
  }

  const handleExServiceManDivisionState = (evt) => {
    const value = evt.target.value;
    setExServiceManBooleanState({
      ...ExServiceManBooleanState,
      [evt.target.name]: value
    });
    setReloadItem(!reloadItem);
  }
  const handleGovEmpDetails = (evt) => {
    const value = evt.target.value;
    setGovOfMaharashtraEmpDetail({
      ...govOfMaharashtraEmpDetail,
      [evt.target.name]: value
    });
    if(evt.target.name === "flgGomEmployee"){
      setGovOfMaharashtraEmpDetail({
      ...govOfMaharashtraEmpDetail,
      ["flgGomEmployee"]: value,
      ["flgStateEmployee"]: "",
      });
      personal_info_form.sevaarthId = ""
      setValue("sevaarthId",'')
    }
    if(evt.target.name === "flgStateEmployee"){
      setGovOfMaharashtraEmpDetail({
        ...govOfMaharashtraEmpDetail,
        ["flgStateEmployee"]: value 
        });
        personal_info_form.sevaarthId = ""
        setValue("sevaarthId",'')
    }
    setReloadItem(!reloadItem);
  }

  const handleDomicileDatils = (evt) => {
    const value = evt.target.value;
    setDomicileDetails({
      ...DomicileDetailsBooleanState,
      [evt.target.name]: value
    });
    setReloadItem(!reloadItem);
  }

  const handleDomicileFlag = (evt) => {
    const value = evt.target.value;

    if(value === "Yes") {
      clearKarnatakMaharashtraBorderAreaDetails();
    }

    setDomicileDetails({
      ...DomicileDetailsBooleanState,
      [evt.target.name]: value,
      domicileIssuingDistrict: "",
      domicileCertificateAvailable: "",
    });
    setDatePiker({
      ...datePickerData,
      'domicileIssuingDate': ''
    })
    if(personal_info_form && personal_info_form.domicileCertificateNo){
      personal_info_form.domicileCertificateNo = ""
    }
    setValue('domicileIssuingDistrict', '')
    setValue('domicileCertificateAvailable', '')
    setValue('domicileIssuingDate', '')
    setValue('domicileCertificateNo', '')

    if ((gender == '321'|| (Number(gender) === 322 && flgTransgenderFemale === "Yes") ) && castListOption == '323' && value === 'No') {
      let message = <div className="confirmMessage">
        <p className="word-break">{profile.DOMICILE_CERTIFICATE_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' && exservicemanoftheregularArmedData === 'Yes' && (Meritorious_Sports_Person_Data === 'No' || Meritorious_Sports_Person_Data === '') &&  (areYouDivyangjanData === 'No' || areYouDivyangjanData === '')) {
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation .EX_SERVICEMAN_RESERVATION_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' && Meritorious_Sports_Person_Data === 'Yes' && (exservicemanoftheregularArmedData === 'No' || exservicemanoftheregularArmedData === '') && (areYouDivyangjanData === 'No' || areYouDivyangjanData === '')) {
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation.SPORTS_RESERVATION_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' && areYouDivyangjanData === 'Yes' &&  (Meritorious_Sports_Person_Data === 'No' || Meritorious_Sports_Person_Data === '') && (exservicemanoftheregularArmedData === 'No' || exservicemanoftheregularArmedData === '')) {
      let message = <div className="confirmMessage">
        <p className="word-break">{profile.BENCHMARK_DISABILITIES_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' && Meritorious_Sports_Person_Data === 'Yes' && exservicemanoftheregularArmedData === 'Yes' && areYouDivyangjanData === 'Yes') {
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation.EX_SERVICEMAN_RESERVATION_AND_SPORTS_RESERVATION_AND_DISABILITY_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' &&  (Meritorious_Sports_Person_Data === 'No' || Meritorious_Sports_Person_Data === '') &&  exservicemanoftheregularArmedData === 'Yes' && areYouDivyangjanData === 'Yes') {
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation.EX_SERVICEMAN_RESERVATION_AND_DISABILITY_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' && Meritorious_Sports_Person_Data === 'Yes'  &&  (exservicemanoftheregularArmedData === 'No' || exservicemanoftheregularArmedData === '') && areYouDivyangjanData === 'Yes') {
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation.SPORTS_RESERVATION_AND_DISABILITY_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    if (castListOption == '323' && value === 'No' && Meritorious_Sports_Person_Data === 'Yes'  &&  exservicemanoftheregularArmedData === 'Yes' && (areYouDivyangjanData === 'No' || areYouDivyangjanData === '')) {
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation.EX_SERVICEMAN_RESERVATION_AND_SPORTS_RESERVATION_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }, onCancel: async () => {
          setDomicileDetails({
            ...DomicileDetailsBooleanState,
            ['maharashtraDomicileCertificate']: '',
          });
          setValue('maharashtraDomicileCertificate', '', { shouldValidate: false })
        }
      })
    }
    
    setReloadItem(!reloadItem);
  }

  const handlePersonalDetailsDropdown = (evt) => {
    const value = evt.target.value;
    setPersonalDetail({
      ...personalDetails,
      [evt.target.name]: value
    });
    setReloadItem(!reloadItem);
  }

  const handleMaritialStatus = (evt) => {
    const value = evt.target.value;
      setPersonalDetail({
        ...personalDetails,
        ["maritalStatus"]: value,
        ["flgCandidateLiable"]: ""
      });
      setValue("flgCandidateLiable", "")
      setReloadItem(!reloadItem);
  }

  const handleMotherTongueDropdown = (evt) => {
    const value = evt.target.value;
    setPersonalDetail({
      ...personalDetails,
      ["motherTongue"]: value
    });
    personal_info_form.otherLanguages = ""
    setValue("otherLanguages", "")
    setReloadItem(!reloadItem);
  }

  const handleGenderChange = (evt) => {
    handlePersonalDetailsDropdown(evt);
    setValue("flgTransgenderFemale", "No");
    setFlgTransgenderFemale("No");
    setReloadItem(!reloadItem);
  }
  const handleDivyangDetails = (evt) => {
    const value = evt.target.value;
    if(value === "No"){
      setDisabilitySubCategoriesLookupIds("");
      setDisabilitySubCategoriesOptions([])
    }
    setDivyangDetails({
      ...DivyangDetails,
      [evt.target.name]: value,
      ["typeOfDisabilityLookupId"]:'',
      ["typeDisabilityCertificate"]:'',
      ['areYouHaveUDID']:'',
      ["udid"]:'',
      ["udidEnrolmentNo"]:'',
    });
    personal_info_form.divyangPercentage = ""
    personal_info_form.sadmCertificateNumber = ""
    setDatePiker({
      ...datePickerData,
      'sadmCertificateDate': ''
    })
    setValue('sadmCertificateDate', '')
    setValue("typeOfDisabilityLookupId",'');
    setValue("typeDisabilityCertificate",'');
    setValue("disabilitySubCategoriesLookupIds","")
    setValue("udid",'');
    setValue("udidEnrolmentNo","")
    setFlgMaharashtraKarnatakBorderArea("No")
    setValue("flgMaharashtraKarnatakBorderArea", "No")
    setReloadItem(!reloadItem);
  }

  const handleDisabilityCategoryChange = (evt) => {
    const value = evt.target.value;
    setDisabilitySubCategoryErr("")
    setShowDisabilitiesNote(false)
    let resetValue = "";
    setDisabilitySubCategoriesLookupIds(resetValue);
    setValue("disabilitySubCategoriesLookupIds", resetValue);
    setDivyangDetails({
      ...DivyangDetails,
      [evt.target.name]: value,
      disabilitySubCategoriesLookupIds: (Number(value) === multiSelectCatId) ? mandatoryMultipleDisabilitySubCats: []
    });
    let currentDisabilitySubCategories = allDisabilitiesData.filter((subCat) => {
      return Number(subCat.parentId) === Number(value)
    });
    setDisabilitySubCategoriesOptions(currentDisabilitySubCategories);
    setFlgMaharashtraKarnatakBorderArea("No")
    setValue("flgMaharashtraKarnatakBorderArea", "No")
    setReloadItem(!reloadItem);
  }

  const handleDisabilitySubCategoriesSelection = (evt) => {
    let selection = evt;
    setDisabilitySubCategoryErr("");
    let disabilitySubCategoriesLookupIdsArray = [];
    if(DivyangDetails.typeOfDisabilityLookupId === "439"){
      if(evt.length < 2){
        setDisabilitySubCategoryErr(profileotherinformation.CATEGORY_ERR);
      }else{
        setDisabilitySubCategoryErr("");
      }
    };

    if(selection) {
      if(Number(DivyangDetails.typeOfDisabilityLookupId) !== multiSelectCatId) {
        disabilitySubCategoriesLookupIdsArray = [ selection.id ]
      } else {
        for(let i=0; i< selection.length; i++) {
          disabilitySubCategoriesLookupIdsArray.push(selection[i].id)
        }
      };
    };
    if((DivyangDetails.typeOfDisabilityLookupId) === multiSelectCatId && disabilitySubCategoriesLookupIdsArray.length !==2){
      setDisabilitySubCategoryErr(profileotherinformation.CATEGORY_ERR)
    }
    if(selection.isRequired){
      setShowDisabilitiesNote(true)
    }else{
      setShowDisabilitiesNote(false)
    }
    setDivyangDetails({
      ...DivyangDetails,
      disabilitySubCategoriesLookupIds: disabilitySubCategoriesLookupIdsArray,
    })
    setDisabilitySubCategoriesLookupIds(selection)
    setReloadItem(!reloadItem);
  }
  
  const handleDisabilityCertificate = (evt) => {
    const value = evt.target.value;
    setDivyangDetails({
      ...DivyangDetails,
      [evt.target.name]: value
    });
    
    setDatePiker({
      ...datePickerData,
      'sadmCertificateDate': ''
    })
    setValue('sadmCertificateDate', '', { shouldValidate: false })
    if(personal_info_form && personal_info_form.sadmCertificateNumber) {
      personal_info_form.sadmCertificateNumber = ''
    }
    setValue('sadmCertificateNumber', '', { shouldValidate: false })
    setReloadItem(!reloadItem);
    
    if(additionResEnvData && additionResEnvData.disCertValidityDate){
        if((value === '1')){
          let message = (
            <div className="confirmMessage">
              <p className="word-break mt-4">{additionResEnvData.disCertValidityDate}</p>
            </div>
          );
          toastr.confirm(message, {
            onOk: async () => {
            }
          });
        }
      }
  }

  const handleDisabilityUDIDChange = (evt) => {
    const value = evt.target.value;
    setDivyangDetails({
      ...DivyangDetails,
      [evt.target.name]: value,
      ["udid"]:'',
      ["udidEnrolmentNo"]:'',
    });
  }

  const handleDivyangPercentage = () => {
    let divyangPercentage =  parseInt(watch("divyangPercentage"));
    if(divyangPercentage &&  divyangPercentage < 40){
      let message = <div className="confirmMessage">
        <p className="word-break">{profileotherinformation.DISABILITY_RESERVATION_AND_SCRIBE_MESSAGE}</p>
      </div>
      toastr.confirm(message, {
        onOk: async () => {

        }
      })
    }
    setReloadItem(!reloadItem);
  }

  const handleFromToDateSelection = (fromdate, todate) => {
    if (!todate) {
      todate = new Date();
    }
    let difference = dateDiffereceExServiceMan(fromdate, todate);
    let expYears = 0;
    let expMonths = 0;
    let expDays = 0;
    if (difference.firstDateWasLater) {
      expYears = 0;
      expMonths = 0;
      expDays = 0;
    } else {
      expYears = difference.years;
      expMonths = difference.months;
      expDays = difference.days;
    }
    setDatePiker({
      ...datePickerData,
      exServiceYears: difference.years,
      exServiceMonths: difference.months,
      exServiceDays: difference.days
    })

    setReloadItem(!reloadItem);
  }

  const listOfAllBorderDistricts = getLookOptionsForADropdown("maha_karnatak_border_district", profileCreationAllLookupData);
  const listOfAllBorderTalukas = getLookOptionsForADropdown("maha_karnatak_border_taluka", profileCreationAllLookupData);
  const listOfAllBorderVillages = getLookOptionsForADropdown("maha_karnatak_border_village", profileCreationAllLookupData);

  let [ flgMaharashtraKarnatakBorderArea, setFlgMaharashtraKarnatakBorderArea ] = useState("No");
  let [ borderTalukaOptions, setBorderTalukaOptions ] = useState([]);
  let [ borderVillageOptions, setBorderVillageOptions ] = useState([]);

  let [ borderAreaDetails, setBorderAreaDetails ] = useState({
    borderDistrictLookupId: "",
    borderTalukaLookupId: "",
    borderVillageLookupId: ""
  });

  const clearKarnatakMaharashtraBorderAreaDetails = () => {

    setBorderAreaDetails({
      borderDistrictLookupId: "",
      borderTalukaLookupId: "",
      borderVillageLookupId: ""
    });

    setFlgMaharashtraKarnatakBorderArea("No");
    setReloadItem(!reloadItem);
  }

  const handleMahaKarBorderDistrictChange = (e) => {
    let districtCode = e.target.value;

    setBorderAreaDetails({
      ...borderAreaDetails,
      borderDistrictLookupId: districtCode
    })

    let currentTalukaOptions = [];
    
    if(districtCode) {
      currentTalukaOptions = listOfAllBorderTalukas.filter((taluka) => {
        return Number(taluka.parentId) === Number(districtCode);
      })
    }
    setBorderTalukaOptions(currentTalukaOptions);
    setBorderVillageOptions([]);
    setReloadItem(!reloadItem);
  }

  const handleMahaKarBorderTalukaChange = (e) => {
    let talukaCode = e.target.value;

    setBorderAreaDetails({
      ...borderAreaDetails,
      borderTalukaLookupId: talukaCode
    })

    let currentVillageOptions = [];
    
    if(talukaCode) {
      currentVillageOptions = listOfAllBorderVillages.filter((taluka) => {
        return Number(taluka.parentId) === Number(talukaCode);
      })
    }

    setBorderVillageOptions(currentVillageOptions);
    setReloadItem(!reloadItem);
  }

  const handleMahaKarBorderVillageChange = (e) => {
    setBorderAreaDetails({
      ...borderAreaDetails,
      borderVillageLookupId: e.target.value
    })
    setReloadItem(!reloadItem);
  }
  const dateDischargeArmedRegularChange=(dateApptArmedRegular,dateDischargeArmedRegular)=>{
    if(dayjs(dateApptArmedRegular).isAfter(dateDischargeArmedRegular)){
      setValue("dateDischargeArmedRegular",'')
    }
    setReloadItem(!reloadItem);
  }
  
  const handleSevaartIdChange = (sevaartid) => {
        setSevaartIdErr(false);
        if((sevaartid.length === 11) || (sevaartid.length === 13)){
          setSevaartIdErr(false);
          setValue("sevaarthId",sevaartid ? sevaartid.toUpperCase(): sevaartid)
        }else if(sevaartid.length === 0){
          setSevaartIdErr(false)
          setValue("sevaarthId", '');  
        }
        else{
          
          setSevaartIdErr(true);
        };
  };

  return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
  (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-4 f12">
          <div className="d-flex">
          <h5>
            {profile.FIRST_PROFILE_HEADER}
          </h5>
          <span className="Note-color ml-2">
            {profile.FIRST_NOTE}
          </span>
          </div>
        <div className="row" >
          <span className="RadioText ml-3" >{numberformat["1.1"]}&nbsp;{profile.Are_YOU_HOLDING_AADHAR_CARD}</span>
          <div className="radio ml-1">
            <label>
              <input
                type="radio"
                checked={(isAdharCardPresentFlag === "Yes" ? true : false)}
                onChange={(e) => {setFlagOfAdharCard(e.target.value) }}
                className="RadioText"
                value="Yes"
                name="isAdharCardPresentFlag"
                ref={register({ required: true })}
              />&nbsp;{profile.YES}</label>
          </div>
          <div className="radio ml-2">
            <label>
              <input
                type="radio"
                checked={(isAdharCardPresentFlag === "No" ? true : false)}
                onChange={(e) => { setFlagOfAdharCard(e.target.value) }}
                name="isAdharCardPresentFlag"
                value="No"
                className="mr-1"
                ref={register({ required: true })}/>{profile.NO}</label>
          </div>
          {isAdharCardPresent === 'No' ? 
          <div className="Note-color ml-2">{profile.AADHAR_SECOND_NOTE}</div> : ""}
        </div>
        <div id="adharcheck">
          <div className="row">
            {isAdharCardPresent === "Yes" ?
              <div id="adharno" className="form-group col-md-3">
                <label className="LeftText" >{numberformat["1.1.1"]}&nbsp;{profile.AADHAR_NO}</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={personal_info_form && personal_info_form.aadhaarNo}
                  name="aadhaarNo" ref={register({ required: false, pattern: aadhaarNumber })}
                />
                {_.get("aadhaarNo.type", errors) === "pattern" && (
                  <p className="errorText">{profile.ENTER_VALID_AADHAR_NUMBER}</p>
                )}
              </div> : ''}
            {isAdharCardPresent === "Yes" ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.1.2"]}&nbsp;{profile.NAME_IN_ENGLISH}<span className="redColor">*</span></label>
              <input
                type="text"
                className="form-control"
                defaultValue={personal_info_form && personal_info_form.aadhaarNameInEnglish}
                maxLength={nameInEnglishMaxLength}
                name="aadhaarNameInEnglish" ref={register({ required: true, pattern: fullname, maxLength: nameInEnglishMaxLength })}
              />
              {_.get("aadhaarNameInEnglish.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("aadhaarNameInEnglish.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_VALID_NAME}</p>
              )}
              {_.get("aadhaarNameInEnglish.type", errors) === "maxLength" && (
                <p className="errorText">{profile.MAX_VALUE_FOR_50_CHAR}</p>
              )}
              <span className="Note">{profile.NAME_IN_ENGLISH_NOTE}</span>
            </div> : ""}
          </div>
        </div>
        <hr></hr>
        <h5 className="text-left m-0">
          {profile.SECOUND_PROFILE_HEADER}
        </h5>
        <div className="row">
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.2"]}&nbsp;{profile.SURNAME}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              maxLength={shortNameMaxLength}
              defaultValue={personal_info_form && personal_info_form.surname}
              name="surName" ref={register({
                required: true, pattern: {
                  value: /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s_]+$/

                }, maxLength: 30
              })}
            />
            {_.get("surName.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("surName.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_SURNAME}</p>
            )}
            {_.get("surName.type", errors) === "maxLength" && (
              <p className="errorText">{profile.MAX_VALUE_FOR_30_CHAR}</p>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{profile.FIRST_NAME}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              maxLength={shortNameMaxLength}
              defaultValue={personal_info_form && personal_info_form.firstName}
              name="firstName" ref={register({
                required: true, pattern: {
                  value: /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s_]+$/

                }, maxLength: 30
              })}
            />
            {_.get("firstName.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("firstName.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_FIRSTNAME}</p>
            )}
            {_.get("firstName.type", errors) === "maxLength" && (
              <p className="errorText">{profile.MAX_VALUE_FOR_30_CHAR}</p>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{profile.MIDDLE_NAME}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              defaultValue={personal_info_form && personal_info_form.middleName}
              maxLength={shortNameMaxLength}
              name="middleName" ref={register({
                required: true, pattern: {
                  value: /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s_]+$/

                }, maxLength: 30
              })}
            />
            {_.get("middleName.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("middleName.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_MIDDLENAME}</p>
            )}
            {_.get("middleName.type", errors) === "maxLength" && (
              <p className="errorText">{profile.MAX_VALUE_FOR_30_CHAR}</p>
            )}
          </div>
          <div className="form-group col-md-6">
            <label className="LeftText">{numberformat["1.3"]}&nbsp;{profile.FULLNAME_ENGLISH}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              maxLength={fullNameMaxLength}
              defaultValue={personal_info_form && personal_info_form.englishFullName}
              name="fullNameInEnglish" ref={register({ required: true, pattern: fullname, maxLength: 150 })}
            />
            {_.get("fullNameInEnglish.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("fullNameInEnglish.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_FULLNAME}</p>
            )}
            {_.get("fullNameInEnglish.type", errors) === "maxLength" && (
              <p className="errorText">{profile.MAX_VALUE_FOR_150_CHAR}</p>
            )}
            <span className="Note" >{profile.FULL_NAME_NOTE}</span>
          </div>
          <div className="form-group col-md-6">
            <label className="LeftText ml-3">{numberformat["1.4"]}&nbsp;{profile.FULLNAME_DEVNAGARI}<span className="redColor">*</span></label>
            {personal_info_form && personal_info_form.devnagariFullName ?
              <Controller
                name="devnagari"
                control={control}
                key="devnagari"
                defaultValue={personal_info_form.devnagariFullName}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Devnagari onChange={onChange} value={value} />
                )}
              /> :
              <Controller
                name="devnagari"
                control={control}
                key="devnagari1"
                defaultValue=""
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Devnagari onChange={onChange} value={value} />
                )}
              />
            }
            {_.get("devnagari.type", errors) === "required" && (
              <p className="errorText ml-3" >{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.5"]}&nbsp;{profile.MOTHERS_NAME}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              maxLength={shortNameMaxLength}
              defaultValue={personal_info_form && personal_info_form.motherName}
              name="mothersName" ref={register({ required: true, pattern: fullname, maxLength: 30 })}
            />
            {_.get("mothersName.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("mothersName.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_MOTHER_NAME}</p>
            )}
            {_.get("mothersName.type", errors) === "maxLength" && (
              <p className="errorText">{profile.MAX_VALUE_FOR_30_CHAR}</p>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.6"]}&nbsp;{profile.DATE_OF_BIRTH}<span className="redColor">*</span></label>
            <Controller
              name="dateOfBirth"
              control={control}
              key="dateOfBirthKey1"
              defaultValue={datePickerData.dateOfBirth}
              rules={{ required: false }}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={fromUTCDate(authenticatedUserData.dateOfBirth)}
                  onChange={(e) => {
                    onChange(toUTCDate(e))
                  }}
                  showMonthDropdown
                  showYearDropdown
                  disabled={true}
                  placeholderText={commonLabels.DATE_FORMAT}
                  className="form-control"
                  maxDate={new Date()}
                  dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                />
              )}
            />
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.7"]}&nbsp;{profile.GENDER}<span className="redColor">*</span></label>
            <select className="form-control" name="gender" ref={register({ required: true })} value={personalDetails.gender} onInput={handleGenderChange}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {genderList && genderList.map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
                    })}
            </select>
            {_.get("gender.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {
            (Number(personalDetails.gender) === 322) ?
              <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.7.1"]}&nbsp;{profile.FLG_TRANSGENDER_FEMALE_RADIO_BTN_LABEL}
                  <span className="redColor">*</span>
                </label>
                <label className="mt-0 mr-1" >
                  <input
                    type="radio"
                    onChange={(e) => {setFlgTransgenderFemale(e.target.value)}}
                    className="RadioText"
                    value={"Yes"}
                    key="flgTransgenderFemale1"
                    name="flgTransgenderFemale"
                    ref={register({ required: true })}
                    checked={(flgTransgenderFemale === "Yes")}
                  />&nbsp;{profile.YES}</label>
                <label className="mt-1 mr-1">
                  <input
                    type="radio"
                    onChange={(e) => {
                      setBorderAreaDetails({
                        borderDistrictLookupId: "",
                        borderTalukaLookupId: "",
                        borderVillageLookupId: ""
                      });
                      setFlgTransgenderFemale(e.target.value)
                    }}
                    name="flgTransgenderFemale"
                    key="flgTransgenderFemale2"
                    value={"No"}
                    className="mr-1"
                    ref={register({ required: true })}
                    checked={(flgTransgenderFemale === "No")}
                  />{profile.NO}</label>
              </div>
            : <div className="form-group col-md-3"></div>
          }
          <div className="col-md-3 mt-4">
            <label className="LeftText">{numberformat["1.8"]}&nbsp;{profile.MOBILE_NO}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              name="mobileNumber"
              defaultValue={authenticatedUserData.registeredMobileNo}
              maxLength={10}
              ref={register({ required: true, pattern: mobile })}
              disabled
            />
            {_.get("mobileNumber.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("mobileNumber.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_MOBILE_NUMBER}</p>
            )}
          </div>
          <div className="col-md-3 mt-4">
            <label className="LeftText">{numberformat["1.9"]}&nbsp;{profile.Email_ID}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              defaultValue={authenticatedUserData.registeredEmailId}
              name="emailID" ref={register({ required: true, pattern: email })}
              disabled
            />
            {_.get("emailID.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("emailID.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_EMAIL}</p>
            )}
          </div>
          <div className="col-md-3 mt-4">
            <label className="LeftText">{numberformat["1.10"]}&nbsp;{profile.WHETHER_MARRIED}<span className="redColor">*</span></label>
            <select className="form-control" name="maritalStatus" ref={register({ required: true })} value={personalDetails.maritalStatus} onInput={handleMaritialStatus}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {maritalStatusList && maritalStatusList.map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
                    })}
            </select>
            {_.get("maritalStatus.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {maritalStatusData == '357' || maritalStatusData == '359' ?
            <div className="col-md-3 mb-0 mt-n4">
              <label className="LeftText">{numberformat["1.10.1"]}&nbsp;{profile.CANDIDATE_LIABLE}<span className="redColor">*</span></label>
                <select className="form-control" name="flgCandidateLiable" 
                ref={register({ required: true })} 
                value={personalDetails.flgCandidateLiable} 
                onInput={handlePersonalDetailsDropdown} >
                  {BOOLEAN_OPTION_PERSONALINFO.map((ele,ind) => {
                    return <option key={ind} value={ele.value}>{ele.label}</option>
                  })}
                </select>
                {_.get("flgCandidateLiable.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
            </div> :  <div className="form-group col-md-3"></div>}

          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.11"]}&nbsp;{profile.NATIONALITY}<span className="redColor">*</span></label>
            <select className="form-control" name="Nationality" ref={register({ required: true })} value={personalDetails.Nationality} onInput={handlePersonalDetailsDropdown}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {nationality && nationality.map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
                    })}
            </select>
            {_.get("Nationality.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.12"]}&nbsp;{profile.MOTHER_TONGUE}<span className="redColor">*</span></label>
            <select className="form-control" name="motherTongue" ref={register({ required: true })} value={personalDetails.motherTongue} onInput={handleMotherTongueDropdown}>
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {motherTongueList && motherTongueList.map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
              })}
            </select>
            {_.get("motherTongue.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {motherTongueData == '356' ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.12.1"]}&nbsp;{profile.OTHER_LANGUAGES}<span className="redColor">*</span></label>
              <input
                type="text"
                className="form-control"
                maxLength={casteCertificationMaxLength}
                defaultValue={personal_info_form && personal_info_form.otherLanguages}
                name="otherLanguages" ref={register({ required: true, pattern: name })}
              />
              {_.get("otherLanguages.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("otherLanguages.type", errors) === "pattern" && (
                <p className="errorText"> {profile.ENTER_VALID_OTHER_LANGUAGE}</p>
              )}
            </div> :""}
            {/* 'Whether your name is changed/updated after 10th or equivalent qualification or after marriage */}
          <div className="col-md-12">
          <div className="row">
          <div className="form-group col-md-9 mt-2" >
            <div className="mt-2">
              <span className="LeftText">
                {numberformat["1.13"]}&nbsp;
                {profile["WHETHER_YOUR_NAME_CHANGED/UPDATES_AFTER_10TH"]}
              </span>&nbsp;
              <label className="position-relative mt12">
                <input
                  type="radio"
                  checked={(personalDetails.flgNameChanged === "Yes" ? true : false)}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  className="RadioText"
                  value="Yes"
                  name="flgNameChanged" ref={register({ required: true })}
                />&nbsp;{profile.YES}
              </label>
              <label className="position-relative mt12">
                <input type="radio"
                  className="RadioText"
                  checked={(personalDetails.flgNameChanged === "No" ? true : false)}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  value="No"
                  name="flgNameChanged" ref={register({ required: true })}
                />&nbsp;{profile.NO}
              </label>
            </div>
          </div>
          {watch("flgNameChanged") === "Yes" ?
          <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.13.1"]}&nbsp;{profile.FULL_NAME_IN_ENGLISH}<span className="redColor">*</span></label>
              <input
                type="text"
                className="form-control"
                maxLength={fullNameMaxLength}
                defaultValue={personal_info_form && personal_info_form.changedEnglishName}
                name="changedEnglishName" ref={register({ required: true, pattern: fullname, maxLength :fullNameMaxLength })}
              />
                 {_.get("changedEnglishName.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("changedEnglishName.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_VALID_DATA}</p>
            )}
            </div> :<div className="form-group col-md-3"></div>}
          </div>
          </div>
          <div className="form-group col-md-12 mt-2 position-relative ml-3">
            <div className="row">
            <span className="LeftText">
                {numberformat["1.14"]}&nbsp;{profile.CAN_YOU_WRITE_SPEAK_MARATHI}
              </span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="LeftText">{numberformat["1.14.1"]} {profile.READ} : </span>
              <label className="position-relative mt12">
                <input
                  type="radio"
                  checked={(personalDetails.canYouRead === "Yes" ? true : false)}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  className="RadioText"
                  value="Yes"
                  name="canYouRead" ref={register({ required: true })}
                />&nbsp;{profile.YES}
              </label>
              <label className="position-relative mt12">
                <input type="radio"
                  className="RadioText"
                  checked={(personalDetails.canYouRead === "No" ? true : false)}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  value="No"
                  name="canYouRead" ref={register({ required: true })}
                />&nbsp;{profile.NO}
              </label>
              <span className="LeftText ml-4">
                {numberformat["1.14.2"]}&nbsp;{profile.WRITE} :
            </span>
              <label className="position-relative mt12">
                <input
                  type="radio"
                  checked={(personalDetails.canYouWrite === "Yes" ? true : false)}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  className="RadioText"
                  value="Yes"
                  name="canYouWrite" ref={register({ required: true })}
                />&nbsp;{profile.YES}
              </label>
              <label className="position-relative mt12">
                <input type="radio"
                  className="RadioText"
                  checked={personalDetails.canYouWrite === "No" ? true : false}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  value="No"
                  name="canYouWrite"
                  ref={register({ required: true })} />
                 &nbsp;{profile.NO}
              </label>
              <span className="LeftText ml-4">
                {numberformat["1.14.3"]}&nbsp;{profile.SPEAk} :
            </span>
              <label className="position-relative mt12">
                <input
                  type="radio"
                  checked={personalDetails.canYouSpeak === "Yes" ? true : false}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  className="RadioText"
                  value="Yes"
                  name="canYouSpeak" ref={register({ required: true })}
                />&nbsp;{profile.YES}
              </label>
              <label className="position-relative mt12">
                <input type="radio"
                  checked={personalDetails.canYouSpeak === "No" ? true : false}
                  onChange={(e) => { handlePersonalDetailsDropdown(e) }}
                  className="RadioText"
                  value="No"
                  name="canYouSpeak" ref={register({ required: true })} />
                  &nbsp;{profile.NO}
              </label>
            </div>
          </div>
        </div>
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.PHYSICAL_MESUREMENT}
        </h5>
        <div className="row">
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.15"]}&nbsp;{profile.HEIGHT}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              maxLength={6}
              defaultValue={personal_info_form && personal_info_form.height}
              name="HeightInCMS" ref={register({ required: true, pattern: weightRegex })}
            />
            {_.get("HeightInCMS.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("HeightInCMS.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_NUMERIC_DATA}</p>
            )}
            <span className="Note">{profile.HEIGHT_EX}</span>
          </div>
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.16"]}&nbsp;{profile.WEIGHT}<span className="redColor">*</span></label>
            <input
              type="text"
              className="form-control"
              maxLength={6}
              defaultValue={personal_info_form && personal_info_form.weight}
              name="Weight" ref={register({ required: true, pattern: weightRegex })}
            />
            {_.get("Weight.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
            {_.get("Weight.type", errors) === "pattern" && (
              <p className="errorText">{profile.ENTER_NUMERIC_DATA}</p>
            )}
            <span className="Note">{profile.WEIGHT_EX}</span>
          </div>
          {(gender == '320' || (Number(gender) === 322 && flgTransgenderFemale === "No")) ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.17"]}&nbsp;{profile.CHEST_DEFLATED}<span className="redColor">*</span></label>
              <input
                type="text"
                className="form-control"
                maxLength={6}
                max= {watch("chestInflated")}
                defaultValue={personal_info_form && personal_info_form.chestDeflated}
                name="chestDeflated" ref={register({ required: true, pattern: weightRegex })}
              />
              {_.get("chestDeflated.type", errors) === "required" && (
                
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("chestDeflated.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_NUMERIC_DATA}</p>
              )}
              {(Number(watch("chestInflated")) <= Number(watch("chestDeflated"))) ? <p className="errorText">{profileotherinformation.CHES_DEFLATED_GREATER_THAN_CHEST_INFLATED}</p> : ""  }
              <span className="Note">{profile.CHEST_DEFLATED_EX}</span>
            </div> : ''}
          {(gender == '320' || (Number(gender) === 322 && flgTransgenderFemale === "No")) ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.18"]}&nbsp;{profile.CHEST_INFLATED}<span className="redColor">*</span></label>
              <input
                type="text"
                className="form-control"
                maxLength={6}
                min= {watch("chestDeflated")}
                defaultValue={personal_info_form && personal_info_form.chestInflated}
                name="chestInflated" ref={register({ 
                  required: true, pattern: weightRegex,
                 })}
              />
              {_.get("chestInflated.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("chestInflated.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_NUMERIC_DATA}</p>
              )}
               {(Number(watch("chestDeflated")) >= Number(watch("chestInflated"))) ? <p className="errorText">{profileotherinformation.CHES_INFLATED_GREATER_THAN_CHEST_DEFLATED}</p> : ""  }
              <span className="Note">{profile.CHEST_INFLATED_EX}</span>
            </div> : ''}
        </div>
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.CASTE_CATEGORY_DETAILS}
        </h5>
        <div className="row">
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.19"]}&nbsp;{profile.CATEGORY}<span className="redColor">*</span></label>
            <select className="form-control" onInput={handleCastType} name="castType" ref={register({ required: true })} value={castTypeSelection.castType} >
              <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {castCategoryList && castCategoryList.sort((a, b) => { return Number(a.sortOrder) - Number(b.sortOrder) }).map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
              })}
            </select>
            {_.get("castType.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {showEWS ?
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.19.1"]}&nbsp;{profile.EWS_CASTE_CERTIFICATE_NUMBER}<span className="redColor">*</span></label>
            <div>
              <input
                type="text"
                className="form-control"
                maxLength={casteCertificationMaxLength}
                defaultValue={personal_info_form && personal_info_form.ewsCertificateNumber}
                name="ewsCertificateNumber" ref={register({ required: true, pattern: alphanumeric })}
              />
              {_.get("ewsCertificateNumber.type", errors) === "required" && (
                <p className="errorText">{profile.REQUIRED_EWS_CERTIFICATE_NUMBER}</p>
              )}
              {_.get("ewsCertificateNumber.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_VALID_EWS_NUMBER}</p>
              )}
            </div>
          </div>:""}
          {hideCastCertificate ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.19.1"]}&nbsp;{profile.CASTE_CERTIFICATE_NUMBER}<span className="redColor">*</span></label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  maxLength={casteCertificationMaxLength}
                  defaultValue={personal_info_form && personal_info_form.castCertificateNo}
                  name="castCertificateNumber" ref={register({ required: true, pattern: certificateNumberRegex })}
                />
                {_.get("castCertificateNumber.type", errors) === "required" && (
                  <p className="errorText">{profile.REQUIRED_CASTE_CERTIFICATE_NUMBER}</p>
                )}
                {_.get("castCertificateNumber.type", errors) === "pattern" && (
                  <p className="errorText">{profile.ENTER_VALID_CASTE_CERTIFICATE_NUMBER}</p>
                )}
              </div>
            </div> : ""}
          {hideCastCertificate ||showEWS ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.19.2"]}&nbsp;{profile.ISSUING_DISTRICT}<span className="redColor">*</span></label>
              <select className="form-control" onInput={handleCastPersonalInfoBooleanState} value={castCategoryBooleanState.castCertificateDistrict} name="castCertificateDistrict" ref={register({ required: true })}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {issuingDistrictCategoryList.length > 0 && issuingDistrictCategoryList.sort((a, b) => a.districtname < b.districtname ? - 1 : 1).map((ele,ind) => {
                    return <option key={ind++} value={ele.districtcode}>{ele.districtname}</option>
                 } )}
              </select>
              {_.get("castCertificateDistrict.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div> : ""}
          {hideCastCertificate || showEWS ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.19.3"]}&nbsp;{profile.ISSUING_DATE}<span className="redColor">*</span></label>
                <Controller
                  name="castCertiIssuingDate"
                  control={control}
                  key={ personal_info_form && personal_info_form.id ? "castCertiIssuingDatekey1" : ""}
                  defaultValue={ personal_info_form && personal_info_form.id ? datePickerData.castCertiIssuingDate :""}
                  rules={{required: true}}
                  onFocus={() => 
                    {
                      castCertiIssuingDateRef.current.setFocus()
                      document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value}) => (
                    <DatePicker
                      selected={fromUTCDate(value)}
                      onChange={(e) => {
                                    onChange(toUTCDate(e))
                                }}
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={commonLabels.DATE_FORMAT}
                      className="form-control"
                      maxDate={new Date()}
                      dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                      ref={castCertiIssuingDateRef}
                    />
                  )}
                />
              {_.get("castCertiIssuingDate.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div> : ""}
        </div>
        {hideNCL ?
          <div className="row">
            <div className="form-group col-md-3">
              <label className="LeftText">
                {numberformat["1.20"]}&nbsp;{profile.DO_YOU_BELONG_TO_NON_CREAMY_LAYER}
                {personalDetails.gender!="321" && <span className="redColor">*</span>}
              </label>
              <select 
                className="form-control" 
                onInput={handleNCLFlag} 
                value={castCategoryBooleanState.doYouBelongToNonCreamyLayer} 
                name="doYouBelongToNonCreamyLayer" 
                ref={register({ required: personalDetails.gender!="321" ? true : false })}>
                {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                  <option key={index} value={ele.value}>{ele.label}</option>
                )}
              </select>
              {personalDetails.gender!="321" && _.get("doYouBelongToNonCreamyLayer.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>

            {doYouBelongToNonCreamyLayerData && doYouBelongToNonCreamyLayerData == 'Yes' && castListOption !== '323' ?
              <div className="form-group col-md-3">
                <label className="LeftText">{numberformat["1.20.1"]}&nbsp;{profile.CASTE_CERTIFICATE_NUMBER_NON_CREAMY}
                {/* <span className="redColor">*</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength={50}
                  defaultValue={personal_info_form && personal_info_form.nclCertificateNo}
                  name="nclCertificateNo" ref={register({ required: false, pattern: certificateNumbeForNclandDomicileRegex })}
                />
                {/* {_.get("nclCertificateNo.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )} */}
                {_.get("nclCertificateNo.type", errors) === "pattern" && (
                  <p className="errorText">{profile.ENTER_VALID_CASTE_CERTIFICATE_NUMBER}</p>
                )}
              </div>
              :
              doYouBelongToNonCreamyLayerData && doYouBelongToNonCreamyLayerData == 'Yes' && castListOption === '323' ?

              <div className="form-group col-md-3">
                <label className="LeftText">{numberformat["1.20.1"]}&nbsp;{profile.CASTE_CERTIFICATE_NUMBER_NON_CREAMY}</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength={50}
                  defaultValue={personal_info_form && personal_info_form.nclCertificateNo}
                  name="nclCertificateNo" 
                  ref={register({ required: false })}
                />
              </div>
              : ""}
            {(gender == '321'|| (Number(gender) === 322 && flgTransgenderFemale === "Yes")) && castListOption !== '323' && doYouBelongToNonCreamyLayerData == 'No' ? <span className="Note-color mt-4 ml-3">{profile.NOT_CONSIDER_FOR_FEMALE_RESERVATION}</span> :""}
            {doYouBelongToNonCreamyLayerData && doYouBelongToNonCreamyLayerData == 'Yes' && castListOption !== '323' ?
              <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.20.2"]}&nbsp;{profile.DATE_OF_ISSUE_OF_LATEST_NCL_CERTIFICATE}
                  {/* <span className="redColor">*</span> */}
                </label>
                  <Controller
                    name="latestNclIssueDate"
                    control={control}
                    key={ personal_info_form && personal_info_form.id ? "latestNclIssueDatekey1" :  "latestNclIssueDatekey2"}
                    defaultValue={personal_info_form && personal_info_form.id ? datePickerData.latestNclIssueDate : ""}
                    rules={{ required: false }}
                    onFocus={() => 
                      {
                        latestNclIssueDateRef.current.setFocus()
                        document.activeElement && document.activeElement.scrollIntoView()
                     }
                    }
                    render={({ onChange, value}) => (
                      <DatePicker
                        selected={fromUTCDate(value)}
                        onChange={(e) => {
                          onChange(toUTCDate(e))
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText={commonLabels.DATE_FORMAT}
                        className="form-control"
                        maxDate={new Date()}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        ref={latestNclIssueDateRef}
                      />
                    )}
                  /> 
                {/* {_.get("latestNclIssueDate.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )} */}
              </div>
              :
              doYouBelongToNonCreamyLayerData && doYouBelongToNonCreamyLayerData == 'Yes' && castListOption === '323' ?
              <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.20.2"]}&nbsp;{profile.DATE_OF_ISSUE_OF_LATEST_NCL_CERTIFICATE}
                </label>
                  <Controller
                    name="latestNclIssueDate"
                    control={control}
                    key={ personal_info_form && personal_info_form.id ? "latestNclIssueDatekey1" :  "latestNclIssueDatekey2"}
                    defaultValue={personal_info_form && personal_info_form.id ? datePickerData.latestNclIssueDate : ""}
                    onFocus={() => 
                      {
                        latestNclIssueDateRef.current.setFocus()
                        document.activeElement && document.activeElement.scrollIntoView()
                     }
                    }
                    render={({ onChange, value}) => (
                      <DatePicker
                        selected={fromUTCDate(value)}
                        onChange={(e) => {
                          onChange(toUTCDate(e))
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText={commonLabels.DATE_FORMAT}
                        className="form-control"
                        maxDate={new Date()}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        ref={latestNclIssueDateRef}
                      />
                    )}
                  /> 
              </div>
              : ""}
          </div> : ""}
        {hideCastValidity ?
          <div className="row">
            <div className="form-group col-md-3">
              <label className="LeftText">
                {numberformat["1.21"]}&nbsp;{profile.DO_YOU_HAVE_CASTE_VALIDITY_CERTIFICATE}<span className="redColor">*</span>
              </label>
              <select className="form-control" onInput={handleCastValidityFlag} value={castCategoryBooleanState.doYouHaveCastValidityCertificate} name="doYouHaveCastValidityCertificate" ref={register({ required: true })} >
                {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                  <option key={index} value={ele.value}>{ele.label}</option>
                )}
              </select>
              {_.get("doYouHaveCastValidityCertificate.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            {doYouHaveCastValidityCertificateData && doYouHaveCastValidityCertificateData == "Yes" ?
              <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.21.1"]}&nbsp;{profile.CASTE_VALIDITY_CERTIFICATE_NO}<span className="redColor">*</span>
                </label>
                <input type="text" className="form-control"
                  name="castValidity"
                  maxLength={casteCertificationMaxLength}
                  defaultValue={personal_info_form && personal_info_form.castValidityCertNo}
                  ref={register({ required: true, pattern: certificateNumberRegex })} />
                {_.get("castValidity.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
                {_.get("castValidity.type", errors) === "pattern" && (
                  <p className="errorText">{profile.ENTER_VALID_CASTE_VALIDITY_CERTIFICATE_NUMBER}  </p>
                )}
              </div> : ''}

            {doYouHaveCastValidityCertificateData && doYouHaveCastValidityCertificateData == "Yes" ?
              <div className="form-group col-md-3">
                <label className="LeftText w350">
                  {numberformat["1.21.2"]}&nbsp;{profile.ISSUING_DATE}<span className="redColor">*</span>
                </label>
                  <Controller
                    name="castValidityCertIssueDate"
                    control={control}
                    key={personal_info_form && personal_info_form.id ? "castValidityCertIssueDatekey1" : "castValidityCertIssueDatekey2" }
                    defaultValue={personal_info_form && personal_info_form.id ? datePickerData.castValidityCertIssueDate :""}
                    rules={{ required: true }}
                    onFocus={() => 
                      {
                        castValidityCertIssueDateRef.current.setFocus()
                        document.activeElement && document.activeElement.scrollIntoView()
                     }
                    }
                    render={({ onChange, value}) => (
                      <DatePicker
                        selected={fromUTCDate(value)}
                        onChange={(e) => {
                          onChange(toUTCDate(e))
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText={commonLabels.DATE_FORMAT}
                        className="form-control"
                        maxDate={new Date()}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        ref={castValidityCertIssueDateRef}
                      />
                    )}
                  />
                {_.get("castValidityCertIssueDate.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div> : ''}
          </div> : ""}
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.Divyang_Details}
        </h5>
        <div className="row">
          <div className="form-group col-md-3">
            <label className="LeftText">{numberformat["1.22"]}&nbsp;{profile.ARE_YOU_DIVYANGJAN}<span className="redColor">*</span></label>
            <select className="form-control" name="areYouDivyangjan" ref={register({ required: true })} onInput={handleDivyangDetails} value={DivyangDetails.areYouDivyangjan}>
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("areYouDivyangjan.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ?
            <div className="form-group col-md-3 mt-3">
              <label className="LeftText">
                {numberformat["1.22.1"]}&nbsp;{profile.CATEGORY_OF_DISABILITIES}<span className="redColor">*</span>
              </label>
              <select className="form-control" name="typeOfDisabilityLookupId" ref={register({ required: true })} onInput={handleDisabilityCategoryChange} value={DivyangDetails.typeOfDisabilityLookupId}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {listOfDisability && listOfDisability.sort((a, b) => a.sortOrder < b.sortOrder ? - 1 : 1).map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
})}
              </select>
              {_.get("typeOfDisabilityLookupId.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div> : ''}
            {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ?
            <div className="form-group col-md-6 mt-3">
                <label className="mb-0 mt-2">
                  {numberformat["1.22.1.1"]}&nbsp;{profile.DISABILITIES_SUB_CATEGORIES}<span className="redColor">*</span>
                </label>
                <Controller
                  name="disabilitySubCategoriesLookupIds"
                  key= {
                    disabilitySubCategoriesLookupIds ? 
                    "disabilitySubCategoriesLookupIds": 
                    "disabilitySubCategoriesLookupIds1"
                  }
                  control={control}
                  defaultValue={disabilitySubCategoriesLookupIds ? disabilitySubCategoriesLookupIds: ""}
                  rules={{required: true}}
                  onFocus={() => 
                    {
                    disabilitySubCategoriesLookupIdsRef.current.select.focus()
                    document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value}) => (
                    <Select
                      value={value}
                      options={disabilitySubCategoriesOptions}
                      getOptionValue={option => option.id}
                      isMulti={(Number(DivyangDetails.typeOfDisabilityLookupId) === multiSelectCatId)}
                      isClearable={
                        (Number(DivyangDetails.typeOfDisabilityLookupId) === multiSelectCatId) ?
                        false
                        : true
                      }
                      onChange={(e, { action, removedValue }) => {
                        if(action === 'remove-value' && removedValue.isFixed) {
                          return;
                        }
                        handleDisabilitySubCategoriesSelection(e);
                        onChange(e)}}
                      ref={disabilitySubCategoriesLookupIdsRef}
                    />
                  )}
                />
                 {_.get("disabilitySubCategoriesLookupIds.type", errors) === "required" && (disabilitySubCategoryErr === "") &&(
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
                {disabilitySubCategoryErr &&
                  <p className="errorText">{disabilitySubCategoryErr}</p>
                }
                {((DivyangDetails.typeOfDisabilityLookupId == "1405" && showDisabilitiesNote) || (DivyangDetails.typeOfDisabilityLookupId == "1405" && disabilitySubCategoriesLookupIds && disabilitySubCategoriesLookupIds.isRequired)) &&
                <div className="form-group col-md-12">
                  <span className="Note-color float-left text-left f13 ml-n2">
                    {profileotherinformation.DIVYANG_CAT_NOTE}
                  </span>
                </div>}
            </div>
          : ''}
        </div>
        <div className="row">
          
        </div>
        <div className="row">
          {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ?
            <div className="form-group col-md-3">
              <label className="LeftText">{numberformat["1.22.2"]}&nbsp;{profile.DIVYANG_PERCENTAGE}<span className="redColor">*</span></label>
              <div className="input-group">
              <input
                type="text"
                className="form-control"
                maxLength={5}
                defaultValue={personal_info_form && personal_info_form.divyangPercentage}
                name="divyangPercentage" ref={register({ required: true, pattern: percentage })}
              />
              <div className="input-group-append">
              <button className={watch('divyangPercentage') >= 40 && _.get("divyangPercentage.type", errors) != "pattern" ? "btn btn-success f12" : "btn btn-secondary f12" } type="button" id="button-addon2" onClick={handleDivyangPercentage} >{profile.VALIDATE}</button>
              </div>
              </div>
              {_.get("divyangPercentage.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("divyangPercentage.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_VALID_DIVYANG_PERCENTAGE}</p>
              )}
            </div> : ''}
          {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ?
          <div className="form-group col-md-3 mt-3">
            <label className="LeftText">{numberformat["1.22.3"]}&nbsp;{profile.TYPE_OF_DISABILITY_CERTIFICATE}<span className="redColor">*</span></label>
            <select className="form-control" name="typeDisabilityCertificate" ref={register({ required: true })} onInput={handleDisabilityCertificate} value={DivyangDetails.typeDisabilityCertificate} >
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
              {Disability_Certificate && Disability_Certificate.map((ele,ind) => {
                    return <option key={ind++} value={ele.id}>{ele.label}</option>
})}
            </select>
            {_.get("typeDisabilityCertificate.type", errors) === "required" && (
            <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
          </div> : ''}
          {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ? 
           <div className="form-group col-md-3 mt-3">
           <label className="LeftText">
               {numberformat["1.22.4"]}&nbsp;{profile.DISABILITY_CERTIFICATE_NUMBER}<span className="redColor">*</span>
             </label>
             <input
               type="text"
               className="form-control"
               maxLength={casteCertificationMaxLength}
               defaultValue={personal_info_form && personal_info_form.sadmCertificateNumber}
               name="sadmCertificateNumber" ref={register({ required: true, pattern: certificateNumberRegex })}
             />
             {_.get("sadmCertificateNumber.type", errors) === "required" && (
               <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
             )}
             {_.get("sadmCertificateNumber.type", errors) === "pattern" && (
               <p className="errorText">{profile.ENTER_VALID_SADM_CERTIFICATE_NUMBER}</p>
             )}
           </div>: '' }
          {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ?
            <div className="form-group col-md-3 mt-3">
                <label className="LeftText">
                    {numberformat["1.22.5"]}&nbsp;{profile.DATE_OF_ISSUE}<span className="redColor">*</span>
                  </label>
                    <Controller
                      name="sadmCertificateDate"
                      control={control}
                      placeholder="MY PLACEHOLDER"
                      key={personal_info_form && personal_info_form.id ?  "sadmCertificateDatekey1" : "sadmCertificateDatekey2" }
                      defaultValue={personal_info_form && personal_info_form.id ? datePickerData.sadmCertificateDate : ""}
                      rules={{ required: true }}
                      onFocus={() => 
                        {
                          sadmCertificateDateRef.current.setFocus()
                        document.activeElement && document.activeElement.scrollIntoView()
                       }
                      }
                      render={({ onChange, value}) => (
                        <DatePicker
                          selected={fromUTCDate(value)}
                          onChange={(e) => {
                                    onChange(toUTCDate(e))
                                }}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText={commonLabels.DATE_FORMAT}
                          className="form-control"
                          maxDate={new Date()}
                          dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                          ref={sadmCertificateDateRef}
                        />
                      )}
                    /> 
                  {_.get("sadmCertificateDate.type", errors) === "required" && (
                    <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                  )}
            </div> : ''}

            {areYouDivyangjanData && areYouDivyangjanData == 'Yes' ?
              <div className="col-md-3">
                <label className="LeftText">
                    {numberformat["1.22.6"]}&nbsp;{profile.DO_YOU_POSSES_UNIQUE_DISABILITY_ID}<span className="redColor">*</span>
                  </label>
                  <select className="form-control" name="areYouHaveUDID" ref={register({ required: true })} onInput={handleDisabilityUDIDChange} value={DivyangDetails.areYouHaveUDID}>
                  {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                    <option key={index} value={ele.value}>{ele.label}</option>
                  )}
                </select>
                {_.get("areYouHaveUDID.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div>
            : ''}

        {((areYouDivyangjanData && areYouDivyangjanData == 'Yes') && (DivyangDetails && DivyangDetails.areYouHaveUDID == 'Yes')) ? 
           <div className="form-group col-md-3 mt-3">
           <label className="LeftText">
               {numberformat["1.22.6.1"]}&nbsp;{profile.ENTER_UDID}<span className="redColor">*</span>
             </label>
             <input
               type="text"
               className="form-control"
               maxLength={18}
               defaultValue={DivyangDetails && DivyangDetails.udid}
               name="udid" ref={register({ required: true, pattern: alphanumeric })}
             />
             {_.get("udid.type", errors) === "required" && (
               <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
             )}
             {_.get("udid.type", errors) === "pattern" && (
               <p className="errorText">{profile.ENTER_VALID_UDID_NUMBER}</p>
             )}
           </div>: '' }

           {((areYouDivyangjanData && areYouDivyangjanData == 'Yes') && (DivyangDetails && DivyangDetails.areYouHaveUDID == 'No')) ? 
           <div className="form-group col-md-3 mt-3">
              <label className="LeftText">
               {numberformat["1.22.6.1"]}&nbsp;{profile.ENTER_UDID_ENROLLMENT_NUMBER}<span className="redColor">*</span>
              </label>
             <input
               type="text"
               className="form-control"
               maxLength={20}
               defaultValue={DivyangDetails && DivyangDetails.udidEnrolmentNo}
               name="udidEnrolmentNo" ref={register({ required: true, pattern: alphanumeric })}
             />
             {_.get("udidEnrolmentNo.type", errors) === "required" && (
               <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
             )}
             {_.get("udidEnrolmentNo.type", errors) === "pattern" && (
               <p className="errorText">{profile.ENTER_VALID_UDID_ENROLLMENT_NUMBER}</p>
             )}
           </div>: '' }

           {((areYouDivyangjanData && areYouDivyangjanData == 'Yes') && (DivyangDetails && DivyangDetails.areYouHaveUDID == 'No')) ?
           <div className="form-group col-md-12">
            <span
              className="Note-color float-left text-left f13 ml-1">
              {profile.ENROLLMENT_NO_MES_FOR_DISABILITY}
            </span>
          </div>
          : ''}

        </div>
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.Sports_Details}
        </h5>

        <div className="form-group row">
          <div className="col-md-9">
            <label className="LeftText">{numberformat["1.23"]}&nbsp; {profileotherinformation["ARE_YOU _MERITORIOUS_SPORTS_PERSON?"]}<span className="redColor">*</span></label>
          </div>
          <div className="col-md-3 otherFormMarginBottom">
            <select className="form-control" name="Meritorious_Sports_Person" ref={register({ required: true })} onInput={handleSportsPersonalInfoBooleanState} value={SportsBooleanState.Meritorious_Sports_Person} >
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("Meritorious_Sports_Person.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
        </div>
        {Meritorious_Sports_Person_Data && Meritorious_Sports_Person_Data == "Yes" ?
          <div>
             <SportsTable/>	
             <div className="mt-1">
             <SportsDetails/>
             </div>
          </div> : ''}
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.Ex_service_Man}
        </h5>
        <div className="row">
          <div className="col-md-9">
            <label className="LeftText">
              {numberformat["1.24"]}&nbsp;{profile["Does the candidate himself is an ex-serviceman of the regular Armed Forces"]}<span className="redColor">*</span>
            </label>
          </div>
          <div className="col-md-3 otherFormMarginBottom">
            <select className="form-control" name="flgExArmedReg" onInput={handleExServiceManState} value={ExServiceManBooleanState.flgExArmedReg} ref={register({ required: true })}>
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("flgExArmedReg.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
        </div>
        {exservicemanoftheregularArmedData && exservicemanoftheregularArmedData == 'Yes' ?
          <div className="row">
            <div className="col-md-9">
              <label className="LeftText">
                {numberformat["1.24.1"]}&nbsp;{profileotherinformation["IF_THE_CANDIDATE_HIMSELF_IS_AN_EX-SERVICEMAN_DIVISION_OF_THE_ARMED_FORCES"]}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-3 otherFormMarginBottom">
              <select className="form-control" name="exServiceManDivision" ref={register({ required: true })}  onInput={handleExServiceManDivisionState} value={ExServiceManBooleanState.exServiceManDivision}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {Division_Armed_Forces && Division_Armed_Forces.map((ele, index) =>
                <option key={index++} value={ele.id}>{ele.label}</option>
              )}
              </select>
              {_.get("exServiceManDivision.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-9">
              <label className="LeftText">
                {numberformat["1.24.2"]}&nbsp;{profileotherinformation["DATE_OF_APPOINTMENT_IN_REGULAR_ARMED_FORCES"]}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-3 otherFormMarginBottom">
                <Controller
                  name="dateApptArmedRegular"
                  control={control}
                  key={personal_info_form && personal_info_form.id ? "dateApptArmedRegularkey1" : "dateApptArmedRegularkey2"}
                  rules={{ required: true }}
                  defaultValue={personal_info_form && personal_info_form.id ?  datePickerData.dateApptArmedRegular :""}
                  onFocus={() => 
                    {
                      dateApptArmedRegularRef.current.setFocus()
                    document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value }) => (
                    <DatePicker
                      selected={fromUTCDate(value)}
                      onChange={(e) => {
                        setDatePiker({
                          ...datePickerData,
                          dateApptArmedRegular: toUTCDate(e)
                        }); handleFromToDateSelection(e, watch("dateRetireArmedRegular")); onChange(toUTCDate(e));
                        dateDischargeArmedRegularChange(e,watch("dateDischargeArmedRegular"))
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={commonLabels.DATE_FORMAT}
                      className="form-control"
                      maxDate={watch("dateRetireArmedRegular") || new Date()}
                      dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                      ref={dateApptArmedRegularRef}
                    />
                  )}
                /> 
                
              {_.get("dateApptArmedRegular.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-9">
              <label className="LeftText">
                {numberformat["1.24.3"]}&nbsp;{profileotherinformation["DATE_OF_RETIREMEN_(DISCHARGE)_IN_REGULAR_ARMED_FORCES"]}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-3 otherFormMarginBottom">
                <Controller
                  name="dateRetireArmedRegular"
                  control={control}
                  key={personal_info_form && personal_info_form.id ? "dateRetireArmedRegularkey1" :"dateRetireArmedRegularkey2"}
                  defaultValue={personal_info_form && personal_info_form.id ? datePickerData.dateRetireArmedRegular :""}
                  rules={{ required: true }}
                  onFocus={() => 
                    {
                    dateRetireArmedRegularRef.current.setFocus()
                    document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value}) => (
                    <DatePicker
                      selected={fromUTCDate(value)}
                      onChange={(e) => {
                        setDatePiker({
                          ...datePickerData,
                          dateRetireArmedRegular: toUTCDate(e)
                        }); handleFromToDateSelection(watch("dateApptArmedRegular"), toUTCDate(e)); onChange(toUTCDate(e))
                      }}
                      className="form-control"
                      showMonthDropdown
                      showYearDropdown
                      minDate={watch("dateApptArmedRegular")}
                      placeholderText={commonLabels.DATE_FORMAT}
                      dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                      ref={dateRetireArmedRegularRef}
                    />
                  )}
                />
              {_.get("dateRetireArmedRegular.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-12">
            <div className="row">
            <div className="col-md-9">
            <label className="LeftText">
                {numberformat["1.24.4"]}&nbsp;{profileotherinformation.PERIOD_OF_SERVICE}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-1 mb-3">
                <label className="float-left mb-0">
                  {profileotherinformation.YEARS}
                </label>
                <input type="text" className="form-control" value={datePickerData.exServiceYears} disabled></input>
              </div>
              <div className="col-md-1 mb-3">
                <label className="float-left mb-0">
                  {profileotherinformation.MONTHS}
                </label>
                <input type="text" className="form-control" value={datePickerData.exServiceMonths} disabled></input>
              </div>
              <div className="col-md-1 mb-3">
                <label className="float-left mb-0">
                  {profileotherinformation.DAYS}
                </label>
                <input type="text" className="form-control" value={datePickerData.exServiceDays}  disabled></input>
              </div>
              </div>
              </div>

            <div className="col-md-9">
              <label className="LeftText">
                {numberformat["1.24.5"]}&nbsp;{profileotherinformation["DISCHARGE_CERTIFICATE_NUMBER"]} {watch("dateRetireArmedRegular") < new Date() ? <span className="redColor">*</span> : ""  }
              </label>
            </div>
            <div className="col-md-3 otherFormMarginBottom">
              <input
                type="text"
                className="form-control"
                defaultValue={personal_info_form && personal_info_form.dischargeCertificateNo}
                placeholder="Discharge Certificate No"
                name="dischargeCertificateNo" maxLength={25} ref={register({ required: (watch("dateRetireArmedRegular") < new Date() ? true : false ), pattern: certificateNumberRegex })}
              />
              {_.get("dischargeCertificateNo.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("dischargeCertificateNo.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_VALID_DISCHARGE_CERTIFICATE_NUMBER}</p>
              )}
            </div>
            <div className="col-md-9">
              <label className="LeftText">
                {numberformat["1.24.6"]}&nbsp;{profileotherinformation["DATE_OF_DISCHARGE_FROM_ARMED_FORCES"]}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-3 otherFormMarginBottom">
                <Controller
                  name="dateDischargeArmedRegular"
                  control={control}
                  key={personal_info_form && personal_info_form.id ?  "dateDischargeArmedRegularKey1" :"dateDischargeArmedRegularKey2"}
                  defaultValue={personal_info_form && personal_info_form.id ? datePickerData.dateDischargeArmedRegular :""}
                  rules={{ required: true }}
                  onFocus={() => 
                    {
                      dateDischargeArmedRegularRef.current.setFocus()
                    document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value}) => (
                    <DatePicker
                      selected={fromUTCDate(value)}
                      onChange={(e) => {
                        onChange(toUTCDate(e),dateDischargeArmedRegularChange(watch("dateApptArmedRegular"),e))
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={commonLabels.DATE_FORMAT}
                      className="form-control"
                      dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                      minDate={watch("dateApptArmedRegular")}
                      ref={dateDischargeArmedRegularRef}
                    />
                  )}
                />
                
              {_.get("dateDischargeArmedRegular.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            
            <div className="col-md-6">
              <label className="LeftText">
                {numberformat["1.24.7"]}&nbsp;{profileotherinformation.THE_SERIAL_NUMBER_AUTHORITY_FOR_AGE_RELAXATION}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-3 otherFormMarginBottom">
              <input type="text" className="form-control" name="ageRelaxCertiNo" placeholder="Serial Number" defaultValue={personal_info_form && personal_info_form.ageRelaxCertiNo} maxLength={10} ref={register({ required: true, pattern: certificateNumberRegex })} />
              {_.get("ageRelaxCertiNo.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
              {_.get("ageRelaxCertiNo.type", errors) === "pattern" && (
                <p className="errorText">{profile.ENTER_VALID_DATA}</p>
              )}
            </div>
            <div className="col-md-3 otherFormMarginBottom">
                <Controller
                  name="ageRelaxCertiNo1"
                  control={control}
                  key={personal_info_form && personal_info_form.id ? "ageRelaxCertiNo1Key1" :""}
                  defaultValue={personal_info_form && personal_info_form.id ? datePickerData.ageRelaxCertiNo1 : ""}
                  rules={{ required: true }}
                  onFocus={() => 
                    {
                      ageRelaxCertiNo1Ref.current.setFocus()
                    document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value}) => (
                    <DatePicker
                      selected={fromUTCDate(value)}
                      onChange={(e) => {
                        onChange(toUTCDate(e))
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={commonLabels.DATE_FORMAT}
                      className="form-control"
                      maxDate={new Date()}
                      dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                      ref={ageRelaxCertiNo1Ref}
                    />
                  )}
                /> 
              {_.get("ageRelaxCertiNo1.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-12">
              <label className="LeftText">
                {numberformat["1.24.8"]}&nbsp;{profileotherinformation.ARE_YOU_DISABILITY_PENSION_HOLDER}<span className="redColor">*</span>
              </label>
              <label className="m-2">
                <input
                  type="radio"
                  checked={(flgDisabilityPensionHolder === "Yes" ? true : false)}
                  onChange={(e) => { setFlgDisabilityPensionHolder(e.target.value) }}
                  className="RadioText"
                  value="Yes"
                  name="flgDisabilityPensionHolder"
                  ref={register({ required: true })}
                />&nbsp;{profile.YES}</label>
              <label className="m-2">
                <input
                  type="radio"
                  checked={(flgDisabilityPensionHolder === "No" ? true : false)}
                  onChange={(e) => { setFlgDisabilityPensionHolder(e.target.value) }}
                  name="flgDisabilityPensionHolder"
                  value="No"
                  className="mr-1"
                  ref={register({ required: true })} />{profile.NO}</label>
            </div>
            <div className="col-md-12">
              <label className="LeftText">
                {numberformat["1.24.9"]}&nbsp;{profileotherinformation.HAVE_YOU_JOIN_STATE_GOVT}<span className="redColor">*</span>
              </label>
              <label className="m-2">
                <input
                  type="radio"
                  checked={(flgJoinedStateGovt === "Yes" ? true : false)}
                  onChange={(e) => { setFlgJoinedStateGovt(e.target.value) }}
                  className="RadioText"
                  value="Yes"
                  name="flgJoinedStateGovt"
                  ref={register({ required: true })}
                />&nbsp;{profile.YES}</label>
              <label className="m-2">
                <input
                  type="radio"
                  checked={(flgJoinedStateGovt === "No" ? true : false)}
                  onChange={(e) => { setFlgJoinedStateGovt(e.target.value) }}
                  name="flgJoinedStateGovt"
                  value="No"
                  className="mr-1"
                  ref={register({ required: true })} />{profile.NO}</label>
                  {watch("flgJoinedStateGovt") === "Yes" ? <span className="Note-color">{profileotherinformation.AGE_RELAXATION_NOTE}</span> :""}
            </div>  
          </div> : ''}
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.Government_of_Maharashtra_Employee_Details}
        </h5>
        <div className="form-group row p-1">
          <div className="col-md-4">
            <label className="LeftText">
              {numberformat["1.25"]}&nbsp;{profileotherinformation.ARE_YOU_AN_EMPLOYEE_OF_GOVERNMENT_OF_MAHARASHTRA}<span className="redColor">*</span>
            </label>
          </div>
          <div className="col-md-2 otherFormMarginBottom ">
            <select className="form-control" onInput={handleGovEmpDetails} value={govOfMaharashtraEmpDetail.flgGomEmployee} name="flgGomEmployee" ref={register({ required: true })} >
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("flgGomEmployee.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {watch("flgGomEmployee") === "Yes" &&
          <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                    <label className="LeftText">
                      {numberformat["1.25.1"]}&nbsp;{profileotherinformation.ARE_YOU_AN_EMPLOYEE_OF_STATE_GOVERNMENT_OF_MAHARASHTRA}<span className="redColor">*</span>
                    </label>
                </div>
              <div className="col-md-4 otherFormMarginBottom ">
                <select className="form-control" onInput={handleGovEmpDetails} value={govOfMaharashtraEmpDetail.flgStateEmployee} name="flgStateEmployee" ref={register({ required: true })} >
                  {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                    <option key={index} value={ele.value}>{ele.label}</option>
                  )}
                </select>
                {_.get("flgStateEmployee.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div>
            </div>
          </div>}
          
          {
            (watch("flgGomEmployee") === "Yes" && watch("flgStateEmployee") === "Yes") &&
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-8">
                    <label className="LeftText">
                      {numberformat["1.25.2"]}&nbsp;{profileotherinformation.Sevaarth_ID}<span className="redColor">*</span>
                    </label>
                  </div>
                  <div className="col-md-4 otherFormMarginBottom ">
                    <input
                      type="text"
                      className="form-control"
                      maxLength={13}
                      placeholder="Sevaarth ID"
                      onChange={(e) => {
                        handleSevaartIdChange(e.target.value)
                      }}
                      defaultValue={personal_info_form && personal_info_form.sevaarthId}
                      name="sevaarthId" ref={register({ required: true, pattern: sevaarthId})}
                    />
                    {_.get("sevaarthId.type", errors) === "required" && (
                      <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                    )}
                    {(_.get("sevaarthId.type", errors) === "pattern" ||  sevaartIdErr) && (
                      <p className="errorText">{profile.ENTER_VALID_SEVAARTH_ID}</p>
                    )}
                  </div>
                </div>
              </div> 
              }
              {
                (watch("flgGomEmployee") === "Yes" && watch("flgStateEmployee") !== "Yes") &&
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-8">
                      <label className="LeftText">
                        {numberformat["1.25.2"]}&nbsp;{profileotherinformation.Sevaarth_ID}
                      </label>
                    </div>
                    <div className="col-md-4 otherFormMarginBottom ">
                      <input
                        type="text"
                        className="form-control"
                        maxLength={13}
                        placeholder="Sevaarth ID"
                        onChange={(e) => {
                          handleSevaartIdChange(e.target.value)
                        }}
                        defaultValue={personal_info_form && personal_info_form.sevaarthId}
                        name="sevaarthId" ref={register({ required: false, pattern: sevaarthId})}
                      />
                       { (_.get("sevaarthId.type", errors) === "pattern" || sevaartIdErr) && (
                      <p className="errorText">{profile.ENTER_VALID_SEVAARTH_ID}</p>
                      )}
                    </div>
                  </div>
                </div>
            }
          <div className="col-md-4">
            <label className="LeftText">
              {numberformat["1.26"]}&nbsp;{profileotherinformation["ARE_YOU_EMPLOYEE_OF_B.M.C.?"]}<span className="redColor">*</span>
            </label>
          </div>
          <div className="col-md-2 otherFormMarginBottom">
            <select className="form-control" onInput={handleGovEmpDetails} value={govOfMaharashtraEmpDetail.flgBmcEmployee} name="flgBmcEmployee" ref={register({ required: true })}>
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("flgBmcEmployee.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          <div className="col-md-4">
            <label className="LeftText">
              {numberformat["1.27"]}&nbsp;{profileotherinformation["ARE_YOU_EMPLOYEE_OF_B.E.S.T.?"]}<span className="redColor">*</span>
            </label>
          </div>
          <div className="col-md-2 otherFormMarginBottom">
            <select className="form-control" onInput={handleGovEmpDetails} value={govOfMaharashtraEmpDetail.flgBestEmployee} name="flgBestEmployee" ref={register({ required: true })}>
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("flgBestEmployee.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
        </div>
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.Domicile_Details}
        </h5>
        <div className="row p-1" >
          <div className="form-group col-md-4">
            <label className="LeftText">
              {numberformat["1.28"]}&nbsp;{profile.MAHARASHTRA_DOMICILE_CERTIFICATE}<span className="redColor">*</span>
            </label>
            <select className="form-control" name="maharashtraDomicileCertificate" ref={register({ required: true })} value={DomicileDetailsBooleanState.maharashtraDomicileCertificate} onInput={handleDomicileFlag} >
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
            {_.get("maharashtraDomicileCertificate.type", errors) === "required" && (
              <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
            )}
          </div>
          {isMaharashtraDomicileCertificate == 'Yes' ?
            <div className="col-md-4">
              <label className="LeftText">{numberformat["1.28.1"]}&nbsp;{profile.DOMICILE_CERTIFICATE_AVAILABLE}<span className="redColor">*</span></label>
              <select className="form-control" name="domicileCertificateAvailable" ref={register({ required: true })} onInput={handleDomicileDatils} value={DomicileDetailsBooleanState.domicileCertificateAvailable}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {Domicile_AVAILABLE && Domicile_AVAILABLE.map((ele, index) =>
                  <option key={index++} value={ele.id}>{ele.label}</option>
                )}
              </select>
              {_.get("domicileCertificateAvailable.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div> : ''}
          {domicileCertificateAvailableData == 'Yes' && DomicileDetailsBooleanState.domicileCertificateAvailable == "Yes" ?
            <div className="col-md-4">
                <label className="LeftText">{numberformat["1.28.2"]}&nbsp;{profile.DOMICILE_CERTIFICATE_NO}{domicileCertificateAvailableData === 'Yes' ? <span className="redColor">*</span> : ""}</label>
              <div>
                <label className="LeftText">&nbsp;</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength={50}
                  placeholder="Enter Domicile Certificate No"
                  defaultValue={personal_info_form && personal_info_form.domicileCertificateNo}
                  name="domicileCertificateNumber" ref={register({ required: true, pattern: certificateNumbeForNclandDomicileRegex })}
                />
                {_.get("domicileCertificateNumber.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
                {_.get("domicileCertificateNumber.type", errors) === "pattern" && (
                  <p className="errorText">{profile.ENTER_VALID_DOMICILE_CERTIFICATE_NUMBER}</p>
                )}
              </div></div> : ''}
          {isMaharashtraDomicileCertificate == 'Yes' && DomicileDetailsBooleanState.domicileCertificateAvailable == "Yes"?
            <div className="col-md-4">
              <label className="LeftText">{numberformat["1.28.3"]}&nbsp;{profile.ISSUING_DISTRICT}{domicileCertificateAvailableData === 'Yes' ? <span className="redColor">*</span> : ""}</label>
              <select className="form-control" name="domicileIssuingDistrict" ref={register({ required: (domicileCertificateAvailableData === 'Yes') })} onInput={handleDomicileDatils} value={DomicileDetailsBooleanState.domicileIssuingDistrict}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {issuingDistrictList.length > 0 && issuingDistrictList.sort((a, b) => a.districtname < b.districtname ? - 1 : 1).map((ele,ind) => {
                    return <option key={ind++} value={ele.districtcode}>{ele.districtname}</option>
})}
              </select>
              {_.get("domicileIssuingDistrict.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div> : ''}
          {isMaharashtraDomicileCertificate == 'Yes' && DomicileDetailsBooleanState.domicileCertificateAvailable == "Yes"?
            <div className="col-md-4">
              <label className="LeftText">{numberformat["1.28.4"]}&nbsp;{profile.DOMICILE_ISSUING_DATE}{domicileCertificateAvailableData === 'Yes' ? <span className="redColor">*</span> : ""}</label>
                <Controller
                  name="domicileIssuingDate"
                  control={control}
                  key={personal_info_form && personal_info_form.id ? "domicileIssuingDateKey1" : "domicileIssuingDateKey2"}
                  defaultValue={personal_info_form && personal_info_form.id ? datePickerData.domicileIssuingDate : ""}
                  rules={{ required: (domicileCertificateAvailableData === 'Yes') }}
                  onFocus={() => 
                    {
                      domicileIssuingDateRef.current.setFocus()
                    document.activeElement && document.activeElement.scrollIntoView()
                   }
                  }
                  render={({ onChange, value}) => (
                    <DatePicker
                      selected={fromUTCDate(value)}
                      onChange={(e) => {
                                    onChange(toUTCDate(e))
                                }}
                      showMonthDropdown
                      showYearDropdown
                      placeholderText={commonLabels.DATE_FORMAT}
                      className="form-control"
                      maxDate={new Date()}
                      dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                      ref={domicileIssuingDateRef}
                    />
                  )}
                /> 
              {_.get("domicileIssuingDate.type", errors) == "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div> : ''}
          

          <div className="form-group col-md-12">
            <span
              className="Note-color float-left text-left f13 ml-1">
              {profile.MAHARASHTRA_DOMICILE_CERTIFICATE_NOTE}
            </span>
          </div>
          {isMaharashtraDomicileCertificate !== 'Yes' &&
          <div className="form-group col-md-12">
            <span
              className="errorMsg float-left text-left f13 ml-1">
              {profile.MAHARASHTRA_DOMICILE_CERTIFICATE_NOTE_FOR_NO_SELECTION}
            </span>
          </div>}
        </div>
        <hr></hr>
        <h5 className="align-left p-1">
          {profile.Additional_Reservation}
        </h5>
        <>
          <div className="row p-1" >
             
             <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.29"]}&nbsp;{profile.IS_ADDITIONAL_RESERVATION}
                </label>
                <select className="form-control" name="isAdditionalReservation"  value={isAdditionalReservationFlag} defaultValue={personal_info_form.isAdditionalReservation?'Yes':'No'}   onChange={handleAdditionalReservationFlag} >
                  {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                    <option key={index} value={ele.value}>{ele.label}</option>
                  )}
                </select>
           
            </div>
            </div>
            {(isAdditionalReservationFlag == 'Yes') ?
            <>
            <div className="row p-1" >
          
            <div className="form-group col-md-3">
            <label className="LeftText">
              {numberformat["1.29.1"]}&nbsp;{profile.PROJECT_AFFECTED_PERSON}
            </label>
            <select className="form-control" name="projectAffectedPerson"  value={projectAffectedPersonFlag?'Yes':'No'} defaultValue={personal_info_form.isProjectAffected?'Yes':'No'} onChange={handleprojectAffectedPersonFlag} >
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
           
            </div>
            
            {(projectAffectedPersonFlag == true)?
            IS_PROJECT_AFFECTED_REQUIRED==true?
            <>
                <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.29.1.1"]}&nbsp;{profile.PROJECT_AFFECTED_PERSON_CERT_NUM}<span className="redColor">*</span>
                </label>
                <input
                      type="text"
                      className="form-control"
                      maxLength={50}
                      ref={register({ required: true})}
                      placeholder="Enter Certificate No"
                      defaultValue={certificateNoPa}
                      name="projectAffectedCertificateNumber"
                    />
                    {_.get("projectAffectedCertificateNumber.type", errors) === "required" && (
                                      <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                    )}

                </div>
                 <div className="form-group col-md-3">
                 <label className="LeftText">{numberformat["1.29.1.2"]}&nbsp;{profile.PROJECT_AFFECTED_PERSON_ISSUING_DATE} <span className="redColor">*</span></label>
                   <Controller
                     name="projectAffectedIssuingDate"
                     control={control}
                     key={personal_info_form && personal_info_form.id ? "projectAffectedIssuingDateKey1" : "projectAffectedIssuingDateKey2"}
                     defaultValue={issuingDatePa}
                     rules={{ required: true }}
                     onFocus={() => 
                       {
                        projectAffectedIssuingDateRef.current.setFocus()
                       document.activeElement && document.activeElement.scrollIntoView()
                      }
                     }
                     render={({ onChange, value}) => (
                       <DatePicker
                         selected={fromUTCDate(value)}
                         onChange={(e) => {
                                       onChange(toUTCDate(e))
                                   }}
                         showMonthDropdown
                         showYearDropdown
                         placeholderText={commonLabels.DATE_FORMAT}
                         className="form-control"
                         maxDate={new Date()}
                         dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                         ref={projectAffectedIssuingDateRef}
                       />
                     )}
                   /> 
                 {_.get("projectAffectedIssuingDate.type", errors) == "required" && (
                   <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                 )}
               </div>
               
               </>
          :
                      <>
            <div className="form-group col-md-3">
            <label className="LeftText">
              {numberformat["1.29.1.1"]}&nbsp;{profile.PROJECT_AFFECTED_PERSON_CERT_NUM}
            </label>
            <input
                  type="text"
                  className="form-control"
                  maxLength={50}
                  placeholder="Enter Certificate No"
                  defaultValue={certificateNoPa}
                  name="projectAffectedCertificateNumber"
                />
            </div>
            <div className="form-group col-md-3">
                 <label className="LeftText">{numberformat["1.29.1.2"]}&nbsp;{profile.PROJECT_AFFECTED_PERSON_ISSUING_DATE}</label>
                   <Controller
                     name="projectAffectedIssuingDate"
                     control={control}
                     key={personal_info_form && personal_info_form.id ? "projectAffectedIssuingDateKey1" : "projectAffectedIssuingDateKey2"}
                     defaultValue={issuingDatePa}
                     onFocus={() => 
                       {
                        projectAffectedIssuingDateRef.current.setFocus()
                       document.activeElement && document.activeElement.scrollIntoView()
                      }
                     }
                     render={({ onChange, value}) => (
                       <DatePicker
                         selected={fromUTCDate(value)}
                         onChange={(e) => {
                                       onChange(toUTCDate(e))
                                   }}
                         showMonthDropdown
                         showYearDropdown
                         placeholderText={commonLabels.DATE_FORMAT}
                         className="form-control"
                         maxDate={new Date()}
                         dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                         ref={projectAffectedIssuingDateRef}
                       />
                     )}
                   /> 
               </div>
              
               </>
            :''
            }
           </div>
           <div className="row p-1" >
           
            <div className="form-group col-md-3">
            <label className="LeftText">
              {numberformat["1.29.2"]}&nbsp;{profile.EARTHQUAKE_AFFECTED}
            </label>
            <select className="form-control" name="earthquakeAffectedPerson"  value={earthquakeAffectedFlag?'Yes':'No'} defaultValue={personal_info_form.isEarthquakeAffected?'Yes':'No'} onChange={handleearthquakeAffectedFlag} >
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
           
            </div>
              

            {(earthquakeAffectedFlag ==true)?
            IS_EARTHQUAKE_AFFECTED_REQUIRED==true?
            <>
                <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.29.2.1"]}&nbsp;{profile.EARTHQUAKE_AFFECTED_CERT_NUM}<span className="redColor">*</span>
                </label>
                <input
                      type="text"
                      className="form-control"
                      maxLength={50}
                      ref={register({ required: true})}
                      placeholder="Enter Certificate No"
                      defaultValue={certificateNoEqa}
                      name="earthquakeAffectedCertificateNumber"
                    />
                    {_.get("earthquakeAffectedCertificateNumber.type", errors) === "required" && (
                                      <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                    )}

                </div>
                 <div className="form-group col-md-3">
                 <label className="LeftText">{numberformat["1.29.2.2"]}&nbsp;{profile.EARTHQUAKE_AFFECTED_ISSUING_DATE} <span className="redColor">*</span></label>
                   <Controller
                     name="earthquakeAffectedIssuingDate"
                     control={control}
                     key={personal_info_form && personal_info_form.id ? "earthquakeAffectedIssuingDateKey1" : "earthquakeAffectedIssuingDateKey2"}
                     defaultValue={issuingDateEqa}
                     rules={{ required: true }}
                     onFocus={() => 
                       {
                        earthquakeAffectedIssuingDateRef.current.setFocus()
                       document.activeElement && document.activeElement.scrollIntoView()
                      }
                     }
                     render={({ onChange, value}) => (
                       <DatePicker
                         selected={fromUTCDate(value)}
                         onChange={(e) => {
                                       onChange(toUTCDate(e))
                                   }}
                         showMonthDropdown
                         showYearDropdown
                         placeholderText={commonLabels.DATE_FORMAT}
                         className="form-control"
                         maxDate={new Date()}
                         dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                         ref={earthquakeAffectedIssuingDateRef}
                       />
                     )}
                   /> 
                 {_.get("earthquakeAffectedIssuingDate.type", errors) == "required" && (
                   <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                 )}
               </div>
               
               </>
          :
                      <>
            <div className="form-group col-md-3">
            <label className="LeftText">
              {numberformat["1.29.2.1"]}&nbsp;{profile.EARTHQUAKE_AFFECTED_CERT_NUM}
            </label>
            <input
                  type="text"
                  className="form-control"
                  maxLength={50}
                  placeholder="Enter Certificate No"
                  defaultValue={certificateNoEqa}
                  name="earthquakeAffectedCertificateNumber"
                />
            </div>
            <div className="form-group col-md-3">
                 <label className="LeftText">{numberformat["1.29.2.2"]}&nbsp;{profile.EARTHQUAKE_AFFECTED_ISSUING_DATE}</label>
                   <Controller
                     name="earthquakeAffectedIssuingDate"
                     control={control}
                     key={personal_info_form && personal_info_form.id ? "earthquakeAffectedIssuingDateKey1" : "earthquakeAffectedIssuingDateKey2"}
                     defaultValue={issuingDateEqa}
                     onFocus={() => 
                       {
                        earthquakeAffectedIssuingDateRef.current.setFocus()
                       document.activeElement && document.activeElement.scrollIntoView()
                      }
                     }
                     render={({ onChange, value}) => (
                       <DatePicker
                         selected={fromUTCDate(value)}
                         onChange={(e) => {
                                       onChange(toUTCDate(e))
                                   }}
                         showMonthDropdown
                         showYearDropdown
                         placeholderText={commonLabels.DATE_FORMAT}
                         className="form-control"
                         maxDate={new Date()}
                         dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                         ref={earthquakeAffectedIssuingDateRef}
                       />
                     )}
                   /> 
               </div>
               
              </>
            :''
            }
           </div>
           <div className="row p-1" >
            <div className="form-group col-md-3">
            <label className="LeftText">
              {numberformat["1.29.3"]}&nbsp;{profile.GRADUATE_PARTTIME_EMPLOYEE}
            </label>
            <select className="form-control" name="graduateEmployee"  value={graduateEmployeeFlag?'Yes':'No'}  defaultValue={personal_info_form.isGraduateEmployee?'Yes':'No'}  onChange={handlegraduateEmployeeFlag} >
              {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                <option key={index} value={ele.value}>{ele.label}</option>
              )}
            </select>
           
            </div>
              
            {(graduateEmployeeFlag ==true)?
            IS_GRADUATE_EMPLOYEE_REQUIRED==true?
            <>
                <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.29.3.1"]}&nbsp;{profile.GRADUATE_PARTTIME_EMPLOYEE_EMP_NUM}<span className="redColor">*</span>
                </label>
                <input
                      type="text"
                      className="form-control"
                      maxLength={50}
                      ref={register({ required: true})}
                      placeholder="Enter Employee No"
                      defaultValue={employmentNoGpte}
                      name="graduateEmployeeNumber"
                    />
                    {_.get("graduateEmployeeNumber.type", errors) === "required" && (
                                      <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                    )}

                </div>
                 <div className="form-group col-md-3">
                 <label className="LeftText">{numberformat["1.29.3.2"]}&nbsp;{profile.GRADUATE_PARTTIME_EMPLOYEE_ISSUING_DATE} <span className="redColor">*</span></label>
                   <Controller
                     name="graduateEmployeeIssuingDate"
                     control={control}
                     key={personal_info_form && personal_info_form.id ? "graduateEmployeeIssuingDateKey1" : "graduateEmployeeIssuingDateKey2"}
                     defaultValue={issuingDateGpte}
                     rules={{ required: true }}
                     onFocus={() => 
                       {
                        graduateEmployeeIssuingDateRef.current.setFocus()
                       document.activeElement && document.activeElement.scrollIntoView()
                      }
                     }
                     render={({ onChange, value}) => (
                       <DatePicker
                         selected={fromUTCDate(value)}
                         onChange={(e) => {
                                       onChange(toUTCDate(e))
                                   }}
                         showMonthDropdown
                         showYearDropdown
                         placeholderText={commonLabels.DATE_FORMAT}
                         className="form-control"
                         maxDate={new Date()}
                         dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                         ref={graduateEmployeeIssuingDateRef}
                       />
                     )}
                   /> 
                 {_.get("graduateEmployeeIssuingDate.type", errors) == "required" && (
                   <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                 )}
               </div>
               
               </>
          :
                      <>
            <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.29.3.1"]}&nbsp;{profile.GRADUATE_PARTTIME_EMPLOYEE_EMP_NUM}
                </label>
                <input
                      type="text"
                      className="form-control"
                      maxLength={50}
                     
                      placeholder="Enter Employee No"
                      defaultValue={employmentNoGpte}
                      name="graduateEmployeeNumber"
                    />
                    

                </div>
                 <div className="form-group col-md-3">
                 <label className="LeftText">{numberformat["1.29.3.2"]}&nbsp;{profile.GRADUATE_PARTTIME_EMPLOYEE_ISSUING_DATE} <span className="redColor">*</span></label>
                   <Controller
                     name="graduateEmployeeIssuingDate"
                     control={control}
                     key={personal_info_form && personal_info_form.id ? "graduateEmployeeIssuingDateKey1" : "graduateEmployeeIssuingDateKey2"}
                     defaultValue={issuingDateGpte}
                     
                     onFocus={() => 
                       {
                        graduateEmployeeIssuingDateRef.current.setFocus()
                       document.activeElement && document.activeElement.scrollIntoView()
                      }
                     }
                     render={({ onChange, value}) => (
                       <DatePicker
                         selected={fromUTCDate(value)}
                         onChange={(e) => {
                                       onChange(toUTCDate(e))
                                   }}
                         showMonthDropdown
                         showYearDropdown
                         placeholderText={commonLabels.DATE_FORMAT}
                         className="form-control"
                         maxDate={new Date()}
                         dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                         ref={graduateEmployeeIssuingDateRef}
                       />
                     )}
                   /> 
                
               </div>
               
               </>
            :''
            }
          
            </div>
            </>
            :''}
            <div className="row notesSec">
              <div className="col-md-12">
              <span className="Note-color float-left text-left f13 ml-1 mt-5">{profileotherinformation.ADDITIONAL_RES_CAT_NOTE}</span>
            </div>
            </div>
        </>
        {
          isMaharashtraDomicileCertificate === 'No' && (areYouDivyangjanData === 'Yes' || exservicemanoftheregularArmedData === 'Yes')  ?
            <div className="row p-1">
              <div className="form-group col-md-12">
                <label className="LeftText">
                  {numberformat["1.28.1"]}&nbsp;{profile.FLG_BORDER_AREA_RADIO_BTN_LABEL}
                  <span className="redColor">*</span>
                </label>
                <label className="m-1">
                <input
                  type="radio"
                  onChange={(e) => {setFlgMaharashtraKarnatakBorderArea(e.target.value)}}
                  className="RadioText"
                  value={"Yes"}
                  key="flgMaharashtraKarnatakBorderArea1"
                  name="flgMaharashtraKarnatakBorderArea"
                  ref={register({ required: true })}
                  checked={(flgMaharashtraKarnatakBorderArea === "Yes")}
                />&nbsp;{profile.YES}</label>
                <label className="m-2">
                <input
                  type="radio"
                  onChange={(e) => {
                    setBorderAreaDetails({
                      borderDistrictLookupId: "",
                      borderTalukaLookupId: "",
                      borderVillageLookupId: ""
                    });
                    setFlgMaharashtraKarnatakBorderArea(e.target.value)
                  }}
                  name="flgMaharashtraKarnatakBorderArea"
                  key="flgMaharashtraKarnatakBorderArea2"
                  value={"No"}
                  className="mr-1"
                  ref={register({ required: true })}
                  checked={(flgMaharashtraKarnatakBorderArea === "No")}
                />{profile.NO}</label>
              </div>
            </div>
          : ""
        }
        {
          isMaharashtraDomicileCertificate === 'No' && flgMaharashtraKarnatakBorderArea === "Yes"  && (areYouDivyangjanData === 'Yes' || exservicemanoftheregularArmedData === 'Yes') ?
          <div className="row p-1">
              <div className="form-group col-md-4">
                <label className="float-left mb-0">
                  {numberformat["1.28.1.1"]}
                  &nbsp;{profile.BORDER_AREA_DISTRICTS_LABEL}
                  <span className="redColor">*</span>
                </label>
                <select className="form-control" value={borderAreaDetails.borderDistrictLookupId} name ="borderDistrictLookupId" ref={register({ required: true })} onInput={handleMahaKarBorderDistrictChange}>
                  <option key={0} value="">{profileotherinformation.SELECT_DISTRICT}</option>
                  {
                    listOfAllBorderDistricts && listOfAllBorderDistricts.sort((a, b) => a.sortOrder < b.sortOrder ? - 1 : 1).map((ele,ind) => {
                      return <option key={ind++} value={ele.id}>{ele.label}</option>
})
                  }
                </select>
                {_.get("borderDistrictLookupId.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div>
              <div className="form-group col-md-4">
                <label className="float-left mb-0">
                  {numberformat["1.28.1.2"]}
                  &nbsp;{profile.BORDER_AREA_TALUKAS_LABEL}
                  <span className="redColor">*</span>
                </label>
                <select className="form-control" value={borderAreaDetails.borderTalukaLookupId} name ="borderTalukaLookupId" ref={register({ required: true })} onInput={handleMahaKarBorderTalukaChange}>
                  <option key={0} value="">{profileotherinformation.SELECT_TALUKA}</option>
                  {
                    borderTalukaOptions && borderTalukaOptions.sort((a, b) => a.label.localeCompare(b.label)).map((ele,ind) => {
                      return <option key={ind++} value={ele.id}>{ele.label}</option>
})
                  }
                </select>
                {_.get("borderTalukaLookupId.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div>
              <div className="form-group col-md-4">
                <label className="float-left mb-0">
                  {numberformat["1.28.1.3"]}
                  &nbsp;{profile.BORDER_AREA_VILLAGES_LABEL}
                  <span className="redColor">*</span>
                </label>
                <select className="form-control" value={borderAreaDetails.borderVillageLookupId} name ="borderVillageLookupId" ref={register({ required: true })} onInput={handleMahaKarBorderVillageChange}>
                  <option key={0} value="">{profileotherinformation.SELECT_VILLAGE}</option>
                  {
                    borderVillageOptions && borderVillageOptions.sort((a, b) => a.sortOrder < b.sortOrder ? - 1 : 1).map((ele,ind) => {
                      return <option key={ind++} value={ele.id}>{ele.label}</option>
})
                  }
                </select>
                {_.get("borderVillageLookupId.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div>
          </div> :""
        }
        <hr></hr>
        {castListOption?
          <div>
            <h5 className="align-left p-1">
              {profile.Orphan_Detail}
            </h5>
            <div className="row">
              <div className="form-group col-md-3">
                <label className="LeftText">
                  {numberformat["1.30"]}&nbsp;{profile.DO_YOU_WANT_TO_TAKE_ADVANTAGE_FOR_ORPHAN_RESERVATION}<span className="redColor">*</span>
                </label>
                <select className="form-control" name="DoYouWantTotakeAvantageOfOrphan" value={orphanDetails.DoYouWantTotakeAvantageOfOrphan} ref={register({ required: true })} onInput={handleOrphanDetails}>
                  {BOOLEAN_OPTION_PERSONALINFO && BOOLEAN_OPTION_PERSONALINFO.map((ele, index) =>
                    <option key={index} value={ele.value}>{ele.label}</option>
                  )}
                </select>
                {_.get("DoYouWantTotakeAvantageOfOrphan.type", errors) === "required" && (
                  <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div>
              {DoYouWantTotakeAvantageOfOrphanData == 'Yes' ?
                <div className="form-group col-md-3 mt-3">
                  <label className="LeftText">{numberformat["1.30.1"]}&nbsp;{profile.ORPHAN_TYPE}<span className="redColor">*</span></label>
                  <select className="form-control" name="orphanType" value={orphanDetails.orphanType} ref={register({ required: true })} onInput={handleOrphanDetails}>
                  {ORPHAN_TYPE && ORPHAN_TYPE.map((ele, index) =>
                    (ele.labelForCastListOption.includes(castListOption) && <option key={index} value={ele.value}>{ele.label}</option>)
                  )}
                </select>
                  {_.get("orphanType.type", errors) === "required" && (
                    <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div> : ""}
              {DoYouWantTotakeAvantageOfOrphanData == 'Yes' ?
                <div className="form-group col-md-3 mt-3">
                  <label className="LeftText">{numberformat["1.30.2"]}&nbsp;{profile.ORPHAN_CERTIFICATE_NO}<span className="redColor">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={personal_info_form && personal_info_form.orphanReservationCertiNo}
                    maxLength={casteCertificationMaxLength}
                    name="OrphanCertificateNumber" ref={register({ required: true, pattern: certificateNumberRegex })}
                    onChange={handleOrphanDetails}
                  />
                  {_.get("OrphanCertificateNumber.type", errors) === "required" && (
                    <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                  {_.get("OrphanCertificateNumber.type", errors) === "pattern" && (
                    <p className="errorText">{profile.ENTER_VALID_ORPHAN_CERTIFICATE_NUMBER}</p>
                  )}
                </div> : ""}
              {DoYouWantTotakeAvantageOfOrphanData == 'Yes' ?
                <div className="form-group col-md-3 mt-3">
                  <label className="LeftText">
                    {numberformat["1.30.3"]}&nbsp;{profile.ORPHAN_CERTIFICATE_ISSUE_DATE}<span className="redColor">*</span>
                  </label>
                    <Controller
                      name="orphanCertiIssueDate"
                      control={control}
                      key={personal_info_form && personal_info_form.id  ? "orphanCertiIssueDateKey1" : "orphanCertiIssueDateKey2"}
                      defaultValue={ personal_info_form && personal_info_form.id ? datePickerData.orphanCertiIssueDate : ""}
                      rules={{ required: true }}
                      onFocus={() => 
                        {
                        orphanCertiIssueDateRef.current.setFocus()
                        document.activeElement && document.activeElement.scrollIntoView()
                       }
                      }
                      render={({ onChange, value}) => (
                        <DatePicker
                          selected={fromUTCDate(value)}
                          onChange={(e) => {
                            onChange(toUTCDate(e))
                          }}
                          showMonthDropdown
                          showYearDropdown
                          placeholderText={commonLabels.DATE_FORMAT}
                          className="form-control"
                          maxDate={new Date()}
                          dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                          ref={orphanCertiIssueDateRef}
                        />
                      )}
                    />
                  {_.get("orphanCertiIssueDate.type", errors) === "required" && (
                    <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                  )}
                </div> : ""}
            </div>
            <hr></hr>
          </div> : ''}
        <div className="text-left">
          <button type="submit" disabled={isDisableAllTabSave} className="btn btn-primary m-1" >
            {profile.SAVE}
          </button>
        </div>
      </div>
    </form>
  );
}
export default PersonalInformation;