import { toastr } from "react-redux-toastr";
import {SET_CORRESPONDENCE_DATA} from "../../common/constants/ActionTypes";
import { USERCORRESPONDENCE,  DOWNLOADCORRESPONDENCEDOC } from "../../common/constants/endpoints";
import API from "../../helpers/API";
import FileDownload from "js-file-download";
import axios from "axios";
import { hideLoader, showLoader } from '../../common/actions/commonActions';

export const getCorrespondenceData = () => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(USERCORRESPONDENCE, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({
                type: SET_CORRESPONDENCE_DATA,
                payload: response.data,
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            
                dispatch(hideLoader());
            
            let errorMessage = error.response?.data?.message ?? error.message
            toastr.error(errorMessage);
            return;
        }
    };
};

export const downloadFile = (requstBody) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url:  DOWNLOADCORRESPONDENCEDOC+'/'+requstBody.correspondenceFileId,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
        FileDownload(
            response.data,
            `${requstBody.filename}.pdf`
        );
    };
};

export const downloadLinkFile= (requstBody) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url:  requstBody.url,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
                dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message
            toastr.error(errorMessage);
            return;
        }
        FileDownload(
            response.data,
            `${requstBody.filename}.pdf`
        );
    };
};