import { SET_CORRESPONDENCE_DATA} from "../../common/constants/ActionTypes";

const initialState = {
    correspondenceData:[],
};

export default function correspondenceReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CORRESPONDENCE_DATA:
            return{
                ...state,
                correspondenceData:action.payload
            }
            default:
                return state;
        }
};