import React from "react";
import { Controller } from "react-hook-form";
import qualificationinformation from '../labels/qualification_information_en.json';
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import { RESULTS } from "../../common/constants/dropdownOptions";
import numberformat from "../labels/serial_nos_en.json";
import dayjs from 'dayjs';
import _ from "lodash/fp";
import { registrationNo, projectTitle } from '../../common/constants/regex'
import useQualificationActions from "./useQualificationActions";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";

const MPhilPhDEtcQualification = () => {
  
  const { handleQualificationChange, handleQualificationInfoSubmit, setActionType, setQualificationState,
    register, handleSubmit, errors, qualificationState, currentEditQualificationIndex, control,
    isResultDateMandatory, onQualificationNameChange, handleResultTypeChange, qualificationNames,
    subjects, onStateChange, statesList, universities, flgSubjectDisable, flgStateDisable, flgUniversityDisable } = useQualificationActions();

  return (
    <form onSubmit={handleSubmit((e) => { handleQualificationInfoSubmit(e) })}>
      <div className="form-row">
        <QualificationTypeDropdown />
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.2"]}&nbsp;{qualificationinformation.NAME_OF_DEGREE}</label>
          <span className="redColor">*</span>
          <select className="form-control" value={qualificationState.qualificationnamecode} name="qualificationnamecode" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {
              qualificationNames.sort((name1, name2) => { return name1.sort_order - name2.sort_order }).map((ele, i) => {
                return <option key={i} value={ele.qualificationNameCode}>{ele.qualificationName}</option>
              })
            }
          </select>
          {_.get("qualificationnamecode.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.3"]}&nbsp;{qualificationinformation.SUBJECT}</label>
          <select className="form-control" value={qualificationState.subjectLookupId} name="subjectLookupId" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgSubjectDisable}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {
              subjects.sort((sub1, sub2) => { return sub1.subjectName.localeCompare(sub2.subjectName) }).map((ele, i) => {
                return <option key={i} value={ele.subjectId}>{ele.subjectName}</option>
              })
            }
          </select>
        </div>
        {/** Following div is to displayed conditionally */}
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.4"]}&nbsp;{qualificationinformation.STATE}</label>
          <select className="form-control" value={qualificationState.statecode} name="statecode" ref={register({ required: false })} onInput={onStateChange} disabled={flgStateDisable}>
            <option key={0} value={999}>{qualificationinformation.SELECT_STATE}</option>
            {
              statesList.map((ele, i) => {
                return <option key={i} value={ele.statecode}>{ele.statename}</option>
              })
            }
          </select>
        </div>
        <div className="form-group col-md-6">
          <label className="float-left mb-0" for="universitycode">{numberformat["4.5"]}&nbsp;{qualificationinformation.BOARD_UNIVERSITY}</label>
          <select id="universitycode" className="form-control" value={qualificationState.universitycode} name="universitycode" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgUniversityDisable}>
            <option key={0} value={0}>{qualificationinformation.SELECT_UNIVERSITY}</option>
            {
              universities.sort((university1, university2) => { return university1.universityName.localeCompare(university2.universityName) })
              .map((ele, i) => {
                return <option key={i} value={ele.universityCode}>{ele.universityName}</option>
              })
            }
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.6"]}&nbsp;{qualificationinformation.RESULT} </label>
          <span className="redColor">*</span>
          <select className="form-control" value={qualificationState.typeResult} name="typeResult" ref={register({ required: true })} onInput={handleResultTypeChange}>
            {
              RESULTS.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("typeResult.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.7"]}&nbsp;{qualificationinformation.RESULT_DATE}</label>
          <Controller
            name="resultDate"
            rules={{ required: false, validate: value => value ? value <= new Date() : true }}
            key={qualificationState.resultDate ? "resultDate" : "resultDate1"}
            defaultValue={qualificationState.resultDate ? dayjs(qualificationState.resultDate).toDate() : ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.resultDate ? dayjs(qualificationState.resultDate).toDate() : fromUTCDate(value)}
                maxDate={new Date()}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    resultDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                disabled={!isResultDateMandatory}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
              />
            )}
          />
          {_.get("resultDate.type", errors) === "validate" && (
            <p className="errorText">{qualificationinformation.DATA_LTE_TODAY_MSG}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.8"]}&nbsp;{qualificationinformation.REG_DATE}</label>
          <Controller
            name="registrationDate" ref={register({ required: false })}
            key={qualificationState.registrationDate ? "registrationDate" : "registrationDate1"}
            defaultValue={qualificationState.registrationDate ? dayjs(qualificationState.registrationDate).toDate() : ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.registrationDate ? dayjs(qualificationState.registrationDate).toDate() : fromUTCDate(value)}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    registrationDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
              />
            )}
          />
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.9"]}&nbsp;{qualificationinformation.REG_NO}</label>
          <input type="text" className="form-control" defaultValue={qualificationState.registrationNumber} name="registrationNumber" ref={register({ required: false, pattern: registrationNo })} onChange={handleQualificationChange}></input>
          {_.get("registrationNumber.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_INPUT}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.10"]}&nbsp;{qualificationinformation.PROJECT_TITLE}</label>
          <input type="text" id="projectTitle" maxLength={100} defaultValue={qualificationState.projectTitle} className="form-control" name="projectTitle" ref={register({ required: false, pattern: projectTitle })} onChange={handleQualificationChange} />
          {_.get("projectTitle.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_INPUT}</p>
          )}
          <span className="text-primary">{qualificationinformation.PROJ_TITLE_VALIDATION_NOTE}</span>
        </div>
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
            <button type="submit" name="edit" className="btn btn-primary m-1" onClick={() => { setActionType('edit') }}>{qualificationinformation.UPDATE}</button>
            :
            <button type="submit" name="add" className="btn btn-primary m-1" onClick={() => { setActionType('add') }}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default MPhilPhDEtcQualification;