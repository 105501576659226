/* eslint-disable */
import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../registration_login/actions/sessionActions';
import { Modal, Button } from 'react-bootstrap';
import RegistrationJson from "../../registration_login/labels/registration_en.json";

const SessionTimeOut = (props) => {
    const dispatch = useDispatch();
    const [ showModal, setShowModal ] = useState(false)
    let accessToken = useSelector(state => state.loginReducer.accessToken);
    const session = JSON.parse(sessionStorage.getItem("session"));
    const isLoggedIn = accessToken || (session && session?.jwtResponse?.token);

    const handleClose = () => {
        setShowModal(false);
        window.location.reload();
    }

    const handleOnIdle = event => {
        if(isLoggedIn) {
            dispatch(logout(props, false));
            setShowModal(true);
        }
    }

    const handleOnActive = event => {

    }
    
    const handleOnAction = event => {

    }

    const timeoutInMinutes = process.env.REACT_APP_INACTIVE_SESSION_TIMEOUT || 30

    const { reset } = useIdleTimer({
        timeout: 60 * 1000 * timeoutInMinutes,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    return (
        <Modal show={showModal} backdrop="static">
            <Modal.Header>
                <Modal.Title>{RegistrationJson.YOU_HAVE_BEEN_IDLE_FOR} {timeoutInMinutes} {RegistrationJson.MINUTES}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{RegistrationJson.SESSION_TIME_OUT_NOTE}</Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={ handleClose }>
                    {RegistrationJson.OK}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SessionTimeOut;