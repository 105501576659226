import { ERROR_ACTION, SET_CAPTCHA_IMAGE, SET_GET_EMAIL_OTP_RESPONSE, SET_GET_MOBILE_OTP_RESPONSE, SHOW_LOADER, HIDE_LOADER, SET_VERIFY_OTP_FLAG_LOGIN, SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD, 
    SET_GET_USERNAME_OTP_RESPONSE,  SET_VERIFY_OTP_FLAG_REGISTRATION_EMAIL, SET_VERIFY_OTP_FLAG_REGISTRATION_MOBILE, SET_GUIDELINE_LINKS, SET_MODULE_ENABLE_DISABLE, 
    SET_API_CALL_FOR_MODULE,GET_OTP_DATA_EMAIL,GET_OTP_DATA_MOBILE,GET_OTP_DATA_USERNAME, SET_IS_MOBILE_NO_ALREADY_EXIST_FLG, SET_IS_EMAIL_ID_ALREADY_EXIST_FLG} from "../../common/constants/ActionTypes";

const initialState = { 
    errorMessage: "",
    captchaId: 0,
    loading: false,
    emailOTPResponse: {
        id: "",
        type: ""
    },
    mobileOTPResponse: {
        id: "",
        type: ""
    },
    userNameOTPResponse: {
        id: "",
        type: ""
    },
    verifyOTPFlag:false,
    verifyOTPFlagForgotPassword:false,
    verifyOTPFlagRegistrationEmail: false,
    verifyOTPFlagRegistrationMobile: false,
    has2018RecoLetterConfig: {},
    guidelineLinks:[],
    moduleDetails : {},
    isApiCallForModule : false,
    emailOtpData:{},
    mobileOtpData:{},
    userNameOtpData:{},
    mobileNoAlreadyExistFlg: false,
    emailIdAlreadyExistFlg: false,
}

export default function commonReducer(state = initialState, action) {
    switch(action.type) {
        case ERROR_ACTION:
            return {
                ...state,
                errorMessage: action.payload
            }
        case SET_CAPTCHA_IMAGE:
            return {
                ...state,
                captchaId: action.payload
            }
        case SHOW_LOADER:
            return {
                ...state,
                loading: true
            }
        case HIDE_LOADER:
            return {
                ...state,
                loading: false
            }
        case SET_GET_EMAIL_OTP_RESPONSE:
            return {
                ...state,
                emailOTPResponse: action.payload
            }
        case SET_GET_MOBILE_OTP_RESPONSE:
            return {
                ...state,
                mobileOTPResponse: action.payload
            }
        case SET_GET_USERNAME_OTP_RESPONSE:
            return {
                ...state,
                userNameOTPResponse: action.payload
            }
        case SET_VERIFY_OTP_FLAG_LOGIN:
            return {
                ...state,
                verifyOTPFlag: action.payload
            }
        case SET_VERIFY_OTP_FLAG_FORGOT_PASSWORD:
            return {
                ...state,
                verifyOTPFlagForgotPassword: action.payload
            }
        case SET_VERIFY_OTP_FLAG_REGISTRATION_EMAIL:
            return {
                ...state,
                verifyOTPFlagRegistrationEmail: action.payload
            }
        case SET_VERIFY_OTP_FLAG_REGISTRATION_MOBILE:
            return {
                ...state,
                verifyOTPFlagRegistrationMobile: action.payload
            }
        case 'set_has_2018_reco_letter_config':
            return {
                ...state,
                has2018RecoLetterConfig: action.payload,
            }
        case SET_GUIDELINE_LINKS:
            return {
                 ...state,
                 guidelineLinks: action.payload
            }
        case SET_MODULE_ENABLE_DISABLE:
            return {
                ...state,
                moduleDetails : action.payload
            }
        case SET_API_CALL_FOR_MODULE:
            return {
                ...state,
                isApiCallForModule : action.payload
            }  
        case GET_OTP_DATA_EMAIL:
            return{
                ...state,
                emailOtpData : action.payload
            }                     
        case GET_OTP_DATA_MOBILE:
            return{
                ...state,
                mobileOtpData : action.payload
            }
        case GET_OTP_DATA_USERNAME:
            return{
                ...state,
                userNameOtpData:action.payload
            }
        case SET_IS_MOBILE_NO_ALREADY_EXIST_FLG:
            return{
                ...state,
                mobileNoAlreadyExistFlg:action.payload
            }
        case SET_IS_EMAIL_ID_ALREADY_EXIST_FLG:
            return{
                ...state,
                emailIdAlreadyExistFlg:action.payload
            }
        default:
            return state;
    }
};