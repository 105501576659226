/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import qualificationinformation from '../labels/qualification_information_en.json';
import { shallowEqual, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import QualificationTypeDropdown from "./QualificationTypeDropdown";
import { RESULTS } from "../../common/constants/dropdownOptions";
import { getLookOptionsForADropdown } from "../../helpers/LookupOptionHelper";
import numberformat from "../labels/serial_nos_en.json";
import {  percentage, twoDigitInteger, numberRegex, certificateNumberRegex } from '../../common/constants/regex'
import dayjs from 'dayjs';
import _ from "lodash/fp";
import useQualificationActions from "./useQualificationActions";
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";
import Select from "react-select";

const GraduationPGQualification = () => {
  let { profileCreationAllLookupData } = useSelector(state => state.profile, shallowEqual);
  const { handleQualificationChange, handleQualificationInfoSubmit, setActionType, setQualificationState,
      register, handleSubmit, errors, qualificationState, currentEditQualificationIndex, control,
      isResultDateMandatory, isResultInfoMandatory, onQualificationNameChange, handleResultTypeChange, qualificationNames,
      subjects, onStateChange, statesList, universities, flgSubjectDisable, flgStateDisable, flgUniversityDisable, 
      flgAttemptsDisable, flgPercentageDisable, flgNumberOfAcadmicMonthDisable,flgClassDisable, flgModeDisable, 
      flgCompSubjectDisable, flgOptSubjectDisable, flgNameOfRegDisable, flgCouncilRegDateDisable, flgCouncilRegNumDisable, 
      flgDateOFRenewalDisable,
    } = useQualificationActions();

  const grades = getLookOptionsForADropdown("qualification_grade", profileCreationAllLookupData);
  const modes = getLookOptionsForADropdown("qualification_mode", profileCreationAllLookupData);
  const councilNames = getLookOptionsForADropdown("qualification_council", profileCreationAllLookupData);
  const [disableUpdateBtnFlg, setDisableUpdateBtnFlg] = useState(false);

  useEffect(()=>{
    if(((qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue) && ((!qualificationState.optionalSubjects) || (qualificationState && qualificationState.optionalSubjects.length === 0))) || ((qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue) && ((!qualificationState.compulsorySubjects) || (qualificationState && qualificationState.compulsorySubjects.length === 0)))){
      setDisableUpdateBtnFlg(true);
    }else{
      setDisableUpdateBtnFlg(false)
    }
  },[qualificationState]);

  return (
    <form onSubmit={handleSubmit((e) => { handleQualificationInfoSubmit(e) })}>
      <div className="form-row">
        <QualificationTypeDropdown />
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.2"]}&nbsp;{qualificationinformation.NAME_OF_DEGREE}</label>
          <span className="redColor">*</span>
          <select className="form-control" value={qualificationState.qualificationnamecode} name="qualificationnamecode" ref={register({ required: true })} onInput={onQualificationNameChange}>
            <option key={0} value={""}>{advertisementDetailsLabel.SELECT}</option>
            {
              qualificationNames.sort((name1, name2) => { return name1.qualificationName.localeCompare(name2.qualificationName)}).map((ele, i) => {
                return <option key={i} value={ele.qualificationNameCode}>{ele.qualificationName}</option>
              })
            }
          </select>
          {_.get("qualificationnamecode.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        {/** Following div is to displayed conditionally */}
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.3"]}&nbsp;{qualificationinformation.SUBJECT}</label>
          <select className="form-control" value={qualificationState.subjectLookupId} name="subjectLookupId" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgSubjectDisable || (subjects.length === 0 ? true : false)}>
            <option key={0} value={""}>{advertisementDetailsLabel.SELECT}</option>
            {
              subjects.sort((sub1, sub2) => { return sub1.subjectName.localeCompare(sub2.subjectName) }).map((ele, i) => {
                return <option key={i} value={ele.subjectId}>{ele.subjectName}</option>
              })
            }
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.4"]}&nbsp;{qualificationinformation.STATE}</label>
          <select className="form-control" value={qualificationState.statecode} name="statecode" ref={register({ required: false })} onInput={onStateChange} disabled={flgStateDisable}>
            <option key={0} value={999}>{qualificationinformation.SELECT_STATE}</option>
            {
              statesList.map((ele, i) => {
                return <option key={i} value={ele.statecode}>{ele.statename}</option>
              })
            }
          </select>
        </div>
        <div className="form-group col-md-6">
          <label className="float-left mb-0" for="universitycode"> {numberformat["4.5"]}&nbsp;{qualificationinformation.BOARD_UNIVERSITY}</label>
          <select id="universitycode" className="form-control" value={qualificationState.universitycode} name="universitycode" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgUniversityDisable}>
            <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
            {
              universities.sort((university1, university2) => { return university1.universityName.localeCompare(university2.universityName) }).
              map((ele, i) => {
                return <option key={i} value={ele.universityCode}>{ele.universityName}</option>
              })
            }
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.6"]}&nbsp;{qualificationinformation.RESULT} </label>
          <span className="redColor">*</span>
          <select className="form-control" value={qualificationState.typeResult} name="typeResult" ref={register({ required: true })} onInput={handleResultTypeChange}>
            {
              RESULTS.map((ele, i) => {
                return <option value={ele.value} key={i}>{ele.label}</option>
              })
            }
          </select>
          {_.get("typeResult.type", errors) === "required" && (
            <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3" >
          <label className="float-left mb-0"> {numberformat["4.7"]}&nbsp;{qualificationinformation.RESULT_DATE}</label>
          {isResultDateMandatory ? <span className="redColor">*</span>: ""}
          <Controller
            name="resultDate"
            rules={{ required: isResultDateMandatory, validate: value => value <= new Date() }}
            key={qualificationState.resultDate ? "resultDate" : "resultDate1"}
            defaultValue={qualificationState.resultDate ? dayjs(qualificationState.resultDate).toDate() : ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.resultDate ? dayjs(qualificationState.resultDate).toDate() : fromUTCDate(value)}
                maxDate={new Date()}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    resultDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                disabled={!isResultDateMandatory}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
              />
            )}
          />
          {_.get("resultDate.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("resultDate.type", errors) === "validate" && (
            <p className="errorText">{qualificationinformation.DATA_LTE_TODAY_MSG}</p>
          )}
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.8"]}&nbsp; {qualificationinformation.ATTEMPTS}</label>
          {isResultDateMandatory && isResultInfoMandatory ? <span className="redColor">*</span>: ""}
          <input type="text" className="form-control" value={qualificationState.attempts ? qualificationState.attempts : ""}  defaultValue={qualificationState.attempts} name="attempts" maxLength={2} ref={register({ required: isResultDateMandatory && isResultInfoMandatory , pattern: twoDigitInteger})} onChange={handleQualificationChange} disabled={flgAttemptsDisable} />
          {_.get("attempts.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("attempts.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_ATTEMPTS_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.9"]}&nbsp;{qualificationinformation.PERCENTAGE_GRADE} {isResultDateMandatory && isResultInfoMandatory ? <span className="redColor">*</span>: ""}</label>
          <input type="text" className="form-control" value={qualificationState.percentageGrade ? qualificationState.percentageGrade : ""} defaultValue={qualificationState.percentageGrade} name="percentageGrade" ref={register({ required: isResultDateMandatory && isResultInfoMandatory, pattern: percentage })} onChange={handleQualificationChange} disabled={flgPercentageDisable} />
          {_.get("percentageGrade.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("percentageGrade.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_PERCENTAGE_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.10"]}&nbsp;{qualificationinformation.COURSE_DURATION}</label>
          {isResultDateMandatory && isResultInfoMandatory ? <span className="redColor">*</span>: ""}
          <input type="text" className="form-control" maxLength={2} value={qualificationState.courseDurationMonths ? qualificationState.courseDurationMonths:""} defaultValue={qualificationState.courseDurationMonths} name="courseDurationMonths" ref={register({ required: isResultDateMandatory && isResultInfoMandatory, pattern: numberRegex})} onChange={handleQualificationChange} disabled={flgNumberOfAcadmicMonthDisable} />
          {_.get("courseDurationMonths.type", errors) === "required" && (
            isResultDateMandatory ? <p className="errorText">{qualificationinformation.MANDATORY_FIELD_MESSAGE}</p>: ""
          )}
          {_.get("courseDurationMonths.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_COURSE_DURATION_MESSAGE}</p>
          )}
        </div>
        <div className="col-md-3 mt-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.11"]}&nbsp;{qualificationinformation.CLASS_GRADE}</label>
          <select className="form-control" value={qualificationState.classGradeLookupId} name="classGradeLookupId" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgClassDisable}>
            <option key={0} value={""}>{advertisementDetailsLabel.SELECT}</option>
            {grades && grades.sort((grade1, grade2) => { return grade1.label.localeCompare(grade2.label)}).
            map(ele =>
              <option key={ele.id} value={ele.id}>{ele.label}</option>
            )}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.12"]}&nbsp; {qualificationinformation.MODE}</label>
          <select className="form-control" value={qualificationState.modeLookupId ? qualificationState.modeLookupId : ""} defaultValue={qualificationState.modeLookupId} name="modeLookupId" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgModeDisable}>
            <option key={0} value={""}>{advertisementDetailsLabel.SELECT}</option>
            {modes && modes.sort((mode1, mode2) => { return mode1.label.localeCompare(mode2.label)}).
            map(ele =>
              <option key={ele.id} value={ele.id}>{ele.label}</option>
            )}
          </select>
        </div>
        <div className="col-md-3 mb-1">
          <label className="col-md-12 mb-0 mr-2 ml-n2"> {numberformat["4.13"]}&nbsp;{qualificationinformation.COMPULSORY_SUBJECTS}{qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue ? <span className="redColor">*</span>: ""}</label>
          <Select
            className="w-100"
            name="compulsorySubjects"
            isMulti={true}
            getOptionValue={(option) => option.subjectId}
            getOptionLabel={(option) => option.subjectName}
            value={qualificationState.compulsorySubjects ? qualificationState.compulsorySubjects : ""}
            onChange={(e) => { handleQualificationChange(e, "compulsorySubjects")}}
            isDisabled={flgSubjectDisable || (subjects.length === 0 ? true : false) || flgCompSubjectDisable}
            options={subjects.sort((sub1, sub2) => sub1.subjectName.localeCompare(sub2.subjectName))} 
          />
        </div>
        {qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue && 
        <div className="col-md-3 mb-1">
          <label className="col-md-12 mb-0 mr-2 ml-n2">{qualificationinformation.COMPULSORY_SUBJECTS} {qualificationinformation.OLD}</label>
          <input type="text" className="form-control" defaultValue={qualificationState && qualificationState.compulsorySubjectsIsNumberFlgValue} name="compulsorySubjectsIsNumberFlgValue" disabled/>
        </div>
        }
        <div className="col-md-3 mb-1">
          <label className="col-md-12 mb-0 mr-2 ml-n2"> {numberformat["4.14"]}&nbsp;{qualificationinformation.OPTIONAL_SUBJECTS}&nbsp;{qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue ? <span className="redColor">*</span>: ""}</label>
          <Select
            className="w-100"
            name="optionalSubjects"
            isMulti={true}
            getOptionValue={(option) => option.subjectId}
            getOptionLabel={(option) => option.subjectName}
            value={qualificationState.optionalSubjects ? qualificationState.optionalSubjects : ""}
            onChange={(e) => { handleQualificationChange(e, "optionalSubjects")}}
            isDisabled={flgSubjectDisable || (subjects.length === 0 ? true : false) || flgOptSubjectDisable}
            options={subjects.sort((sub1, sub2) => sub1.subjectName.localeCompare(sub2.subjectName))} 
          />
        </div>

        {qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue && 
          <div className="col-md-3 mb-1">
            <label className="col-md-12 mb-0 mr-2 ml-n2"> {qualificationinformation.OPTIONAL_SUBJECTS} {qualificationinformation.OLD}</label>
            <input type="text" className="form-control" defaultValue={qualificationState && qualificationState.optionalSubjectsIsNumberFlgValue} name="optionalSubjectsIsNumberFlgValue" disabled/>
          </div>
        }
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0"> {numberformat["4.15"]}&nbsp; {qualificationinformation.COUNCIL_NAME}</label>
          <select className="form-control" value={qualificationState.councilId} name="councilId" ref={register({ required: false })} onInput={handleQualificationChange} disabled={flgNameOfRegDisable}>
            <option key={0} value={""}>{advertisementDetailsLabel.SELECT}</option>
            {councilNames && councilNames.sort((name1, name2) => { return name1.label.localeCompare(name2.label)}).
            map(ele =>
              <option key={ele.id} value={ele.id}>{ele.label}</option>
            )}
          </select>
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.16"]}&nbsp;{qualificationinformation.COUNCIL_REG_DATE}</label>
          <Controller
            name="councilRegistrationDate"
            rules={{ required: false }}
            key={qualificationState.councilRegistrationDate ? "councilRegistrationDate" : "councilRegistrationDate1"}
            defaultValue={qualificationState.councilRegistrationDate ? dayjs(qualificationState.councilRegistrationDate).toDate() : ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                selected={qualificationState.councilRegistrationDate ? dayjs(qualificationState.councilRegistrationDate).toDate() : fromUTCDate(value)}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    councilRegistrationDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
                disabled={flgCouncilRegDateDisable}
              />
            )}
          />
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.17"]}&nbsp;{qualificationinformation.COUNCIL_REG_NO}</label>
          <input type="text" className="form-control"  value={qualificationState.councilRegistrationNumber ? qualificationState.councilRegistrationNumber : ""} defaultValue={qualificationState.councilRegistrationNumber} name="councilRegistrationNumber" ref={register({ required: false, pattern: certificateNumberRegex })} onChange={handleQualificationChange} disabled={flgCouncilRegNumDisable} />
          {_.get("councilRegistrationNumber.type", errors) === "pattern" && (
            <p className="errorText">{qualificationinformation.INVALID_INPUT}</p>
          )}
        </div>
        <div className="col-md-3 mb-3">
          <label className="float-left mb-0">{numberformat["4.18"]}&nbsp;{qualificationinformation.RENEWAL_DATE}</label>
          <Controller
            name="councilRenewalDate"
            rules={{ required: false ,validate: value => true }}
            key={qualificationState.councilRenewalDate ? "councilRenewalDate" : "councilRenewalDate1"}
            defaultValue={qualificationState.councilRenewalDate ? dayjs(qualificationState.councilRenewalDate).toDate() : ""}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
              selected={qualificationState.councilRenewalDate ? dayjs(qualificationState.councilRenewalDate).toDate() : fromUTCDate(value)}
                onChange={(e) => {
                  setQualificationState({
                    ...qualificationState,
                    councilRenewalDate: toUTCDate(e)
                  }); onChange(toUTCDate(e))
                }}
                showMonthDropdown
                showYearDropdown
                placeholderText={commonLabels.DATE_FORMAT}
                dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                className="form-control"
                disabled={flgDateOFRenewalDisable}
              />
            )}
          />
        </div>
      </div>
      <div className="text-center">
        {
          currentEditQualificationIndex !== -1
            ?
            <button type="submit" name="edit" className="btn btn-primary m-1" onClick={(e) => { setActionType('edit') }} disabled={disableUpdateBtnFlg}>{qualificationinformation.UPDATE}</button>
            :
            <button type="submit" name="add" className="btn btn-primary m-1" onClick={(e) => { setActionType('add') }}>{qualificationinformation.ADD}</button>
        }
      </div>
    </form>
  );
}
export default GraduationPGQualification;