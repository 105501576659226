export let qualificationInfoReqDTO = {
	qualificationtypecode: "",
	qualificationnamecode: "",
	schoolName: "",
	subjectLookupId: "",
	otherSubject: "",
	statecode: "",
	universitycode: "",
	typeResult: "",
	resultDate: "",
	attempts: "",
	percentageGrade: "",
	courseDurationMonths: "",
	certificationGrade: "",
	classGradeLookupId: "",
	modeLookupId: "",
	compulsorySubjects: "",
	optionalSubjects: "",
	councilId: "",
	councilRegistrationDate: "",
	councilRegistrationNumber: "",
	councilRenewalDate: "",
	registrationDate: "",
	registrationNumber: "",
	projectTitle: "",
	publicationType: "",
	publicationAuthorName: "",
	flgPublicationFirstAuthor: "",
	publicationAuthorType: "",
	publicationJournalDetails: "",
	publicationYear: "",
	flgImpactIndex2Or0OrMore: "",
	publicationPaperTitle: "",
	recognitionDate: "",
	noStudentsRegisteredYw: "",
	noStudentsResearchComplete: "",
	noStudentsPhdAwarded: "", 
	flgDeleted:false,
	indexingBodyLookupId: "",
	articleTypeLookupId: "",
	publicationAcceptanceDate: "",
	publicationPublishedDate: "",
	guidedBy:""
};