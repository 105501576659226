/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../../styles/candidate_nav.css";
import "../../styles/my_account.css";
import _ from "lodash/fp";
import commonLabels from "../../application_management/labels/common_en.json"
import common from "../labels/common_en.json";
import { Modal } from 'react-bootstrap';
import { createRetotalingRequest } from "../../application_management/actions/advertisementAction";
import myAccountLabels from "../../my_account/labels/my_account_en.json";
import "../../styles/advertisementTable.css";
import "../../styles/profile.css";

function RetotalingModal(props) {

    let RetotalingTabs = [
        {
            "id": 1,
            "name": "Retotaling Requests"
        },
        {
            "id": 2,
            "name": "Apply For Retotaling"
        }
    ];

    let headerField = [
        {
            'name': "Select"
        },
        {
            'name': "Subject"
        },
        {
            'name': "Subject Code"
        },
        {
            'name': "Obtained Marks"
        },
        {
            'name': "Fees(Rs.)"
        }
    ];

    let requestHeaderField = [
        {
            'name': "S.No"
        },
        {
            'name': "Subject"
        },
        {
            'name': "Fees(Rs.)"
        },
        {
            'name': "Created At"
        },
        {
            'name': ""
        }
    ];

    const dispatch = useDispatch();
    let [selectedSubjectAmount, setSelectedSubjectAmount] = useState(0);
    let [selectedSubjects, setSelectedSubject] = useState([]);
    let [stepName, setName] = useState(0);
    let [isPaymentDisable, setIsPaymentDisable] = useState(true);
    let [stepTab, setTabIndex] = useState(0);
    let [isRetotaling, setIsRetotaling] = useState(true);
    let [currentPostId, setCurrentPostId] = useState(null);
    let [selectedPostRetotalingEndDate, setSelectedPostRetotalingEndDate] = useState('');

    useEffect(() => {
        setName(0);
        setTabIndex(0);
    }, [])

    useEffect(() => {
        calculateAmount();
    }, [selectedSubjects]);

    useEffect(() => {
    }, [selectedPostRetotalingEndDate]);

    const gotoPayment = (requestId) => {
        let reqData = { 'id': requestId, 'type': 'retotaling' };
        props.gotoRetotalingPayment(reqData, props.applicationData);
    };

    const modalCancel = () => {
        props.setShowRetotalingForm(false)
    };

    const checkUncheckSubject = (e, subId, fee, pid) => {
        setIsPaymentDisable(false);
        if (e.target.checked) {
            let subjectArray = { "id": subId, "fee": fee };
            let tempArray = [...selectedSubjects];
            tempArray.push(subjectArray);
            setSelectedSubject(tempArray,);
        }
        else {
            setSelectedSubject(current =>
                current.filter(item => {
                    return item.id !== subId;
                }),
            );
        }
        if (pid) {
            setCurrentPostId(pid);
        }
    };

    const calculateAmount = () => {
        let tAmount = 0;
        selectedSubjects.map((item) => {
            tAmount = tAmount + item.fee;
        })
        setSelectedSubjectAmount(tAmount);
    };

    const resetSubjectProp = (ele) => {
        setSelectedSubject([]);
        setCurrentPostId(ele.postCriteria.id);
        setSelectedPostRetotalingEndDate(ele.advertisementStage.onlinePaymentEndDate);
    };

    const setRetotalingTab = (tabindex) => {
        if (tabindex == 0) {
            setIsRetotaling(true);
        }
        else {
            setIsRetotaling(false);
        }
    };

    const clickPayAndApply = () => {
        let totalAmount = selectedSubjectAmount + props.retotalingPostSubjectsList.processingFees;
        let sendRequestData = {
            "fees": totalAmount,
            "applicationId": props && props.applicationData && props && props.applicationData.id,
            "posts": [
                currentPostId
            ],
            "processingFees": props.retotalingPostSubjectsList.processingFees,
            "examPostDetailsDtos": []
        };
        let allreadyAdd = [];
        props && props.retotalingPostSubjectsList && props.retotalingPostSubjectsList.postSubjects && props.retotalingPostSubjectsList.postSubjects.length > 0 && props.retotalingPostSubjectsList.postSubjects.map((ele) => {
            ele && ele.examPostDetailsDtos && ele.examPostDetailsDtos.length > 0 && ele.examPostDetailsDtos.map((elm, index1) => {
                if (allreadyAdd.includes(elm.applicationSubjectMarks.examSubject.id)) {
                    return;
                }
                selectedSubjects.filter((elem) => elem.id == elm.applicationSubjectMarks.examSubject.id).map((item) => {
                    let subjectData = {
                        "applicationSubjectMarks": {
                            "id": elm.applicationSubjectMarks.id,
                            "applicationMarksId": elm.applicationSubjectMarks.applicationMarksId,
                            "marks": elm.applicationSubjectMarks.marks,
                            "remarks": elm.applicationSubjectMarks.remarks,
                            "examSubject": {
                                "id": elm.applicationSubjectMarks.examSubject.id,
                                "name": elm.applicationSubjectMarks.examSubject.name,
                                "nameDvng": elm.applicationSubjectMarks.examSubject.nameDvng,
                                "code": elm.applicationSubjectMarks.examSubject.code,
                                "parentExamSubjectId": elm.applicationSubjectMarks.examSubject.parentExamSubjectId,
                                "retotalingFee": elm.applicationSubjectMarks.examSubject.retotalingFee
                            }
                        }
                    }
                    sendRequestData.examPostDetailsDtos.push(subjectData);
                    allreadyAdd.push(item.id);
                })
            })
        });
        setSelectedSubject([]);
        dispatch(createRetotalingRequest(sendRequestData, props.applicationData, props.gotoRetotalingPayment, props.setShowRetotalingForm));
    };

    return (
        <form className="RetotalingForm mt50">
            <div>
                <Modal show={props.showModal} onHide={modalCancel} backdrop='static' size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{commonLabels.RETOTALING_DETEAILS}</Modal.Title>
                    </Modal.Header>
                    <div>
                        <Modal.Body>
                            <div className="col-sm-12 col-md-12 col-lg-12 float-left p-0">
                                <ul className="nav nav-tabs" id="retotalingTab" role="tablist">
                                    {RetotalingTabs.map((tabData, tabindex) =>
                                        <li className="nav-item cursor-pointer" key={tabData.id} >
                                            <a className={(stepTab === tabindex) ? "nav-link active" : "nav-link bg-profile-tabs"} id={'retabid_' + tabData.id} onClick={() => { setTabIndex(tabindex); setRetotalingTab(tabindex); }} data-toggle="tab" role="tab" aria-controls="home" aria-selected="false">
                                                <span>{tabData.name}</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 float-left p-0">
                                <div className="tab-content border border-light" id="retotalingMainContent">
                                    <div className={(stepTab == 0) ? "tab-pane fade show active boxShadowCss bg-white" : "tab-pane fade boxShadowCss bg-white"} id="RetotalingRequestSec" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="border border-light text-left w-100 p-4">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                                    <div className="content-row clearfix ml-3" >
                                                        <h6>{common.objectionModuleCandidateHeaders.ADVT_ID}</h6> <span>{props && props.applicationData && props.applicationData.advertisement && props.applicationData.advertisement.advertisementNo} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                                    <table className="table table-bordered">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                {requestHeaderField.map((ele, index) =>
                                                                    <th scope="col" key={index} >{ele.name} </th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="f12">
                                                            {props && props.retotalingRequests && props.retotalingRequests.length > 0 && props.retotalingRequests.map((ele, index) => {
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td className="w-10 word-break text-center" > {++index}</td>
                                                                            <td className="word-break" >
                                                                                {ele && ele.details && ele.details.examPostDetailsDtos && ele.details.examPostDetailsDtos.length > 0 && ele.details.examPostDetailsDtos.map((elem, ind) => {
                                                                                    return (
                                                                                        <>{(ind != 0) ? ', ' : ''}
                                                                                            <span>
                                                                                                {elem.applicationSubjectMarks.examSubject.name}
                                                                                            </span>
                                                                                        </>
                                                                                    );
                                                                                })}
                                                                            </td>
                                                                            <td className="word-break" >{ele.fees}</td>
                                                                            <td className="word-break" >{new Date(ele.createdAt).toLocaleDateString('en-US')}</td>
                                                                            <td className="w-15 text-center word-break" > {(ele.paymentRefNo == null || ele.paymentRefNo == undefined || ele.paymentRefNo == '') ?
                                                                                <button type="button" disabled={((ele && (ele.paymentRefNo != "" && ele.paymentRefNo != null)) || (props.isReadOnlyRetotalingStage))} className="btn btn-outline-primary btn-sm mr-1" onClick={() => { gotoPayment(ele.id) }}>{myAccountLabels.PAY_NOW}</button> : 'Paid'}</td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={(stepTab == 1) ? "tab-pane fade show active boxShadowCss bg-white" : "tab-pane fade boxShadowCss bg-white"} id="RetotalingApplyFormSec" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="border border-light text-left w-100 p-4">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                                    <div className="content-row clearfix ml-3" >
                                                        <h6>{common.objectionModuleCandidateHeaders.ADVT_ID}</h6> <span>{props && props.applicationData && props.applicationData.advertisement && props.applicationData.advertisement.advertisementNo} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <h6>{common.LAST_DATE_TO_APPLY_RETOTALING}</h6> <span>{selectedPostRetotalingEndDate ? new Date(selectedPostRetotalingEndDate).toLocaleDateString('en-US') : new Date(props && props.retotalingPostSubjectsList && props.retotalingPostSubjectsList.postSubjects && props.retotalingPostSubjectsList.postSubjects.length > 0 && props.retotalingPostSubjectsList.postSubjects[0].advertisementStage.onlinePaymentEndDate).toLocaleDateString('en-US')} </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="retotalingSection float-left w-100 m-3 borderCss">
                                                    <div className="col-sm-3 col-md-3 col-lg-3 float-left p-0">
                                                        <ul className="nav nav-tabs" id="retotalingTab" role="tablist">
                                                            {props && props.retotalingPostSubjectsList && props.retotalingPostSubjectsList.postSubjects && props.retotalingPostSubjectsList.postSubjects.length > 0 && props.retotalingPostSubjectsList.postSubjects.map((ele, index) => {
                                                                if (ele.postCriteria) {
                                                                    <li className="nav-item cursor-pointer w-100" key={ele.postCriteria.id}>
                                                                        <a className={(stepName === index) ? "nav-link active" : "nav-link bg-profile-tabs"} id={'tabid_' + ele.postCriteria.id} onClick={() => { setName(index); resetSubjectProp(ele); }} data-toggle="tab" role="tab" aria-controls="home" aria-selected="false">
                                                                            <span>{ele.postCriteria.name}</span><br /><span>{ele.postCriteria.nameDvng}</span>
                                                                        </a>
                                                                    </li>
                                                                }
                                                            }
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <div className="col-sm-9 col-md-9 col-lg-9 float-left p-0">
                                                        <div className="tab-content border border-light" id="retotalingTabContent">
                                                            {props && props.retotalingPostSubjectsList && props.retotalingPostSubjectsList.postSubjects && props.retotalingPostSubjectsList.postSubjects.length > 0 && props.retotalingPostSubjectsList.postSubjects.map((ele, index) => {
                                                                if (ele.postCriteria != null && ele.postCriteria != undefined && ele.postCriteria != '') {
                                                                    return (
                                                                        <div className={(stepName === index) ? "tab-pane fade show active boxShadowCss bg-white" : "tab-pane fade boxShadowCss bg-white"} id={'TabContent' + ele.postCriteria.id} role="tabpanel" aria-labelledby="profile-tab">
                                                                            <div className="border border-light text-left w-100 p-4">
                                                                                <span>{ele.postCriteria.name}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{ele.postCriteria.nameDvng}</span>
                                                                                <div className="border border-light text-left w-100 p-4">
                                                                                    <table className="table table-bordered">
                                                                                        <thead className="thead-light">
                                                                                            <tr>
                                                                                                {headerField.map((ele, index) =>
                                                                                                    <th scope="col" key={index} >{ele.name} </th>
                                                                                                )}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody className="f12">
                                                                                            {ele && ele.examPostDetailsDtos && ele.examPostDetailsDtos.length > 0 && ele.examPostDetailsDtos.map((item, index) => {
                                                                                                return (
                                                                                                    <tr key={index}>
                                                                                                        <td className="word-break" >{item && item.alreadySubmitted && item.alreadySubmitted == true ? <input type="checkbox" name="selectedSubjectOfPost" checked disabled /> : <input type="checkbox" name="selectedSubjectOfPost" onClick={(e) => checkUncheckSubject(e, item.applicationSubjectMarks.examSubject.id, props && props.applicationData && props.applicationData.advertisementConfig && props.applicationData.advertisementConfig.retotalingFee ? props.applicationData.advertisementConfig.retotalingFee : 0, (ele.postCriteria != null && ele.postCriteria != '' && ele.postCriteria != undefined) ? ele.postCriteria.id : '')} />} </td>
                                                                                                        <td className="word-break" >{item.applicationSubjectMarks.examSubject.name} {item && item.alreadySubmitted && item.alreadySubmitted == true ? <span className="float-left f10 text-secondary w-100 clearfix mt-1">{common.ALREADY_APPLIED_FOR_RETOTALING}</span> : ''}</td>
                                                                                                        <td className="word-break" >
                                                                                                            {item.applicationSubjectMarks.examSubject.code}
                                                                                                        </td>
                                                                                                        <td className="word-break" >
                                                                                                            {item.applicationSubjectMarks.marks}
                                                                                                        </td>
                                                                                                        <td className="word-break" >
                                                                                                            {props && props.applicationData && props.applicationData.advertisementConfig && props.applicationData.advertisementConfig.retotalingFee ? props.applicationData.advertisementConfig.retotalingFee : 0}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-12 float-left p-0">
                                                        {props && props.retotalingPostSubjectsList && props.retotalingPostSubjectsList.postSubjects && props.retotalingPostSubjectsList.postSubjects.length > 0 && props.retotalingPostSubjectsList.postSubjects.map((ele) => {
                                                            if (ele.postCriteria == null || ele.postCriteria == undefined || ele.postCriteria == '') {
                                                                return (
                                                                    <table className="table table-bordered">
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                {headerField.map((ele, index) =>
                                                                                    <th scope="col" key={index} >{ele.name} </th>
                                                                                )}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="f12">
                                                                            {ele && ele.examPostDetailsDtos && ele.examPostDetailsDtos.length > 0 && ele.examPostDetailsDtos.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="word-break" >{item && item.alreadySubmitted && item.alreadySubmitted == true ? <input type="checkbox" name="selectedSubjectOfPost" checked disabled /> : <input type="checkbox" name="selectedSubjectOfPost" onClick={(e) => checkUncheckSubject(e, item.applicationSubjectMarks.examSubject.id, props && props.applicationData && props.applicationData.advertisementConfig && props.applicationData.advertisementConfig.retotalingFee ? props.applicationData.advertisementConfig.retotalingFee : 0, (ele.postCriteria != null && ele.postCriteria != '' && ele.postCriteria != undefined) ? ele.postCriteria.id : '')} />} </td>
                                                                                        <td className="word-break" >{item.applicationSubjectMarks.examSubject.name} {item && item.alreadySubmitted && item.alreadySubmitted == true ? <span className="float-left f10 text-secondary w-100 clearfix mt-1">{common.ALREADY_APPLIED_FOR_RETOTALING}</span> : ''}</td>
                                                                                        <td className="word-break" >
                                                                                            {item.applicationSubjectMarks.examSubject.code}
                                                                                        </td>
                                                                                        <td className="word-break" >
                                                                                            {item.applicationSubjectMarks.marks}
                                                                                        </td>
                                                                                        <td className="word-break" >
                                                                                            {props && props.applicationData && props.applicationData.advertisementConfig && props.applicationData.advertisementConfig.retotalingFee ? props.applicationData.advertisementConfig.retotalingFee : 0}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                            }
                                                                        </tbody>
                                                                    </table>);
                                                            }
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                    <Modal.Footer>
                        <span className={(isRetotaling && isRetotaling == true) ? 'd-none position-absolute l10' : 'position-absolute l10'}>{common.TOTAL_FEES_MES} <em>{common.RS} {selectedSubjectAmount} + {common.RS} {props && props.applicationData && props.applicationData.advertisementConfig && props.applicationData.advertisementConfig.retotalingServiceCharges ? props.applicationData.advertisementConfig.retotalingServiceCharges : 0} ({common.SERVICE_CHARGE})</em></span>
                        <button type="button" className={(isRetotaling && isRetotaling == true) ? 'd-none' : "btn btn-secondary"} onClick={modalCancel} >{common.CANCEL}</button>
                        <button type="button" disabled={(isPaymentDisable) || (!selectedSubjects.length > 0)} className={(isRetotaling && isRetotaling == true) ? 'd-none' : "btn btn-primary"} onClick={clickPayAndApply} >{commonLabels.PAY_FEES_APPLY}</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </form>
    );
}
export default RetotalingModal;