import React from 'react';
import profile from '../labels/personal_information_en.json';
import profileuploaddocument from '../labels/upload_document_information_en.json'
import "../../styles/advertisementTable.css";

function UploadInformation() {
  return (
    <div className="border border-light m-3 text-left">
    <table className="table">
      <thead className="thead-light">
        <tr>
          {profileuploaddocument.qualificationHeaderContent && profileuploaddocument.qualificationHeaderContent.map((ele, index) => 
            <th scope="col" key={index}>{ele}</th>
          )}
        </tr>
      </thead>
      <tbody className='f12'>
      {profileuploaddocument.qualificationDataContent && profileuploaddocument.qualificationDataContent.map((ele,index) =>
        <tr key={index}>
        <td  className="word-break">{ele.Document_Code}</td>
        <td className="word-break">{ele.Document_Title}</td>
        <td className="word-break"><button type="button" className="btn btn-link btn-sm">{profileuploaddocument.FILE}</button></td>
       </tr>
      )}  
      </tbody>
    </table>
    <p className='paraCss'></p>
        <div className='text-left'>
          <button type="button" className="btn btn-primary m-1" >{profile.SAVE}</button>
          <button type="button" className="btn btn-primary m-1" >{profile.SAVE_AND_NEXT}</button>
        </div>
    </div>
  )

}
export default UploadInformation;