import { for_loader } from "../common/constants/excluded_endpoints";

const isLoaderApplicable = (url) => {
    let flgDisplayLoader = true;
    for(let i=0; i < for_loader.length; i++) {
        if(url.includes(for_loader[i])) {
            flgDisplayLoader = false;
            break;
        }
    }
    return flgDisplayLoader;
};
export { isLoaderApplicable } ;

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */

export function formPost(path, params, method='post') {

    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
  
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
  
        form.appendChild(hiddenField);
      }
    }
  
    document.body.appendChild(form);
    form.submit();
}
