 export function getPasswordStrength(password) {
  if (typeof(password) != 'string') return 0;
  let digitScore = 0;
  let lowerCaseScore = 0;
  let upperCaseScore = 0;
  let specialScore = 0;
  for (let i = 0; i < password.length; i++) {
    const ch = password[i];
    if (/\d/.test(ch)) {
      digitScore = 1;
    } else if (/[a-z]/.test(ch)) {
      lowerCaseScore = 1;
    } else if (/[A-Z]/.test(ch)) {
      upperCaseScore = 1;
    } else {
      specialScore = 1;
    }
  }
  const lengthScore = password.length >= 8 ? 1 : 0;
  return digitScore + lowerCaseScore + upperCaseScore + specialScore + lengthScore;
};
