/* eslint-disable */
import React, { useEffect, useRef } from "react";
import "../../styles/candidate_nav.css";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { admitCardHeaders, examTimeTableHeaders, DATE_FORMAT } from "../labels/common_en.json";
import common from "../labels/common_en.json";
import { QRCodeSVG } from 'qrcode.react';
import 'jspdf-autotable';
import dayjs from "dayjs";
import { admitCard } from "../actions/myAccountAction";
import "../../styles/admit_card.css";

const AdmitCard = () => {
  const dispatch = useDispatch();
  const pdfRef = useRef(null);
  const { admitCardData, profileSummaryInfo, applicationDetails, profileDetailsInfo } = useSelector(state => state.myaccount, shallowEqual);

  useEffect(() => {
    if (admitCardData && Object.keys(admitCardData).length > 0) { }
  }, [admitCardData]);

  useEffect(() => {
    // dispatch(admitCard(applicationDetails.application.id));
  }, [dispatch, applicationDetails.application.id]);

  if (!admitCardData) {
    return null;
  };

  const formatTime = (timeString) => {
    const date = new Date(`2000-01-01T${timeString}`);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    let [time, period] = formattedTime.split(' ');
    if (period === 'PM') {
      period = common.IN_THE_AFTERNOON;
    } else if (period === 'AM') {
      period = common.IN_THE_MORNING;
    }
    return `${period}, ${time}`;
  };

  return (
    <div className="flex-center position-ref" id="pdf-content" ref={pdfRef} >
      <div className='content'>
        <div  >
          <img className="logo header-bd" src="/logos/mpsc_pdf_logo.jpg" alt="mpsc_img" width="710" height="150" />
        </div>
        <div className="heading-pdf mt-1 mb-1">
          <h3 className="admissionCertificate">- {common.ADMISSION_CERTIFICATE} -</h3>
        </div>
        {admitCardData && admitCardData?.admitCard && admitCardData?.admitCard?.timeTable && admitCardData?.admitCard?.timeTable?.mode === 1 ?
          <div>
            <h5 className="t-left ml-3 common">{common.EXAM_NAME} {admitCardData && admitCardData.admitCard && admitCardData.admitCard.examInstructionsList && admitCardData.admitCard.examInstructionsList[0] && admitCardData.admitCard.examInstructionsList[0].examDisplayName}</h5>
          </div>
          :
          <div>
            <h5 className="t-left ml-2 common">{common.EXAM_NAME} {admitCardData && admitCardData.admitCard && admitCardData.admitCard.examInstructionsList && admitCardData.admitCard.examInstructionsList[0] && admitCardData.admitCard.examInstructionsList[0].examDisplayName}</h5>
          </div>}
        <div>

          <table  className="table-responsive table-first t-left tableCenter "  >
            <tbody className="common w-100 d-table">
              <tr >
                <th className="bg-f1 bold-f">{admitCardHeaders.SEAT_NUM}</th>
                <td >  {admitCardData?.admitCard?.timeTable?.hallticketNo ? (
                  admitCardData.admitCard.timeTable.hallticketNo
                ) : (
                  '--'
                )}</td>
                <td rowspan="3"  ><span className="d-flex justify-content-center"><QRCodeSVG value={admitCardData?.admitCard?.timeTable?.hallticketNo} size={75} level={'H'} includeMargin={true} /></span>
                  
                </td>
                <th className="bg-f1 t-center bold-f " >{admitCardHeaders.CANDIDATE_PIC_SIGN}</th>

              </tr>

              <tr>
                <th className="t-left bg-f1 bold-f" >{admitCardHeaders.DOB}</th>
                <td > {admitCardData?.personalInfoAdmitCardDto?.dob ? (
                  dayjs(admitCardData?.personalInfoAdmitCardDto?.dob).format(DATE_FORMAT)
                ) : (
                  '--'
                )}</td>
                <td rowspan="6" class="w-15" >
                  <div className="d-flex flex-column align-items-center">
                    <img
                      className="imgwidth d-block"
                      src={`data:image/png;base64,${profileSummaryInfo && profileSummaryInfo.photo}`}
                      alt="Profile_Photo"
                      width="100"
                      height="150"
                    />
                    <img
                      className="imgwidthSign d-block mt-2"
                      src={`data:image/png;base64,${profileDetailsInfo && profileDetailsInfo.signature}`}
                      alt="sign"
                      width="100"
                      height="100"
                    />
                  </div>
                </td>

              </tr>
              <tr>
                <th className="t-left bg-f1 bold-f"  >{admitCardHeaders.REGISTRATED_NUM}</th>
                <td >{admitCardData?.admitCard?.applicationNo || '--'}</td>
              </tr>
              <tr>
                <th className="t-left bg-f1 bold-f"  >{admitCardHeaders.CANDIDATE_NAME}</th>
                <td colSpan="2">{admitCardData?.personalInfoAdmitCardDto?.englishFullName || '--'}</td>
              </tr>
              <tr>
                <th className="t-left bg-f1 bold-f"  >{admitCardHeaders.CHANGED_CANDIDATE_NAME}</th>
                <td colSpan="2">{admitCardData?.personalInfoAdmitCardDto?.flgNameChanged == true ? admitCardData?.personalInfoAdmitCardDto?.changedEnglishName : '--'}</td>
              </tr>
              <tr>
                <th className="t-left bg-f1 bold-f"  >{admitCardHeaders.EXAM_CENTRE}</th>

                {admitCardData?.admitCard?.timeTable?.mode === 1 ? (<>
                  <td >{admitCardData?.admitCard?.timeTable?.venueName || '--'}</td>
                  <td >
                    <QRCodeSVG value={admitCardData?.admitCard?.timeTable?.venueName} size={100} level={'H'} includeMargin={true} />
                  </td>
                </>
                ) : (
                  <td colSpan="2" >{admitCardData?.admitCard?.timeTable?.venueName || '--'}</td>
                )}
              </tr>
            </tbody>
          </table>
          <div >
            <table className="table-responsive table-sec t-center " >
              <thead>
                <tr>
                  {examTimeTableHeaders.map((el, i) => {
                    return <th className="common text-center" scope="col" key={i}>{el}</th>
                  })}
                </tr>
              </thead>
              <tbody className="common">
                {admitCardData && admitCardData.admitCard && admitCardData.admitCard.timeTableExamsList && admitCardData.admitCard.timeTableExamsList.map((ele, index) => {
                  if (ele) {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="text-center font-weight-bold">
                            {ele.examDate ? (
                              <>
                                {new Date(ele.examDate).toLocaleDateString('mr-IN', { weekday: 'long' })}
                                {','}
                                {new Date(ele.examDate).toLocaleDateString('en-US', { day: 'numeric' })}
                                {','}
                                <br />
                                {new Date(ele.examDate).toLocaleDateString('mr-IN', { month: 'long' })}
                                {','}
                                {new Date(ele.examDate).toLocaleDateString('en-US', { year: 'numeric' })}
                              </>
                            ) : (
                              '--'
                            )}
                          </td>
                          <td className="text-center examTime">{ele.presentTime ? formatTime(ele.presentTime) : '--'}</td>
                          <td className="text-center examTime">{ele.getInTime ? formatTime(ele.getInTime) : '--'}</td>
                          <td className="text-center examTime">
                            {ele.durationFrom ? `${formatTime(ele.durationFrom)} ते ` : '--'}
                            {ele.durationTo
                              ? new Date(`1970-01-01T${ele.durationTo}`).toLocaleTimeString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              }).replace('PM', '')
                              : '--'}
                          </td>
                          <td className="text-center examTime">
  {ele.subjectNameDvng && ele.tableExamSubjectsDto && ele.tableExamSubjectsDto.subjectNameDvng ? (
    <>{ele.subjectNameDvng} <br/> {ele.tableExamSubjectsDto.subjectNameDvng}</>
  ) : (
    ele.subjectNameDvng
  )}
</td>
                          <td className="text-center font-weight-bold">{ele.subjectCodes ? ele.subjectCodes : '--'}</td>
                          <td className="text-center examTime">{ele.examPatternNameDvng ? ele.examPatternNameDvng : '--'}</td>
                        </tr>
                      </React.Fragment>
                    )
                  }
                })}
              </tbody>
            </table>

          </div>
          {admitCardData?.admitCard?.timeTable?.mode === 1 ?
            <div>
              <div className="mt-1 text-right common" >
                 {admitCardData && admitCardData.admitCard && admitCardData.admitCard.examInstructionsList && admitCardData.admitCard.examInstructionsList.map((ele, i) => {
    if (ele) {
             return(
              <div className="centered-right">
              <div className="centered-content">
              <h6 >{ele.examAuthoritySignature}</h6>
              <h6 >{ele.examAuthority}</h6>
                </div>
                </div>
             )
    }
  })}
              </div>
            </div>
            : <div className="mt-1 text-right common">
                           {admitCardData && admitCardData.admitCard && admitCardData.admitCard.examInstructionsList && admitCardData.admitCard.examInstructionsList.map((ele, i) => {
    if (ele) {
             return(
              <div className="centered-right">
              <div className="centered-content">
              <h6 >{ele.examAuthoritySignature}</h6>
              <h6 >{ele.examAuthority}</h6>
                </div>
                </div>
             )
    }
  })}
            </div>}
        </div>
<div className="mt-2">
<h4  className="common">
{common.VERY_IMPORTANT}
</h4>
{admitCardData &&
  admitCardData.admitCard &&
  admitCardData.admitCard.examInstructionsList &&
  admitCardData.admitCard.examInstructionsList.map((ele, i) => {
    if (ele) {
      const instructions = ele.importantInstructions.split("</li>");
      return (
        <div className="mt-2 commnFontSize">
          {ele.importantInstructions && (
            <div>
              {instructions.map((instruction, index) => (
                <p key={index} className="text-dark">
                  {index !== instructions.length - 1 && `${index + 1}.`}
                  {instruction.replace(/<\/?[^>]+(>|$)/g, "")}
                  {index !== instructions.length - 1 && <span>&#8226;</span>}
                </p>
              ))}
            </div>
          )}
        </div>
      );
    }
    return null;
  })}



</div>

<div className="mt-2">
  <h4 className="common">{common.INSTRUCTIONS_TO_CANDIDATES}</h4>
  {admitCardData &&
  admitCardData.admitCard &&
  admitCardData.admitCard.examInstructionsList &&
  admitCardData.admitCard.examInstructionsList.map((ele, i) => {
    if (ele) {
      const instructions = ele.studentInstructions.split("</li>");
      return (
        <div className="mt-2 commnFontSize">
          {ele.importantInstructions && (
            <div>
              {instructions.map((instruction, index) => (
                <p key={index} className="text-dark">
                  {index !== instructions.length - 1 && `${index + 1}.`}
                  {instruction.replace(/<\/?[^>]+(>|$)/g, "")}
                  {index !== instructions.length - 1 && <span>&#8226;</span>}
                </p>
              ))}
            </div>
          )}
        </div>
      );
    }
    return null;
  })}
</div>
      </div>
    </div>
  )
};
export default AdmitCard;
