/* eslint-disable */
import React, { useEffect, useState } from "react";
import login from "../labels/login_en.json";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "../../styles/profile.css";
import "../../styles/registration.css";
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import { Link } from "react-router-dom";
import { loginAction, onCaptchaProceed } from "../actions/sessionActions";
import {
  generateCaptcha,
  enableOTPButton,
  checkModuleEnableDisable,
} from "../../common/actions/commonActions";
import ModuleHide from "../../common/components/ModuleHide";
import ForcefullyLogoutModal from "./ForcefullyLogoutModal";
import { showLoader } from "../../common/actions/commonActions";
import { OAS_API_BASE_URL } from "../../configuration";

function Login(props) {
  const currentDomain = window.location.hostname;
  let [forceFulLogout, setForceFulLogout] = useState(false);
  let [forceFulLogoutErrorMessage, setForceFulLogoutErrorMessage] =
    useState(false);
  let [usernameForLogin, setUsernameForLogin] = useState(false);
  let [showCaptcha, setShowCaptcha] = useState(false);
  let [details, setDetails] = useState();
  let [userDetail, setUserDetail] = useState();
  const [data, setData] = useState({});
  const matchingBaseUrl = OAS_API_BASE_URL.find(
    (item) => item.key === currentDomain
  )?.baseUrl;

  const { register, handleSubmit, formState, errors, watch } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    
    showLoader();
  }, []);

  const dispatch = useDispatch();
  const captchaId = useSelector((state) => state.common.captchaId);
  let { emailOTPResponse, moduleDetails } = useSelector(
    (state) => state.common,
    shallowEqual
  );
  let { accessToken, loginStatus } = useSelector(
    (state) => state.loginReducer,
    shallowEqual
  );
  const captchaImageUrl = `${matchingBaseUrl}/oas/api/v1/otps/${captchaId}`;

  useEffect(() => {
    dispatch(checkModuleEnableDisable("LOGIN"));
  }, []);

  const reDirectToForgotPassword = () => {
    dispatch(enableOTPButton("login"));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onProceed();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmits = (event) => {
    event.preventDefault();
    dispatch(
      loginAction(
        data,
        emailOTPResponse,
        setShowCaptcha,
        setForceFulLogout,
        setForceFulLogoutErrorMessage,
        setUsernameForLogin
      )
    );
    setUserDetail(data);
  };
  const handleKeyDownLogin = (event) => {
    if (event.key === "Enter") {
      dispatch(
        loginAction(
          data,
          emailOTPResponse,
          setShowCaptcha,
          setForceFulLogout,
          setForceFulLogoutErrorMessage,
          setUsernameForLogin
        )
      );
    }
  };
  const onProceed = () => {
    dispatch(onCaptchaProceed(props, details, captchaId, userDetail));
  };
  return moduleDetails.isEnabled == false ? (
    <ModuleHide details={moduleDetails} />
  ) : (
    <form onSubmit={handleSubmits} className="mt-3">
      <div className="container">
        <div className="row d-flex mb-4">
          <div className="col-md-3 border border-light"></div>
          <div className="col-md-6 border border-light bg-white ml-2">
            <div className="form-group d-flex justify-content-center p-3">
              <span className="bold-text"> {login.LOGIN}</span>
            </div>
            {!showCaptcha ? (
              <div className="row d-flex justify-content-center">
                <div className="form-group col-md-9">
                  <label className="LeftText">
                    {" "}
                    {login.USERNAME}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder={login.USERNAME}
                      className="form-control"
                      id="userName"
                      name="userName"
                      ref={register({
                        required: true,
                        validate: {
                          email: (value) =>
                            /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/.test(
                              value
                            ) || /^\s*([1-9]{1}[0-9]{9})\s*$/.test(value),
                        },
                      })}
                      onChange={handleInputChange}
                    />
                  </div>
                  {_.get("userName.type", errors) === "required" && (
                    <p className="errorText">
                      {login.validationMessage.USERNAME_REQ}
                    </p>
                  )}
                  {errors.userName?.type === "email" && (
                    <p className="errorText">{login.VALID_VALUE_NOTE} </p>
                  )}
                  <span>
                    <Link
                      to="/candidate/forgotlogin"
                      onClick={(e) => {
                        dispatch(checkModuleEnableDisable("FRGT_LOGIN", true));
                      }}
                    >
                      {login.FORGOT_USERNAME}
                    </Link>
                  </span>
                </div>
                <div className="form-group col-md-9">
                  <label className="LeftText">
                    {" "}
                    {login.PASSWORD}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="Password"
                    onKeyDown={handleKeyDownLogin}
                    ref={register({ required: true })}
                    disabled={
                      errors.userName?.type === "email" ||
                      _.get("userName.type", errors) ||
                      !watch("userName")
                    }
                    onChange={handleInputChange}
                  />
                  {_.get("Password.type", errors) === "required" && (
                    <p className="errorText">
                      {login.validationMessage.PASSWORD_REQ}
                    </p>
                  )}
                  <span onClick={reDirectToForgotPassword}>
                    <Link
                      to="/candidate/forgotPassword"
                      onClick={(e) => {
                        dispatch(
                          checkModuleEnableDisable("FRGT_RESET_PWD", true)
                        );
                      }}
                    >
                      {login.FORGOT_PASSWORD}
                    </Link>
                  </span>
                </div>

                {/* <div className="form-group col-md-9 background-field">
                  {captchaId !== 0 && matchingBaseUrl ? (
                    <img src={captchaImageUrl} id="captchaimage" />
                  ) : captchaId !== 0 ? (
                    <img
                      src={`/oas/api/v1/otps/${captchaId}`}
                      id="captchaimage"
                    />
                  ) : (
                    ""
                  )}
                  <span className="ml-3">
                    {" "}
                    <i
                      className="fa fa-refresh fa-lg cursor-pointer"
                      onClick={() => {
                        dispatch(generateCaptcha());
                      }}
                    />
                  </span>
                  <label className="LeftText">
                    {" "}
                    {login.ENTER_THE_TEXT_AS_ABOVE_IMAGE}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput2"
                    name="enteredCaptcha"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  {_.get("enteredCaptcha.type", errors) === "required" && (
                    <p className="errorText">
                      {login.validationMessage.FIELD_REQ}
                    </p>
                  )}
                </div> */}
                <div className="form-group col-md-9 d-flex justify-content-center">
                  <button
                    type="submit"
                    disabled={!formState.isValid}
                    className="btn btn-primary"
                  >
                    {login.LOGIN}
                  </button>
                </div>
              </div>
            ) : (
              <div className="row d-flex justify-content-center">
                <div className="form-group col-md-9 background-field">
                  {captchaId !== 0 && matchingBaseUrl ? (
                    <img src={captchaImageUrl} id="captchaimage" />
                  ) : captchaId !== 0 ? (
                    <img
                      src={`/oas/api/v1/otps/${captchaId}`}
                      id="captchaimage"
                    />
                  ) : (
                    ""
                  )}
                  <span className="ml-3">
                    {" "}
                    <i
                      className="fa fa-refresh fa-lg cursor-pointer"
                      onClick={() => {
                        dispatch(generateCaptcha());
                      }}
                    />
                  </span>
                  <label className="LeftText">
                    {" "}
                    {login.ENTER_THE_TEXT_AS_ABOVE_IMAGE}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput2"
                    name="enteredCaptcha"
                    autoComplete="off"
                    ref={register({ required: true })}
                    onChange={(e) => setDetails(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {_.get("enteredCaptcha.type", errors) === "required" && (
                    <p className="errorText">
                      {login.validationMessage.FIELD_REQ}
                    </p>
                  )}
                </div>
                <div className="form-group col-md-9 d-flex justify-content-center">
                  <button
                    type="button"
                    disabled={!formState.isValid}
                    className="btn btn-primary"
                    onClick={onProceed}
                  >
                    {login.Proceed}
                  </button>
                </div>
              </div>
            )}

            <div className="form-group col-md-12 bottomHeight">
              <hr className="ml15 mr15"></hr>
              <div className="d-flex justify-content-center text-dark">
                {" "}
                {login["DON'T_HAVE_AN_ACCOUNT?"]}
              </div>
              <div className="d-flex justify-content-center bold-text">
                {" "}
                <Link
                  to="/candidate/registration"
                  onClick={(e) => {
                    dispatch(
                      checkModuleEnableDisable("USER_REGISTRATION", true)
                    );
                  }}
                >
                  {" "}
                  {login.CREATE_A_NEW_ACCOUNT}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 border border-light"></div>
        </div>
      </div>
      <ForcefullyLogoutModal
        forceFulLogout={forceFulLogout}
        setForceFulLogout={setForceFulLogout}
        forceFulLogoutErrorMessage={forceFulLogoutErrorMessage}
        setForceFulLogoutErrorMessage={setForceFulLogoutErrorMessage}
        usernameForLogin={usernameForLogin}
        setUsernameForLogin={setUsernameForLogin}
      />
    </form>
  );
}
export default Login;
