/* eslint-disable */
import API from "../../helpers/API";
import { generateCaptcha, hideLoader, showLoader } from "../../common/actions/commonActions";
import {
  SET_USER_SESSION,
  LOGOUT_CLEAR_USER_SESSION,
  SET_API_REPO_VERSION,
  SET_SECURITY_ANSWERS_FOR_LOGIN,
  SET_MODULE_LIST,
  SET_LOG_OUT_OTP_DATA,
  GET_LOGIN_STATUS,
} from "../../common/constants/ActionTypes";
import {
  API_REPO_VERSION,
  LOGIN,
  LOGOUT,
  VERIFY_SECURITY_ANSWER,
  UPDATE_SECURITY_ANSWER,
  MODULE_CHECK,
  LOG_OUT_USER_OTP,
  LOG_OUT_OTP_VERIFICATION,
  GET_VERIFY_CAPTCHA,
} from "../../common/constants/endpoints";
import { toastr } from "react-redux-toastr";
import { getSecurityQuestions } from "../../registration_login/actions/registrationActions";
import RegistrationJson from "../labels/registration_en.json";

export const loginAction = (loginDetails, emailOTPResponse,setShowCaptcha) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let request = {
        username: loginDetails.userName.trim(),
        password: loginDetails.Password,
        otpId: emailOTPResponse.id,
        enteredOtp: loginDetails.OTP_email,
        otpType: emailOTPResponse.type,
      };
      let response = await API.post(LOGIN, request);
      if (response && response.data) {
        dispatch(getSecurityQuestions());
        dispatch({
          type:GET_LOGIN_STATUS,
          payload:response.data.message
        })
        if(response.data.message=="success")
        {
          dispatch(generateCaptcha());
          setShowCaptcha(true)
        }else{
          setShowCaptcha(false)
        }
      }
      if (response != null) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      if (
        errorMessage &&
        errorMessage.includes(RegistrationJson.ALREADY_LOGIN_NOTE)
      ) {
        toastr.confirm(errorMessage);
      } else {
        toastr.error(RegistrationJson.LOGIN_FAILED, errorMessage);
      }
    }
  };
};

export const onCaptchaProceed = (
  props,
  loginDetail,
  captchaId,
  userDetail
) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let request = {
        captchaId: captchaId,
        enteredCaptcha: loginDetail,
        username: userDetail.userName.trim(),
        password: userDetail.Password,
      };
      let response = await API.post(GET_VERIFY_CAPTCHA, request);
      if (response && response.data) {
        const token = response.data.jwtResponse.token;
        sessionStorage.setItem("session", JSON.stringify(response.data));
        // const token = response.data.jwtResponse.token;
        let responseForSecurityQuestion = await API.get(
          VERIFY_SECURITY_ANSWER + `?username=${request.username}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (responseForSecurityQuestion != null) {
          dispatch(hideLoader());
        }
        if (responseForSecurityQuestion.status == 200) {
          const session = JSON.parse(sessionStorage.getItem("session"));
          session.securityAnswer = responseForSecurityQuestion.data;
          sessionStorage.setItem("session", JSON.stringify(session));
        }

        dispatch({
          type: SET_SECURITY_ANSWERS_FOR_LOGIN,
          payload: response.data.securityAnswerList,
        });
        dispatch({
          payload: token,
          type: SET_USER_SESSION,
        });
        let message = response.data.message ? response.data.message : "";
        toastr.success(message);
        if (token) {
          props.history.push("/candidate/main");
        }
      }
      if (response != null) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      if (
        errorMessage &&
        errorMessage.includes(RegistrationJson.ALREADY_LOGIN_NOTE)
      ) {
        toastr.confirm(errorMessage);
      } else {
        toastr.error(RegistrationJson.LOGIN_FAILED, errorMessage);
      }
      toastr.error(errorMessage)
    }
  };
};

export const logout = (props, flgRefreshRequired = true) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const session = JSON.parse(sessionStorage.getItem("session"));
      let userId = session.userId;
      let token = session.jwtResponse && session.jwtResponse.token;
      const logoutResponse = await API.patch(
        LOGOUT + `/${userId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (logoutResponse != null) {
        dispatch(hideLoader());
      }
      if (logoutResponse.data) {
        sessionStorage.clear();
        props.history.push("/candidate/login");
        toastr.success(logoutResponse.data.message)
        if (flgRefreshRequired) {
          window.location.reload();
        }
        
        dispatch({
          type: LOGOUT_CLEAR_USER_SESSION,
        });
      } else {
        toastr.error(RegistrationJson.LOGIN_FAILED);
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(RegistrationJson.LOGIN_FAILED, errorMessage);
    }
  };
};

export const getAPIRepoVersion = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    let response = await API.get(API_REPO_VERSION);
    if (response != null) {
      dispatch(hideLoader());
    }
    dispatch({
      type: SET_API_REPO_VERSION,
      payload: response ? response.data : "",
    });
  };
};

export const saveSecurityAnswerForLogin = (props, reqBody) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const session = JSON.parse(sessionStorage.getItem("session"));
      let emailId = session.emailId;
      let token = session.jwtResponse && session.jwtResponse.token;
      reqBody.username = emailId;
      const response = await API.patch(UPDATE_SECURITY_ANSWER, reqBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        dispatch(showLoader());
        toastr.success(response.data);
        let responseForSecurityQuestion = await API.get(
          VERIFY_SECURITY_ANSWER + `?username=${emailId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (responseForSecurityQuestion != null) {
          dispatch(hideLoader());
        }
        if (responseForSecurityQuestion.status == 200) {
          const session = JSON.parse(sessionStorage.getItem("session"));
          session.securityAnswer = responseForSecurityQuestion.data;
          sessionStorage.setItem("session", JSON.stringify(session));
        }
        props.history.push("/candidate/main");
      }
      if (response != null) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
    }
  };
};

export const setSecurityAnswerForLogin = () => {
  return async (dispatch) => {
    const session = JSON.parse(sessionStorage.getItem("session"));
    let securityAnswer = session && session.securityAnswerList;
    dispatch({
      type: SET_SECURITY_ANSWERS_FOR_LOGIN,
      payload: securityAnswer,
    });
  };
};

export const checkModuleEnableForTabs = () => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const session = JSON.parse(sessionStorage.getItem("session"));
      let token = session.jwtResponse && session.jwtResponse.token;
      let response = await API.get(MODULE_CHECK, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response != null) {
        dispatch(hideLoader());
      }
      if (response.data) {
        dispatch({
          type: SET_MODULE_LIST,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const logOutUserOtp = (userName) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let request = {
        username: userName,
      };
      let response = await API.post(LOG_OUT_USER_OTP, request);
      if (response.data) {
        dispatch({
          payload: response.data,
          type: SET_LOG_OUT_OTP_DATA,
        });
      }
      if (response != null) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(hideLoader());

      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};

export const verificationOfLogoutOTP = (reqBody, logoutOtpData) => {
  let request = {
    otpId: logoutOtpData.id,
    enteredOtp: reqBody.OTP_email,
  };
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      let response = await API.post(LOG_OUT_OTP_VERIFICATION, request);
      if (response != null) {
        dispatch(hideLoader());
      }
      if (response.data) {
        toastr.success(
          RegistrationJson.OTP_VERIFICATION_SUCCESSFUL + response.data
        );
        window.setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        toastr.error(RegistrationJson.OTP_VERIFICATION_UNSUCCESSFUL);
      }
    } catch (error) {
      dispatch(hideLoader());
      let errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      toastr.error(errorMessage);
      return;
    }
  };
};
