import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { reducer as toastrReducer } from "react-redux-toastr";

import registrationReducer from "./registration_login/reducers/registrationReducer";
import loginReducer from "./registration_login/reducers/loginReducer";
import commonReducer from "./common/reducers/commonReducer";
import profileReducer from "./candidate_profile_creation/reducers/profileReducer";
import forgotPasswordReducer from "./registration_login/reducers/forgotPasswordReducer";
import advertisementReducer from "./application_management/reducers/advertisementReducer.js";
import myAccountReducer from "./my_account/reducers/myAccountReducer.js";
import homeReducer from "./home/reducers/homeReducer";
import correspondenceReducer from "./correspondence/reducers/correspondenceReducer"

const rootReducer = combineReducers({
    toastr: toastrReducer,
    registration: registrationReducer,
    loginReducer: loginReducer,
    profile: profileReducer,
    common: commonReducer,
    forgotPasswordReducer: forgotPasswordReducer,
    advertisement: advertisementReducer,
    myaccount: myAccountReducer,
    home:homeReducer,
    correspondence :correspondenceReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
