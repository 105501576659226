/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../styles/candidate_nav.css";
import "../../styles/my_account.css";
import { Modal } from 'react-bootstrap';
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";
import '../../styles/advertisement.css';

function ReviewSelectedModal(props) {
    const currentPostId = props.currentPostId;
    const [mumbaiDataList, setmumbaiDataList] = useState([]);
    const [outsideMumbaiDataList, setoutsideMumbaiDataList] = useState([]);
    const [reloadItem, setReloadItem] = useState(0);
    const allOptions = props.options;

    useEffect(()=>{
        allOptions && allOptions.length > 0 && allOptions.filter((ele)=>ele.pid==currentPostId).map((elem)=>{
                if(elem.pid==currentPostId)
                {
                    elem.selectedDepartment && elem.selectedDepartment.length > 0 && elem.selectedDepartment.map((dep)=>{
                        if(dep.isMumbai==true)
                        {
                            setmumbaiDataList((arr)=>[...arr,dep]); 
                            setReloadItem(!reloadItem);
                        }
                        else
                        {
                            setoutsideMumbaiDataList((arr)=>[...arr,dep]);
                            setReloadItem(!reloadItem);
                        }
                    })  
                }
        })
    },[props])
 
    const modalCancel = () => {
        props.setReviewDepartmentModal(false)
    };

    return (
        <form className="MarksheetModel mt-5">
            <div>
                <Modal show={props.showModal} onHide={modalCancel} backdrop='static' size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{advertisementDetailsLabel.REVIEW_SELECTED_APPOINTING_AUTHORITIES}</Modal.Title>
                    </Modal.Header>
                    <div>
                        <Modal.Body>
                            <div className="row">
                                <hr />
                                <div className="card-body p-0 pre-wrap">

                                {((!mumbaiDataList || mumbaiDataList.length <= 0) && (!outsideMumbaiDataList || outsideMumbaiDataList.length <= 0) ) ?
                                                <h6 className="text-muted text-center">{advertisementDetailsLabel.NO_APPOINTING_AUTHORITIES_SELECTED}</h6>
                                           : 
                                mumbaiDataList && mumbaiDataList.length > 0 && 
                                    <table className="table table-bordered">
                                        
                                        <thead>
                                            <tr>
                                                <th scope="col" key={2} className="word-break w60"> {advertisementDetailsLabel.MUMBAI_APPOINTING_AUTHORITIES} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mumbaiDataList && mumbaiDataList.map((e) => (
                                                <tr key={e.id}>
                                                    <td className="word-break">{e.label}</td>
                                                </tr>
                                            ))}
                                            
                                        </tbody>
                                        </table>

}
                                {outsideMumbaiDataList && outsideMumbaiDataList.length > 0 && 
                                        <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" key={2} className="word-break w60"> {advertisementDetailsLabel.OTHER_THAN_MUMBAI_APPOINTING_AUTHORITIES} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {outsideMumbaiDataList && outsideMumbaiDataList.map((e) => (
                                                <tr key={e.id}>
                                                    <td className="word-break">{e.label}</td>
                                                </tr>
                                            ))}
                                          
                                        </tbody>
                                    </table>
}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={modalCancel} >Close</button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </form>
    );
}
export default ReviewSelectedModal;