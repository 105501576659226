/* eslint-disable */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import sportsInformationLable from "../labels/sports_details_en.json";
import { editSportsDtails } from  "../actions/profileAction";
import dayjs from 'dayjs';
import { setSportsDetails,clearSportForm } from  "../actions/profileAction";
import common from "../labels/common_en.json";
import { Modal } from 'react-bootstrap';
import _ from "lodash/fp";
import { getLookupOptionLabel } from "../../helpers/LookupOptionHelper";
import "../../styles/advertisementTable.css";
import commonLabels from "../../my_account/labels/common_en.json";

const SportsTable = () => {
    let { meritoriousSportsmanDtoList, profileCreationAllLookupData, profileStatus } = useSelector(state => state.profile, shallowEqual);
    const dispatch = useDispatch();
    const editSportsData = (data, index) => {
        dispatch(editSportsDtails(data, index));
    }
    let [ isShowModal, setShowModal ] = useState(false);
    let [ deleteIndex, setDeleteIndex ] = useState("");
    let [sportsList, setSportsList] = useState([])

    const modalConfirm = () => {
        meritoriousSportsmanDtoList[deleteIndex].flgDeleted = true;
        sportsList.splice(deleteIndex, 1)
        dispatch(setSportsDetails(meritoriousSportsmanDtoList));
        dispatch(clearSportForm(true));
        setDeleteIndex("");
        setShowModal(false);
      }

    useEffect(() => {
        if(meritoriousSportsmanDtoList){
            let list = []
            meritoriousSportsmanDtoList.map(ele => {
            if(!ele.flgDeleted){
                list.push(ele)
            }
            })
            setSportsList(list)
        }
    }, [meritoriousSportsmanDtoList])
    
      const getEligibility = (eligibility) => {
          let list = []
        eligibility.forEach((ele, index) => {
            if(ele && index === 0){
                list.push('A') 
            }
            if(ele && index === 1){
                list.push('B') 
            }
            if(ele && index === 2){
                list.push('C') 
            }
            if(ele && index === 3){
                list.push('D') 
            }
        })
        return list.toString()
      }
    

    return (
        <div className="text-center overflow-auto">
            <table className="table table-bordered m-0">
                <thead className="thead-light">
                    <tr>
                        {sportsInformationLable.sportsHeaderContent && sportsInformationLable.sportsHeaderContent.map((ele, index) =>
                            <th scope="col" key={index}>{ele}</th>
                        )}
                    </tr>
                </thead>
                <tbody className="f12">
                    {
                        sportsList && sportsList.length > 0 && sportsList.map((ele, index) => {
                            if (!ele.flgDeleted) {
                                return (
                                    <tr key={index}>
                                        <td className="w-10 word-break text-center">{index + 1}</td>
                                        <td className="word-break">{getLookupOptionLabel(ele.typeOfCompetitionLookupId, profileCreationAllLookupData)}</td>
                                        <td className="word-break">{getLookupOptionLabel(ele.nameOfCompetitionLookupId, profileCreationAllLookupData)}</td>
                                        <td className="word-break">{getLookupOptionLabel(ele.positionMedalLookupId, profileCreationAllLookupData)}</td>
                                        <td className="word-break">{ele.tournamentStartDate ? dayjs(ele.tournamentStartDate).format(commonLabels.DATE_FORMAT): ""}</td>
                                        <td className="word-break">{ele.tournamentEndDate ? dayjs(ele.tournamentEndDate).format(commonLabels.DATE_FORMAT): ""}</td>
                                        <td className="word-break">{ele.certificateVerificationStatus != undefined ? (ele.certificateVerificationStatus === true ? "Yes": "No") : "-"}</td>
                                        <td className="word-break">{getLookupOptionLabel(ele.sportsCertiVerificationCityLookupId, profileCreationAllLookupData)}</td>
                                        <td className="word-break">{ele.sportsCertiReportNo ? ele.sportsCertiReportNo : "-"}</td>
                                        <td className="word-break">{ele.dateOfIssue ? dayjs(ele.dateOfIssue).format(commonLabels.DATE_FORMAT): "-"}</td>
                                        <td className="word-break">{ele.acknowledgementNumber ? ele.acknowledgementNumber: '-' }</td>
                                        <td className="word-break">{ele.dateOfSubmission ? dayjs(ele.dateOfSubmission).format(commonLabels.DATE_FORMAT): "-"}</td>
                                        <td className="word-break">{getEligibility(ele.eligibleForGroup)}</td>
                                        <td className="word-break"><i className={`fa fa-lg btn-link btn fa-edit ${profileStatus ? "disabled-link" : ""}`}
                                                data-toggle="tooltip" 
                                                title={profileStatus ? common.EDIT_DISALLOWED_TOOLTIP : common.EDIT_TOOLTIP}
                                                onClick={() =>editSportsData(ele, index)}/></td>
                                        <td className="word-break"><i className={`fa fa-lg btn-link btn fa-trash ${profileStatus ? "disabled-link" : ""}`}
                                                data-toggle="tooltip" 
                                                title={profileStatus ? common.DELETE_DISALLOWED_TOOLTIP : common.DELETE_TOOLTIP}
                                                onClick={() => { setShowModal(true); setDeleteIndex(index); }} /></td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
            </table>
            {(sportsList && sportsList.length === 0) || (sportsList.find( ele => ele['flgDeleted'] === false).length > 0) ?
         <h6 className="text-muted text-center">{commonLabels.NO_DATA_TO_DISPLAY}</h6>:""}
            <Modal show={isShowModal} onHide={() => { setShowModal(false) }}  backdrop='static'>
                <Modal.Header closeButton>
                    <Modal.Title>{sportsInformationLable.DELETE_SPORT_CONFIRMATION_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>{sportsInformationLable.DELETE_SPORT_CONFIRMATION_MESSAGE}</label>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => { setShowModal(false) }}>{common.CANCEL}</button>
                    <button className="btn btn-primary" onClick={modalConfirm}>{common.CONFIRM}</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SportsTable;