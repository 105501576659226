/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../styles/forgot_password.css';
import _ from "lodash/fp";
import ForgotPasswordJson from "../labels/forgot_password_en.json";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { resetPassword, hideResetPasswordScreen } from "../actions/forgotPasswordAction";
import { getSecurityQuestions } from "../actions/registrationActions";
import { securityQuestionRegex , numberValidation } from "../../common/constants/regex"
import RegistrationJson from "../labels/registration_en.json";
import "../../styles/candidate_nav.css";
import "../../styles/advertisementTable.css";

function ResetPassword(props) {
    const { register, handleSubmit, errors, watch } = useForm({
        mode: "onChange"
    });

    let forgetPasswordDetails= useSelector(state => state.forgotPasswordReducer.forgetPasswordDetails)
    const questions = useSelector(state => state.registration.securityQueForgotPassword);
    const securityAnswerMap = useSelector(state => state.forgotPasswordReducer.securityAnswerMap)
    const dispatch = useDispatch();
    const [confirmPasswordFlag, setConfirmPasswordFlag ]= useState(false)
    const [securityAnswer, setSecurityAnswer ]= useState(new Map())
   
    useEffect(() => {
        if(securityAnswerMap){
            let securityAnswer = new Map(Object.entries(securityAnswerMap));
            setSecurityAnswer(securityAnswer)
        }
    }, [securityAnswerMap])

    const onSubmit = (data) => {
        if(!confirmPasswordFlag){
            let formDataMap = new Map(Object.entries(data));
            let SQList = Object.keys(questions);
            let obj = new Map()
            SQList.map(ele => {
                if(securityAnswer.has(ele) && securityAnswer.get(ele) != null){
                    obj.set(ele, securityAnswer.get(ele))
                }else{
                    obj.set(ele, formDataMap.get(ele))
                }
            })
            let securityAnswersDto = Object.fromEntries(obj.entries());
            let reqBody = {
                email: forgetPasswordDetails.email, 
                password: data.password,
                emailOtpId:forgetPasswordDetails.emailOtpId,
                enteredEmailOtp: forgetPasswordDetails.enteredEmailOtp,
                type:forgetPasswordDetails.type,
                securityAnswersDto: securityAnswersDto
            }
            dispatch(resetPassword(reqBody, props))
        }
    }  

    useEffect(() => {
        dispatch(getSecurityQuestions());
    }, [])

    const checkValidity = (evt) => {
        if(evt.target.value === watch('password') ){
            setConfirmPasswordFlag(false)
        }else{
            setConfirmPasswordFlag(true)
        }
    }

    const reDirectToLogIn = () => {
        props.history.push("/candidate/login");
        dispatch(hideResetPasswordScreen())
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row mt50 mb50">
            <div className="col-md-3"></div>
            <div className="col-md-6 forgotPassTabCss">
                <div className="text-center" >
                    <span className="bold-text">{ForgotPasswordJson.Reset_Password}</span>
                </div>
                <div>
                    <label className="LeftText">{ForgotPasswordJson.Password}<span className="errorText">*</span></label>
                    <input className="form-control" id="registrationFormControlInput1" type="password" name="password" 
                     ref={register({ required: true, minLength: 8, maxLength: 15,
                        validate: {
                         oneCapitalLetter: (value) => /(?![.\n])(?=.*[A-Z]).*$/.test(value),
                         oneNumber: (value) => /((?=.*\d)|(?=.*\W+)).*$/.test(value),
                         oneSpecialCharacter: (value) => /(?=.*[!@#\$%\^&\*])(?=.{8,}$)/.test(value),
                       }
                       })} />
                    {_.get("password.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.PASSWORD_REQ}</p>
                    )}
                    {_.get("password.type", errors) === "minLength" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MIN_LENGTH_VALIDATION}</p>
                    )}
                    {_.get("password.type", errors) === "maxLength" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MAX_LENGTH_VALIDATION}</p>
                    )}
                    {errors.password?.type === "oneCapitalLetter" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MIN_ONE_CAPITAL_LETTER}</p>
                    )}
                    {errors.password?.type === "oneNumber" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MIN_ONE_NUMBER}</p>
                    )}
                    {errors.password?.type === "oneSpecialCharacter" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.MIN_ONE_SPECIAL_CHAR}</p>
                    )}
                    {errors.password && (
                        <p className="errorText">{errors.password.message}</p>
                    )}
                </div>
                <div>
                    <label className="LeftText">{ForgotPasswordJson.Confirm_Password}<span className="errorText">*</span></label>
                    <input className="form-control" id="registrationFormControlInput1" type="password" name="confirm_password" ref={register({ required: true})}
                     onChange={checkValidity}
                      />
                    {_.get("confirm_password.type", errors) === "required" && (
                        <p className="errorText">{ForgotPasswordJson.validationMessage.CONFIRM_PASSWORD_REQUIRED}</p>
                    )}
                    {confirmPasswordFlag && _.get("confirm_password.type", errors) !== "required" ? <p className="errorText">{RegistrationJson.NEW_PASS_AND_CONFIRM_PASS_NOTE}</p> : ''}
                </div>
                <div>
                    {(Object.entries(securityAnswerMap).length === Object.entries(questions).length && Object.values(securityAnswerMap).every(a => a != null)) ? ""  :
                    <div className="background-field mt-3">
                        <span className="errorText text-primary">{ForgotPasswordJson.SECURITY_QUESTIONS_NOTE}</span>
                    </div>}
                    <div className="form-group">
                        {Object.entries(questions).sort().map(([key, value]) => {
                        return(
                                <div>
                                    {securityAnswer && securityAnswer.has(key) && (securityAnswer.get(key) != undefined || securityAnswer.get(key) != null) ?
                                     "" :
                                    <div className="row form-group" key={key}>
                                        <div className="col-md-8 d-flex align-items-center">
                                            <label>{value}<span className="errorText">*</span></label>
                                        </div>
                                        {key === 'SQ3' ?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" name={key} id="sq" ref={register({required: true})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                        </div>: ""}
                                        {key === 'SQ4' ?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" maxLength={4} name={key} id="sq" ref={register({required: true, pattern:numberValidation})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                            {_.get(key + ".type", errors) === "pattern" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.VALID_VALUE}</p>
                                            )}
                                        </div>: ""}
                                        {key === ('SQ1') || key === ('SQ2') || key === ('SQ5') || key === ('SQ6')?
                                        <div className="col-md-4 otherFormMarginBottom">
                                            <input type="text" className="form-control" name={key} id="sq" ref={register({required: true, pattern: securityQuestionRegex})} />
                                            {_.get(key + ".type", errors) === "required" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.FIELD_REQ}</p>
                                            )}
                                            {_.get(key + ".type", errors) === "pattern" && (
                                            <p className="errorText">{ForgotPasswordJson.validationMessage.VALID_VALUE}</p>
                                            )}
                                        </div>: ""}
                                    </div> 
                                        }
                                </div>)
                        })}
                    </div>
                </div>
                <div className="buttonAlign">
                    <button type="submit" className="btn btn-primary mr-1">{ForgotPasswordJson.Reset}</button>
                    <button type="button" className="btn btn-primary" onClick={reDirectToLogIn}>{ForgotPasswordJson.CANCEL}</button>
                </div>
            </div>
        </form>
    );
}
export default ResetPassword;