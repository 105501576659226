/* eslint-disable */
import React, { useEffect, useState } from "react";
import '../../styles/advertisement.css';
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";
import common from "../labels/common_en.json";
import DocumentList from "./DocumentList";
import ReviewSelectedModal from "./ReviewSelectedModal";
import { toastr } from "react-redux-toastr";
import dayjs from 'dayjs';
import { getAdvertisementDetails, getCheckEligibility, resetApplicationPostPref, downloadAdvertisementPdf,
        doApply, doPayment, downloadDocument, postUploadDocument, getUploadDocument, resetUploadeDocumentChecks , closePaymentModal, getDepartments,getAllDepartments } from '../actions/advertisementAction';
import ProfileDetails  from './ProfileDetails';
import QuestionDetails  from './QuestionDetails';
import { BOOLEAN_OPTION , SCRIBE_OPTION} from '../../common/constants/dropdownOptions';
import { Modal } from 'react-bootstrap';
import Select, { components } from "react-select";
import { hideLoader, showLoader } from '../../common/actions/commonActions';
import commonLabels from "../../my_account/labels/common_en.json";
import myAccountLabels from "../../my_account/labels/my_account_en.json";

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";
  
    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex "
    };
  
    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };
    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" className="mr-2" checked={isSelected}/>
        {children}
      </components.Option>
    );
  };
const AdvertisementDetails = () => {

    const [dummy,setDummy] = useState(0);
    const [isNext,setIsNext] = useState(false);
    const [selectedExamCenters, setSelectedExamCenters] = useState('');
    const [declAccepted,setDeclAccepted] = useState(false);
    const [additionalAnswers, additionalAnswersState] = useState({});
    const [location, setLocation] = useState('');
    const [flgDisabilityCompTime, setFlgDisabilityCompTime] = useState('');
    const [flgDisabilityScribeReq, setFlgDisabilityScribeReq] = useState('');
    const [scribeArrangedBy, setScribeArrangedBy] = useState('');
    const [showDocListModal, setshowDocListModal] = useState(false);
    const [singleDocList ,setSingleDocList ] = useState("");
    let [showChallan, setShowChallan] = useState(true);
    let [showOnlinePayment, setShowOnlinePayment] = useState(true);
    let [showSbi, setShowSbi] = useState(true);
    let [showBillDesk, setShowBillDesk] = useState(true);  
    let [showEaseBuzz, setShowEaseBuzz] = useState(true);
    let [showCsc, setShowCsc] = useState(true);
    let [showQuickwallet, setShowQuickwallet] = useState(true);
    const [selectedOptionErr, setSelectedOptionErr] = useState(false);
    const [selectedDepartmentArr, setSelectedDepartmentArr] = useState([]);
    const [selectedTempDepartmentArr, setSelectedTempDepartmentArr] = useState([]);
    const [reviewDepartmentModal, setReviewDepartmentModal] = useState(false);
    const [deptOptions, setDeptOptions]= useState([]);
    const [currentPostId, setOpenModelPostid]= useState([]);
    const [reloadItem, setReloadItem] = useState(0);
    const [someOptionsThisPost, setSomeOptionsThisPost]= useState([]);
    
    const handleQuestions = (evt) => {
        const value = evt.target.value;
        additionalAnswersState({
            ...additionalAnswers,
            [evt.target.name]: value,
        });
    };
    
    const { register, formState, watch } = useForm({
        mode: "onChange"
    });
    let { advertisementDetailsInfo, advertisementId, eligibilityResultList,applicableAdditionalQuestionsList, applicationPostPrefList, examCenterOptionList, profileInfo, 
        applicationFee, proceedToPayment,applyedApplication , uploadedDocumentData, setCheckEligibilty, allDepartments,allPostsDepartments } = useSelector(state => state.advertisement, shallowEqual);
     const dispatch = useDispatch();
    const maximumNoCenter=1    
    const challanDownloadDate = applyedApplication?.challanDownloadEndDate != null ?  applyedApplication?.challanDownloadEndDate : applyedApplication?.applnEndDate    

    useEffect(()=>{
        setDeptOptions([]);
        applicationPostPrefList && applicationPostPrefList?.map((postPref, index) =>{
                allPostsDepartments?.map((pos,ind)=>{
                    if(pos.postCriteriaId==postPref.postCriteriaId)
                    {
                        let opt = { 
                            'pid' : postPref.postCriteriaId,
                            "options":pos.appointintAuthorities
                        }
                        setDeptOptions((arr)=>[...arr,opt])
                    }
                })
            }
            )
    }, [allPostsDepartments,applicationPostPrefList]);

    useEffect(() => {
        dispatch(getAdvertisementDetails(advertisementId));
        dispatch(getAllDepartments(advertisementId))
    }, []);

    const downloadPdfFile = () =>{
        dispatch(downloadAdvertisementPdf(advertisementId));
    };

    const checkEligibility = () =>{
        dispatch(getCheckEligibility(advertisementId));        
    };

    const computeIsEligible = (postCriteriaId) => {
        const eligibilityResult = eligibilityResultList?.find(elgbResult => elgbResult.postCriteriaId == postCriteriaId);
        if (!eligibilityResult) {
            return null;
        }
        return eligibilityResult.isEligible;
    };

    const computeIsEligibleMsg = (postCriteriaId) => {
        const eligibilityResult = eligibilityResultList?.find(elgbResult => elgbResult.postCriteriaId == postCriteriaId);
        if (!eligibilityResult) {
            return null;
        }
        return eligibilityResult.message;
    };

    const getElibilityIndicator = (postCriteriaId) => {
        const isEligible = computeIsEligible(postCriteriaId);
        const message = computeIsEligibleMsg(postCriteriaId);
        const text = message && message.split("\n")
        if (isEligible == null) {
            return;
        }
        return isEligible ?
            <span className="text-success">{advertisementDetailsLabel.YouAreEligiblePost}</span>
            :
            <span className="text-danger">
                <p className="text-danger">{advertisementDetailsLabel.YouAreNotEligiblePost}</p> {text.map((ele, i) => (
                    <p key={i} className="mb-n1">{ele}</p>))}
            </span>
    }

    const getPostName = (postCriteriaId, isDvng) => {
        const examPost = advertisementDetailsInfo?.data?.examPostList?.find(examPost => examPost.postCriteria.id == postCriteriaId);
        if (!examPost) {
            return `Unknown (${postCriteriaId})`
        }
        return isDvng ? examPost.postCriteria.nameDvng : examPost.postCriteria.name;
    }

    const nextApply = () => {
        setIsNext(true);
        let post = applicationPostPrefList
            .filter(pref => pref.isInterested)
            .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
            dispatch(getUploadDocument(advertisementId,post))
    };

    const doNext = () => {
        nextApply();
    };
    
    useEffect(() => { }, [reloadItem]);
    
    const handlePrefChange = (postPref, name, value,postCritId) => {
        postPref[name] = value;
        setDummy(dummy+1);
        const newArr = [];
        applicationPostPrefList.find((e)=>{
            if(e.isInterested == true && e.isAssignAuthority == true){
                newArr.push(e.postCriteriaId);
            }
            else if(e.isInterested == false && e.isAssignAuthority == true){
                removeFromSelectedDeptArray(postPref);
                let uniqueChars = [];
                someOptionsThisPost.forEach((c) => {
                    if (!uniqueChars.includes(c)) {
                        setSomeOptionsThisPost(uniqueChars);
                    }
                });
            };
        });
        if(newArr.length > 0 && (someOptionsThisPost && someOptionsThisPost.indexOf(postCritId) != -1)){
            setSelectedOptionErr(false)
        }else if(newArr.length > 0){
            setSelectedOptionErr(true)
        }
        else{
            setSelectedOptionErr(false)
        };
        deptOptions.filter((ele)=>ele.pid==postCritId).map((elem,index)=>{
            if(elem.options.mumbai.length==0 && elem.options.outsideMumbai.length== 0)
            {
                setSelectedOptionErr(false)
            }
        });
        if(postPref.isInterested === false){
            removeFromSelectedDeptArray(postPref);
        }; 

        if(postPref.isAssignAuthority === true && postPref.isInterested === true){
            dispatch(getDepartments(postPref.postCriteriaId))
        };
        applicationPostPrefList.filter((e)=>{
            if(e.postCriteriaId === postPref.postCriteriaId){
                if((e.isInterested == false && (e.isAssignAuthority == true || e.isAssignAuthority == false) &&  e.preferredAppointingAuthorities) ||( e.isInterested == true && e.isAssignAuthority == false && !e.preferredAppointingAuthorities)){
                    setSelectedOptionErr(true);
                }
                else
                if((e.isInterested == true && e.isAssignAuthority == true && !e.preferredAppointingAuthorities) || (e.isInterested == false)){
                    setSelectedOptionErr(false);
                }
            }else if(e.postCriteriaId !== postPref.postCriteriaId){
                if(e.isInterested == true && e.isAssignAuthority == true && !e.preferredAppointingAuthorities){
                    setSelectedOptionErr(false);
                }
            }
            if((e.isInterested == true && e.isAssignAuthority == true && someOptionsThisPost.length <=0)){
                setSelectedOptionErr(false);
            }
        });
    };

        const handleDocChange= (doc, isUploaded, value) => {
         doc[isUploaded] = value;
         setDummy(dummy+1);
    };

    const removeFromSelectedDeptArray =(postData)=>{
        let newArray = selectedTempDepartmentArr && selectedTempDepartmentArr.filter((eleN)=>eleN.pid!=postData.postCriteriaId);
        setSelectedTempDepartmentArr(newArray);
    };

    const applyInfo = () => {
        const foundTrueValue = uploadedDocumentData && uploadedDocumentData.find(element => !element. isUploaded );
        if(foundTrueValue){
            toastr.error(advertisementDetailsLabel.UPLOAD_DOC_INFO_NOTE);
        }
         else {
            let additionalAnswers1;
            if (additionalAnswers.length == 0) { additionalAnswers1 = null; } else { additionalAnswers1 = additionalAnswers; }
            const applnData = {
                advertisementId,
              location,
                additionalAnswers: JSON.stringify(additionalAnswers1),
                applicationPostPrefList:
                    applicationPostPrefList
                        .filter(pref => pref.isInterested)
                        .map((pref, index) => ({ ...pref, prefOrder: index + 1, preferredAppointingAuthorities:selectedDepartmentArr.length > 0 ?  JSON.stringify(selectedDepartmentArr.filter((ele)=>ele.pid==pref.postCriteriaId).map((elem)=>elem.selectedDepartment && elem.selectedDepartment.length > 0 && elem.selectedDepartment.map((dep)=>dep.id))[0]) : null })),
                applicationExamCenterPrefList:
                    selectedExamCenters
                        .map(examCenterOption => ({ examCenterId: examCenterOption.value })),
                flgDisabilityCompTime,
                flgDisabilityScribeReq,
                scribeArrangedBy,
                amountPayable: applicationFee,
                requiredDocumentsSelected:
                uploadedDocumentData
                        .map((doc) => ({ ...doc, documentInfoId:doc.isUploaded ? doc.documentInfoId:null })),
                additionalQuestions: JSON.stringify(applicableAdditionalQuestionsList)
            }
            dispatch(doApply(applnData));
                const enabledPaymentMethods  = JSON.parse(advertisementDetailsInfo &&advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementConfig && advertisementDetailsInfo.data.advertisementConfig.enabledPaymentMethods);

                const disabledPaymentMethods = JSON.parse(advertisementDetailsInfo &&advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementConfig && advertisementDetailsInfo.data.advertisementConfig.disabledPaymentMethods)

                if (enabledPaymentMethods !== null  ) {
                    
                    setShowChallan( enabledPaymentMethods.includes("challan"));

                    setShowOnlinePayment(enabledPaymentMethods.includes("sbiepay") ||
                    enabledPaymentMethods.includes("billdesk") ||
                    enabledPaymentMethods.includes("csc") ||
                    enabledPaymentMethods.includes("easebuzz")  ||
                    enabledPaymentMethods.includes("quikwallet"));

                    setShowSbi(enabledPaymentMethods.includes("sbiepay"));
                    setShowBillDesk(enabledPaymentMethods.includes("billdesk"));
                    setShowEaseBuzz(enabledPaymentMethods.includes("easebuzz"));
                    setShowQuickwallet(enabledPaymentMethods.includes("quikwallet"));
                    setShowCsc(enabledPaymentMethods.includes("csc"));
                    
                }
                if (disabledPaymentMethods !== null) {
                    
                    setShowChallan(!disabledPaymentMethods.includes("challan"));

                    setShowOnlinePayment(!disabledPaymentMethods.includes("sbiepay") ||
                    !disabledPaymentMethods.includes("billdesk") ||
                    !disabledPaymentMethods.includes("csc") ||
                    !disabledPaymentMethods.includes("quikwallet") ||
                    !disabledPaymentMethods.includes("easebuzz"));
                    
                    setShowSbi(!disabledPaymentMethods.includes("sbiepay"));
                    setShowBillDesk(!disabledPaymentMethods.includes("billdesk"));
                    setShowEaseBuzz(!disabledPaymentMethods.includes("easebuzz"));
                    setShowQuickwallet(!disabledPaymentMethods.includes("quikwallet"));
                    setShowCsc(!disabledPaymentMethods.includes("csc"));
                    
                }                  
        }
    };

    const onApply = () => {
        applyInfo();
    };

    const goBack = () => {
        setSelectedExamCenters('')
        setDeclAccepted(false)
        setLocation('')
        setFlgDisabilityCompTime('')
        setFlgDisabilityScribeReq('')
        setScribeArrangedBy('')
        setIsNext(false);
       dispatch(resetUploadeDocumentChecks())
    }

    const movePostPrefUp = (index) => {
        if (index == 0) return;
        const tmp = applicationPostPrefList[index];
        applicationPostPrefList[index] = applicationPostPrefList[index-1];
        applicationPostPrefList[index-1] = tmp;
        setDummy(dummy+1);
    }

    const movePostPrefDown = (index) => {
        if (index >= (applicationPostPrefList.length)) return;
        const tmp = applicationPostPrefList[index];
        applicationPostPrefList[index] = applicationPostPrefList[index+1];
        applicationPostPrefList[index+1] = tmp;
        setDummy(dummy+1);
    };

    const resetPref = () => {
        dispatch(resetApplicationPostPref());
        setSelectedDepartmentArr([]);
        setSomeOptionsThisPost([]);
    };

    const onChangeDocument = (event,doc) => {
        let post = applicationPostPrefList
            .filter(pref => pref.isInterested)
            .map((pref, index) => ({ ...pref, prefOrder: index + 1 }))
            if(event.target.files.length == 0) {
              return
            }
            let requestBody={}
            requestBody.uploadPdfDoc =   event.target.files[0]
            requestBody.documentTypeId = doc.documentTypeId
            dispatch(postUploadDocument(requestBody,advertisementId,post))  
    }
        
    const viewDocument = (doc) => {
        dispatch(downloadDocument(doc.documentInfoId,doc.documentTypeName))
    }
    
    const handleDocumentListShow = (docList) => {
        setshowDocListModal(true)
        setSingleDocList(docList) 
    };

    const processToPayment =(paymentData,paymenttype) =>{
        let data={}
        data.paymentBank= watch("paymentBank")
        if(paymenttype){
            dispatch(doPayment(paymentData.applicationId,paymenttype, data))
        }
        dispatch(closePaymentModal(false))
    }  

    const handleOptionsChange = (value, postPrefId) => {
        const tempArr = [];
        if(value.length > 0){
            setSelectedOptionErr(true);
        value.map((e)=>{
            if(e.id){
                tempArr.push(e.id);
            }
        });
        
        let alreadyArray = selectedTempDepartmentArr && selectedTempDepartmentArr.filter((eleN)=>eleN.pid==postPrefId);
                                if(alreadyArray.length > 0 )
                                {
                                    let newArray =[];
                                    selectedTempDepartmentArr && selectedTempDepartmentArr.length > 0 && selectedTempDepartmentArr.map((eleNN,index)=>{
                                        if(eleNN.pid==postPrefId)
                                        {
                                            let arrDep = {
                                                'pid': eleNN.pid,
                                                'selectedDepartment': value
                                            };
                                            
                                            newArray.push(arrDep);
                                        }
                                        else
                                        {
                                            newArray.push(eleNN);
                                        }
                                    })
                                    
                                    setSelectedTempDepartmentArr(newArray);
                                }
                                else
                                {
                                    let arrDep = {
                                        'pid': postPrefId,
                                        'selectedDepartment': value
                                    };
                                    setSelectedTempDepartmentArr((arr)=>[...arr,arrDep]);
                                }
       
        applicationPostPrefList && applicationPostPrefList.map((e)=>{
            if(e.postCriteriaId === postPrefId){
                e.preferredAppointingAuthorities = JSON.stringify(tempArr);
            }else{
                e.preferredAppointingAuthorities = null;
            }
        })

        setSomeOptionsThisPost((arr)=>[...arr,postPrefId]);
        setReloadItem(!reloadItem);
        if(value.length > 0){
            setSelectedOptionErr(true);
        }else{
            setSelectedOptionErr(false);
        }

        }
        else{
            setSelectedOptionErr(false);
            let newOption = someOptionsThisPost && someOptionsThisPost.filter((eleN)=>eleN!=postPrefId);;
            let newArray = selectedTempDepartmentArr && selectedTempDepartmentArr.filter((eleN)=>eleN.pid!=postPrefId);
    
            setSomeOptionsThisPost(newOption);
            
            setSelectedTempDepartmentArr(newArray);
        setReloadItem(!reloadItem);
        }
        
        deptOptions.filter((ele)=>ele.pid==postPrefId).map((elem,index)=>{
           
            if(elem.pid==postPrefId)
            {
               
               if(elem.options.isMultiple === true && elem.options.isSelectAny === false){
                if(value.length > 0){
                    const upatedVal = value;
                    upatedVal.map((e)=>{
                        (elem.options.mumbai).map((ele,index)=>{

                            
                            if(ele.id === e.id && ele.isDisable === false){
                              
                                (elem.options.outsideMumbai).map((ele)=>{
                                    ele.isDisable = true;
                                   
                                });
                            }
                        });
                        (elem.options.outsideMumbai).map((ele)=>{
                            if(ele.id === e.id  && ele.isDisable === false){
                               
                              
                                (elem.options.mumbai).map((ele)=>{
                                    ele.isDisable = true;
                                });
                            }
                        });
                    })
                }else{
                    (elem.options.mumbai).map((ele)=>{
                            ele.isDisable = false;
                        });
                    (elem.options.outsideMumbai).map((ele)=>{
                            ele.isDisable = false;
                    });
                }
                }
                 else if(elem.options.isMultiple === false && elem.options.isSelectAny === true){
                if(value.length > 0){
                    const upatedVal = value;
                    upatedVal.map((e)=>{
                        (elem.options.mumbai).map((ele)=>{
                            if(ele.id === e.id && ele.isDisable === false){
                              
                                (elem.options.outsideMumbai).map((ele)=>{
                                    ele.isDisable = true;
                                });
                            }else{
                                ele.isDisable = true;
                            }
                        });
                        (elem.options.outsideMumbai).map((ele)=>{
                            if(ele.id === e.id  && ele.isDisable === false){
                             
                                (elem.options.mumbai).map((ele)=>{
                                    ele.isDisable = true;
                                });
                            }else{
                                ele.isDisable = true;
                            }
                        });
                    })
                }else{
                    (elem.options.mumbai).map((ele)=>{
                            ele.isDisable = false;
                        });
                    (elem.options.outsideMumbai).map((ele)=>{
                            ele.isDisable = false;
                    });
                };
                }
            }
        })
        if(tempArr.length <= 0){
            toastr.error(advertisementDetailsLabel.PLEASE_SELECT_APPOINTING_AUTHORITY);
            setSelectedOptionErr(false);
        }
    };

  useEffect(()=>{
     setSelectedDepartmentArr(selectedTempDepartmentArr);
  },[selectedTempDepartmentArr]);

  const handleMenuClick = () => {
    if(allDepartments.length === 0){
        dispatch(showLoader())
    }else{
        dispatch(hideLoader())
    }
};

    const showReviewDepartmentModal = (postCriteriaId) => {
       setOpenModelPostid(postCriteriaId);
         setReviewDepartmentModal(true);
         setReloadItem(!reloadItem);
        }
         
        const formatGroupLabel = (data) => (
        <div style={groupStyles}>
          <span>{data.label}</span>
        </div>
      );

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color:'#007bff',
        fontWeight: '600',
      };
      
    return ( 
            <div className="advertisement_container mb-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-9" >
                        <div className="row mar_LeftRight_0" >
                            <div className="col-md-8 advertisement_details_firstrow border border-light">
                                <div className="row">
                                    <div className="col-md-6 font-weight-bold" >{advertisementDetailsLabel.Advertisement_No} {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement ? advertisementDetailsInfo.data.advertisement.advertisementNo:""}</div>
                                </div><hr />
                                <span className="font-weight-bold">{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement ? advertisementDetailsInfo.data.advertisement.name:""}  | {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement ? advertisementDetailsInfo.data.advertisement.nameDvng:""} </span><br />
                                <span className="font-weight-bold"> {advertisementDetailsLabel.EXAMINATION} {advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.exam ? advertisementDetailsInfo.data.advertisement.exam.name:""}</span><br /><br />
                            </div>
                            <div className="col-md-4 advertisement_details_firstrow border border-light">
                                <label className="font-weight-bold">{advertisementDetailsLabel.Start_Date_Application} </label>
                                <span className="float-right">{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.applnStartDate? dayjs(advertisementDetailsInfo.data.advertisement.applnStartDate).format(commonLabels.DATE_FORMAT):""}
                                 </span>
                                <label className="font-weight-bold">{advertisementDetailsLabel.Last_Date_Application}</label><span className="float-right">{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.applnEndDate? dayjs(advertisementDetailsInfo.data.advertisement.applnEndDate).format(commonLabels.DATE_FORMAT):""}</span>
                                <label className="font-weight-bold">{advertisementDetailsLabel.Last_Date_Fees} </label><span className="float-right">{advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisement && advertisementDetailsInfo.data.advertisement.challanPaymentEndDate? dayjs(advertisementDetailsInfo.data.advertisement.challanPaymentEndDate).format(commonLabels.DATE_FORMAT):"" }</span>
                                <label className="font-weight-bold">{advertisementDetailsLabel.Fees_Applicable} </label> <span className="float-right">{applicationFee}</span>
                            </div>
                        </div>
                        <div className="advertisement_details_Eligibility border border-light">
                            <label className="font-weight-bold">{advertisementDetailsLabel.POST}</label>
                            <button type="button" className="btn btn-primary btn float-right" onClick={()=>{checkEligibility()}} disabled={setCheckEligibilty == false ? eligibilityResultList != null : true} >{common.ELIGIBILITY}</button>
                            <hr />
                            {
                                advertisementDetailsInfo?.data?.examPostList?.map(examPost =>
                                    <div key={examPost.id} className="card">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-md-6">
                                                <span>{examPost?.postCriteria?.name}</span>
                                                </div>
                                                <div className="col-md-6">
                                                { getElibilityIndicator(examPost?.postCriteria?.id) }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pre-wrap">
                                            {examPost?.postCriteria?.description}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                         <div >
                        { (eligibilityResultList != null && applicationPostPrefList?.length != 0) && applicableAdditionalQuestionsList &&
                            applicableAdditionalQuestionsList?.length != 0 && <QuestionDetails additionalAnswers={additionalAnswers} handleQuestions={handleQuestions} isNext={isNext} />}
                        </div>
                        <div >
                            <ProfileDetails />
                          </div>
                        <div className="advertisement_details_Eligibility border border-light">
                           <label className="font-weight-bold">{advertisementDetailsLabel.INSTRUCTIONS}</label>
                            <hr />
                            <div className="card">
                                <div className="card-body pad_0">  
                                    <ul className="list-group list-group-flush"> 
                                        { advertisementDetailsInfo.data && advertisementDetailsInfo.data.advertisementInstructionList.map((ele) =>
                                            <li className="list-group-item">
                                                    <span>{ele.instruction}</span>
                                                    <span className="float-right">{ele.instructionDvng}</span>
                                            </li> 
                                        )}
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="card mt-3">
                            <div className="card-body">
                                <span>{advertisementDetailsLabel.Before_Applying_PDF}</span>
                                <div>
                                    <button type="button" className="btn btn-primary m-1" onClick={()=>{downloadPdfFile()}} >{common.Download_PDF}</button>
                                </div>
                            </div>
                        </div>
                        { (eligibilityResultList != null && applicationPostPrefList?.length != 0) &&
                            <>
                            <div className="card mt-2">
                                <div className="card-header">
                                    <span className="font-weight-bold">{advertisementDetailsLabel.Select_and_apply}</span><br/>
                                    <hr className="mar_top_bottom_8"/>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <span>{advertisementDetailsLabel.EligibleFollowingPost}</span>
                                    </li>
                                    { applicationPostPrefList?.map((postPref, index) =>
                                    <div >
                                        <li key={index} className="list-group-item post-pref-item d-flex" >
                                            <div className="flex-grow-1">
                                                <input className="d-inline-block" type="checkbox" name="isInterested" 
                                                    checked={postPref.isInterested} onChange={(event) => handlePrefChange(postPref, 'isInterested', event.target.checked,postPref.postCriteriaId )} disabled={isNext}/> &nbsp;
                                                <span >{getPostName(postPref.postCriteriaId)}</span> <br /> &nbsp; &nbsp;  &nbsp;
                                                <span className="w65">{getPostName(postPref.postCriteriaId, true)}</span>
                                            </div>
                                            <div className="arrange-arrows d-flex flex-column justify-content-between" >
                                                <div>
                                                    { (index > 0) &&
                                                        <i className="fa fa-arrow-up"
                                                            onClick={() => movePostPrefUp(index)}mtitle="Move Up"></i>
                                                    }
                                                </div>
                                                <div>
                                                    { (index < (applicationPostPrefList.length-1)) &&
                                                        <i className="fa fa-arrow-down"
                                                            onClick={() => movePostPrefDown(index)} title="Move Down"></i>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        {(postPref.isInterested === true && postPref.isAssignAuthority === true) &&
                                        <div className="card-body">         
                                            <div className="pad_0 btn-dropdown">
                                                <Select
                                                    isMulti={true}
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    openMenuOnClick={handleMenuClick()}
                                                    placeholder={advertisementDetailsLabel.APPOINTING_AUTHORITIES}
                                                    onChange={(options)=>{handleOptionsChange(options,postPref.postCriteriaId)}}
                                                    formatGroupLabel={formatGroupLabel}
                                                    options={
                                                        [
                                                            { label:advertisementDetailsLabel.MUMBAI_APPOINTING_AUTHORITIES, options: deptOptions.filter((ele)=>ele.pid==postPref.postCriteriaId).map((elem)=>elem.options.mumbai)[0]},
                                                            { label:advertisementDetailsLabel.OTHER_THAN_MUMBAI_APPOINTING_AUTHORITIES, options: deptOptions.filter((ele)=>ele.pid==postPref.postCriteriaId).map((elem)=>elem.options.outsideMumbai)[0]}
                                                        ]
                                                    }
                                                    controlShouldRenderValue={false}
                                                    isOptionDisabled={(option) => option.isDisable}        
                                                    components={{
                                                        Option: InputOption
                                                      }}
                                                />             
                                                    {((someOptionsThisPost && someOptionsThisPost.indexOf(postPref.postCriteriaId) == -1) )? <p className="errorText">{advertisementDetailsLabel.PLEASE_SELECT_APPOINTING_AUTHORITY}</p> : ""}                               
                                            </div>
                                            <button type="button" className="btn btn-primary button_reset_apply review_btn_css" onClick={() => showReviewDepartmentModal(postPref.postCriteriaId)} >{advertisementDetailsLabel.REVIEW_SELECTED_APPOINTING_AUTHORITIES}</button>&nbsp;
                                        </div>}
                                        </div>
                                    )}
                                </ul>
                            </div>
                            </>
                        }
                        { (eligibilityResultList != null && applicationPostPrefList?.length == 0) &&
                            <div className="card mt-2">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <span>{advertisementDetailsLabel.YOU_ARE_NOT_ELIGIBLE_FOR_ANY_POST}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                        { (eligibilityResultList != null && applicationPostPrefList?.length != 0 && !isNext) &&
                            <>
                                <button type="button" className="btn btn-outline-dark button_reset_apply" onClick={resetPref} >{common.RESET}</button>&nbsp;
                                <button type="button" className="btn btn-primary btn button_reset_apply" disabled={ selectedOptionErr === false ? true : false}  onClick={doNext} >{common.APPLY}</button>
                            </>
                        }
                        { isNext &&
                            <>
                            {uploadedDocumentData && uploadedDocumentData.length >0 ?
                        <div className="card mt-2">
                            <div className="card-header pr-0" >
                                <span className="font-weight-bold">{advertisementDetailsLabel.Upload_select_documents} <span className="redColor">*</span></span>
                                <hr className="mar_top_bottom_8"/>
                                <span className="noteColor">{advertisementDetailsLabel.CONFIRM_UPLOAD_DOCUMENT_NOTE} </span>
                            </div>
                            <div className="card-body">
                                <div className="pad_0 btn-dropdown">
                                    {uploadedDocumentData && uploadedDocumentData?.map((doc,i) =>
                                        <li key={i} className="list-group-item post-pref-item d-flex">
                                            <div className="flex-grow-1">
                                                <input className="d-inline-block" type="checkbox" name="isUploaded" checked={doc.isUploaded} 
                                                 onChange={(event) => handleDocChange(doc, 'isUploaded', event.target.checked)}
                                                 disabled={!doc.documentInfoId}
                                               /> &nbsp;

                                               <i className='fa fa-info-circle document_list_css' data-toggle="tooltip" title="Document List"aria-hidden="true" onClick={()=> handleDocumentListShow(doc.documentTypeDescription)} ></i>

                                                <span >{doc.documentTypeName}</span>
                                                <div 
                                                className="float-right" >
                                                    <input type="file" name="document" id={"document"+i} onChange={(event) => onChangeDocument(event,doc)} className="d-none" accept="application/pdf"/>
                                                    {doc.documentInfoId ?
                                                    <label for={"document"+i}  className="pointer-class">{advertisementDetailsLabel.RE_UPLOAD}</label>
                                                    :
                                                    <label for={"document"+i}  className="pointer-class">{advertisementDetailsLabel.UPLOAD}</label>
                                                    }   
                                                   
                                                </div> <br />
                                                <div>
                                                {doc.documentInfoId ?
                                                    <button  type="button" className="pointer-class" onClick={(e)=>{
                                                        viewDocument(doc)
                                                    }}>{advertisementDetailsLabel.VIEW_UPLOADED_DOCUMENT}</button>
                                                    :
                                                    <button  type="button" className="text-danger ml37">{advertisementDetailsLabel.NOT_UPLOADED}</button>
                                                }
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </div>
                            </div>
                        </div>:""}
                                <div className="card mt-2">
                                    <div className="card-header">
                                        <span className="font-weight-bold">{advertisementDetailsLabel.Select_exam_center} <span className="redColor">*</span></span>
                                        <br/>{maximumNoCenter ? <span className="noteColor"> {advertisementDetailsLabel.SELECT_NOTE} {maximumNoCenter} {advertisementDetailsLabel.EXAM_CENTER} </span> : "" }
                                    </div>
                                    <div className="card-body">
                                        <div className="pad_0 btn-dropdown">
                                            <Select
                                                isMulti={true}
                                                isOptionDisabled={() => maximumNoCenter ? selectedExamCenters.length >= maximumNoCenter : false }
                                                value={selectedExamCenters}
                                                onChange={setSelectedExamCenters}
                                                options={examCenterOptionList.filter(examCenter => examCenter.isDeleted == false)} />
                                                 {selectedExamCenters && selectedExamCenters.length >= 1 ? '' : <p className="errorText">{advertisementDetailsLabel.SELECT_OPTIONS}</p>}
                                        </div>
                                    </div>
                                </div>
                                { profileInfo && profileInfo.personalInfoDto && profileInfo.personalInfoDto.flgDivyang && profileInfo.personalInfoDto.divyangPercentage >= 40 ?
                                  <div className="card mt-2">
                                    <div className="card-header">
                                        <span className="font-weight-bold">{advertisementDetailsLabel.BENCHMARK_DISABILITIES_DETAILS}</span>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <label>
                                                {advertisementDetailsLabel.DISABILITY_COMP_TIME}
                                            </label>
                                            <select className="form-control" name="disabilityCompTime" value={flgDisabilityCompTime}
                                                onChange={(event) => setFlgDisabilityCompTime(event.target.value)}>
                                                <option key="" value="">{advertisementDetailsLabel.SELECT}</option>  
                                                {BOOLEAN_OPTION.map((ele, i) => {
                                                    return <option key={i} value={ele.value}>{ele.label}</option>
                                                })}
                                            </select>
                                        </li>
                                        <li className="list-group-item">
                                            <label>
                                            {advertisementDetailsLabel.DISABILITY_SCRIBE_REQ}
                                            </label>
                                            <select className="form-control" name="disabilityScribeReq" value={flgDisabilityScribeReq}
                                                onChange={(event) => setFlgDisabilityScribeReq(event.target.value)}>
                                                <option key="" value="">{advertisementDetailsLabel.SELECT}</option>  
                                                {BOOLEAN_OPTION.map((ele, i) => {
                                                    return <option key={i} value={ele.value}>{ele.label}</option>
                                                })}
                                            </select>
                                        </li>
                                        <li className="list-group-item">
                                            <label>
                                            {advertisementDetailsLabel.SCRIBE_ARRANGED_BY}
                                            </label>
                                            <select className="form-control" name="scribeArrangedBy" value={scribeArrangedBy}
                                                onChange={(event) => setScribeArrangedBy(event.target.value)}>
                                                <option key="" value="">{advertisementDetailsLabel.SELECT}</option>  
                                                {SCRIBE_OPTION.map((ele, i) => {
                                                    return <option key={i} value={ele.value}>{ele.label}</option>
                                                })}
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                                :""}
                                <div className="card mt-2">
                                    <div className="card-header">
                                        <span className="font-weight-bold">{advertisementDetailsLabel.Declaration} <span className="redColor">*</span></span>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <input type="checkbox" checked={declAccepted} onChange={(event) => setDeclAccepted(event.target.checked)} />&nbsp;
                                            I&nbsp;
                                            { profileInfo && profileInfo.personalInfo ?
                                            
                                                profileInfo.personalInfo.englishFullName
                                                :
                                                "CANDIDATE FULL NAME"
                                            }&nbsp;
                                            <span>{advertisementDetailsLabel.DECLARATION}</span>
                                        </li>
                                        <li className="list-group-item">
                                            <div>{advertisementDetailsLabel.DATE} {dayjs().format(commonLabels.DATE_FORMAT)}</div>
                                            <div className="d-flex text-center">
                                                <span className="pr-2">{advertisementDetailsLabel.Location}&nbsp;<span className="redColor">*</span></span>
                                                <input type="text" className="form-control" value={location} onChange={(event) => setLocation(event.target.value)} />
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                        <img
                                            src={profileInfo && profileInfo.signature ?`data:image/png;base64,${profileInfo.signature}`: ""}
                                            width="130" height="60"  />
                                        </li>
                                    </ul>
                                </div>
                                <button type="button" className="btn btn-outline-dark btn-sm button_reset_apply" onClick={goBack} >{common.CANCEL}</button>&nbsp;
                                <button type="button" className="btn btn-primary btn-sm button_reset_apply" onClick={onApply} disabled={!(declAccepted && location && selectedExamCenters.length > 0 && selectedOptionErr)}>{common.SUBMIT_AND_FEES}</button>
                            </>
                        }
                    </div>

                </div>
                <Modal show={proceedToPayment} backdrop='static'>
                <form>
                    <Modal.Header >
                        <Modal.Title>{myAccountLabels.SELECT_PAYMENT_MODE}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                    <div className="row">
                        <div className="col-md-12">
                        <span>{advertisementDetailsLabel.APPLICATION_SAVE_NOTE}</span>  <br/><br/>
                        { showChallan ? 
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymenttype" value="challan_payment" disabled={challanDownloadDate && dayjs(challanDownloadDate).isBefore(new Date(),'day')} ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Challan_Payment}
                            </label>
                            <span className="m17">{advertisementDetailsLabel.DOWNLOAD_AND_PRINT_CHALLAN_NOTE}  &nbsp;
                                {challanDownloadDate ? dayjs(challanDownloadDate ).format(commonLabels.DATE_FORMAT): ""}. {advertisementDetailsLabel.CHALLAN_PAYMENT_NOTE} &nbsp;{applyedApplication?.challanPaymentEndDate ? dayjs( applyedApplication.challanPaymentEndDate ).format(commonLabels.DATE_FORMAT): ""}.
                            </span>
                        </div> : "" }
                        <br/>
                        { showOnlinePayment ?
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymenttype" value="online_payment" disabled={applyedApplication && dayjs(applyedApplication.onlinePaymentEndDate).isBefore(new Date(),'day')} ref={register({ required: true })} />&nbsp;{advertisementDetailsLabel.Online_Payment}
                            </label>
                            <span className="m17">{advertisementDetailsLabel.ONLINE_PAYMENT_NOTE} &nbsp;
                                {applyedApplication && applyedApplication.onlinePaymentEndDate? dayjs(applyedApplication.onlinePaymentEndDate).format(commonLabels.DATE_FORMAT): ""}
                            </span>
                        </div> : "" }
                        <br/>
                        {watch("paymenttype") === 'online_payment' ?
                        <div className="pl-3">
                             { showSbi ?   
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymentBank" value="sbi" ref={register({ required: true })} />&nbsp;{commonLabels.SBI}
                            </label>
                        </div> : "" }
                        { showBillDesk ?   
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymentBank" value="billdesk" ref={register({ required: true })} />&nbsp;{commonLabels.BILL_DESK}
                            </label>
                        </div> : "" }
                        { showCsc ? 
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymentBank" value="csc" ref={register({ required: true })} />&nbsp;{commonLabels.CSC}
                            </label>
                        </div>: "" }
                        { showEaseBuzz ? 
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymentBank" value="easebuzz" ref={register({ required: true })} />&nbsp;{commonLabels.EASEBUZZ}
                            </label>
                        </div>: "" }
                        { showQuickwallet ?
                        <div className="input-group">
                            <label className="position-relative">
                                <input type="radio" className="radio" name="paymentBank" value="quickPayWallet" ref={register({ required: true })} />&nbsp;{commonLabels.QUICK_WALLET}
                            </label>
                        </div> : "" }
                        </div>
                        :""}
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button   type="button"  className="btn btn-primary "
                        disabled={!formState.isValid }  onClick={() => { processToPayment(applyedApplication, watch("paymenttype"))}}>{commonLabels.OK}</button>
                    </Modal.Footer>
                    </form>
                </Modal>
                <DocumentList 
                    showModal = {showDocListModal}
                    setshowDocList = {setshowDocListModal}
                    docData = {singleDocList}
                />
                { reviewDepartmentModal &&
                <ReviewSelectedModal
                    showModal = {reviewDepartmentModal}
                    setReviewDepartmentModal = {setReviewDepartmentModal}
                    currentPostId={currentPostId}
                    options={selectedDepartmentArr}
                />
}
            </div>

    )
}

export default AdvertisementDetails;