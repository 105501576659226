import { toastr } from "react-redux-toastr";
import API from "../../helpers/API";
import {
  SET_PROFILE_INFO_DISPLAY,
} from "../../common/constants/ActionTypes";
import { hideLoader, showLoader } from '../../common/actions/commonActions';
const SUPPORT_PROFILE_DISPLAY = "/oas/api/v1/support/profiledisplay";

export function getSupportProfile(apiKey, username) {
  
  return async (dispatch) => {
    dispatch(showLoader());
      let response;
      try {
        response = await API.get(SUPPORT_PROFILE_DISPLAY, {
          headers: { 'x-api-key': apiKey },
          params: { username },
        });
        if(response!=null)
            {
                dispatch(hideLoader());
            }
      } catch (error) {
        dispatch(hideLoader());
        let errorMessage = (error.response && error.response.data) ? error.response.data.message
        : error.message;
        toastr.error(errorMessage);
        return;
      }
      dispatch({
        type: SET_PROFILE_INFO_DISPLAY,
        payload: response.data,
    });
  };
}
