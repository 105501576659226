/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import qualificationinformation from '../labels/qualification_information_en.json';
import common from "../labels/common_en.json";
import dayjs from 'dayjs';
import {deleteQualification, getDetailsForEditQualification, getDisplayableQualificationList, getPublicationTypeData, getAllNameOfIndexingBodies, getAllPublicationArticleTypes, getSubjectsForAQualification} from '../actions/profileAction';
import { Modal } from 'react-bootstrap';
import { SET_CURRENT_EDIT_QUALIFICATION_INDEX } from '../../common/constants/ActionTypes';
import "../../styles/advertisementTable.css";
import commonLabels from "../../my_account/labels/common_en.json";
import { BOOLEAN_OPTION, AUTHOR_TYPES } from "../../common/constants/dropdownOptions";
import { nameOfIndexingBodies, articleType, flgPublicationFirstAuthor, authorType} from "../../helpers/LookupOptionHelper";
import { SUBJECTS } from '../../common/constants/endpoints';
import API from '../../helpers/API';

const QualificationTabTable = () => {
  let { profileStatus, qualificationsList, displayableQualificationList,
    qualificationTypes, nameOfIndexingBodiesList, getPublicationArticleTypeList, subjects} = useSelector( state => state.profile, shallowEqual);
  const dispatch = useDispatch();
  let [showModal, setShowModal] = useState(false);
  let [deleteIndex, setDeleteIndex] = useState("");
  let [qualificationSortList, setQualificationSortList] = useState([]);
  let [showPublicationTable, setShowPublicationTable] = useState(false);
  let count = 0;
  let publicationTableCount = 0;

  useEffect(() => {
    dispatch(getDisplayableQualificationList(qualificationsList));

  if (qualificationsList) {
    const hasPbQualification = qualificationsList.some(item => item.qualificationtypecode === "Pb");
    setShowPublicationTable(hasPbQualification)
  }
  }, [qualificationsList]);

  useEffect(()=>{
    dispatch(getAllNameOfIndexingBodies());
    dispatch(getAllPublicationArticleTypes())
  },[]);

  useEffect(() => {
    let list = []
    qualificationTypes.forEach((qualfn, index) => list[index] = qualfn.qualificationtypecode);
    setQualificationSortList(list)
  }, [qualificationTypes, displayableQualificationList])

  let deleteQualificationRow = (qualificationRowIndex) => {
    dispatch(deleteQualification(qualificationRowIndex, qualificationsList));
  }

  const checkNumberOrNot = (inputString) => {
    const parts = inputString.split(',');
    const isNumberArray = parts.map((part) => !isNaN(Number(part.trim())));
    const isNumber = isNumberArray.every((result) => result);
    return isNumber;
  }

  let editQualificationRow = async (qualificationRowIndex, ele) => {
    try {
      let subjectsArrList = subjects;
      if(ele && ele.id){
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response = await API.get(SUBJECTS + `?qualificationnamecode=${qualificationsList[qualificationRowIndex].qualificationnamecode}`, { headers: { 'Authorization': `Bearer ${token}` } });
        subjectsArrList = response && response.data;
    };

    let optionalSubjectsIsNumberFlg = false;
    let compulsorySubjectsIsNumberFlg = false;
    let optionalSubjectsIsNumberFlgValue = "";
    let compulsorySubjectsIsNumberFlgValue = "";

    if(ele && ele.optionalSubjects){
        let optionalSubjectsIsNumber = (checkNumberOrNot(ele.optionalSubjects));
        if(!optionalSubjectsIsNumber){
          optionalSubjectsIsNumberFlg = false;
          optionalSubjectsIsNumberFlgValue = ele.optionalSubjects;
        };
    };

    if(ele && ele.compulsorySubjects){
      let compulsorySubjectsIsNumber = (checkNumberOrNot(ele.compulsorySubjects));
        if(!compulsorySubjectsIsNumber){
          compulsorySubjectsIsNumberFlg = false;
          compulsorySubjectsIsNumberFlgValue = ele.compulsorySubjects;
    }
    };

    dispatch({
      type: SET_CURRENT_EDIT_QUALIFICATION_INDEX,
      payload: qualificationRowIndex
    });
    if(ele.qualificationtypecode === "Pb"){
      dispatch(getPublicationTypeData("Pb"))
    }
    if((qualificationsList[qualificationRowIndex].compulsorySubjects) || (qualificationsList[qualificationRowIndex].optionalSubjects)){
        const subjectIdsArray = qualificationsList[qualificationRowIndex].compulsorySubjects.includes(',')
            ? qualificationsList[qualificationRowIndex].compulsorySubjects.split(',')
            : [qualificationsList[qualificationRowIndex].compulsorySubjects];
      const matchingSubjects = subjectsArrList && subjectsArrList.length > 0 && subjectsArrList.filter(item => subjectIdsArray.includes(item.subjectId.toString()));  
        const optionalSubjectIdsArray = qualificationsList[qualificationRowIndex].optionalSubjects.includes(',')
            ? qualificationsList[qualificationRowIndex].optionalSubjects.split(',')
            : [qualificationsList[qualificationRowIndex].optionalSubjects];

      const matchingOptionalSubjects = subjectsArrList && subjectsArrList.length > 0 && subjectsArrList.filter(item => optionalSubjectIdsArray.includes(item.subjectId.toString()));  

      let qualificationToBeEdited = qualificationsList[qualificationRowIndex];
        qualificationToBeEdited.compulsorySubjects = matchingSubjects;
        qualificationToBeEdited.optionalSubjects = matchingOptionalSubjects;
        qualificationToBeEdited.optionalSubjectsIsNumberFlg = optionalSubjectsIsNumberFlg;
        qualificationToBeEdited.compulsorySubjectsIsNumberFlg = compulsorySubjectsIsNumberFlg;
        qualificationToBeEdited.optionalSubjectsIsNumberFlgValue = optionalSubjectsIsNumberFlgValue;
        qualificationToBeEdited.compulsorySubjectsIsNumberFlgValue = compulsorySubjectsIsNumberFlgValue;

      dispatch(getDetailsForEditQualification(qualificationToBeEdited));
    }
    else{
    let qualificationToBeEdited = qualificationsList[qualificationRowIndex];
      qualificationToBeEdited.optionalSubjectsIsNumberFlg = optionalSubjectsIsNumberFlg;
      qualificationToBeEdited.compulsorySubjectsIsNumberFlg = compulsorySubjectsIsNumberFlg;
      qualificationToBeEdited.optionalSubjectsIsNumberFlgValue = optionalSubjectsIsNumberFlgValue;
      qualificationToBeEdited.compulsorySubjectsIsNumberFlgValue = compulsorySubjectsIsNumberFlgValue;
    dispatch(getDetailsForEditQualification(qualificationToBeEdited));
    }
  }
    catch (error) {
      console.error("Error fetching publication type data", error);
    }
  }

  const modalConfirm = () => {
    deleteQualificationRow(deleteIndex);
    setDeleteIndex("");
    setShowModal(false);
  }

  const sortQualificationList = (qualificationList) => {
    if(displayableQualificationList && Array.isArray(displayableQualificationList) && displayableQualificationList.length>0 && qualificationList && Array.isArray(qualificationList) && qualificationList.length>0){
      displayableQualificationList.sort((a, b) =>  qualificationSortList.indexOf(a.qualificationtypecode) - qualificationSortList.indexOf(b.qualificationtypecode))
      return qualificationList.sort((a, b) => qualificationSortList.indexOf(a.qualificationtypecode) - qualificationSortList.indexOf(b.qualificationtypecode))
    }else{
      return []
    }
  }

  return(
      <div className="border border-light overflow-auto">
        <table className="table table-bordered mb-4">
          <thead className="thead-light">
            <tr>
              {qualificationinformation.qualificationHeaderContent && qualificationinformation.qualificationHeaderContent.map((ele, index) => 
                <th scope="col" key={index}>{ele}</th>
              )}
            </tr>
          </thead>
          <tbody className='f12'>
          {
           sortQualificationList(qualificationsList).map((ele,index) => {
              if(!ele.flgDeleted && (ele.qualificationtypecode !== "Pb")) {
                count++;
                return (
                  <tr key={index}>
                    <td className="w-10 word-break text-center" >{count}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].qualificationTypeLabel: ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].qualificationNameLabel: ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].subjectLabel: ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].universityOrBoardLabel: ""}</td>
                    <td className="word-break">{ele.resultDate? dayjs(ele.resultDate).format(commonLabels.DATE_FORMAT): ""}</td>
                    <td className="word-break">{ele.attempts}</td>
                    <td className="word-break">{ele.percentageGrade}</td>
                    <td className="word-break">{ele.courseDurationMonths}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].classGradeLabel: ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].modeLabel: ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].compulsorySubjectsLabel : ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].optionalSubjectsLabel : ""}</td>
                    <td className="word-break"><i className={`fa fa-lg btn-link btn fa-edit ${profileStatus ? "disabled-link" : ""}`} data-toggle="tooltip" title={profileStatus ? common.EDIT_DISALLOWED_TOOLTIP : common.EDIT_TOOLTIP} onClick={() => { editQualificationRow(index, ele)}}/></td>
                    <td className="word-break"><i className={`fa fa-lg btn-link btn fa-trash ${profileStatus ? "disabled-link" : ""}`} data-toggle="tooltip" title={profileStatus ? common.DELETE_DISALLOWED_TOOLTIP : common.DELETE_TOOLTIP} onClick={() => { setShowModal(true); setDeleteIndex(index); }}/></td>
                  </tr>
                )
              }
            })
          }  
          </tbody>
        </table>
        {qualificationsList && qualificationsList.length===0 ?
         <h6 className="text-muted text-center">{commonLabels.NO_DATA_TO_DISPLAY}</h6>
         :
         ""}
          {showPublicationTable &&
          <>
         <hr/>
         <h5 className="float-left m-1 mb-4`">
              {qualificationinformation.PUBLICATION_DETAILS}
          </h5>
          <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              {qualificationinformation.publicationQualificationHeaderContent && qualificationinformation.publicationQualificationHeaderContent.map((ele, index) => 
                <th scope="col" key={index}>{ele}</th>
              )}
            </tr>
          </thead>
          <tbody className='f12'>
          {
           sortQualificationList(qualificationsList).map((ele,index) => {
              if(!ele.flgDeleted && (ele.qualificationtypecode === "Pb")) {
                publicationTableCount++;
                return (
                  <tr key={index}>
                    <td className="w-10 word-break text-center" >{publicationTableCount}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].qualificationTypeLabel: ""}</td>
                    <td className="word-break">{displayableQualificationList[index] ? displayableQualificationList[index].qualificationNameLabel: ""}</td>
                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.indexingBodyLookupId && nameOfIndexingBodies((ele.publicationQualificationInfo.indexingBodyLookupId), nameOfIndexingBodiesList)}</td>
                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.articleTypeLookupId && articleType((ele.publicationQualificationInfo.articleTypeLookupId), getPublicationArticleTypeList)}</td>
                    <td className="word-break">{ele && flgPublicationFirstAuthor(((ele.flgPublicationFirstAuthor === true) || (ele.flgPublicationFirstAuthor === "true") ? true : (ele.flgPublicationFirstAuthor === false) || (ele.flgPublicationFirstAuthor === "false") ? false : ""), BOOLEAN_OPTION)}</td>
                    <td className="word-break">{ele && ele.publicationAuthorType && authorType((ele.publicationAuthorType), AUTHOR_TYPES)}</td>
                    <td className="word-break">{ele && ele.publicationJournalDetails}</td>
                    <td className="word-break">{ele && ele.publicationYear}</td>
                    <td className="word-break">{ele && ((ele.flgImpactIndex2Or0OrMore === true || ele.flgImpactIndex2Or0OrMore === "true") ? "Yes" : (ele.flgImpactIndex2Or0OrMore === false || ele.flgImpactIndex2Or0OrMore === "false") ? "No" : "")}</td>
                    <td className="word-break">{ele && ele.publicationPaperTitle}</td>
                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.publicationAcceptanceDate && dayjs(ele.publicationQualificationInfo.publicationAcceptanceDate).format(commonLabels.DATE_FORMAT)}</td>
                    <td className="word-break">{ele && ele.publicationQualificationInfo && ele.publicationQualificationInfo.publicationPublishedDate && dayjs(ele.publicationQualificationInfo.publicationPublishedDate).format(commonLabels.DATE_FORMAT)}</td>
                    <td className="word-break"><i className={`fa fa-lg btn-link btn fa-edit ${profileStatus ? "disabled-link" : ""}`} data-toggle="tooltip" title={profileStatus ? common.EDIT_DISALLOWED_TOOLTIP : common.EDIT_TOOLTIP} onClick={() => { editQualificationRow(index, ele)}}/></td>
                    <td className="word-break"><i className={`fa fa-lg btn-link btn fa-trash ${profileStatus ? "disabled-link" : ""}`} data-toggle="tooltip" title={profileStatus ? common.DELETE_DISALLOWED_TOOLTIP : common.DELETE_TOOLTIP} onClick={() => { setShowModal(true); setDeleteIndex(index); }}/></td>
                  </tr>
                )
              }
            })
          }  
          </tbody>
        </table>
        </>}

        <Modal show={showModal} onHide={() => { setShowModal(false) }}  backdrop='static'>
          <Modal.Header closeButton>
            <Modal.Title>{qualificationinformation.DELETE_QUALIFICATION_MODAL_TITLE}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>{qualificationinformation.DELETE_QUALIFICATION_CONFIRMATION_MESSAGE}</label>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => { setShowModal(false) }}>{common.CANCEL}</button>
            <button className="btn btn-primary" onClick={modalConfirm}>{common.CONFIRM}</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
}

export default QualificationTabTable;