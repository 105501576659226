/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import profileotherinformation from "../labels/other_information_en.json";
import '../../styles/profile.css'
import profile from "../labels/personal_information_en.json";
import numberformat from "../labels/serial_nos_en.json";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { otherInfoAction } from '../actions/profileAction';
import { BOOLEAN_OPTION } from '../../common/constants/dropdownOptions';
import { DEBARRED_NATURE } from '../../common/constants/dropdownOptions';
import { getOtherInformation } from '../actions/profileAction';
import _ from "lodash/fp";
import dayjs from 'dayjs';
import { fromUTCDate, toUTCDate } from "../../helpers/Datetime";
import ModuleHide from "../../common/components/ModuleHide";
import "../../styles/advertisementTable.css";
import advertisementDetailsLabel from "../../application_management/labels/advertisement_details_en.json";
import commonLabels from "../../my_account/labels/common_en.json";

function OtherInformation() {
  const { register, handleSubmit, control, errors, watch, setValue } = useForm({
    mode: "onChange"
  });

  const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
  const [arraymModule, setarraymModule] = useState({})

  useEffect(() => {
    setarraymModule(moduleList && moduleList.filter((ele) => ele.moduleName === "OTHER_INFO")[0])
  }, [moduleList])

  const [otherInfoBooleanState, setOtherInfoBooleanState] = useState({
    isProsecutionPending: "",
    isBarDisciplinaryActionInitiated: "",
    isGovDisciplinaryActionInitiated: "",
    isCourtCasePending: "",
    isDebarredForExamination: "",
    activities: "",
    distinctionLeadershipPosition: "",
    extraCurricular: ""
  });

  const handleOtherInfoBooleanState = (evt) => {
    const value = evt.target.value;
    setOtherInfoBooleanState({
      ...otherInfoBooleanState,
      [evt.target.name]: value
    });
    if (evt.target.name === "isDebarredForExamination") {
      setOtherInfoBooleanState({
        ...otherInfoBooleanState,
        ["isDebarredForExamination"]: value
      });
      setOtherInfoState({
        ...otherInfoState,
        ["nature"]: '',
        ["debarredStartDate"]: '',
        ["debarredEndDate"]: ''
      })
      setValue("nature", "")
      setValue("debarredStartDate", "")
      setValue("debarredEndDate", "")
    }
  }

  const [otherInfoState, setOtherInfoState] = useState({
    nature: "",
    debarredStartDate: '',
    debarredEndDate: ''
  })

  const handleOtherInfoState = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if(name === "nature" && value === "2"){
      setOtherInfoState({
        ...otherInfoState,
        "debarredStartDate": '',
        "debarredEndDate": '',
        [event.target.name]: value
      })
    }else {
      setOtherInfoState({
        ...otherInfoState,
        [event.target.name]: value
      })
    }
  }

  let otherInformationResponse = useSelector(state => state.profile.otherInformationResponse);
  const isDebarredForExaminationFlag = watch("isDebarredForExamination");
  const natureFlag = watch("nature");
  const debarredStartDate = watch("debarredStartDate")
  const debarredEndDate = watch("debarredEndDate")
  const isDisableAllTabSave = useSelector(state => state.profile.isDisableAllTabSave);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOtherInformation());
  }, []);

  useEffect(() => {
    setOtherInfoBooleanState({
      isProsecutionPending: otherInformationResponse.isProsecutionPending,
      isBarDisciplinaryActionInitiated: otherInformationResponse.isBarDisciplinaryActionInitiated,
      isGovDisciplinaryActionInitiated: otherInformationResponse.isGovDisciplinaryActionInitiated,
      isCourtCasePending: otherInformationResponse.isCourtCasePending,
      isDebarredForExamination: otherInformationResponse.isDebarredForExamination,
      activities: otherInformationResponse.activities,
      distinctionLeadershipPosition: otherInformationResponse.distinctionLeadershipPosition,
      extraCurricular: otherInformationResponse.extraCurricular,
    });
    setOtherInfoState({
      nature: otherInformationResponse.nature,
      debarredStartDate: otherInformationResponse.debarredStartDate && dayjs(otherInformationResponse.debarredStartDate).toDate(),
      debarredEndDate: otherInformationResponse.debarredEndDate && dayjs(otherInformationResponse.debarredEndDate).toDate()
    });
    setValue("debarredStartDate", otherInformationResponse.debarredStartDate && dayjs(otherInformationResponse.debarredStartDate).toDate())
    setValue("debarredEndDate", otherInformationResponse.debarredEndDate && dayjs(otherInformationResponse.debarredEndDate).toDate())
  }, [otherInformationResponse]);

  return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule} /> :
    (
      <form className="form-group row p-1" onSubmit={handleSubmit((data) => { dispatch(otherInfoAction(data)) })} >
        <div className="m-4 f12">
          <div className="p-1 mb-2">
            <h5 className="float-left m-0">
              {numberformat["3.1"]}.&nbsp;{profile.OTHER_DETAILS_INFO_NOTE_1}
            </h5>
          </div>
          <br />
          <div className="form-group row p-1">
            <div className="col-md-4" >
              <label className="LeftText">
                {numberformat["1"]}.&nbsp;{profileotherinformation["WHETHER_ANY_PROSECUTION_IS_PENDING_IN_ANY_COURT_OF_LAW_AGAINST_YOU?"]} &nbsp;<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-2 otherFormMarginBottom ">
              <select className="form-control" name="isProsecutionPending" value={otherInfoBooleanState.isProsecutionPending} ref={register({ required: true })} onInput={handleOtherInfoBooleanState}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {BOOLEAN_OPTION.map((ele, ind) =>
                  <option key={ind++} value={ele.value}>{ele.label}</option>
                )}
              </select>
              {_.get("isProsecutionPending.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-4">
              <label className="LeftText w400" >
                {numberformat["2"]}.&nbsp;{
                  profileotherinformation["WHETHER_DISCIPLINARY_OR_ANY_OTHER_SIMILAR_ACTION_IS_INITIATED_INSTITUTION?"]
                }<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-2 otherFormMarginBottom">
              <select className="form-control" name="isBarDisciplinaryActionInitiated" value={otherInfoBooleanState.isBarDisciplinaryActionInitiated} ref={register({ required: true })} onInput={handleOtherInfoBooleanState}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {BOOLEAN_OPTION.map((ele, ind) => {
                  return <option key={ind++} value={ele.value}>{ele.label}</option>
                })}
              </select>
              {_.get("isBarDisciplinaryActionInitiated.type", errors) === "required" && (
                <p className="errorText"> {profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-4">
              <label className="LeftText w350">
                {numberformat["3"]}.&nbsp;{profileotherinformation["WHETHER_ANY_DISCIPLINARY_ACTION_HAS_BEEN_INITIATED_BY_GOVERNMENT?"]}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-2 otherFormMarginBottom">
              <select className="form-control" name="isGovDisciplinaryActionInitiated" value={otherInfoBooleanState.isGovDisciplinaryActionInitiated} ref={register({ required: true })} onInput={handleOtherInfoBooleanState}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {BOOLEAN_OPTION.map((ele, ind) => {
                  return <option key={ind++} value={ele.value}>{ele.label}</option>
                })}
              </select>
              {_.get("isGovDisciplinaryActionInitiated.type", errors) === "required" && (
                <p className="errorText"> {profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-4">
              <label className="LeftText">
                {numberformat["4"]}.&nbsp;{
                  profileotherinformation["WAS_ANY_COURT_CASE_FILED_AGAINST_YOU?"]
                }<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-2 otherFormMarginBottom">
              <select className="form-control" name="isCourtCasePending" value={otherInfoBooleanState.isCourtCasePending} ref={register({ required: true })} onInput={handleOtherInfoBooleanState}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {BOOLEAN_OPTION.map((ele, ind) => {
                  return <option key={ind++} value={ele.value}>{ele.label}</option>
                })}
              </select>
              {_.get("isCourtCasePending.type", errors) === "required" && (
                <p className="errorText">{profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            <div className="col-md-4" >
              <label className="LeftText">
                {numberformat["5"]}.&nbsp;{profileotherinformation["WHETHER_DEBARRED_OR_BLACK_LISTED_FOR_EXAMINATION_OR_ANY_OTHER_ORGANISATIONS?"]}<span className="redColor">*</span>
              </label>
            </div>
            <div className="col-md-2 otherFormMarginBottom ">
              <select className="form-control" name="isDebarredForExamination" value={otherInfoBooleanState.isDebarredForExamination} ref={register({ required: true })} onInput={handleOtherInfoBooleanState}>
                <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                {BOOLEAN_OPTION.map((ele, ind) => {
                  return <option key={ind++} value={ele.value}>{ele.label}</option>
                })}
              </select>
              {_.get("isDebarredForExamination.type", errors) === "required" && (
                <p className="errorText"> {profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
              )}
            </div>
            {isDebarredForExaminationFlag && isDebarredForExaminationFlag == 'true' ?
              <div className="col-md-4" >
                <label className="LeftText">
                  {numberformat["5.1"]}. &nbsp;{profileotherinformation.DURATION}<span className="redColor">*</span>
                </label>
              </div> : ''}
            {isDebarredForExaminationFlag && isDebarredForExaminationFlag == 'true' ?
              <div className="col-md-2" >
                <select className="form-control" name="nature" value={otherInfoState.nature} ref={register({ required: true })} onInput={handleOtherInfoState}>
                  <option key={0} value="">{advertisementDetailsLabel.SELECT}</option>
                  {DEBARRED_NATURE.map((ele, ind) => {
                    return <option key={ind++} value={ele.value}>{ele.label}</option>
                  })}
                </select>
                {_.get("nature.type", errors) === "required" && (
                  <p className="errorText"> {profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div> : ''}
            {natureFlag && natureFlag === "2" && isDebarredForExaminationFlag == 'true' ?
              <div className="col-md-4" >
                <label className="LeftText">
                  {numberformat["5.1.1"]}.&nbsp;{
                    profileotherinformation.FROM_DATE
                  }<span className="redColor">*</span>
                </label>
              </div> : ''}
            {natureFlag && natureFlag === "2" && isDebarredForExaminationFlag == 'true' ?
              <div className="col-md-2" >
                {otherInformationResponse && otherInformationResponse.otherInfoRowId ?
                  <Controller
                    name="debarredStartDate"
                    control={control}
                    key="fromDate1"
                    defaultValue={otherInfoState.debarredStartDate}
                    rules={{
                      required: true
                    }}
                    render={({ onChange, value }) => (
                      <DatePicker
                        maxDate={debarredEndDate || new Date()}
                        selected={fromUTCDate(value)}
                        onChange={(e) => { onChange(toUTCDate(e)) }}
                        placeholderText={commonLabels.DATE_FORMAT}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                      />
                    )}
                  /> :
                  <Controller
                    name="debarredStartDate"
                    control={control}
                    key="fromDate2"
                    rules={{ required: true }}
                    render={({ onChange, value }) => (
                      <DatePicker
                        maxDate={debarredEndDate || new Date()}
                        selected={fromUTCDate(value)}
                        onChange={(e) => { onChange(toUTCDate(e)) }}
                        placeholderText={commonLabels.DATE_FORMAT}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                      />
                    )}
                  />
                }
                {_.get("debarredStartDate.type", errors) === "required" && (
                  <p className="errorText"> {profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div> : ''}
            {natureFlag && natureFlag === "2" && isDebarredForExaminationFlag == 'true' ?
              <div className="col-md-4" >
                <label className="LeftText m-2">
                  {numberformat["5.1.2"]}.&nbsp;{
                    profileotherinformation.TO_DATE
                  }<span className="redColor">*</span>
                </label>
              </div> : ''}
            {natureFlag && natureFlag === "2" && isDebarredForExaminationFlag == 'true' ?
              <div className="col-md-2" >
                {otherInformationResponse && otherInformationResponse.otherInfoRowId ?
                  <Controller
                    name="debarredEndDate"
                    control={control}
                    key="toDate1"
                    defaultValue={otherInfoState.debarredEndDate}
                    rules={{
                      required: true
                    }}
                    render={({ onChange, value }) => (
                      <DatePicker
                        minDate={debarredStartDate}
                        selected={fromUTCDate(value)}
                        onChange={(e) => { onChange(toUTCDate(e)) }}
                        placeholderText={commonLabels.DATE_FORMAT}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                      />
                    )}
                  /> :
                  <Controller
                    name="debarredEndDate"
                    control={control}
                    key="toDate2"
                    rules={{ required: true }}
                    render={({ onChange, value }) => (
                      <DatePicker
                        minDate={debarredStartDate}
                        selected={fromUTCDate(value)}
                        onChange={(e) => { onChange(toUTCDate(e)) }}
                        placeholderText={commonLabels.DATE_FORMAT}
                        dateFormat={commonLabels.SMALL_SIZE_DATE_FORMAT}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                      />
                    )}
                  />
                }
                {_.get("debarredEndDate.type", errors) === "required" && (
                  <p className="errorText"> {profileotherinformation.MANDATORY_FIELD_MESSAGE}</p>
                )}
              </div> : ''}
          </div>
          <hr></hr>
        </div>

        <div className="ml-4 f12">
          <div className="p-1">
            <h5 className="float-left m-0">
              {numberformat["3.2"]}.&nbsp;{profile.OTHER_DETAILS_INFO_NOTE_2}
            </h5>
          </div>
          <br />
          <div className="form-group row p-1">

            <div className="col-md-4" >
              <label className="LeftText">
                {numberformat["1"]}.&nbsp;{profileotherinformation.OTHER_INFO_NOTE_3_2_1}
              </label>
            </div>
            <div className="col-md-5 mb-3">
              <input
                type="text"
                className="form-control"
                onInput={handleOtherInfoBooleanState}
                defaultValue={otherInfoBooleanState.activities}
                name="activities" ref={register({ required: false })}
              />
            </div>
            
            <div className="col-md-4" >
              <label className="LeftText" >
                {numberformat["2"]}.&nbsp;{profileotherinformation.OTHER_INFO_NOTE_3_2_2}
              </label>
            </div>
            <div className="col-md-5 mb-3">
              <input
                type="text"
                className="form-control"
                onInput={handleOtherInfoBooleanState}
                defaultValue={otherInfoBooleanState.distinctionLeadershipPosition}
                name="distinctionLeadershipPosition" ref={register({ required: false })}
              />
            </div>
             
            <div className="col-md-4" >
              <label className="LeftText">
                {numberformat["3"]}.&nbsp;{profileotherinformation.OTHER_INFO_NOTE_3_2_3}
              </label>
            </div>
            <div className="col-md-5 mb-3">
              <input
                type="text"
                className="form-control"
                onInput={handleOtherInfoBooleanState}
                defaultValue={otherInfoBooleanState.extraCurricular}
                name="extraCurricular" ref={register({ required: false })}
              />
            </div>

          </div>
          <div className="text-left">
            <hr></hr>
            <button type="submit" className="btn btn-primary" disabled={isDisableAllTabSave}>
              {profile.SAVE}
            </button>
          </div>
        </div>
      </form>
    );
}

export default OtherInformation;
