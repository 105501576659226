/* eslint-disable */
import React, { useRef } from "react";
import "../../styles/candidate_nav.css";
import common from "../labels/common_en.json";
import 'jspdf-autotable';
import "../../styles/admit_card.css";

const InterviewPTLetter = (props) => {
    const interviewPTLetterPdfRef = useRef();
    let interviewPtCsvDto = props && props.interviewPTLetterData && props.interviewPTLetterData.interviewPtCsvDto;
    let examInstructions = props && props.interviewPTLetterData && props.interviewPTLetterData.examInstructions;
    const importantInstructions = examInstructions && examInstructions.importantInstructions && examInstructions.importantInstructions;
    const studentInstructions = examInstructions && examInstructions.studentInstructions && examInstructions.studentInstructions;

    const addStylesToContent = (htmlContent) => {
        return htmlContent
        .replace(/<p>/g, '<p class="custom-paragraph">')
        .replace(/<ol>/g, '<ol class="custom-list-item">')
        .replace(/<li>/g, '<li class="custom-list-item">')
    };
    
    return (
        <div ref={interviewPTLetterPdfRef}>
            <div className="row headerSection">
                <div className="col-lg-12 col-md-12 col-12 d-flex mb-2 align-items-center justify-content-center">
                    <img src="/logos/mpsc_pdf_logo.jpg" className="img-fluid interviewLetterBorderCss" alt="" width="99%" />
                </div>
            </div>
            <div className="row mt-3 ml-n2">
                <div className="col-md-6">
                    <div className="content-row clearfix">
                        <h6>
                            {common.INTERVIEW_SERIAL_NO_MR}
                        </h6>
                        <span className="blackColor">
                            {interviewPtCsvDto && interviewPtCsvDto.serialNo ? interviewPtCsvDto.serialNo : ''}
                        </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content-row clearfix">
                        <h6>
                            {common.INTERVIEW_REGISTRATION_NO_MR}
                        </h6>
                        <span className="blackColor">
                            {interviewPtCsvDto && interviewPtCsvDto.profileTransactionID ? interviewPtCsvDto.profileTransactionID : ''}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mt-1 ml-n2">
                <div className="col-md-6">
                    <div className="content-row clearfix">
                        <h6>
                            {common.INTERVIEW_DATE_MR}
                        </h6>
                        <span className="blackColor">
                            {interviewPtCsvDto && interviewPtCsvDto.publicationDate ? interviewPtCsvDto.publicationDate : ''}
                        </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="content-row clearfix">
                        <h6>
                            {common.INTERVIEW_MR}
                        </h6>
                        <span className="font-weight-bold">
                            {interviewPtCsvDto && interviewPtCsvDto.interview_no ? interviewPtCsvDto.interview_no : ''}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mt-3 ml-n2">
                <div className="col-md-12">
                    <div className="content-row clearfix">
                        <h6>
                            {common.INTERVIEW_PER_MR}
                        </h6>
                    </div>
                </div>
                <div className="col-md-12 ml-4">
                    <div className="content-row clearfix">
                        <h6>
                            {interviewPtCsvDto && interviewPtCsvDto.candidateName ? interviewPtCsvDto.candidateName : ''}
                        </h6>
                    </div>
                </div>
            </div>
            <div className="row mt-3 ml-n2">
                <div className="col-md-12 ml-2">
                    <div className="content-row clearfix">
                        <h6>
                            {common.INTERVIEW_SUBJECT_MR}
                        </h6>
                        <span className="font-weight-bold">
                            {interviewPtCsvDto && interviewPtCsvDto.interviewLetterTitle ? interviewPtCsvDto.interviewLetterTitle : ''}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mt-3 ml-n2">
                <div className="col-md-12 ml-2">
                    <div className="content-row clearfix d-flex justify-content-center">
                        <span className="text-center">
                            {common.INTERVIEW_LETTER_MR}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mt-2 ml-n2">
                <div className="col-md-12 ml-2">
                    {importantInstructions && (
                        <div>
                            <h6
                                className="ml-n4"
                                dangerouslySetInnerHTML={{ __html: addStylesToContent(importantInstructions) }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="interviewLetterTableCss">
                <table className="table-responsive table-sec t-center">
                    <thead>
                        <tr>
                            {common.INTERVIEW_PT_LETTER_TABLE_HEADER && common.INTERVIEW_PT_LETTER_TABLE_HEADER.map((ele, index) =>
                                <th scope="col" key={index} className='text-center'>{ele}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="fontSize14">
                        {interviewPtCsvDto && interviewPtCsvDto ? (
                            <tr >
                                <td className="word-break text-center">{interviewPtCsvDto && interviewPtCsvDto.interview_date ? interviewPtCsvDto.interview_date : ''}</td>
                                <td className="word-break text-center">{interviewPtCsvDto && interviewPtCsvDto.interview_time ? interviewPtCsvDto.interview_time : ''}</td>
                                <td className="text-center interviewLetterTableDataCss" >{interviewPtCsvDto && interviewPtCsvDto.interview_venue ? interviewPtCsvDto.interview_venue : ''}</td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center">{common.NO_DATA_TO_DISPLAY}</td>
                            </tr>
                        )
                        }
                    </tbody>
                </table>
            </div>
            <div className="row mt-2 ml-n2">
                <div className="col-md-12 ml-2">
                    {studentInstructions && (
                        <div>
                            <h6
                                className="ml-n4"
                                dangerouslySetInnerHTML={{ __html: addStylesToContent(studentInstructions) }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="row mt-2 ml-n2">
                <div className="col-md-12 ml-2">
                    <div className="content-row clearfix d-flex justify-content-end pr70">
                        <h6 className="text-right">
                            {common.INTERVIEW_SIGNATURE_HEADER_MR}
                        </h6>
                    </div>
                </div>
            </div>
            <div className="row mt-2 ml-n2">
                <div className="col-md-12 ml-2 mt-2">
                    <div className="content-row clearfix d-flex justify-content-end pr80">
                        <h6 className="text-center">
                            {examInstructions && examInstructions.examAuthoritySignature ? examInstructions.examAuthoritySignature : ''}
                        </h6>
                    </div>
                </div>
            </div>
            <div className="row mt-2 ml-n2">
                <div className="col-md-12 ml-2 mb-3">
                    <div className="content-row clearfix d-flex justify-content-end pr30">
                        <h6 className="text-center">
                            {examInstructions && examInstructions.examAuthority	 ? examInstructions.examAuthority	 : ''}
                        </h6>
                    </div>
                </div>
            </div>
            <div className="row mt-2 ml-n2">
                <div className="col-md-12 ml-2 mb-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h6 className="text-center">
                                {interviewPtCsvDto && interviewPtCsvDto.profileId ? interviewPtCsvDto.profileId : ''}
                            </h6>
                        </div>
                        <div>
                            <h6 className="text-left mb-2 pr30">
                                {interviewPtCsvDto && interviewPtCsvDto.applicationId ? interviewPtCsvDto.applicationId : ''}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default InterviewPTLetter;