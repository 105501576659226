export let experienceInfoReqDTO = {
    typeEmploymentLookupId: "",
    flgMpscSelection: "",
    postNameLookupId: "",
    postNameOther: "",
    officeName: "",
    flgOfficeGovOwned: "",
    designation: "",
    designationId: "",
    jobNatureLookupId: "",
    flgGazettedPost: "",
    typeGroupLookupId: "",
    apointmentNatureLookupId: "",
    appointmentLetterNo: "",
    appointmentLetterDate: "",
    fullTimeLookupId: "",
    payScale: "",
    gradePay: "",
    basicPay: "",
    monthlyGrossSalary: "",
    employmentFrom: "",
    employmentTo: "",
    expYears: "",
    expMonths: "",
    expDays: "",
    remarks: "",
    flgDeleted:false
};