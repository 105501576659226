import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import RegistrationJson from "../../registration_login/labels/registration_en.json";
import "../../styles/candidate_nav.css";

const HomePageNav = () => {
    let accessToken = useSelector(state => state.loginReducer.accessToken);
    const session = JSON.parse(sessionStorage.getItem("session"));
    if(accessToken || (session && session?.jwtResponse?.token)) {
        return ""
    } else {
        return (
            <div>
                <ul className="navbar navbar-expand-lg navbar-dark bg-color-third row justify-content-end pr100"> 
                    <div>
                        <Link to="/candidate" className="nav-item col-md-1  " onClick={() => window.location.href="/candidate"}>
                            <span className="text-third-nav ml-1" data-toggle="tab">{RegistrationJson.HOME}</span>
                        </Link>
                        <Link to="/candidate/login" className="nav-item col-md-1" onClick={() => window.location.href="/candidate/login"}>
                            <span className="text-third-nav ml-1" data-toggle="tab">{RegistrationJson.Login}</span>
                        </Link>
                    </div>
                </ul>
            </div>
        )
    }
};

export default HomePageNav;