/* eslint-disable */
import axios from 'axios';
import { OAS_API_BASE_URL as baseUrlArr } from "../configuration";

let baseURL = "api.mpsconline.gov.in";
const currentDomain = window.location.hostname;

baseUrlArr && baseUrlArr.length > 0 && baseUrlArr.filter((e)=>{
    if(currentDomain === e.key){
        baseURL = e.value;
        axios.defaults.baseURL = e.baseUrl;
    }
});

export default axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: "json"
});