import moment from 'moment';
import 'moment-precise-range-plugin';

export const dateDifferece = (fromdate, todate) => {
    var date1 = moment(fromdate);
    var date2 = moment(todate).add(1, 'days');
    var difference = moment.preciseDiff(date1, date2, true);
    return difference
};

export const dateDiffereceExServiceMan = (fromdate, todate) => {
    var date1 = moment(fromdate);
    var date2 = moment(todate).add(1, 'days');
    var difference = moment.preciseDiff(date1, date2, true);
    return difference
};

export const toUTCDate = (date) => {
    return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : date;
};

export const fromUTCDate = (date) => {
    return date ? new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()): date;
};