/* eslint-disable */
import axios from "axios";
import FileDownload from "js-file-download";
import { toastr } from "react-redux-toastr";
import { hideLoader, showLoader } from '../../common/actions/commonActions';
import {
    ADVERTISEMENT,
    CHECK_ELIGIBILITY,
    GET_DOCUMENT_LIST,
    DOWNLOAD_DOCUMENT,
    UPLOAD_DOCUMENT,
    ADVERTISEMENT_PDF,
    APPLICATIONS,
    PAYNOW,
    SBIEPAY,
    BILLDESK,
    PROFILE_INFO_DISPLAY,
    MERGED_PROFILE_INFO_DISPLAY,
    API_CHALLAN_PAYMENT_RECEIPT,
    QUIKWALLET_EPAY,
    GET_ADVERTISEMENT_POST_SUBJECTS,
    GET_RETOTALING_REQUESTS_BY_APP_ID,
    MAKE_RETOTALING_REQUEST,
    SBIEPAY_RETOTALING,
    BILLDESK_RETOTALING,
    QUIKWALLET_EPAY_RETOTALING,
    PAYNOW_RETOTALING,
    EASEBUZZ_EPAY,
    EASEBUZZEPAY_RETOTALING,
    GETDEPARTMENTS,
    GETALLDEPARTMENTS
} from "../../common/constants/endpoints";
import {
    SET_ADVERTISEMENT_DETAILS,
    SET_ADVERTISEMENT_LIST,
    SET_ADVERTISEMENT_SHOW_DETAILS,
    SET_APPLICATION_ELIGIBILITY_INFO,
    SET_APPLICATION_POST_PREF_LIST,
    SET_UPLOADED_DOCUMENT,
    SET_UPLOAD_DOCUMENT_INFO_ID,
    SET_APPLICATION_IS_REQST_IN_PROGRESS,
    SET_PROCEED_TO_PAYMENT,
    SET_APPLICATION_ID,
    SET_PROFILE_INFO_DISPLAY,
    SET_CHECK_ELIGIBILITY_DISABLE,
    SET_RETOTALING_POST_SUBJECTS,
    SET_RETOTALING_REQUESTS,
    SET_RETOTALING_REQUEST_ID,
    SET_ALL_DEPARTMENTS_DATA,
    SET_ALLDEPARTMENTS_DATA,
} from "../../common/constants/ActionTypes";
import API from "../../helpers/API";
import { getMyApplications, setShowApplicationDetails, backToMyAccount } from "../../my_account/actions/myAccountAction";
import { formPost } from "../../helpers/util";
import advertisementDetailsLabel from "../labels/advertisement_details_en.json";

export const getAdvertisements = () => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(ADVERTISEMENT, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
                : error.message;
            toastr.error(errorMessage);
            return;
        }
        processAdvertisementsResponse(response, dispatch);
    };
};

const processAdvertisementsResponse = (response, dispatch) => {
    if (response && response.data) {
        let advertisementList = response.data;
        dispatch({
            payload: advertisementList,
            type: SET_ADVERTISEMENT_LIST,
        });
    }
};

export const getAdvertisementDetails = (advertisementId) => {
    return async (dispatch) => {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let profileId = session ? session.profileId : "";
        if (!profileId) {
            dispatch(hideLoader());
            return;
        }
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(ADVERTISEMENT + `/${advertisementId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
                : error.message;
            toastr.error(errorMessage+ advertisementDetailsLabel.AD_BLOCKER_NOTE);
            return;
        }
        processAdvertisementDetailsResponse(response, dispatch);
    };
};

export const processAdvertisementDetailsResponse = (response, dispatch) => {
    if (response && response.data) {
        let advertisementDetails = response;
        const examCenterOptionList = response.data.advertisementExamCenterList?.map(
            (advtExamCenter) => ({
                value: advtExamCenter.examCenter.id,
                label: advtExamCenter.examCenter.name,
                isDeleted: advtExamCenter.isDeleted
            })
        );
        dispatch({
            type: SET_ADVERTISEMENT_DETAILS,
            payload: advertisementDetails,
            examCenterOptionList,
        });
        const enabledPaymentMethods  = JSON.parse(response.data.advertisementConfig && response.data.advertisementConfig.enabledPaymentMethods);
        const disabledPaymentMethods = JSON.parse(response.data.advertisementConfig && response.data.advertisementConfig.disabledPaymentMethods)
        if(enabledPaymentMethods !== null && disabledPaymentMethods !== null){
            toastr.error(advertisementDetailsLabel.ENABLE_DISABLE_PAYMENT_NOTE)
            dispatch({
                type : SET_CHECK_ELIGIBILITY_DISABLE,
                payload : true
            })
        }else{
            dispatch({
                type : SET_CHECK_ELIGIBILITY_DISABLE,
                payload : false
            })            
        }
    }
};

export const setShowAdvertisementDetails = (
    showAdvertisementDetails,
    advertisementId
) => ({
    type: SET_ADVERTISEMENT_SHOW_DETAILS,
    payload: showAdvertisementDetails,
    advertisementId,
});

export const getCheckEligibility = (advertisementId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(CHECK_ELIGIBILITY, {
                params: { advertisementId },
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch(getMergedProfileInfoDisplay(advertisementId));
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage);
            return;
        }
        dispatch({
            type: SET_APPLICATION_ELIGIBILITY_INFO,
            payload: response.data,
        });
        dispatch({
            type: SET_APPLICATION_POST_PREF_LIST,
            payload: response.data.eligibilityResultDtoList
                .filter((elgbResult) => elgbResult.isEligible)
                .map((elgbResult) => ({
                    postCriteriaId: elgbResult.postCriteriaId,
                    isInterested: false,
                    isAssignAuthority: elgbResult.isAssignAuthority,
                    preferredAppointingAuthorities: null,
                })),
        });
    };
};

export const getDepartments = (postCriteriaId, applicationPostPreId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            if(postCriteriaId && applicationPostPreId){
                response = await API.get(GETDEPARTMENTS + `/${postCriteriaId}` +`?appPostPrefId=${applicationPostPreId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            else{
                response = await API.get(GETDEPARTMENTS + `/${postCriteriaId}` , {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage);
            return;
        }
        let updatedData = response.data;
        (updatedData.mumbai).map((e)=>{
            e.isDisable = false;
            e.isSelect = true
        });
        (updatedData.outsideMumbai).map((e)=>{
            e.isDisable = false;
            e.isSelect = false;
        });
        dispatch({
            type: SET_ALL_DEPARTMENTS_DATA,
            payload:updatedData
        });
    };
};

export const getAllDepartments = (advertisementId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
                response = await API.get(GETALLDEPARTMENTS + `/${advertisementId}` , {
                    headers: { Authorization: `Bearer ${token}` },
                });
                let updatedData = response.data;
                (updatedData).map((ele)=>{
                    (ele.appointintAuthorities.mumbai).map((e)=>{
                        e.isDisable = false;
                        e.isSelect = true
                    });
                (ele.appointintAuthorities.outsideMumbai).map((e)=>{
                    e.isDisable = false;
                    e.isSelect = false;
                });
            })
                dispatch({
                    type: SET_ALLDEPARTMENTS_DATA,
                    payload:updatedData
                });
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage);
            return;
        }
    }
};

export const getUploadDocument = (advertisementId,eligibilityResultDtoList) => {
    return async function (dispatch) {
        dispatch(showLoader());
        let postCriteriaIds = [];
        let postid = {}
        for (let i = 0; i < eligibilityResultDtoList.length; i++) {
            postid = eligibilityResultDtoList[i].postCriteriaId
            postCriteriaIds.push(postid)
       }
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(GET_DOCUMENT_LIST + `/${advertisementId}`+`/${postCriteriaIds}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage);
            return;
        }
        dispatch({
            type: SET_UPLOADED_DOCUMENT,
            payload: response.data,
        });
    };
};

export const getUploadDocumentForReCheckEligibility = (advertisementId,eligibilityResultDtoList,applicationId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        let postCriteriaIds = [];
        let postid = {}
        for (let i = 0; i < eligibilityResultDtoList.length; i++) {
            postid = eligibilityResultDtoList[i].postCriteriaId
            postCriteriaIds.push(postid)
       }
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(GET_DOCUMENT_LIST + `/${advertisementId}`+`/${postCriteriaIds}`+`/${applicationId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage);
            return;
        }
        dispatch({
            type: SET_UPLOADED_DOCUMENT,
            payload: response.data,
        });
    };
};

export const downloadDocument = (docId,documentTypeName) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url: DOWNLOAD_DOCUMENT + `/${docId}`,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            toastr.error(advertisementDetailsLabel.DOCUMENT_NOT_AVAILABLE_TO_DOWNLOAD);
            return;
        }
        FileDownload(
            response.data,
            `${documentTypeName}.pdf`
        );
    };
};

export const postUploadDocument = (data) => {
    const session = JSON.parse(sessionStorage.getItem("session"));
    let token = session.jwtResponse && session.jwtResponse.token
    let profileId = data.profileId ? data.profileId : session.profileId;
    let formData = new FormData();
    if(data.uploadPdfDoc) {
        formData.append('uploadPdfDoc', data.uploadPdfDoc);
    }
    if(data.applicationId){
        formData.append('applicationId', data.applicationId);
    }
    if(data.documentTypeId) {
        formData.append('documentTypeId', data.documentTypeId);
    }
    if(profileId) {
        formData.append('profileId', profileId);
    }
    let response;
    let header = {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "type": "formData",
    }
    return async (dispatch) => {
        dispatch(showLoader());
        try {
                response = await API.post(UPLOAD_DOCUMENT, formData, {
                    headers: header
                });
                if(response!=null)
                {
                    dispatch(hideLoader());
                } 
                toastr.success(advertisementDetailsLabel.DOCUMENT_UPLOADED_SUCCESSFULLY); 
                dispatch({
                    type:SET_UPLOAD_DOCUMENT_INFO_ID,
                    payload:response.data
                })
                if(data.applicationId){
                    dispatch(setShowApplicationDetails(data.applicationId))
                }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage)
        }
    }
};

export const getProfileInfoDisplay = (profileId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        if (!profileId) {
            profileId = session.profileId;
        }
        let response;
        try {
            response = await API.get(PROFILE_INFO_DISPLAY + `/${profileId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
            toastr.error(advertisementDetailsLabel.ERR_GETTING_PROFILE_INFO);
            return;
        }
        dispatch({
            type: SET_PROFILE_INFO_DISPLAY,
            payload: response.data,
        });
    };
};

export const getMergedProfileInfoDisplay = (advertisementId) => {
    return async function (dispatch) {
        dispatch(showLoader());
        const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
        try {
            response = await API.get(MERGED_PROFILE_INFO_DISPLAY + `/${advertisementId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({
                type: SET_PROFILE_INFO_DISPLAY,
                payload: response.data,
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
                                : error.message;
            toastr.error(errorMessage);
        }
    };
};

export const resetApplicationPostPref = () => {
    return (dispatch, getState) => {
        const { eligibilityResultList } = getState().advertisement;
        dispatch({
            type: SET_APPLICATION_POST_PREF_LIST,
            payload: eligibilityResultList
                ?.filter((elgbResult) => elgbResult.isEligible)
                .map((elgbResult) => ({
                    postCriteriaId: elgbResult.postCriteriaId,
                    isInterested: false,
                    isAssignAuthority: elgbResult.isAssignAuthority,
                })),
        });
    };
};

export const resetUploadeDocumentChecks = () => {
    return (dispatch, getState) => {
        const { uploadedDocumentData } = getState().advertisement;
        dispatch({
            type: SET_UPLOADED_DOCUMENT,
            payload: uploadedDocumentData
                .map(() => ({
                    isUploaded: false,
                })),
        });
    };
};

export const downloadAdvertisementPdf = (advertisementId) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await axios.request({
                url: ADVERTISEMENT_PDF + advertisementId,
                method: "GET",
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (err) {
            dispatch(hideLoader());
                toastr.error(advertisementDetailsLabel.PDF_NOT_AVAILABLE_TO_DOWNLOAD);
            return;
        }
        FileDownload(
            response.data,
            `Advertisement_Details_${advertisementId}.pdf`
        );
    };
};

export const doApply = (applnData) => {
    return async (dispatch, getState) => {
        dispatch(showLoader());
        const { isReqstInProgress } = getState().advertisement;
        if (isReqstInProgress) 
        {
            dispatch(hideLoader());
            return;
        }
        let response;
        dispatch({
            type: SET_APPLICATION_IS_REQST_IN_PROGRESS,
            payload: true,
        });
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await API.post(APPLICATIONS, applnData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch({
                type: SET_APPLICATION_IS_REQST_IN_PROGRESS,
                payload: false,
            });
            dispatch(getMyApplications())
            if(response.status===200){
            dispatch({
                type: SET_APPLICATION_ID,
                payload: response.data,
                });
            dispatch({
                    type: SET_PROCEED_TO_PAYMENT,
                    payload: true,
                });
             }
            if(response!=null)
            {
                dispatch(hideLoader());
            }
        } catch (error) {
            dispatch(hideLoader());
            dispatch({
                type: SET_APPLICATION_IS_REQST_IN_PROGRESS,
                payload: false,
            });
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message

             toastr.error(errorMessage);
            return;
        }
    };
};

export const doPayment = (applicationId,paymentType, data) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            if(paymentType=='online_payment'){
                if (data.paymentBank == 'sbi') {
                    //for sbi
                    response = await API.post(SBIEPAY + applicationId, {}, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    let paymentUrl;
                    if (response.data.isProdEnv) {
                        paymentUrl = `https://www.sbiepay.sbi/secure/AggregatorHostedListener`;
                        //paymentUrl = `https://www.sbiepay.sbi/secure/aggMerchantStatusQueryWithAmountAction`;
                    } else {
                        paymentUrl = `https://test.sbiepay.sbi/secure/AggregatorHostedListener`;
                        //paymentUrl = `https://test.sbiepay.sbi/secure/aggMerchantStatusQueryWithAmountAction`;
                    }
                    formPost(paymentUrl, {
                        EncryptTrans: response.data.encryptTrans,
                        merchIdVal: response.data.merchantId,
                        // merchantOrderNo: response.data.merchantOrder,
                        // Amount: response.data.amount
                    });
                    // window.location.href = paymentUrl;
                }
                    //for QuikWallet
               else if(data.paymentBank == 'quickPayWallet') {
                    // for quickPayWallet
                    response = await API.post(QUIKWALLET_EPAY + applicationId, {}, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    window.location.href = response.data.url;
                } 
                else if(data.paymentBank == 'easebuzz') {
                    // for easebuzz 9501235618
                    response = await API.get(EASEBUZZ_EPAY + `${applicationId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                  window.location.href =response?.data;
                }
                else if(data.paymentBank == 'billdesk') {
                    // for billdesk
                    response = await API.get(BILLDESK + `${applicationId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    };
                    let isProdEnv = response.data.isProdEnv;
                    let paymentMes = response.data.msg;
                    if(!isProdEnv){
                    let paymentUrl;
                        paymentUrl = `https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=${paymentMes}`;
                        window.location.href = paymentUrl;
                    }else{
                        let paymentUrl;
                        paymentUrl = `https://pgi.billdesk.com/pgidsk/PGIMerchantPayment?msg=${paymentMes}`;
                        window.location.href = paymentUrl;
                    }
                }
                else {
                    //for csc
                    response = await API.post(PAYNOW + applicationId, {}, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    let paymentUrl;
                    if(response.data.isProdEnv){
                        paymentUrl = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${response.data.merchantId}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`;
                    }else{
                        paymentUrl = `https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${response.data.merchantId}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`;
                    }
                    window.location.href = paymentUrl;
            }
                dispatch({
                    type: SET_PROCEED_TO_PAYMENT,
                    payload: false,
                });
            }else{
                if(data.paymentLocationTab =="my_account"){
                    response = await axios.request({
                        url: API_CHALLAN_PAYMENT_RECEIPT + applicationId,
                        method: "POST",
                        responseType: "blob",
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    FileDownload(
                        response.data,
                        `Chalan_Details_${applicationId}.html`
                    );
                }
                else{
                response = await axios.request({
                    url: API_CHALLAN_PAYMENT_RECEIPT + applicationId,
                    method: "POST",
                    responseType: "blob",
                    headers: { Authorization: `Bearer ${token}` },
                });
                if(response!=null)
                {
                    dispatch(hideLoader());
                }
                dispatch({
                    type: SET_PROCEED_TO_PAYMENT,
                    payload: false,
                });
                FileDownload(
                    response.data,
                    `Chalan_Details_${applicationId}.html`
                );
                dispatch(backToMyAccount(true))
                }
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message

            toastr.error(errorMessage);
            return;
        }
        // formPost('https://test.ccavenue.com/transaction.do?command=initiateTransaction', {
        //     encRequest: response.data.encRequest,
        //     access_code: response.data.accessCode,
        //     merchant_id: response.data.merchantId,
        // });
      
        //window.location.replace(paymentUrl);
        //window.location.assign()

        // const linkElem = document.createElement("a");
        // linkElem.href = paymentUrl;
        // linkElem.referrerpolicy = "no-referrer";
        // document.body.appendChild(linkElem);
        // linkElem.click();
    }
};

export const closePaymentModal = (flg) => {
    return (dispatch) => {
        dispatch({
            type: SET_PROCEED_TO_PAYMENT,
            payload: flg
        });
    };
};

export const getRetotalingPostSubjectsList = (applicationId)=>{
    return  async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            let type = "1";
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await API.get(GET_ADVERTISEMENT_POST_SUBJECTS+`/${applicationId}`+`/NOT QUALIFIED`+`/${type}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null){
                dispatch({
                    type: SET_RETOTALING_POST_SUBJECTS,
                    payload: response.data,
                    });
                     dispatch(hideLoader());
                 }
        } catch (err) {
            dispatch(hideLoader());
                toastr.error(advertisementDetailsLabel.NO_RECORD_FOUND);
            return;
        }
    };
};

export const getRetotalingRequestsList = (applicationId)=>{
    return  async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            response = await API.get(GET_RETOTALING_REQUESTS_BY_APP_ID+`/${applicationId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            if(response!=null){
                if(response.data.length > 0)
                {
                    for(let i=0; i < response.data.length; i++)
                    {
                        let details = '';
                        if(response.data[i].details!=null && response.data[i].details!=undefined && response.data[i].details!='')
                        {
                            details = JSON.parse(response.data[i].details);
                        }
                        response.data[i].details = details;
                    }
                    dispatch({
                        type: SET_RETOTALING_REQUESTS,
                        payload: response.data,
                        });
                }
                     dispatch(hideLoader());
                 }
        } catch (err) {
            dispatch(hideLoader());
                toastr.error(advertisementDetailsLabel.NO_RECORD_FOUND);
            return;
        }
    };
};

export const createRetotalingRequest = (reqData,applicationData,gotoRetotalingPayment,setShowRetotalingForm)=>
{
    return async (dispatch) => {
        dispatch(showLoader());
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
        let token = session.jwtResponse && session.jwtResponse.token;
        let response;
       let type= 1;
                response = await API.post(MAKE_RETOTALING_REQUEST+`/${type}`, reqData, {
                    headers: { Authorization: `Bearer ${token}` }
                }); 
                if(response!=null){
                    setShowRetotalingForm(false);
                    let req ={'id':response.data.id,'type':'retotaling'};
                    gotoRetotalingPayment(req,applicationData);
                   dispatch({
                    type: SET_RETOTALING_REQUEST_ID,
                    payload: response.data.id,
                    });
                    dispatch(hideLoader());
                }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ? error.response.data.message
            : error.message;
            toastr.error(errorMessage)
        }
    }
};

export const doRetotalingPayment = (retotalingRequestId,paymentType, data) => {
    return async (dispatch) => {
        dispatch(showLoader());
        let response;
        try {
            const session = JSON.parse(sessionStorage.getItem("session"));
            const token = session.jwtResponse && session.jwtResponse.token;
            if(paymentType=='online_payment'){
                if (data.paymentBank == 'sbi') {
                    //for sbi
                    response = await API.post(SBIEPAY_RETOTALING + retotalingRequestId, {}, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    let paymentUrl;
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    if (response.data.isProdEnv) {
                        paymentUrl = `https://www.sbiepay.sbi/secure/AggregatorHostedListener`;
                        //paymentUrl = `https://www.sbiepay.sbi/secure/aggMerchantStatusQueryWithAmountAction`;
                    } else {
                        paymentUrl = `https://test.sbiepay.sbi/secure/AggregatorHostedListener`;
                        //paymentUrl = `https://test.sbiepay.sbi/secure/aggMerchantStatusQueryWithAmountAction`;
                    }
                    formPost(paymentUrl, {
                        EncryptTrans: response.data.encryptTrans,
                        merchIdVal: response.data.merchantId,
                        // merchantOrderNo: response.data.merchantOrder,
                        // Amount: response.data.amount
                         
                    });
                    // window.location.href = paymentUrl;
                }
                    //for QuikWallet
               else if(data.paymentBank == 'quickPayWallet') {
                    // for quickPayWallet
                    response = await API.post(QUIKWALLET_EPAY_RETOTALING + retotalingRequestId, {}, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    window.location.href = response.data.url;
                } 
                else if(data.paymentBank == 'easebuzz') {
                    // for easebuzz
                    response = await API.post(EASEBUZZEPAY_RETOTALING + `${retotalingRequestId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    window.location.href = response.data;
                }
                else if(data.paymentBank == 'billdesk') {
                    // for billdesk
                    response = await API.get(BILLDESK_RETOTALING + `${retotalingRequestId}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    let isProdEnv = response.data.isProdEnv;
                    let paymentMes = response.data.msg;
                    if(!isProdEnv){
                    let paymentUrl;
                        paymentUrl = `https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=${paymentMes}`;
                        window.location.href = paymentUrl;
                    }else{
                        let paymentUrl;
                        paymentUrl = `https://pgi.billdesk.com/pgidsk/PGIMerchantPayment?msg=${paymentMes}`;
                        window.location.href = paymentUrl;
                    }
                }
                else {
                    //for csc
                    response = await API.post(PAYNOW_RETOTALING + retotalingRequestId, {}, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    let paymentUrl;
                    if(response!=null)
                    {
                        dispatch(hideLoader());
                    }
                    if(response.data.isProdEnv){
                        paymentUrl = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${response.data.merchantId}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`;
                    }else{
                        paymentUrl = `https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${response.data.merchantId}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`;
                    }
                    window.location.href = paymentUrl;
            }
                dispatch({
                    type: SET_PROCEED_TO_PAYMENT,
                    payload: false,
                });
                dispatch(backToMyAccount(true))
            }
        } catch (error) {
            dispatch(hideLoader());
            let errorMessage = (error.response && error.response.data) ?
            error.response.data.message: error.message

            toastr.error(errorMessage);
            return;
        }
    }
};