/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PersonalInformation from './PersonalInformation';
import AddressInformation from './AddressInformation';
import ExperienceInformation from './ExperienceInformation';
import OtherInformation from './OtherInformation';
import QualificationInformation from './QualificationInformation';
import UploadInformation from './UploadDocument';
import UploadPhotoAndSignature from './UploadPhotoAndSignature';
import profile from '../labels/profile_en.json';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setProfileCreationDropdown, getUploadedImageAndSignature, getProfileStatus, getPersonalInfoData, getAllDisabilities} from '../actions/profileAction';
import renderStatusIcon from '../helper/renderStatusIcon';
import ModuleHide from '../../common/components/ModuleHide';
import '../../styles/profile.css'

function ProfileCreation() {  
    const [stepName, setName] = useState("1");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setProfileCreationDropdown());
        dispatch(getAllDisabilities());
        dispatch(getPersonalInfoData());
        dispatch(getUploadedImageAndSignature());
        dispatch(getProfileStatus());
    }, []);

    let profileCreationAllLookupData = useSelector(state => state.profile.profileCreationAllLookupData);
    let statesList = useSelector(state => state.profile.statesList);
    let { flgSubmittableList} = useSelector(state => state.profile);
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
    const [arraymModule , setarraymModule] = useState({})
    let { updateProfileDone } = useSelector(state => state.myaccount, shallowEqual);

    useEffect(() => {
        if(updateProfileDone) {
            setName("1")
        }
    }, [updateProfileDone]);

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "PROFILE_CREATION_TAB")[0])
    },[moduleList])

    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    (
          <div className="tab-content border border-light m-4 position-relative mt12" id="myTabContentContainer">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {profile.headerField && profile.headerField.map((ele, index) =>
                      <li className="nav-item" key={ele.id}>
                          <a className={stepName === ele.id ? "nav-link active" : "nav-link bg-profile-tabs"} value="home" id="home-tab" onClick={() => setName(ele.id)} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="false">
                                {
                                    renderStatusIcon(Object.values(flgSubmittableList)[index])
                                }
                                <span>{ele.steps}</span><br></br>
                                <span>{ele.name}</span>
                          </a>
                      </li>
                  )}
              </ul>
              <div className="tab-content border border-light" id="myTabContent">
                  <div className={stepName === "1" ? "tab-pane fade show active bg-white boxShadowCss" : "tab-pane fade bg-white boxShadowCss"} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <PersonalInformation profileCreationAllLookupData={profileCreationAllLookupData}/>
                  </div>
                  <div className={stepName === "2" ? "tab-pane fade show active bg-white" : "tab-pane fade bg-white"} id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                      <AddressInformation statesList={statesList} />
                  </div>
                  <div className={stepName === "3" ? "tab-pane fade show active bg-white" : "tab-pane fade bg-white"} id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                      <OtherInformation />
                  </div>
                  <div className={stepName === "4" ? "tab-pane fade show active bg-white" : "tab-pane fade bg-white"} id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                      <QualificationInformation />
                  </div>
                  <div className={stepName === "5" ? "tab-pane fade show active bg-white" : "tab-pane fade bg-white"} id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                      <ExperienceInformation profileCreationAllLookupData={profileCreationAllLookupData}/>
                  </div>
                  <div className={stepName === "6" ? "tab-pane fade show active bg-white" : "tab-pane fade bg-white"} id="profile" role="tabpanel" aria-labelledby="profile-tab" >
                    <UploadPhotoAndSignature />
                  </div>
                  <div className={stepName === "7" ? "tab-pane fade show active bg-white" : "tab-pane fade bg-white"} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                     <UploadInformation />
                  </div>
              </div>
          </div>  
  );
}

export default ProfileCreation;