import React from 'react';
import RegistrationJson from "../../registration_login/labels/registration_en.json";

const NotFound = () => {
    return (
        <div>
            <h2 className="d-flex justify-content-center bold-text">{RegistrationJson.PAGE_NOT_FOUND}</h2>
        </div>
    )
};
export default NotFound;