import { SET_SECURITY_QUESTIONS, SET_REGISTRATION_RESPONSE, SET_SECURITY_QUESTIONS_FORGOT_PASSWORD } from "../../common/constants/ActionTypes";

const initialState = {
    securityQuestions: [],
    flgRegistrationSuccess: false,
    securityQueForgotPassword: []
}

export default function registrationReducer(state = initialState, action) {
    switch(action.type) {
        case SET_SECURITY_QUESTIONS:
            return {
                ...state,
                securityQuestions: action.payload
            }
        case SET_SECURITY_QUESTIONS_FORGOT_PASSWORD:
            return {
                ...state,
                securityQueForgotPassword: action.payload
            }
        case SET_REGISTRATION_RESPONSE:
            return {
                ...state,
                flgRegistrationSuccess: action.payload
            }
        default:
            return state;
    }
}