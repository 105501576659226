/* eslint-disable */
import React from "react";
import "../../styles/candidate_nav.css";
import "../../styles/my_account.css";
import { Modal } from 'react-bootstrap';
import "../../styles/advertisementTable.css";
import "../../styles/profile.css";
import "../../styles/home.css";
import "../../styles/advertisement.css";
import common from "../labels/common_en.json"

function ViewAppliedDocumentsModal(props) {
    const mumbaiDataList = props.allDepartments.mumbai;
    const outsideMumbaiDataList = props.allDepartments.outsideMumbai;

    const modalCancel = () => {
        props.setAppliedDocumentModal(false)
    };

    return (
        <form className="MarksheetModel mt50">
            <div>
                <Modal show={props.showModal} onHide={modalCancel} backdrop='static' size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{common.APPLIED_DEPT}</Modal.Title>
                    </Modal.Header>
                    <div>
                        <Modal.Body>
                            <div className="row">
                                <hr />
                                <div className="card-body whitespace-pre-wrap p-0">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" key={2} className="word-break w60">{common.MUMBAI_DEPT}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mumbaiDataList && mumbaiDataList.map((e) => (
                                                <tr key={e.id}>
                                                    <td className="word-break">{e.label}</td>
                                                </tr>
                                            ))}
                                            {!mumbaiDataList || mumbaiDataList.length <= 0 &&
                                                <h6 className="text-muted text-center">{common.NO_DEPT_SELECTED}</h6>
                                            }
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th scope="col" key={2} className="word-break w60">{common.OTHER_THAN_MUMBAI_DEPT}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {outsideMumbaiDataList && outsideMumbaiDataList.map((e) => (
                                                <tr key={e.id}>
                                                    <td className="word-break">{e.label}</td>
                                                </tr>
                                            ))}
                                            {!outsideMumbaiDataList || outsideMumbaiDataList.length <= 0 &&
                                                <h6 className="text-muted text-center">{common.NO_DEPT_SELECTED}</h6>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CLOSE}</button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </form>
    );
}
export default ViewAppliedDocumentsModal;