function getMaxFitImageDimen(cropWidth, cropHeight) {
  const MAX_WIDTH = 300;
  const MAX_HEIGHT = 200;
  if ((cropWidth / cropHeight) > (MAX_WIDTH / MAX_HEIGHT)) {
    if (cropWidth < MAX_WIDTH) {
      return {
        width: cropWidth,
        height: cropHeight,
      }
    } else {
      return {
        width: MAX_WIDTH,
        height: cropHeight * MAX_WIDTH / cropWidth,
      }
    }
  } else {
    if (cropHeight < MAX_HEIGHT) {
      return {
        width: cropWidth,
        height: cropHeight,
      }
    } else {
      return {
        width: cropWidth * MAX_HEIGHT / cropHeight,
        height: MAX_HEIGHT,
      }
    }
  }
};

function readFileData(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function(e) {
        resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  })
};

function createImageElem(imageData) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    if (typeof(imageData) == "string") {
      image.src = imageData;
    } else {
      image.src = window.URL.createObjectURL(imageData);
    }
    image.onload = function () {
      resolve(image);
    }
  });
};

function canvasToBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => resolve(blob), 'image/jpeg');
  });
};

export async function getResizedImage(imageData) {
  if (!imageData) {
    return;
  };

  if (imageData instanceof File) {
    imageData = await readFileData(imageData);
  };

  const image = await createImageElem(imageData);
  const canvas = document.createElement('canvas');
  const maxFitSize = getMaxFitImageDimen(image.naturalWidth, image.naturalHeight);
  canvas.width = maxFitSize.width;
  canvas.height = maxFitSize.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    image,
    0,
    0,
    maxFitSize.width,
    maxFitSize.height
  );
  return await canvasToBlob(canvas);
};
