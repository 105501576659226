import { getPasswordStrength } from '../../helpers/PasswordStrength';
import RegistrationJson from "../../registration_login/labels/registration_en.json";

const colors = ['gray','red', 'red', 'orange', 'yellow', 'green'];
const names = ['None', 'Low', 'Low', 'Medium', 'Medium', 'Strong'];

export default function PasswordMeter(props) {
  const strength = getPasswordStrength(props.password);
  const containerStyle = {
    width: '150px',
    height: '5px',
    backgroundColor: 'gray',
  };
  const indicatorStyle = {
    width: (strength*30)+'px',
    height: '5px',
    backgroundColor: colors[strength],
  }
  return (
    <div className='mt-1'>
      <div>{RegistrationJson.PASSWORD_STRENGTH} {names[strength]}</div>
      <div style={containerStyle}>
        <div style={indicatorStyle}></div>
      </div>
    </div>
  )
};
