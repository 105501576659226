export const SECURITY_QUESTIONS = '/oas/api/v1/securityquestion';
export const REGISTRATION = '/oas/api/v1/register';
export const LOGIN = '/oas/api/v1/authenticate';
export const OTPS = '/oas/api/v1/otps';
export const ADDRESS_INFORMATION ='/oas/api/v1/addressinformationdraft';
export const EXPERIENCE_INFORMATION ='/oas/api/v1/experienceinformationdraft';
export const QUALIFICATION_INFORMATION ='/oas/api/v1/qualificationinformationdraft';
export const OTHER_INFORMATION = '/oas/api/v1/otherinformationdraft'
export const PERSONALINFORMATION ='/oas/api/v1/createpersonalinformation';
export const LOOKUP_OPTION ='/oas/api/v1/lookupoption';
export const LOOKUP_OPTIONS ='/oas/api/v1/lookupoptions';
export const STATES ='/oas/api/v1/state';
export const DISTRICTS ='/oas/api/v1/district';
export const TALUKAS ='/oas/api/v1/taluka';
export const VILLAGES ='/oas/api/v1/village';
export const QUALIFICATION_TYPES ='/oas/api/v1/qualificationtype';
export const QUALIFICATION_NAME ='/oas/api/v1/qualificationname';
export const SUBJECTS = '/oas/api/v1/subject';
export const UNIVERSITIES = '/oas/api/v1/university';
export const PROFILE_CREATION = "/oas/api/v1/personalinformationdraft";
export const PHOTO_URL = "/oas/api/v1/photosignaturedraft";
export const GET_DISPLAYABLE_QUALIFICATION_LIST = "/oas/api/v1/getdisplayablequalificationlist";
export const PROFILE = "/oas/api/v1/profile";
export const FORGETPASSWORD = "/oas/api/v1/forgotpassword";
export const RESETPASSWORD = "/oas/api/v1/resetpassword";
export const ADVERTISEMENT ="/oas/api/v1/advertisements"
export const CHECK_ELIGIBILITY ="/oas/api/v1/checkeligibility";
export const UPDATEFEESPAIDAPPLICATION = "/oas/api/v1/updatefeespaidapplication";
export const GET_DOCUMENT_LIST = "/oas/api/v1/advertisementreqdocuments";
export const DOWNLOAD_DOCUMENT= "/oas/api/v1/downloadrequireddocuments";
export const UPLOAD_DOCUMENT = "/oas/api/v1/uploadrequireddocuments";
export const ADVERTISEMENT_PDF = "/oas/api/v1/downloadpdfadvertisement/";
export const APPLICATIONS = "/oas/api/v1/applications";
export const POST_PREFERENCE_DETAILS = "/oas/api/v1/preferences";
export const UPDATED_POST_PREFERENCE_DETAILS = "/oas/api/v1/preferences";
export const OPT_OUT_FROM_APPLICATION = "/oas/api/v1/optout"
export const HAS_PENDING_TRANSACTIONS = "/oas/api/v1/payment/haspendingtransactions";
export const RECONCILE_NOW = "/oas/api/v1/payment/reconcilenow";
export const PROFILE_INFO_DISPLAY = "/oas/api/v1/profilecreationdraft";
export const API_REPO_VERSION = "/oas/api/v1/version";
export const API_APPLICATION_RECEIPT = "/oas/api/v1/receipts/";
export const API_CHALLAN_PAYMENT_RECEIPT = "/oas/api/v1/selectchallanpayment/";
export const API_CHALLAN_SLIP ="/oas/api/v1/downloadchallanslip/";
export const CHANGE_PASSWORD ="/oas/api/v1/changepassword";
export const VERIFYOTP = "/oas/api/v1/verifyotps";
export const OTP_FOR_EMAIIL_MOBILE="/oas/api/v1/otpsforemailmobile";
export const UPDATE_PROFILE="/oas/api/v1/updateprofile";
export const FORGOT_LOGIN = "/oas/api/v1/forgotlogin";
export const PROFILE_SUMMARY = "/oas/api/v1/profilesummary";
export const LOGOUT = "/oas/api/v1/logout";
export const SESSION_HEARTBEAT = "/oas/api/v1/sessionheartbeat";
export const CANCEL_APPLICATION ="/oas/api/v1/cancelapplications";
export const MERGED_PROFILE_INFO_DISPLAY = "/oas/api/v1/profilecreationmergeddraft";
export const PAYNOW = "/oas/api/v1/paynow/";
export const PAYMENTDETAILS = "/oas/api/v1/paymentdetails/";
export const SBIEPAY= "/oas/api/v1/paynow/sbiepay/";
export const BILLDESK= "/oas/api/v1/payment/billdesk/";
export const GUIDELINELINKS = "/oas/api/v1/admin/guidelinelinks";
export const VERIFY_CANDIDATES = "/oas/api/v1/verifyresetemailmobile";
export const UPDATEEMAILMOBILE= "/oas/api/v1/updateresetemailmobile";
export const EXAM_CENTER_LIST = "/oas/api/v1/examcentersbyadvtid";
export const QUIKWALLET_EPAY= "/oas/api/v1/paynow/quickwallet/";
export const USERCORRESPONDENCE = "/oas/api/v1/correspondence/usercorrespondence";
export const DOWNLOADCORRESPONDENCEDOC = "/oas/api/v1/correspondence/downloadcorrespondencedoc";
export const USER_APPLICATION_STAGES = "/oas/api/v1/userapplicationstages/userdata";
export const VERIFY_SECURITY_ANSWER = "/oas/api/v1/verifysecurityanswers";
export const UPDATE_SECURITY_ANSWER = "/oas/api/v1/updatesecurityanswer";
export const RECHECK_ELIGIBILITY= "/oas/api/v1/recheckeligibility/";
export const MODULE_CHECK= "/oas/api/v1/module";
export const LOG_OUT_USER_OTP= "/oas/api/v1/logoutother";
export const LOG_OUT_OTP_VERIFICATION= "/oas/api/v1/validateotpforlogout";
export const GETMARKSHEET = "/oas/api/v1/marksheet";
export const DOWNLOADMARKSHEET = "/oas/api/v1/downloadmarksheet";
export const GET_ADVERTISEMENT_POST_SUBJECTS = "/oas/api/v1/retotaling/getsubjects";
export const GET_RETOTALING_REQUESTS_BY_APP_ID = "/oas/api/v1/retotaling/requests/application";
export const MAKE_RETOTALING_REQUEST = "/oas/api/v1/retotaling/requests";
export const QUIKWALLET_EPAY_RETOTALING = "/oas/api/v1/retotaling/paynow/quickwallet/";
export const PAYNOW_RETOTALING = "/oas/api/v1/retotaling/paynow/ccavenue/";
export const SBIEPAY_RETOTALING = "/oas/api/v1/retotaling/paynow/sbiepay/";
export const BILLDESK_RETOTALING = "/oas/api/v1/retotaling/payment/billdesk/";
export const EASEBUZZEPAY_RETOTALING = "/oas/api/v1/retotaling/paynow/easebuzz/";
export const EASEBUZZ_EPAY = "/oas/api/v1/easebuzz/";
export const EASEBUZZ_CALLBACK = '/oas/api/v1/payment/easebuzz/';
export const GET_ADDITIONAL_RES_ENV = "/oas/api/v1/profilecreation/getadditionalreservationrequired";
export const GETDEPARTMENTS = "/oas/api/v1/admin/getdepartments";
export const GETALLDEPARTMENTS = "/oas/api/v1/admin/getallappointingauthorities";
export const GETADMITCARD = "/oas/api/v1/admin/timetable/admitcard";
export const GETOBJECTIONREQUEST = "/oas/api/v1/objection/all";
export const ADDOBJECTIONREQUEST="/oas/api/v1/objection";
export const DROPDOWNOBJECTIONREQ = "/oas/api/v1/objectionrequest/dropdowndetails";
export const GETOBJREDBYID = "/oas/api/v1/objection";
export const UPDATEOBJREQ = "/oas/api/v1/objection";
export const GETOBJPAGINATION = "/oas/api/v1/objection";
export const MANAGEOBJREQ = "/oas/api/v1/objection/manage";
export const UPLOADIMAGEREQ = "/oas/api/v1/objection/uploadreferencedoc";
export const GETOBJECTIONQUESTIONS = "/oas/api/v1/objection/objectionquestions";
export const SBIEPAY_OBJECTION_REQUEST ="/oas/api/v1/objectionrequest/paynow/sbiepay/";
export const QUIKWALLET_EPAY_OBJECTION_REQUEST ="/oas/api/v1/objectionrequest/paynow/quickwallet/";
export const EASEBUZZEPAY_OBJECTION_REQUEST ="/oas/api/v1/objectionrequest/paynow/easebuzz/";
export const BILLDESK_OBJECTION_REQUEST ="/oas/api/v1/objectionrequest/paynow/billdesk/";
export const CSC_OBJECTION_REQUEST ="/oas/api/v1/objectionrequest/paynow/ccavenue/";
export const GET_PUBLICATION_TYPE_DATA = "/oas/api/v1/publicationname";
export const SET_ADDITIONAL_QUESTIONS = "/oas/api/v1/additionalquestions";
export const GET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD = "/oas/api/v1/admin/admitcard/examdisplaynames";
export const GET_VERIFY_CAPTCHA="/oas/api/v1/verifycaptcha";
export const DOWNLOAD_INTERVIEW_PT_LETTER="/oas/api/v1/download/interviewptletter";