/* eslint-disable */
import React, { useState, useEffect } from "react";
import "../../styles/candidate_nav.css";
import "../../styles/my_account.css";
import { Modal } from 'react-bootstrap';
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import common from "../labels/common_en.json"
import { lockPreferenceOtp, verificationOfOTP, setUpdatedPostPreferenceDetails } from "../actions/myAccountAction";
import experienceInfoLabels from "../../candidate_profile_creation/labels/experience_information_en.json";
import "../../styles/advertisementTable.css";

function PreferenceChangeModal(props) {
    const { register, errors, watch, setError, formState } = useForm({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const [dummy, setDummy] = useState(0);
    const [preferenceVal, setPreferenceVal] = useState(0);
    const [preferenceValErr, setPreferenceValErr] = useState(false);
    const [preferenceChangeList, setPreferencehangeList] = useState([]);
    const [disableAddBtn, setDisableAddBtn] = useState(false);
    const [disableLockBtn, setDisableLockBtn] = useState(false);
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [disableLockPreferenceDetails, setDisableLockPreferenceDetails] = useState(false);
    let postPreferenceDetails = props.postPreferenceDetails;
    const session = JSON.parse(sessionStorage.getItem("session"));
    const emailId = session && session.emailId;
    const mobileNumber = session && session.mobileNumber;

    const { profileUpdateEmailAndMobileOtpResponse, verifyEmailOtpFlag, verifyMobileOtpFlag } = useSelector(state => state.myaccount, shallowEqual);

    useEffect(() => {
        startCount();
    }, [profileUpdateEmailAndMobileOtpResponse])

    function getMobileMask(mobileno) {
        if (mobileno) {
            const mobilenumber = mobileno.toString();
            const maskMobileNumber = mobilenumber.replace(mobilenumber.substring(3, 8), 'XXXXXX')
            return maskMobileNumber
        }
    };

    const verificationEmailOTP = (type) => {
        let reqBody;
        let otpid;
        let otptype;
        profileUpdateEmailAndMobileOtpResponse.map((ele, key) => {
            if (ele.type === type) {
                otpid = ele.id;
                otptype = ele.type
            }
        })
        if (watch("OTP_email") && otptype === 'email') {
            reqBody = {
                "username": emailId,
                "otpId": otpid,
                "otpType": otptype,
                "enteredOtp": watch("OTP_email")
            }
            dispatch(verificationOfOTP(reqBody, 'email'))
        } else {
            setError("OTP_email", { type: "required" });
        }
    };

    const verificationMobileOTP = (type) => {
        let reqBody;
        let otpid;
        let otptype;
        profileUpdateEmailAndMobileOtpResponse.map((ele, key) => {
            if (ele.type === type) {
                otpid = ele.id;
                otptype = ele.type
            }
        })
        if (watch("OTP_mobile") && otptype === 'mobile') {
            reqBody = {
                "username": emailId,
                "otpId": otpid,
                "otpType": otptype,
                "enteredOtp": watch("OTP_mobile")
            }
            dispatch(verificationOfOTP(reqBody, 'mobile'))
        } else {
            setError("OTP_mobile", { type: "required" });
        }
    };

    const startCount = () => {
        let time = '';
        profileUpdateEmailAndMobileOtpResponse && profileUpdateEmailAndMobileOtpResponse.map(e => {
            time = parseInt(e.expiryDuration)
        })
        let start2 = '';
        if (time) {
            start2 = time;
        }
        const createClock = setInterval(displayT, 1000);

        function displayT() {
            if (start2 != '') {
                start2 = start2 - 1;
            }
            if (start2 == 0) {
                clearInterval(createClock)
            }
        }
    };

    const handlePreferenceChange = (value) => {
        setPreferenceValErr(false);
        setPreferenceVal(value);
        setDisableAddBtn(false);
    };

    const handleAddButtonClick = () => {
        setDisableAddBtn(true);
        if (preferenceVal === 0 || preferenceVal === "0") {
            setPreferenceValErr(true);
        } else {
            postPreferenceDetails.forEach((e) => {
                if (e.postId == preferenceVal) {
                    e.isSelected = true;
                    let newData = [...preferenceChangeList];
                    newData.push({
                        "postName": e.postName,
                        "postId": e.postId,
                    });
                    setPreferencehangeList(newData)
                }
            })
            setPreferenceVal(0);
        }
    };

    const modalCancel = () => {
        props.setPreferenceModal(false)
        setShowVerifyModal(false);
        setPreferencehangeList([]);
        setPreferenceVal(0);
        setDisableLockPreferenceDetails(false);
    };

    const updateModalCancel = () => {
        setShowVerifyModal(false);
        setDisableLockPreferenceDetails(false);
    };

    const modalSave = () => {
        let appNo = props.applicationNo;
        props.setPreferenceModal(false);
        const prefList = preferenceChangeList.map((pref, index) => ({ ...pref, prefOrder: index + 1 }));
        dispatch(setUpdatedPostPreferenceDetails(appNo, prefList, profileUpdateEmailAndMobileOtpResponse));
        setShowVerifyModal(false);
        setDisableLockPreferenceDetails(false);
        setPreferencehangeList([]);
        setPreferenceVal(0);
    };

    const handleRemoveBtnClick = (id) => {
        postPreferenceDetails.forEach((e) => {
            if (e.postId == id) {
                e.isSelected = false;
            }
        })
        const updatedData = preferenceChangeList.filter((item) => item.postId !== id);
        setPreferencehangeList(updatedData);
        toastr.success(common.POST_REMOVED_MES);
    };

    const handleLockedClick = () => {
        setShowVerifyModal(true);
        dispatch(lockPreferenceOtp())
    };

    const movePostPrefUp = (index) => {
        if (index == 0) return;
        const tmp = preferenceChangeList[index];
        preferenceChangeList[index] = preferenceChangeList[index - 1];
        preferenceChangeList[index - 1] = tmp;
        setDummy(dummy + 1);
    };

    const movePostPrefDown = (index) => {
        if (index >= (preferenceChangeList.length)) return;
        const tmp = preferenceChangeList[index];
        preferenceChangeList[index] = preferenceChangeList[index + 1];
        preferenceChangeList[index + 1] = tmp;
        setDummy(dummy + 1);
    };

    const handleSubmitClick = () => {
        setDisableLockPreferenceDetails(true);
        setShowVerifyModal(false);
        toastr.success(common.PREFERENCE_LOCK_MES);
        setDisableLockBtn(true);
    };

    return (
        <form className="MarksheetModel mt50">
            <div>
                <Modal show={props.showModal} onHide={modalCancel} backdrop='static' size="xl" className="f18 ml-4">
                    <Modal.Header closeButton>
                        <Modal.Title> {common.PREFERENCE_CHANGE} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="card-body whitespace-pre-wrap p-0">
                                <table className="table table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" key={2} className="word-break w80"> {experienceInfoLabels.POST_NAME} </th>
                                            <th scope="col" key={3} className="word-break w20"> {common.ACTION} </th>
                                        </tr>
                                    </thead>
                                    <tbody className="f12">
                                        <tr >
                                            <td className="word-break" >
                                                <select
                                                    className="form-control"
                                                    name="disabilityCompTime"
                                                    value={preferenceVal}
                                                    onChange={(e) => handlePreferenceChange(e.target.value)}
                                                    disabled={(disableLockPreferenceDetails) || (props && props.isReadOnlyPreferenceChangeStage)}
                                                >
                                                    <option key="" value={0}> {common.SELECT} </option>
                                                    {postPreferenceDetails && postPreferenceDetails.map((ele, i) => {
                                                        return <option key={i} value={ele.postId} disabled={ele.isSelected}>{ele.postName}</option>
                                                    })}
                                                </select>
                                                {preferenceValErr &&
                                                    <span className="errorText">{common.validationMessage.FIELD_REQ} </span>}
                                            </td>
                                            <div>
                                                <button type="button" className="btn btn-outline-primary btn-sm f15 cursor-pointer m-3" onClick={handleAddButtonClick} disabled={(disableAddBtn) || (disableLockPreferenceDetails) || (props && props.isReadOnlyPreferenceChangeStage)}>
                                                    {common.ADD}
                                                </button>
                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {preferenceChangeList && preferenceChangeList.length > 0 &&
                                <div className="card-body whitespace-pre-wrap p-0">
                                    <hr />
                                    <h5 className="f18 ml-4">{common.UPDATED_PREF}</h5>
                                    <table className="table table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" key={2} className="word-break w80"> {experienceInfoLabels.POST_NAME} </th>
                                                <th scope="col" key={3} className="word-break w20"> {common.ACTION} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {preferenceChangeList.map((e, index) => {
                                                return (
                                                    <tr>
                                                        <td className="word-break f13"> {e.postName}</td>
                                                        <td className="word-break">
                                                            <div className="row f10 justify-content-around">
                                                                <button type="button" className="btn btn-outline-primary btn-sm f12 cursor-pointer" disabled={disableLockPreferenceDetails} onClick={() => handleRemoveBtnClick(e.postId)}>
                                                                    {common.REMOVE}
                                                                </button>
                                                                {!disableLockPreferenceDetails &&
                                                                    <div className="arrange-arrows d-flex flex-column justify-content-between">
                                                                        <div>
                                                                            {(index > 0) &&
                                                                                <i className="fa fa-arrow-up"
                                                                                    onClick={() => movePostPrefUp(index)}
                                                                                    mtitle="Move Up"
                                                                                >
                                                                                </i>
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {(index < (preferenceChangeList.length - 1)) &&
                                                                                <i className="fa fa-arrow-down"
                                                                                    onClick={() => movePostPrefDown(index)}
                                                                                    title="Move Down"
                                                                                >
                                                                                </i>
                                                                            }
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div>
                                        <button type="button" className="btn btn-outline-primary btn-sm f15 cursor-pointer mr96 mt-4 float-right" disabled={disableLockPreferenceDetails || (postPreferenceDetails.length !== preferenceChangeList.length)} onClick={handleLockedClick}>
                                            {common.LOCK_PREF}
                                        </button>
                                    </div>
                                </div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={modalCancel} >{common.CANCEL}</button>
                        <button type="button"
                            className="btn btn-primary"
                            onClick={modalSave}
                            disabled={disableLockBtn === false}
                        >
                            {common.SAVE}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={showVerifyModal} onHide={updateModalCancel} backdrop='static' centered>
                    <Modal.Header closeButton>
                        <Modal.Title> {common.LOCK_PREF} </Modal.Title>
                    </Modal.Header>
                    <div>
                        <Modal.Body>
                            <div>
                                <span className="Note-color"> {common.UPDATE_PROFILE_NOTE} </span>
                            </div>
                            <div className="form-group">
                                <label className="LeftText">{common.Email_OTP} {'('}{emailId ? emailId.replace(emailId.substring(2, 12), 'XXXXXX') : ""}{')'}<span className="text-danger"> *</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" maxLength={6} name="OTP_email" ref={register({ required: true })} aria-label="emailotp" aria-describedby="button-addon2" disabled={verifyEmailOtpFlag} />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={() => { verificationEmailOTP('email') }} disabled={verifyEmailOtpFlag}>{common.VERIFY_OTP}</button>
                                    </div>

                                </div>
                                {_.get("OTP_email.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label className="LeftText">{common.Mobile_OTP} {getMobileMask('(' + mobileNumber + ')')}<span className="text-danger"> *</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" maxLength={6} name="OTP_mobile" ref={register({ required: true })} aria-label="mobileotp" aria-describedby="button-addon2" disabled={verifyMobileOtpFlag} />
                                    <div className="input-group-append">
                                        <button className="btn btn-secondary f12" type="button" id="button-addon2" onClick={() => { verificationMobileOTP('mobile') }} disabled={verifyMobileOtpFlag}>{common.VERIFY_OTP}</button>
                                    </div>
                                </div>
                                {_.get("OTP_mobile.type", errors) === "required" && (
                                    <p className="errorText">{common.validationMessage.OTP_REQ}</p>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={updateModalCancel} >{common.CANCEL}</button>
                            <button className="btn btn-primary" disabled={!(verifyMobileOtpFlag && verifyEmailOtpFlag && formState.isValid)} onClick={handleSubmitClick}>{common.CONFIRM} </button>
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </form>
    );
}
export default PreferenceChangeModal;