import { SET_FORGOT_PASSWORD_STATE, SET_USER_LOGIN_DATA} from "../../common/constants/ActionTypes";

const initialState = {
    isShowResetPassword: false,
    forgetPasswordDetails: {
        "email": "",
        "dob": "",
        "emailOtpId":"",
        "enteredEmailOtp": ""
    },
    userLoginData: [],
    securityAnswerMap: {}
}

export default function forgotPasswordReducer(state = initialState, action) {
    switch(action.type) {
        case SET_FORGOT_PASSWORD_STATE:
            return {
                ...state,
                isShowResetPassword: action.payload.flgSubmit,
                forgetPasswordDetails: action.payload.submitDetails,
                securityAnswerMap: action.payload.securityAnswerList
            }
        case SET_USER_LOGIN_DATA: 
            return{
                ...state,
                userLoginData: action.payload,
            }
        default:
            return state;
    }
}