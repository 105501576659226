import React from 'react';
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from 'react-redux';
import RegistrationJson from "../../registration_login/labels/registration_en.json";

const Loader = (flg) => {
    let loading = useSelector(state => state.common.loading);
    return (loading || (flg && flg.showFlg)) ?
            <div className="appLoader">
                <Button variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                </Button>&nbsp;
                <Button variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    &nbsp;{RegistrationJson.LOADING}
                </Button>
            </div> 
        : 
            "";
};

export default Loader;