/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../styles/candidate_nav.css";
import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash/fp";
import ModuleHide from "../../common/components/ModuleHide";
import commonLabels from "../../my_account/labels/common_en.json";
import "../../styles/home.css";

export default function CandidateHome() {
    const { moduleList } = useSelector(state => state.loginReducer, shallowEqual);
     const [arraymModule , setarraymModule] = useState({})

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };

    useEffect(()=>{
        setarraymModule( moduleList && moduleList.filter((ele) => ele.moduleName === "HOME_TAB")[0])
    },[moduleList])
    
    let squarebullet = "◾"
    
    return arraymModule && arraymModule.isEnabled == false ? < ModuleHide details={arraymModule}/>  :
    (
        <div>
            <div className="container">
                <div className="row card d-flex mb-4 mt-4">
                    <div className="col-md-12 border border-light bg-white">
                        <p className="text-left ml-4">{commonLabels.HOME_NOTE_1}</p>

                        <p className="borderBottomCss mx-4"></p>
                        <h5 className="text-left ml-4 mt-2">{commonLabels.HOME_NOTE_2}</h5>
                        <p className="borderBottomCss m-4"></p>
                        
                        <p className="text-left ml-4 mt-4">{commonLabels.HOME_NOTE_3}</p>
                        <p className="text-left ml60">{commonLabels.HOME_NOTE_3_1}</p>
                        <p className="text-left ml60">{commonLabels.HOME_NOTE_3_2}</p>
                        <p className="text-left ml60">{commonLabels.HOME_NOTE_3_3}</p>
                        <p className="text-left ml60">{commonLabels.HOME_NOTE_3_4}</p>
                        <p className="text-left ml60">{commonLabels.HOME_NOTE_3_5}</p>
                        <p className="text-left ml-4">{commonLabels.HOME_NOTE_4}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_4_1} </p>
                        <p className="text-left ml-4">{commonLabels.HOME_NOTE_5}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_1} </p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_2} </p>
                        <p className="text-left ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_1}</p>
                        <p className="text-left ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_2}</p>
                        <p className="text-left ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_3}</p>
                        <p className="text-left ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_4}</p>
                        <p className="text-left ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_5}</p>
                        <p className="text-left ml90">{squarebullet} {commonLabels.HOME_NOTE_5_2_6}</p>
                        
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_3}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_4}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_5}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_5_6}</p>
                        
                        <p className="text-left ml-4">{commonLabels.HOME_NOTE_6}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_1}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_2}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_3}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_4}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_5}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_6_6}</p>

                        <p className="text-left ml-4">{commonLabels.HOME_NOTE_7}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_1}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_2}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_3}</p>
                        <p className="text-left ml60"><span className="f20">•</span> {commonLabels.HOME_NOTE_7_4}</p>
                    </div>
                </div>
            </div>
        </div> 
    )
};